/** @format */

import React, {useState, useEffect} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import ProductCard from './productCard.js';
import {Typography} from '@material-ui/core';
import ProductStyles from './styles';
import TextField from '@material-ui/core/TextField';
import {styled} from '@material-ui/core';
// Material UI Icons
import SearchIcon from '@material-ui/icons/Search';
import AnimateHeight from '../../animateheight/index.js';
import {TopCopy, BottomCopy, Headers} from './copy.js';
const API_TAG_BLACKLIST = ['new', 'sale', 'showOnHome'];
const UI_TAG_BLACKLIST = ['color'];

const SearchBar = styled(TextField)({
  fontSize:"6rem",
  padding:"0 16rem 8rem",
  '& .MuiInputBase-root': {
    fontSize:'6rem'
  },
  '& .MuiSvgIcon-root': {
    width:'8rem',
    height:'8rem'
  }

}, {name: 'search-bar'});

const Spacer = styled('div')(
  {
    height:'8rem'
  },
  {name: 'Spacer'}
);


export class FilterObj {
  title = '';
  vals = [];

  constructor(filterTitle, filterVals = []) {
    this.title = filterTitle;
    filterVals.length > 1
      ? (this.vals = this.vals.concat(filterVals))
      : this.vals.push(filterVals[0]);
  }

  addVal(newVal) {
    this.vals.push(newVal);
  }

  removeVal(valName) {
    this.vals.splice(this.vals.indexOf(valName), 1);
  }
}

function parseQuery(search) {
  const query = new URLSearchParams(search);
  let queryFilters = [];

  for (const key of query.keys()) {
    let sanitizedKey = key;
    if (key.startsWith('tags.')) {
      sanitizedKey = key.split('.')[1];
    }
    queryFilters.push(
      new FilterObj(sanitizedKey, query.getAll(key)[0].split(','))
    );
  }
  return queryFilters;
}

const store = {};
const Products = ({userData}) => {
  const [filters, setFilters] = useState([]);
  const [filterTags, setFilterTags] = useState({});
  const [searchResults,setSearchResults] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const {membership} = userData;
  const {category} = useParams();
  const hist = useHistory();
  const loc = useLocation();
  const [queryStr, setQueryStr] = useState('');
  const EXTRA_TAGS = encodeURIComponent('&nsg=false&tags.showOnHome="true"');
  useEffect(() => {
    // if(navigator.userAgent === "ReactSnap") {
    //   return;
    // }
    fetch(
      '/api/items/tags/all?filter=color,colorName,weight,material,diameter&filterInclusion=true'
    )
      .then(resp => resp.json())
      .then(data => {
        let dataCopy = data;
        let usableTagsObj = {};
        for (const tagName in dataCopy) {
          if (!API_TAG_BLACKLIST.includes(tagName)) {
            usableTagsObj[tagName] = dataCopy[tagName];
          }
        }
        setFilterTags(usableTagsObj);

        const tempFilters = [];
        for (const tagName in usableTagsObj) {
          let newFilterObj = new FilterObj(tagName);
          for (const val of usableTagsObj[tagName]) {
            newFilterObj.addVal(val);
          }
          tempFilters.push(newFilterObj);
        }
        setFilters(tempFilters);
        setSelectedFilters(parseQuery(loc.search));
        let queryString = loc.search.slice(1);
        if(queryString.indexOf('gclid')>=0) {
          hist.replace('/shop');
          queryString = '';
        }
        handleDialogClose(queryString);
      });
  }, []);

  function handleDialogClose(passedQueryStr) {
    if(category) {
      let string = getCategory(category);
      let materialPattern = /material=[^&]*/;
      if (materialPattern.test(passedQueryStr)) {
        passedQueryStr.replace(/(?<=material=)[^&]+/, string);
      } else {
        passedQueryStr += '&tags.material='+string;
      }
    }
    const queryIsEncoded = containsEncodedComponents(passedQueryStr);
    fetch(
      `/api/items/tags?q=${
        (queryIsEncoded ? passedQueryStr : encodeURIComponent(passedQueryStr)) +
        EXTRA_TAGS
      }`
    )
      .then(resp => resp.json())
      .then(data => {
        data.sort((a,b)=>(a.displayIndex < b.displayIndex) ? 1 : -1);
        data.reverse();
        let tmp = data.filter(item=>{
          if(item.tags.material === 'PLA Sub' && item.active !== true) {
            return true
          } else if(item.tags.material !== 'PLA Sub') {
            return true;
          } else {
            return false
          }
          
        })

        setSearchResults(tmp);
        if (loc.search.slice(1) !== passedQueryStr & !category) {
          updateSelectedFilters(passedQueryStr);
          hist.replace({
            pathname: loc.pathname,
            search: passedQueryStr,
          });
        }
      });
  }

  const getCategory = cat => {
    switch(cat) {
      case 'merch': 
        return 'tshirt,Poster';
      case 'upgrades':
        return 'nozzles,Tubing,parts';
      default:
        return cat;
    }
  }

  useEffect(() => {
    updateSelectedFilters(queryStr);
  }, [queryStr,membership]);

  function updateSelectedFilters(passedQuery) {
    setSelectedFilters(parseQuery(passedQuery));
  }

  const filterResults = (e) => {
    const str = e.target.value;
    if(!store.original) {
      store.original = [...searchResults];
    }
    if(str === '') {
      return setSearchResults(store.original);
    }
    let tmp = [...store.original].filter(item=>{
      return(item.name.toLowerCase().indexOf(str.toLowerCase()) >=0 )
    });
    setSearchResults(tmp);
  }
  
  // Slightly modified version from here:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent
  function containsEncodedComponents(x) {
    // ie ?,=,&,/ etc
    return x !== decodeURIComponent(x);
  }

  return (
    <>
      <Headers category={category} />
      <Grid container spacing={2} justifyContent="center" style={{paddingTop:'18rem'}}>
        <Grid item sm={12}>
          <TopCopy category={category} />
        </Grid>
        <SearchBar
            InputProps={{
              startAdornment: <SearchIcon htmlColor="#A4AAB8" />,
            }}
            variant="outlined"
            placeholder="Search all products"
            id="search"
            size="small"
            fullWidth={true}
            autoFocus={true}
            onChange={filterResults}
          />
        <Grid item sm={12}>&nbsp;</Grid>
        <Grid container spacing={4} justifyContent="center" style={{minHeight:'100vh'}}>
            {searchResults.map(item => {
              return <ProductCard key={item.id} {...item} />;
            })}
            {searchResults.length % 2 !== 0 && (
              <Grid item s={1} lg={3}>
                <div style={{width: 135}}>&nbsp;</div>
              </Grid>
            )}
          </Grid>
        {/* <AnimateHeight>
          <Grid container spacing={4} justifyContent="center">
            {searchResults.map(item => {
              return <ProductCard key={item.id} {...item} />;
            })}
            {searchResults.length % 2 !== 0 && (
              <Grid item s={1} lg={3}>
                <div style={{width: 135}}>&nbsp;</div>
              </Grid>
            )}
          </Grid>
        </AnimateHeight> */}
        <Grid item sm={12}>
        <Spacer />
        <BottomCopy category={category} /> 
        </Grid>
      </Grid>
    </>
  );
};

export default Products;
