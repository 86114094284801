/** @format */

import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
// Custom components
// Material UI
import Button from '@material-ui/core/Button';
import {styled} from '@material-ui/core/styles';

//#region Styled Components

const StyledBtn = styled(({bgColor, txtColor, ...other}) => (
  <Button {...other} />
))(
  {
    alignItems: 'center',
    backgroundColor: props => props.bgColor ?? '#eee',
    border: '1px solid transparent',
    borderRadius: '4px',
    color: props => props.txtColor ?? 'blue',
    display: 'flex',
    fontSize: '1.75rem',
    fontWeight: '500',
    height: props => props.custheight ?? '6rem',
    justifyContent: 'center',
    width: props => props.custwidth ?? '28.25rem',
    '&:hover': {
      backgroundColor: props => props.bgColor ?? '#eee',
      border: props => `1px solid ${props.txtColor ?? 'blue'}`,
    },
    '& .MuiButton-label': {
      fontFamily: 'Barlow Condensed, Roboto, sans-serif',
      fontSize: props => props.custfontsize ?? '2.75rem',
      fontWeight: props => props.custfontweight ?? '300',
    },
  },
  {name: 'banner-btn'}
);

//#endregion

export default function BannerBtn(props) {
  const {
    bgColor = '#eee',
    btnVariant = 'contained',
    colorVariant = '',
    custHeight,
    custWidth,
    fontSize,
    fontWeight,
    label = '',
    to = '/',
    txtColor = 'blue',
    ...otherProps
  } = props;
  const [altBgColor, setAltBgColor] = useState('');
  const [altTxtColor, setAltTxtColor] = useState('');

  useEffect(() => {
    switch (colorVariant) {
      case 'black':
        setAltBgColor('#000');
        setAltTxtColor('#fff');
        break;
      case 'blue':
        setAltBgColor('#0054dd');
        setAltTxtColor('#fff');
        break;
      case 'orange':
        setAltBgColor('#f44305');
        setAltTxtColor('#fff');
        break;
      case 'yellow':
        setAltBgColor('#ffc905');
        setAltTxtColor('#000');
        break;
      default:
        setAltBgColor(bgColor);
        setAltTxtColor(txtColor);
        break;
    }
  }, []);

  return (
    <Link to={to}>
      <StyledBtn
        bgColor={altBgColor}
        disableElevation
        disableRipple
        custheight={custHeight}
        custfontsize={fontSize}
        custfontweight={fontWeight}
        custwidth={custWidth}
        txtColor={altTxtColor}
        variant={btnVariant}>
        {props.children}
      </StyledBtn>
    </Link>
  );
}
