/** @format */

import React from 'react';
import Shop from '../../components/shop';
import ShopMobile from '../../components/mobile/products';

const ShopPage = (props) => {
  const {isMobile} = props;
  return (
    <>
      {isMobile?
        <ShopMobile {...props}/>:
        <Shop {...props}/>}
    </>
  );
};

export default ShopPage;
