/** @format */

import React, {useState} from 'react';
// Material UI Components
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
// Custom imports
import SubmitButton from '../styled/submit-button';
import StyledInput from '../styled/input';
// Styles
import ModalStyles from './styles';

export default function ForgotPasswordForm(props) {
  const {formSwitch = () => {}} = props;
  const [email, setEmail] = useState(null);
  const [emailHasAccount, setEmailHasAccount] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const pgStyles = ModalStyles();

  const submitEmail = e => {
    e.preventDefault();

    const body = {
      email: email,
    };

    fetch('/api/auth/password/reset', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(resp => {
      if (!resp.ok) {
        if (resp.status === 404) {
          setEmailHasAccount(false);
        } // else generic error
      } else {
        setEmailHasAccount(true);
      }
      setIsFormSubmitted(true);
      //return resp.json();
    });
  };

  function EmailSent(props) {
    return (
      <>
        <Typography variant="h3" className={pgStyles.title} noWrap>
          Email Sent
        </Typography>
        <Typography className={pgStyles.bodyText}>
          We have sent you a reset password link on your registered email.
        </Typography>
        <SubmitButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={e => formSwitch('login')}
        >
          Sign In
        </SubmitButton>
      </>
    );
  }

  function NoEmailFound(props) {
    return (
      <>
        <Typography variant="h3" className={pgStyles.title} noWrap>
          Wrong Email
        </Typography>
        <Typography className={pgStyles.infoText}>
          The email "{email}" doesn't match any of our PrintBed accounts. Would
          you like to{' '}
          <Link
            href="#"
            onClick={e => formSwitch('register')}
            className={pgStyles.infoText}
            underline="always"
          >
            create an account
          </Link>{' '}
          with us?
        </Typography>
        <SubmitButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={e => {
            setEmailHasAccount(true);
            setIsFormSubmitted(false);
          }}
        >
          Try Again
        </SubmitButton>
      </>
    );
  }

  return (
    <>
      {isFormSubmitted ? (
        emailHasAccount ? (
          <EmailSent />
        ) : (
          <NoEmailFound />
        )
      ) : (
        <>
          <Typography variant="h3" className={pgStyles.title} noWrap>
            Forgot Password
          </Typography>
          <Typography className={pgStyles.infoText}>
            Enter the email associated with your account and we'll send an email
            with instructions to reset your password.
          </Typography>
          <form className={pgStyles.form} noValidate onSubmit={submitEmail}>
            <StyledInput
              className="input"
              disableUnderline={true}
              required={true}
              fullWidth={true}
              id="email"
              name="email"
              type="email"
              placeholder="Email address"
              autoComplete="email"
              errorText="Invalid email address"
              showValidIcon={false}
              onChange={e => setEmail(e.target.value)}
            />
            <SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Send
            </SubmitButton>
          </form>
        </>
      )}
    </>
  );
}
