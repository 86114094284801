/** @format */

import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {detect} from 'detect-browser';
// Custom components
import DescModalBtn from '../desc-modal';
import MiniOption from './miniOption.js';
import QtySelect from '../select.js';
import SelectedMinis from './selectedMinis.js';
import {SirvViewer} from '../image-viewer.js';
import SubmitButton from '../../styled/submit-button.js';
// Feather Icons
import {ArrowLeft, ShoppingCart} from 'react-feather';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';
// Styles
import ItemStyles from '../styles';

const QTY_LIMIT_ARRAY = Array(20).fill(0);

//#region Styled Comps

const StyledTxt = styled(Typography)(
  {
    fontSize: '4rem',
    fontWeight: '500',
    '&.MuiTypography-h5': {
      fontSize: '6rem',
      fontWeight: '600',
    },
  },
  {name: 'styled-txt'}
);

//#endregion

export default function Minis(props) {
  const BROWSER = detect();
  const pgStyles = ItemStyles();
  const {baseItem, cartUtils, individualMinis, itemId} = props;
  const {addItemToCart=()=>{}} = cartUtils || {};
  const [selectedMinis, setSelectedMinis] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const history = useHistory();
  function handleMiniQtyChange(targetMini, changeAmnt) {
    let tempMinis = [...selectedMinis];
    if (changeAmnt > 0) {
      tempMinis.push(targetMini);
    } else if (changeAmnt < 0) {
      if (tempMinis.includes(targetMini)) {
        tempMinis.splice(tempMinis.indexOf(targetMini), 1);
      }
    }

    setSelectedMinis(tempMinis);
  }

  function getSelectedCount(item) {
    if (!selectedMinis.includes(item)) {
      return 0;
    } else {
      return selectedMinis.filter(i => i === item).length;
    }
  }

  function getSelectedColorNames() {
    let returnStr = '';
    for (let i = selectedMinis.length; i > 0; i--) {
      const item = selectedMinis[i - 1];
      returnStr = returnStr.concat(item.tags.colorName);
      if (i - 1 > 0) {
        returnStr = returnStr.concat(', ');
      }
    }
    return returnStr;
  }

  function isSelected(item) {
    return selectedMinis.includes(item);
  }

  const browserPadding = ['safari', 'crios', 'ios', 'chrome'];
  return (
    <div
      className={clsx(
        pgStyles.wrapper,
        browserPadding.includes(BROWSER) ? 'safari-pad' : ''
      )}
      key={'item-wrapper-' + itemId}>
      <IconButton
        className={pgStyles.backArrow}
        onClick={() => {
          history.goBack();
        }}>
        <ArrowLeft color="#3d3d3d" />
      </IconButton>
      <div className={pgStyles.imgs}>
        <SirvViewer currentItem={baseItem} />
      </div>
      <Paper className={pgStyles.details} elevation={0}>
        <div className={pgStyles.topTitles}>
          <div className={pgStyles.titles}>
            <StyledTxt variant="h5" className="color">
              Minis Bundle
            </StyledTxt>
          </div>
          <StyledTxt variant="h5" className="price">
            ${(baseItem.price / 100.0).toFixed(2)}
          </StyledTxt>
        </div>
        <DescModalBtn
          desc={baseItem.desc}
          wrapperProps={{
            style: {
              marginTop: '-3rem',
              marginBottom: '2rem',
            },
          }}
        />
        <StyledTxt variant="h5">Select 5 colors:</StyledTxt>
        <div className={pgStyles.list + ' minis'}>
          {individualMinis.map((item, index) => {
            return (
              <MiniOption
                handleOptionSelect={(targetMini, changeAmnt) => {
                  handleMiniQtyChange(targetMini, changeAmnt);
                }}
                isSelected={isSelected(item)}
                isDisabled={
                  item.qoh <= 0 ||
                  (selectedMinis.length >= 5 && !isSelected(item))
                }
                item={item}
                key={item.id}
                totalRollsSelected={selectedMinis.length}
                thisRollSelectedCount={getSelectedCount(item)}
              />
            );
          })}
        </div>
        <StyledTxt variant="h5">
          Selected:&nbsp;
          <StyledTxt component="span">{getSelectedColorNames()}</StyledTxt>
        </StyledTxt>
        <SelectedMinis
          handleRollClick={targetMini => handleMiniQtyChange(targetMini, -1)}
          selectedRolls={selectedMinis}
        />
        <QtySelect
          curVal={quantity}
          onSelect={e => setQuantity(e)}
          vals={QTY_LIMIT_ARRAY}
          wrapperProps={{
            style: {
              marginTop: '4rem',
            },
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}>
          <SubmitButton
            className={pgStyles.addToCartButton}
            disabled={!(selectedMinis.length === 5)}
            endIcon={<ShoppingCart size={16} />}
            onClick={e => {
              addItemToCart({
                id: baseItem.id,
                quantity: parseInt(quantity),
                options: {
                  material: 'MINI',
                  weight: '200g',
                  diameter: '1.75mm',
                  color: selectedMinis
                    .map(mini => mini.tags.colorName)
                    .join(', '),
                  temps: selectedMinis.map(mini => mini.data.temp),
                },
                sides: selectedMinis.map(mini => mini.id),
              });
            }}
            variant="contained">
            Add to Cart
          </SubmitButton>
        </div>
      </Paper>
    </div>
  );
}
