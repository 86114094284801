/** @format */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    fontSize: '2em',
    minHeight: 900,
    backgroundColor: '#eee',
  },
  breadCrumb: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    flex: 1,
    position:'relative',
    margin: 'auto',
    width: '80%',
    minHeight: 800,
    backgroundColor: 'white',
    borderRadius: 16,
    padding: '10px 100px',
    '& iframe': {
      position:'absolute',
      top:'10%',
      left:'5%',
      width:'90%',
      height:'80%'
    }
  },
  tabs: {
    width: '100%',
    fontWeight: 'bold',
    borderBottom: '1px solid #eee',
  },
}));

const CardsPromo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <h1> </h1>
        <img data-src="https://cdn.sunsh1n3.com/home/cards/CollectibleCards_Web.jpg" class="Sirv" alt="Cards V2" />
      </Paper>
    </div>
  );
};

export default CardsPromo;
