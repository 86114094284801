/** @format */

import React, {useState} from 'react';
// Material UI Components
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
// Custom imports
import {SunValidatedInput} from '@sunsh1n3/sun-validated-input';
import ModalStyles from './styles';
// Cookies
import {useSnackbar} from 'notistack';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function ConvertEmailToUserId(email) {
  let userId = email.replaceAll(/\W|\s/g, '_');
  return userId;
}

export default function LoginForm(props) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const pgStyles = ModalStyles();
  const {enqueueSnackbar} = useSnackbar();

  const {formSwitch = () => {}} = props;

  const submitLogin = (e, v) => {
    e.preventDefault();
    let convertedEmail = ConvertEmailToUserId(email);

    const body = {
      username: convertedEmail,
      password,
    };
    fetch('/api/auth/login', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(resp => {
        if (!resp.ok) {
          throw resp;
        }
        return resp.json();
      })
      .then(user => {
        localStorage.setItem('user', JSON.stringify(user));
        const {jwt} = user;
        if (jwt) {
          cookies.set('JWT', jwt, {
            path: '/',
            sameSite: 'strict',
            secure: true,
          });
        } else {
          cookies.remove('JWT');
        }
        props.closeModal();
        window.location.reload();
      })
      .catch(e => {
        enqueueSnackbar('Bad username or password.', {variant: 'error'});

        // console.log('e: ', e);
        return e;
      });
    return false;
  };

  function forgotPassword(e) {
    formSwitch('forgot-password');
  }

  function register(e) {
    formSwitch('register');
  }
  return (
    <div className={pgStyles.formDiv + ' login'}>
      <Typography variant="h3" className="title">
        Sign In
      </Typography>
      <Typography className="form-switch-txt">
        New user?{' '}
        <Link onClick={register} className="register link">
          Create an account
        </Link>
      </Typography>
      <form className="form-wrapper" noValidate onSubmit={submitLogin}>
        <SunValidatedInput
          className="input"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="email"
          name="email"
          placeholder="Email"
          autoComplete="email"
          showValidIcon={false}
          autoFocus
          onChange={e => setEmail(e.target.value)}
        />
        <SunValidatedInput
          className="input"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="password"
          name="Password"
          placeholder="Password"
          autoComplete="password"
          type="password"
          showValidIcon={false}
          overrideValidation={true}
          onChange={e => setPassword(e.target.value)}
        />
        <FormControlLabel
          className="remember-me"
          control={
            <Checkbox
              className="rm-checkbox"
              value="remember"
              color="primary"
            />
          }
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          size="large"
          variant="contained"
          className="submit"
          onClick={submitLogin}
        >
          Sign In
        </Button>
        <Button
          variant="text"
          className="forgot-pwd-link"
          onClick={forgotPassword}
        >
          Forgot Password
        </Button>
      </form>
    </div>
  );
}
