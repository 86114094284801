/** @format */

import React from 'react';
// Custom components
// Material UI
import Typography from '@material-ui/core/Typography';
// Styled components
import SavedInfoCard from '../styled/saved-info-card';
// Styles
import ShippingStyles from './styles.js';

export default function AddressCard(props) {
  const {shippingInfo} = props;
  const pgStyles = ShippingStyles();
  return (
    <SavedInfoCard title={shippingInfo.name}>
      <div className={pgStyles.addrLines}>
        <Typography variant="inherit">
          {shippingInfo.address.line1},&nbsp;
        </Typography>
        {shippingInfo.address.line2 ? (
          <Typography variant="inherit">
            {shippingInfo.address.line2}, &nbsp;
          </Typography>
        ) : null}
        <div className="combined">
          <Typography variant="inherit">
            {shippingInfo.address.city},&nbsp;
          </Typography>
          <Typography variant="inherit">
            {shippingInfo.address.state}&nbsp;
          </Typography>
          <Typography variant="inherit">
            {shippingInfo.address.postal_code}
          </Typography>
        </div>
      </div>
    </SavedInfoCard>
  );
}
