/** @format */

import React from 'react';
// Custom components
// Material UI
import Avatar from '@material-ui/core/Avatar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/styles';
// Styles

const BannerImg = withStyles({
  root: {
    height: '10rem',
    marginLeft: '2rem',
    width: '10rem',
  },
})(Avatar);

const MobileBannerImg = withStyles({
  root: {
    height: '25rem',
    marginLeft: '6rem',
    width: '25rem',
  },
})(Avatar);

const StyledText = withStyles({
  root: {
    color: '#fff',
    flexGrow: '1',
    fontSize: '2.0rem',
    fontWeight: '600',
    marginLeft: '2rem',
    marginRight: '6.5rem',
  },
})(Typography);

const StyledWrapper = withStyles(
  {
    root: {
      backgroundColor: 'transparent',
      borderRadius: 'inherit',
      height: '100%',
      minHeight: '0',
      minWidth: '0',
      padding: '0',
      width: '100%',
    },
    message: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      width: '100%',
    },
  },
  {name: 'styled-wrapper'}
)(SnackbarContent);

export default function BannerContent(props) {
  const {text = '', image='', isMobile} = props;

  return (
    <StyledWrapper
      elevation={0}
      message={
        <>
          {isMobile?<MobileBannerImg src={"data:image/png;base64,"+image}></MobileBannerImg>:<BannerImg src={"data:image/png;base64,"+image} />}
          <StyledText align="left" variant="body1">
            {text}
          </StyledText>
        </>
      }
    />
  );
}
