/** @format */

import React, {useEffect, useState} from 'react';
// Material UI Component Imports
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
// Feather icons
import {ShoppingCart} from 'react-feather';
// Material UI Icons
// Styling import
import {DrawerStyles} from './styles';
// Custom imports
import CartItems from './cart';
import EmptyCart from './empty';

export default function Cart(props) {
  const pgStyles = DrawerStyles();
  const {cartData, cartUtils, location} = props;
  const history = useHistory();
  const [showCart, setShowCart] = useState(location.pathname === '/cart');
  useEffect(() => {
    let unlisten = history.listen(location => {
      setShowCart(location.pathname === '/cart');
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(()=>{
    if (process.env.NODE_ENV === 'production') {
      try{
        if(showCart) {
          const {grandTotal=0} = cartUtils?.getTotals(cartData).grandTotal
            window.gtag("event", "view_cart", {
              currency:"USD",
              value:Number(grandTotal),
              items:cartData.map((item=>{
                  return { item_id: item.itmeId,
                    item_name:item.name,
                    item_category:item.options.material,
                    price:Number(item.price),
                    quantity:item.qty}
              }))
            });
          }
      } catch(e) {}
    }
  },[showCart]);

  return (
    <>
      <Button onClick={() => history.push('/cart')}>
        <Badge badgeContent={cartData.length} overlap="rectangular" color="secondary">
          <ShoppingCart color="#fff" />
        </Badge>
      </Button>
      <Drawer
        open={showCart}
        anchor="right"
        PaperProps={{className: pgStyles.paper}}>
        {cartUtils?.itemCount > 0 ? (
          <CartItems
            cartData={cartData}
            cartUtils={cartUtils}
            close={() => {
              history.push('/');
            }}
            hasCheckoutButton={true}
          />
        ) : (
          <EmptyCart
            close={() => {
              history.push('/');
            }}
          />
        )}
      </Drawer>
    </>
  );
}
