/** @format */

import {makeStyles} from '@material-ui/core/styles';

const BestSellersStyle = makeStyles({
  wrapper: {
    height: 'min-content',
    width: '100%',
    display:'flex',
    flexDirection:'column',
    alignContent:'center',
    alignItems:'center'
  }
});

export default BestSellersStyle;
