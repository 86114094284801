/** @format */

import React from 'react';
// Custom components
// Material UI
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// Styles
import {StepCardStyles} from './styles';

export default function StepCard(props) {
  const pgStyles = StepCardStyles();
  const {
    onClick = () => {},
    srcPath = '',
    stepName = 'Plastic',
    stepNum = 0,
  } = props;

  const w = Math.round(window.innerWidth * 0.33 * window.devicePixelRatio);
  return (
    <div className={pgStyles.wrapper}>
      <div className={pgStyles.titleWrapper}>
        <Typography className={pgStyles.stepNum}>
          Step 0{stepNum}:&nbsp;
        </Typography>
        <Typography className={pgStyles.stepName}>{stepName}</Typography>
      </div>
      <Card className={pgStyles.card} onClick={e => onClick(e)} elevation={0}>
        <CardMedia
          className={pgStyles.img}
          component="img"
          image={srcPath + `?w=${w}`}
        />
      </Card>
    </div>
  );
}
