/** @format */

import React from 'react';
import './mtickerStyles.css';

const shuffleArray = array => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

const TEMP_IMG_NAMES = shuffleArray([
  '10.jpeg',
  '11.jpeg',
  '12.jpeg',
  '13.jpeg',
  '14.jpeg',
  '15.jpeg',
  '16.jpeg',
  '17.jpeg',
  '18.jpeg',
  '19.jpeg',
  '2.jpeg',
  '20.jpeg',
  '21.jpeg',
  '22.jpeg',
  '23.jpeg',
  '24.jpeg',
  '25.jpeg',
  '26.jpeg',
  '27.jpeg',
  '28.jpeg',
  '29.jpeg',
  '3.jpeg',
  '30.jpeg',
  '31.jpeg',
  '32.jpeg',
  '33.jpeg',
  '34.jpeg',
  '35.jpeg',
  '36.jpeg',
  '37.jpeg',
  '38.jpeg',
  '39.jpeg',
  '4.jpeg',
  '40.jpeg',
  '41.jpeg',
  '42.jpeg',
  '43.jpeg',
  '44.jpeg',
  '45.jpeg',
  '46.jpeg',
  '47.jpeg',
  '48.jpeg',
  '49.jpeg',
  '5.jpeg',
  '50.jpg',
  '51.png',
  '52.png',
  '6.jpg',
  '7.jpeg',
  '8.jpeg',
]);

function ManualTickerRow(props) {
  const {row = 0} = props;
  return (
    <div className="marquee">
      <ul className="content" key="visible-marq">
        <div className="item" key="item-1">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-2">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 1]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-3">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 2]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-4">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 3]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-5">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 4]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-6">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 5]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-7">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 6]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-8">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 7]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-9">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 8]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-10">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 9]
            }?w=600`}
          />
        </div>
      </ul>
      <ul aria-hidden="true" className="content" key="hidden-marq">
        <div className="item" key="item-1">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-2">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 1]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-3">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 2]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-4">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 3]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-5">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 4]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-6">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 5]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-7">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 6]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-8">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 7]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-9">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 8]
            }?w=600`}
          />
        </div>
        <div className="item" key="item-10">
          <img
            alt=""
            src={`https://cdn.sunsh1n3.com/Images/ticker/${
              TEMP_IMG_NAMES[row * 10 + 9]
            }?w=600`}
          />
        </div>
      </ul>
    </div>
  );
}

export default function ManualTicker(props) {
  return (
    <div className="enable-animation">
      <ManualTickerRow key="ticker-row-0" row={0} />
      <ManualTickerRow key="ticker-row-1" row={1} />
      <ManualTickerRow key="ticker-row-2" row={2} />
      <ManualTickerRow key="ticker-row-3" row={3} />
      <ManualTickerRow key="ticker-row-4" row={4} />
    </div>
  );
}
