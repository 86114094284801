import {
  isValidEmail,
  isValidPassword,
  isValidEmailEvent,
  isValidPasswordEvent,
  containsNumber,
  containsCapital,
  containsLowercase,
  containsSpecialChar,
  containsExcludedChar,
  containsEnoughChars,
} from './inputValidation.js';

const SunValidator = {
  isValidEmail: isValidEmail,
  isValidPassword: isValidPassword,
  isValidEmailEvent: isValidEmailEvent,
  isValidPasswordEvent: isValidPasswordEvent,
  containsNumber: containsNumber,
  containsCapital: containsCapital,
  containsLowercase: containsLowercase,
  containsSpecialChar: containsSpecialChar,
  containsExcludedChar: containsExcludedChar,
  containsEnoughChars: containsEnoughChars,
};

export default SunValidator;
export {
  SunValidator,
  isValidEmail,
  isValidPassword,
  isValidEmailEvent,
  isValidPasswordEvent,
  containsNumber,
  containsCapital,
  containsLowercase,
  containsSpecialChar,
  containsExcludedChar,
  containsEnoughChars,
};
