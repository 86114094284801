/** @format */

import React, {forwardRef, useState} from 'react';
import Cropper from 'react-easy-crop';
// Custom components
import ConfirmAvatarModal from './modal';
import FilePicker from '../styled/file-picker';
import StyledBtn from '../styled/btn-blue';
// Material UI
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
// Styles
import AvatarEditStyles from './styles';

const MAX_ZOOM = 5;
const MIN_ZOOM = 0.5;

const AvatarEditor = forwardRef((props, ref) => {
  const pgStyles = AvatarEditStyles();
  const {userId} = props;
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [croppedImg, setCroppedImg] = useState(null);
  const [cropAreaPixels, setCropAreaPixels] = useState(null);
  const [imgZoom, setImgZoom] = useState(1);
  const [imgUrl, setImgUrl] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditor, setShowEditor] = useState(false);

  //#region Image cropping methods
  let fr = new FileReader();
  fr.addEventListener('load', e => {
    setImgUrl(e.target.result);
    setShowEditor(true);
    console.log(e.target);
  });

  function updateUserFile(file) {
    fr.readAsDataURL(file);
  }

  function handleCropComplete(croppedArea, croppedAreaPixels) {
    // console.log('croppedArea: ', croppedArea);
    setCropAreaPixels(croppedAreaPixels);
  }

  async function handleUpload(e) {
    setCroppedImg(getCroppedImg(imgUrl, cropAreaPixels));
    setShowConfirmModal(true);
  }
  //#endregion

  function handleModalClose(confirmUpload = false) {
    if (confirmUpload) {
      fetch(`/api/user/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          avatar: croppedImg,
        }),
      })
        .then(resp => resp.json())
        .then(
          result => {
            window.location.reload();
            console.log('Avatar change success');
          },
          error => {
            console.log('error: ', error);
          }
        );
    } else {
    }
    setShowConfirmModal(false);
  }

  return (
    <div className={pgStyles.wrapper}>
      {showEditor ? (
        <div className={pgStyles.hiddenWrapper}>
          <div className={pgStyles.cropWrapper}>
            <Cropper
              aspect={1}
              crop={crop}
              cropShape="round"
              image={imgUrl}
              maxZoom={MAX_ZOOM}
              minZoom={MIN_ZOOM}
              objectFit="vertical-cover"
              onCropChange={setCrop}
              onCropComplete={handleCropComplete}
              restrictPosition={false}
              showGrid={false}
              zoom={imgZoom}
            />
          </div>
          <Typography variant="body1">Zoom</Typography>
          <Slider
            defaultValue={1}
            max={MAX_ZOOM}
            min={MIN_ZOOM}
            onChange={(e, val) => {
              setImgZoom(val);
            }}
            step={0.1}
          />
          <StyledBtn
            className={pgStyles.btnUpload}
            onClick={handleUpload}
            variant="contained"
          >
            Upload
          </StyledBtn>
        </div>
      ) : null}
      <FilePicker getFile={file => updateUserFile(file)} ref={ref} />
      <ConfirmAvatarModal
        avatarImg={croppedImg}
        isOpen={showConfirmModal}
        onSelect={handleModalClose}
      />
    </div>
  );
});

export default AvatarEditor;

const AVATAR_WIDTH = 150;
const AVATAR_HEIGHT = 150;
/**
 * This function was adapted from the one in the demos of https://github.com/ricardo-ch/react-easy-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
function getCroppedImg(imageSrc, pixelCrop) {
  let img = new Image();
  img.src = imageSrc;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // set canvas dimensions
  canvas.width = AVATAR_WIDTH;
  canvas.height = AVATAR_HEIGHT;

  // draw cropped image and store data.
  ctx.drawImage(
    img,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    AVATAR_WIDTH,
    AVATAR_HEIGHT
  );

  // As Base64 string
  return canvas.toDataURL('image/png');

  // As a blob
  // return new Promise((resolve) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file));
  //   }, 'image/png');
  // });
}
