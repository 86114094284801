/** @format */

import {makeStyles} from '@material-ui/core/styles';

const PrefilledPaymentStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '30vw',
    width: '100%',
    padding: '1rem 0',
  },
  header: {
    marginBottom: '3rem',
  },
  cardLogos: {
    display: 'flex',
    flexDirection: 'row',
  },
  newPaymentSourceButton: {
    color: '#174cff',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2rem 0',
    width: '95%',
    '&$newPaymentSourceOpen $plusIcon': {
      animation: '$plusToX 500ms',
      transform: 'rotateZ(45deg)',
    },
    '&$newPaymentSourceClosed $plusIcon': {
      animation: '$xToPlus 500ms',
      transform: 'rotateZ(0deg)',
    },
  },
  confirmButton: {
    backgroundColor: '#049cfc',
    color: 'white',
    width: '40%',
    fontSize: '2rem',
    '&:hover': {
      color: '#049cfc',
    },
  },
  backButton: {
    backgroundColor: '#f7f7f7',
    color: '#049cfc',
    width: '30%',
    marginLeft: '1rem',
    border: '2px solid #049cfc',
    fontSize: '2rem',
  },
  newPaymentSourceOpen: {},
  newPaymentSourceClosed: {},
  plusIcon: {},
  '@keyframes plusToX': {
    '0%': {
      transform: 'translateY(0) rotateZ(0deg)',
    },
    '50%': {
      transform: 'translateY(-5px)rotateZ(22.5deg)',
    },
    '100%': {
      transform: 'translateY(0) rotateZ(45deg)',
    },
  },
  '@keyframes xToPlus': {
    '0%': {
      transform: 'translateY(0) rotateZ(45deg)',
    },
    '50%': {
      transform: 'translateY(-5px)rotateZ(22.5deg)',
    },
    '100%': {
      transform: 'translateY(0) rotateZ(0deg)',
    },
  },
});

export default PrefilledPaymentStyles;

export const PaymentSourceElementStyles = makeStyles({
  card: {
    marginRight: '3rem',
    position: 'relative',
  },
  cardActionArea: {
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minHeight: '10rem',
  },
  cardIcon: {
    marginRight: '3rem',
    borderRadius: '2px',
    height: '4rem',
    width: '7rem',
  },
  icons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 70,
  },
  delete: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  checkCircleOutlineIcon: {
    color: '#00dd22',
    opacity: 0,
  },
  visibleIcon: {
    opacity: 1,
  },
});
