/** @format */

import {makeStyles} from '@material-ui/styles';

const MenuDrawerStyles = makeStyles({
  accountInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flexGrow: 1,
    fontSize: '6rem',
  },
  backButton: {
    justifyContent: 'flex-start',
  },
  toolbarButton: {
    // color: '#FFF',
    // opacity: '50%',
    flexGrow: '1',
    display: 'flex',
    '& .MuiSvgIcon-root':{
      fontSize:'12rem',
      marginright:'2rem'
    }
  },
  bottomLinks: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flexGrow: 1,
    fontSize: '6rem',
    '& a':{
      fontSize: '6rem',
    }
  },
  drawerContent: {
    height: '100%',
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawerPaper: {
    borderRadius: '0 32px 32px 0',
    width: '80vw',
  },
  email: {
    color: '#0099ff',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    fontWeight: '600',
    fontSize:'9rem'
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 7,
    justifyContent: 'space-around',
  },
  mainLinks: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flexGrow: 4,
    fontSize: '6rem',
  },
  signInLink: {
    fontSize: '9rem',
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    padding: 0,
    '& Button span svg': {
      fontSize: '3.5rem',
    },
  },
});

export default MenuDrawerStyles;
