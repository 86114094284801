/** @format */

import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
// Material UI Component imports
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material UI icon imports
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// Component imports
import SubmitButton from '../styled/submit-button';
// Style imports
import GoodCheckoutStyles from './styles';
// Cart utils
import CartUtils from '../../../utils/cartUtils';

const CheckoutResult = props => {
  const history = useHistory();
  const pgStyles = GoodCheckoutStyles();
  const {status, cartUtils=new CartUtils()} = props;

  const [icon, setIcon] = useState(null);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [buttonText, setButtonText] = useState('');

  useEffect(() => {
    switch (status) {
      case 'success':
        cartUtils.clear();
        setIcon(<CheckCircleOutlineIcon className="icon good" />);
        setTitle('Thanks for your order!');
        setText(
          'Your order is complete. Please check your email for more details.'
        );
        setButtonText('Continue Shopping');
        localStorage.removeItem('coupon');
        break;
      case 'processing':
        setIcon(<CircularProgress className="icon processing" size="12rem" />);
        setTitle('Processing...');
        setText('');
        setButtonText('');
        break;
      case 'error':
      default:
        setIcon(<HighlightOffIcon className="icon bad" />);
        setTitle('Something went wrong');
        setText(
          'Something went wrong while submitting your order. Please try again.'
        );
        setButtonText('Try again');
        break;
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <div className={pgStyles.root}>
      <div className={pgStyles.wrapper}>
        <div>
          {icon}
          <Typography variant="h1">{title}</Typography>
          <Typography variant="h3">{text}</Typography>
        </div>
        {status !== 'processing' && (
          <SubmitButton
            className="btn"
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => history.push('/')}
          >
            {buttonText}
          </SubmitButton>
        )}
        {/* {!isLoggedIn && <RegisterAfterCheckout customerInfo={customerInfo} /> */}
      </div>
    </div>
  );
};

export default CheckoutResult;
