/** @format */

import {makeStyles} from '@material-ui/core/styles';

export const Styles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '6rem',
    backgroundColor: '#eee',
  },
  paper: {
    flex: 1,
    margin: 'auto',
    height: '100%',
    backgroundColor: '#f6f6f6',
    // borderRadius:16,
    padding: '10px',
  },
});

export default Styles;
