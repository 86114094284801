/** @format */

import React, {useState, useEffect} from 'react';
// npm Package imports
import parse from 'html-react-parser';
import LinesEllipsis from 'react-lines-ellipsis';
// Material UI Components
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Custom imports
import ExtraInfo from './extra-info';
import ItemTabs from './tabs/tabsContainer';
import ItemStyles from './styles';
import ImageViewer from './image-viewer.js';
import {useSnackbar} from 'notistack';

const TAG_WHITELIST = ['color', 'diameter', 'weight'];

const Item = props => {
  const pgStyles = ItemStyles();
  const {
    itemId,
    options,
    relatedItems,
    history,
    cartUtils,
    userData,
    isSpool,
    spoolOutOfStock,
    selectedItem: currentItem,
  } = props;

 

  const {addItemToCart = () => {}} = cartUtils || {};
  const [quantity, setQuantity] = useState(1);
  const [increment, setIncrement] = useState(1);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [selectedOptions, setSelectedOptions] = useState(
    props.defaultOptions || {}
  );
  const [plasticSpool,setPlasticSpool] = useState(false);
  const [descHeight, setDescHeight] = useState(0);
  const {membership} = userData ?? false;
  const optSet = new Set();

  //#region Initialization
  // Populating optSet
  relatedItems.forEach(i => {
    let setKey = '';
    for (const tag of TAG_WHITELIST) {
      setKey += i.tags[tag].toLowerCase();
    }
    optSet.add(setKey);
  });

  useEffect(() => {
    const descContainer = document.getElementById('desc-container');
    if (descContainer) {
      if (descContainer.style.lineHeight === '') {
        const lineHeightStr =
          window.getComputedStyle(descContainer)['line-height'];
        const lineHeightNum = lineHeightStr.slice(0, lineHeightStr.length - 2);
        const desiredHeight = Number(lineHeightNum) * 3.0;
        setDescHeight(desiredHeight);
      }
    }

    if (
      currentItem.tags.color === 'Leftovers' &&
      currentItem.tags.material.indexOf('Mini') < 0
    ) {
      setQuantity(2);
      setIncrement(2);
    } else {
      setIncrement(1);
      setQuantity(1);
    }
  }, [relatedItems, currentItem]);

  //#endregion

  const handleOptionSelect = (option, choice) => {
    const newOptions = {...selectedOptions};
    newOptions[option] = choice;
    if (option === 'color') {
      newOptions['colorName'] =
        options.colorName[options.color.indexOf(choice)];
    }
    setSelectedOptions(newOptions);
    let newUrl = `/shop/${newOptions.material}/${newOptions.color}-${newOptions.diameter}-${newOptions.weight}/`;

    history.replace(newUrl, {
      newOptions: newOptions,
      pathname: newUrl,
    });
  };

  const getDescFirstParagraphHtml = () => {
    let pTagStopIndex = currentItem.desc.indexOf('</p>') + 4;
    return currentItem.desc.slice(0, pTagStopIndex);
  };

  const getDescFirstParagraphText = () => {
    let html = getDescFirstParagraphHtml();
    return html.slice(html.indexOf('>') + 1, html.lastIndexOf('<'));
  };

  const scrollToTabs = () => {
    document.getElementById('scroll-div').scrollIntoView();
    window.scrollBy(0, -vhToPx(10));
  };

  const vhToPx = vh => {
    let oneVh = (window.innerHeight / 100.0).toFixed(2);
    return oneVh * vh;
  };

  //#region Tab handlers/vars
  const [tabVal, setTabVal] = useState('Description');
  const tabTitles = ['Description', 'Additional Information'];
  const tabContents =
    currentItem.desc !== undefined
      ? [
          <Typography variant="body1" component="div" id="full-desc">
            {parse(
              currentItem.desc.replace(
                '</ul>',
                `<li><span>Recommended Temp: ${currentItem.data.temp}° C</span></li></ul>`
              )
            )}
          </Typography>,
          <Typography variant="body1" component="div" id="extra-info">
            {parse(currentItem.extraInfo)}
          </Typography>,
        ]
      : [];

  const handleTabChange = (event, newVal) => {
    setTabVal(newVal);
  };

  const goToDescTab = event => {
    setTabVal('Description');
    scrollToTabs();
  };
  //#endregion

  return (
    <div className={pgStyles.wrapper} key={'item-wrapper-' + itemId}>
      <div className={pgStyles.overviewDiv}>
        <ImageViewer currentItem={currentItem} className={pgStyles.imgViewer} isSpool={isSpool}/>
        <div className="item-details">
          <Typography variant="h1" className={pgStyles.title} >{currentItem.name}</Typography>
          <LinesEllipsis
            text={getDescFirstParagraphText()}
            maxLine={6}
            className={
              pgStyles.descContainer + ' MuiTypography-root MuiTypography-body2'
            }
            id="desc-container"
            style={{
              minHeight: descHeight,
            }}
          />
          <Typography
            variant="body2"
            className={pgStyles.readMore}
            onClick={goToDescTab}>
            Read More
          </Typography>

          {Object.entries(options)
            .filter(opt => TAG_WHITELIST.includes(opt[0]))
            .map((option, index, array) => {
              if (option[0] === 'weight' || option[0] === 'colorName')
                return null;
              return (
                <div key={option[0]}>
                  <div
                    className={
                      pgStyles.optionWrapper +
                      (index === array.length - 1 ? ' last' : '')
                    }>
                    <Typography variant="body1" className="opt-name">
                      {option[0]}
                      <span className="color-name">
                        {option[0] === 'color'
                          ? ' : ' + currentItem.tags.colorName
                          : null}
                      </span>
                    </Typography>
                    {option[1].map(choice => {
                      const isSelected =
                        selectedOptions[option[0]].toLowerCase() ===
                        choice.toLowerCase();
                      let setComparatorKey = '';
                      for (const tag of TAG_WHITELIST) {
                        if (tag === option[0]) {
                          setComparatorKey += choice.toLowerCase();
                        } else {
                          setComparatorKey +=
                            selectedOptions[tag].toLowerCase();
                        }
                      }
                      const isDisabled = !optSet.has(setComparatorKey);
                      return (
                        <Tooltip
                          key={choice}
                          className={
                            pgStyles.optionContainer +
                            (isSelected === true ? ' selected' : '')
                          }
                          title={choice}
                          placement="top">
                          <span className="test">
                            {option[0] === 'color' ? (
                              <Button
                                className={
                                  pgStyles.optionChoice +
                                  ' color' +
                                  (isSelected === true ? ' selected' : '')
                                }
                                onClick={() => {
                                  handleOptionSelect(option[0], choice);
                                }}
                                style={{padding: 0}}
                                disabled={isDisabled}>
                                <div
                                  className="color-div"
                                  style={{
                                    backgroundImage: `url('https://cdn.sunsh1n3.com/Images/printbed/${encodeURIComponent(
                                      choice.replace("'", '')
                                    )}.jpg?w=${Math.round(
                                      60 * window.devicePixelRatio
                                    )}')`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                  }}>
                                  &nbsp;
                                </div>
                              </Button>
                            ) : (
                              <Button
                                className={
                                  pgStyles.optionChoice +
                                  (isSelected === true ? ' selected' : '')
                                }
                                onClick={() =>
                                  handleOptionSelect(option[0], choice)
                                }
                                disabled={isDisabled}>
                                {choice}
                              </Button>
                            )}
                          </span>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {/* {isSpool &&
            <FormControlLabel
              control={
                <Checkbox
                  checked={plasticSpool}
                  onChange={(e)=>{
                    setPlasticSpool(e.target.checked);
                  }}
                  name="plasticSpool"
                  color="primary"
                  disabled={spoolOutOfStock}
                  className={pgStyles.checkBox}
                />
              }
              label={spoolOutOfStock?<span>Ship with Plastic Spool <sup style={{color:'Red', fontSize:'1rem'}}>* Out of stock</sup></span>:"Ship with Plastic Spool"}
            />} */}
        </div>
        <div className={pgStyles.saleInfoWrapper}>
          <div className={pgStyles.saleInfo}>
            <Typography variant="h4" className={pgStyles.price}>
              ${(currentItem.price / 100).toFixed(2)}
            </Typography>
            <Typography variant="body1" className={pgStyles.availabilityTxt}>
              Availability:&nbsp;
              <span
                className={
                  pgStyles.itemStock +
                  ' ' +
                  (currentItem.qoh > 0 ? 'in-stock' : 'out-of-stock')
                }>
                {currentItem.qoh > 0 ? ' In Stock' : ' Out of Stock'}
              </span>
            </Typography>
            {currentItem.tags.material === 'PLA Sub' && membership === false && (
              <Typography variant="body1" className={pgStyles.availabilityTxt}>
                *Premium Exclusive
              </Typography>
            )}
            <div className={pgStyles.quantitySelection}>
              <section className="qty-btns">
                <Button
                  onClick={() => {
                    if (quantity > 1) {
                      setQuantity(quantity - increment);
                    } else {
                      setQuantity(1);
                    }
                  }}
                  className={pgStyles.quantityButton + ' left'}
                  disabled={!(quantity > 1)}>
                  &ndash;
                </Button>
                <TextField
                  className="qty"
                  InputProps={{disableUnderline: true}}
                  value={quantity}
                  onChange={e => {
                    let qty = Number(e.target.value);
                    let newQty = Math.floor(qty / increment) * increment;
                    if (newQty >= increment) setQuantity(newQty);
                    else setQuantity(increment);
                    if (newQty !== qty) {
                      enqueueSnackbar(
                        `This product must be ordered in multiples of ${increment}`,
                        {variant: 'info'}
                      );
                    }
                  }}
                />
                <Button
                  onClick={() => setQuantity(quantity + increment)}
                  className={pgStyles.quantityButton + ' right'}
                  disabled={!(quantity < currentItem.qoh)}>
                  +
                </Button>
              </section>
              {currentItem.tags.material === 'PLA Sub' && membership === false ? (
                <Button
                  className={pgStyles.addToCartButton}
                  disableElevation
                  fullWidth
                  onClick={e => {
                    history.push('/premium/');
                  }}
                  variant="contained">
                  Subscribe to Premium
                </Button>
              ) : (
                <Button
                  className={pgStyles.addToCartButton}
                  disabled={
                    !(currentItem.qoh > 0) ||
                    (currentItem.tags.material === 'PLA Sub' &&
                      membership === false)
                  }
                  disableElevation
                  fullWidth
                  onClick={e => {
                    e.preventDefault();
                    let tmp = {
                      id: currentItem.id,
                      quantity: quantity,
                      options: selectedOptions,
                    };
                    if (currentItem.sides) {
                      tmp.sides = currentItem.sides;
                    }
                    if(plasticSpool) {
                      if (!tmp.sides) {
                        tmp.sides =[];
                      }
                      tmp.sides.push("66ce1144e47902bb27e8f0ad");
                    }

                    addItemToCart(tmp);
                  }}
                  variant="contained">
                  Add to Cart
                </Button>
              )}
            </div>
            <div className={pgStyles.extraInfo}>
              {/* <div className="info-spacer"></div> */}
              <ExtraInfo />
            </div>
          </div>
        </div>
      </div>
      <ItemTabs
        className={pgStyles.infoTabs}
        titles={tabTitles}
        contents={tabContents}
        curTab={tabVal}
        changeFunc={handleTabChange}
      />
    </div>
  );
};

export default Item;
