/** @format */

import React, {useState, useRef, useEffect} from 'react';
// React Router imports
import {useHistory} from 'react-router-dom';
// Material UI Component Importers
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';

// Styling imports
import CheckoutStyles from './styles';
// Custom Component Imports
import OrderSummary from '../orderSummary';
import PaymentDetails from '../paymentDetails';
import ShippingDetails from '../shippingDetails';
import CheckoutResult from '../checkoutResult';
import PrefilledShippingDetails from '../prefilledShippingDetails';
import PrefilledPaymentDetails from '../prefilledPaymentDetails';
import {useSnackbar} from 'notistack';
import paypalButtons from '../paypalButtons/subscriptions';

let isPOBox = false;
let isInternational = false;
const Checkout = props => {
  const {userData, updateMemberShip} = props;
  const pgStyles = CheckoutStyles();
  const history = useHistory();
  const {enqueueSnackbar,closeSnackbar} = useSnackbar();
  const {cartUtils} = props;
  const paypalButtonsRef = useRef(null);
  const membershipItem =[{
    price:"25.00",
    qty:1,
    itemId:"printbed_premium",
    id:"printbed_premium",
    name:'PrintBed Membership',
    type:'item',
    images:"https://cdn.sunsh1n3.com/Images/misc/premium.jpg",
    options:{"material":"subscription",weight:"1kg",color:"subscription",diameter:'1.75mm'},
    data:{freeGround:true}
  }];
  // Unused but kept for reference
  // const steps = ['Shipping Details', 'Payment Details', 'Order Complete'];
  const [checkoutStep, setCheckoutStep] = useState(0);
  const [localPickup,setLocalPickup] = useState('ground');
  const [isShippingPrefilled, setIsShippingPrefilled] = useState(false);
  const [isPaymentPrefilled, setIsPaymentPrefilled] = useState(
    userData.loggedIn
  );
  const [paymentSources, setPaymentSources] = useState([]);
  const [defaultPaymentSourceId, setDefaultPaymentSourceId] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [customerInfo, setCustomerInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    address: '',
    zipCode: '',
    city: '',
    phone: '',
    note: '',
  });
  const curForm = useRef(null);
  // eslint-disable-next-line
  const [showFormErrs, setShowFormErrs] = useState(false);
  const [chosenAddress,setChosenAddress] = useState(100);
  const [addressValidated, setAddressValidated] = useState(false);
  const [openAddressValidation,setOpenAddressValidation] = useState(false);
  const [addresses,setAddresses] = useState([]);
  const [checkoutStatus, setCheckoutStatus] = useState('error');
  const [saleId, setSaleId] = useState('');
  const [enabled,setEnabled] = useState(false);
  const [activeSub,setActiveSub] = useState(false);

  useEffect(()=>{
    if(userData.loggedIn !== true) {
      history.push('/premium/'); //user must be logged in.
    }
    fetch(`/api/items/?q=${encodeURIComponent('options.material=PLA+Sub&active=true')}`)
    .then(resp => {
      return resp.json();
    })
    .then(resp => {
      // console.log(resp)
      if(resp && resp[0]) {
        const {qoh}= resp[0]
        if(qoh>5) {
          setEnabled(true);
        } else {
          const action = key => (
            <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'2rem', marginRight:'2rem'}}>
                Dismiss
            </Button>
            );
          enqueueSnackbar('We are currently not taking subscriptions due to high demand. Please check back soon.', {variant: 'error', persist: true,action});
        }
      }
    });
  },[]);



  useEffect(()=>{
    fetch('/api/subscription', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(resp => {
      return resp.json();
    }).then(result => {
        if (result.valid === true) {
          const action = key => (
            <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'2rem', marginRight:'2rem'}}>
                Dismiss
            </Button>
            );
          enqueueSnackbar('You already have an active subscription.', {variant: 'error', persist: true,action});
          setActiveSub(true);
        }
    });
  },[]);


  useEffect(() => {
    fetch(`/api/user/${userData.userId}/billing`)
      .then(resp => {
        return resp.json();
      })
      .then(user => {
        if (user && user.shipping) {
          const {email, shipping, phone, metadata} = user;
          const {name, address} = shipping;
          const {city, country, line1, postal_code} = address;
          const {userId} = metadata;
          const [firstName, lastName] = name.split(' ');
          setCustomerInfo({
            firstName,
            lastName,
            name,
            email,
            city,
            country,
            phone,
            address: line1,
            zipCode: postal_code,
            userId,
          });
          cartUtils.zip = postal_code;
          setIsShippingPrefilled(true);
          setDefaultPaymentSourceId(user.default_source);
        }
      });
    fetch(`/api/user/${userData.userId}/payments`)
      .then(resp => {
        return resp.json();
      })
      .then(sources => {
        if (sources && sources.length > 0) {
          sources = sources.filter(source=>source.object!=='card');
          setPaymentSources(sources.slice(0, 4));
          setIsPaymentPrefilled(true);
        }
      });
  // eslint-disable-next-line
  }, [userData.userId]);

  const updateCustomerData = newCustomerInfo => {
    let newVals = {};
    for (const key in newCustomerInfo) {
      newVals[key] = newCustomerInfo[key].value;
    }
    if(newVals.isPOBox === true) {
      // alert('this is a pobox');
      if(isPOBox === false) {
        isPOBox = true;
        const action = key => (
              <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'2rem', marginRight:'2rem'}}>
                  Dismiss
              </Button>
        );
        enqueueSnackbar('Shipping to a PO box will not be next day delivery.  This will ship Fedex Smart post and we won\'t have a delivery timeline for you.  In addition your package may take an extra day to ship.', {variant: 'info', persist: true,action});
      }
    }

    if(newCustomerInfo.international === true) {
      if(isInternational === false) {
        isInternational = true;
        const action = key => (
              <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'2rem', marginRight:'2rem'}}>
                  Dismiss
              </Button>
        );
        enqueueSnackbar('Shipping to Canada will not be next day delivery.  This will ship Fedex Ground and we calculate your shipping rate.  In addition your package may take an extra day to ship.', {variant: 'info', persist: true,action});
      }
    }

    cartUtils.zip = newVals.zipCode;
    cartUtils.international = newCustomerInfo.international;
    setCustomerInfo(newVals);

    
  };

  const validateShippingInfo = (newInfo) => {
    const {city, country, address, address2, zipCode} = newInfo;
    let data = {
      streetLines: [address,address2],
      city,
      country,
      zip:zipCode
    }
    fetch(`/api/shipping/address/validate`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(res => {
        if(res.length>0){
          setAddresses(res);
          setOpenAddressValidation(true);
        } else {
          setAddressValidated(true);
          setCustomerInfo(newInfo);
          setCheckoutStep(1);
        }
      });
  }

  const handleResultButton = event => {
    switch (checkoutStatus) {
      case 'success':
        history.push('/');
        break;
      case 'error':
      default:
        setCheckoutStep(0);
        break;
    }
  };

  useEffect(() => {
    if (checkoutStatus === 'success') {
      cartUtils.clear();
    }
    // eslint-disable-next-line
  }, [checkoutStatus]);

  const validateAddress = ()=> {
    setAddressValidated(true);
    if(chosenAddress !== 100) {
      let tmp = curForm.current.getCustomerInfo();
      tmp = {...tmp,...addresses[chosenAddress]}
      setCustomerInfo(tmp);
    }
    setCheckoutStep(1);
    setOpenAddressValidation(false);
  }

  const submit = async event => {
    if(enabled === false) {
      return;
    }
    event.preventDefault();
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    if (curForm.current.isFormValid()) {
      switch (checkoutStep) {
        case 0:
          if(addressValidated === true || cartUtils.localPickup === true) {
            setCustomerInfo(curForm.current.getCustomerInfo());
            setCheckoutStep(1);
          } else {
            validateShippingInfo(curForm.current.getCustomerInfo())
          }
          
          
          break;
        case 1:
          setCheckoutStatus('processing');
          const payment = await curForm.current.getPayment();
          if (payment.token || payment.source) {
            const body = {
              payment,
              customerInfo: {serviceCode: cartUtils.serviceCode, ...customerInfo},
              couponName: '',
              paymentService: 'stripe',
              // shippingQuote:cartUtils.getShippingQuote()
              // When adding a 'save card' button, this is where that will get passed:
              // saveCard: true (use state instead of true)
            };

            if(cartUtils.international === true) {
              body.customerInfo.shippingQuote = cartUtils.shipping;
            }

            if(cartUtils.localPickup === true) {
              body.customerInfo.serviceCode = 0;
            }

            fetch('/api/subscription', {
              method: 'POST',
              body: JSON.stringify(body),
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(async resp => {
              setCheckoutStatus(resp.ok ? 'success' : 'error');
              if(resp.ok === false) {
                throw (await resp.text());
              } else {
                return resp.json();
              }
            })
            .then(result => {
              setSaleId(result.id);
              try {
                updateMemberShip();
              } catch(e) {}
            })
            .then(setCheckoutStep(2))
            .catch(e=>{
              enqueueSnackbar(e, {variant: 'error'});

            });
          }
          break;
        case 3:
          break;
        case 2:
        default:
          break;
      }
    } else {
      enqueueSnackbar('Form is invalid', {variant: 'error'});
    }
  };

  const getStep = (step) => {
    if(step === 0) {
        if(localPickup === 'local') {
          return <div>
            <Typography variant="h4" style={{marginBottom: '3rem'}}>
              Local Pickup
            </Typography>
            <ShippingDetails
                updateFunc={updateCustomerData}
                localPickup={true}
                ref={curForm}
              />
            <div style={{ position:'relative',overflow:'hidden',width:'100%', height: 400, paddingRight:'1.6vw'}}>
              <div style={{ position:'relative',overflow:'hidden',width:'100%', height: '100%'}}>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2125.945782756238!2d-82.9770120926557!3d42.66914823023878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824e1b50269aacd%3A0x3ded9f50052e811!2sPrintBed!5e0!3m2!1sen!2sus!4v1649176231630!5m2!1sen!2sus"
                  style={{border:0,position: 'absolute', top:0, left:0, bottom:0,right:0, width:'100%',height:'100%'}} 
                  allowFullScreen="" 
                  loading="lazy" 
                  referrerPolicy="no-referrer-when-downgrade"
                  title="GoogleMaps">
                </iframe>
              </div>
            </div>
          </div>
        } else {
          return <div>
            <Typography variant="h4" style={{marginBottom: '3rem'}}>
              Shipping Details
            </Typography>
            {isShippingPrefilled ? (
              <PrefilledShippingDetails
                customerInfo={customerInfo}
                userId={userData.userId}
                ref={curForm}
              />
            ) : (
              <ShippingDetails
                updateFunc={updateCustomerData}
                ref={curForm}
              />
            )}
          </div>
        }
    } else if(step === 1) {
      return <div>
      <Typography variant="h4">Payment Details</Typography>
      {isPaymentPrefilled ? (
        <PrefilledPaymentDetails
          paymentSources={paymentSources}
          setPaymentSources={setPaymentSources}
          defaultPaymentSourceId={defaultPaymentSourceId}
          ref={curForm}
          goBack={() => setCheckoutStep(0)}
          customerInfo={customerInfo}
          userData={userData}
        />
      ) : (
        <PaymentDetails
          customerInfo={customerInfo}
          goBack={() => setCheckoutStep(0)}
          ref={curForm}
        />
      )}
      <div style={{padding:'1rem 0'}}>
        <Typography>
          Other Payment Methods
        </Typography>
        <br /><br />
        <div
          id="paypal-subscription-container"
          ref={paypalButtonsRef}
          style={{paddingRight:'3rem'}}
        />
      </div>
    </div>
    }
  }

  useEffect(() => {
    if (checkoutStep === 1 && paypalButtonsRef.current?.childElementCount === 0) {
      paypalButtons({shippingData:customerInfo,enqueueSnackbar});
    }
  }, [checkoutStep]);

  return (
    <div className={pgStyles.wrapper} key="checkout-wrapper">
      {checkoutStep !== 2 ? (
        <form onSubmit={submit} className={pgStyles.formContainer} noValidate>
          <div className="details">
            {getStep(checkoutStep)}
          </div>
          <div className="summary">
            <OrderSummary setCouponCode={setCouponCode} localPickup={localPickup} {...props} cartData={membershipItem} />
            {checkoutStep === 0 ? (
              <>
             <div className={pgStyles.pickupContainer}>
                <ButtonBase
                  focusRipple
                  className={localPickup==='ground'?pgStyles.pickUp:pgStyles.pickUpInActive}
                  onClick={()=>{
                    cartUtils.setStandardShipping();
                    setLocalPickup('ground');
                    // updateTotals();
                  }}
                >
                  <Grid container>
                    <Grid item xs={4}>
                      <LocalShippingIcon />
                    </Grid>
                    <Grid item xs={8} style={{textAlign:'left'}}>
                      <Typography variant="h5">Ship</Typography>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:'center'}}>
                      <Typography>FedEx SmartPost</Typography>
                      <Typography>2-7 Business Days</Typography>
                    </Grid>
                  </Grid>
                </ButtonBase>
                <ButtonBase
                  focusRipple
                  className={localPickup==='local'?pgStyles.pickUp:pgStyles.pickUpInActive}
                  onClick={()=>{
                    cartUtils.setLocalPickup();
                    setLocalPickup('local');
                    // updateTotals();
                  }}
                >
                  <Grid container>
                    <Grid item xs={4}>
                      <LocalMallIcon />
                    </Grid>
                    <Grid item xs={8} style={{textAlign:'left'}}>
                      <Typography variant="h5">Pickup</Typography>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:'center'}}>
                      <Typography>Mon - Fri</Typography>
                      <Typography>8:30am - 4pm</Typography>
                      <Typography variant="caption">Detroit Metro Area</Typography>
                    </Grid>
                  </Grid>
                </ButtonBase>
              </div> 
              <Button type="submit" disabled={activeSub} className={pgStyles.stepButton}>
                Payment Details
              </Button>
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                open={openAddressValidation}
                onClose={()=>{}}
              >
                <Paper style={{minWidth:600}}>
                  <Grid container>
                    <RadioGroup style={{width:'100%',padding:20}} aria-label="Address" name="validatedAddress" value={chosenAddress} onChange={(e)=>{
                      setChosenAddress(Number(e.target.value));
                    }}>
                      <Grid item xs={4}>
                        <FormControlLabel value={100} color="primary" control={<Radio />} label="Use Original:" />
                      </Grid>
                      <Grid item xs={8} style={{textAlign:'left'}}>
                        <Typography >{customerInfo.address}</Typography>
                        <Typography >{customerInfo.address2}</Typography>
                        <Typography >{customerInfo.city}</Typography>
                        <Typography >{customerInfo.zipCode}</Typography>
                      </Grid>
                    {addresses.map((addr,index)=>{
                      return (<>
                                <Grid item xs={4}>
                                  <FormControlLabel value={index} control={<Radio color="primary"/>} label="Use Suggested:" />
                                </Grid>
                                <Grid item xs={8} style={{textAlign:'left'}}>
                                  <Typography >{addr.address}</Typography>
                                  <Typography >{addr.address2}</Typography>
                                  <Typography >{addr.city}</Typography>
                                  {/* <Typography >{addr.state}</Typography> */}
                                  <Typography >{addr.zipCode}</Typography>
                                </Grid>
                              </>);
                    })}
                    </RadioGroup>
                    <Grid item xs={12}>
                    <Button type="button" onClick={validateAddress} className={pgStyles.stepButton}>
                      Confirm
                    </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Modal>
              </>
            ) : (
                <Button type="submit" className={pgStyles.stepButton}>
                  Place Order
                </Button>
            )}
          </div>
        </form>
      ) : (
        <CheckoutResult
          isLoggedIn={userData.loggedIn}
          customerInfo={customerInfo}
          status={checkoutStatus}
          saleId={saleId}
          onClick={handleResultButton}
        />
      )}
    </div>
  );
};

export default Checkout;
