/** @format */

import React from 'react';
// Custom components
// Material UI
import {styled} from '@material-ui/core/styles';

const MINIS_BOX_BACK =
  'https://cdn.sunsh1n3.com/Images/minis/Final_MiniMISC/Box%20Back.png';
const MINIS_BOX_FRONT =
  'https://cdn.sunsh1n3.com/Images/minis/Final_MiniMISC/Box%20Front.png';

//#region Styles
const BoxBack = styled('div')(
  {
    backgroundImage: `url(${MINIS_BOX_BACK}?w=426)`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '392px',
    position: 'relative',
    width: '100%',
    zIndex: '0',
  },
  {name: 'box-back'}
);

const BoxFront = styled('div')(
  {
    backgroundImage: `url(${MINIS_BOX_FRONT}?w=426)`,
    backgroundPosition: 'bottom center',
    backgroundSize: 'auto auto',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    left: '0',
    maxWidth: '100%',
    pointerEvents: 'none',
    position: 'absolute',
    top: '1px',
    width: '100%',
    zIndex: '3',
  },
  {name: 'box-front'}
);

const BulkRoll = styled('img')(
  props => ({
    gridColumn: props.col ?? 'auto',
    maxHeight: 'min-content',
    minWidth: 0,
    // maxWidth: '20%',
    objectFit: 'cover',
    objectPosition: 'top center',
    overflowY: 'visible',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  {name: 'bulk-selected-roll'}
);

const RollWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '100%',
    overflow: 'hidden',
    padding: '15% 10% 10%',
    width: '100%',
  },
  {name: 'roll-wrap'}
);

const RowWrapper = styled('div')(
  {
    display: 'grid',
    gap: '2px',
    gridTemplateColumns: 'repeat(5, 1fr)',
    height: '75%',
    overflowY: 'clip',
    width: '100%',
    zIndex: '2',
    '&.offset': {
      gap: '0',
      height: '25%',
      overflowY: 'visible',
      padding: '0 0.5rem',
      zIndex: '1',
    },
  },
  {name: 'row-wrap'}
);

const Wrapper = styled('div')(
  {
    display: 'flex',
    height: 'auto',
    width: '100%',
  },
  {name: 'bulk-selected-wrap'}
);
//#endregion

export default function SelectedBulkSpool(props) {
  const {handleRollClick = () => {}, selectedRolls = []} = props;
  return (
    <Wrapper>
      <BoxBack>
        <RollWrapper>
          <RowWrapper>
            {selectedRolls
              .slice(0, selectedRolls.length < 6 ? selectedRolls.length : 5)
              .map((item, index) => {
                let indexCalc = (index % 5) + 1;
                let calcCol = `${indexCalc} / ${indexCalc}`;
                return (
                  <BulkRoll
                    col={calcCol}
                    key={`selected-roll-${index}`}
                    onClick={() => handleRollClick(item)}
                    src={
                      // item.imgSrc[1] ??
                      `https://cdn.sunsh1n3.com/Images/minis/Final_MiniSide/Mini_${item.tags.color.replace(
                        /\s/g,
                        ''
                      )}.png`
                    }
                  />
                );
              })}
          </RowWrapper>
          <RowWrapper className="offset">
            {selectedRolls.length < 6
              ? null
              : selectedRolls.slice(5).map((item, index) => {
                  let indexCalc = (index % 5) + 1;
                  let calcCol = `${indexCalc} / ${indexCalc}`;
                  return (
                    <BulkRoll
                      col={calcCol}
                      key={`selected-roll-${index}`}
                      onClick={() => handleRollClick(item)}
                      src={`https://cdn.sunsh1n3.com/Images/minis/Final_MiniSide/Mini_${item.tags.color.replace(
                        /\s/g,
                        ''
                      )}.png`}
                    />
                  );
                })}
          </RowWrapper>
        </RollWrapper>
        <BoxFront />
      </BoxBack>
    </Wrapper>
  );
}
