/** @format */

import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Poll from 'react-polls';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import HeroComp from '../premium/hero';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import {X} from 'react-feather';
import {useHistory} from 'react-router-dom';
  const useStyles = makeStyles(theme => ({
    root: {
      position:'absolute',
      top:0,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width:'100%',
      paddingTop: '2rem',
      paddingBottom: '2rem',
      fontSize: '2em',
      minHeight: 900,
      justifyContent:'center'
    //   backgroundColor: '#eee',
    },
    paper: {
      backgroundColor:'black',
      color:'white',
      marginTop:-60,
      '& article': {
        backgroundColor:'black',
        color:'white',
        '& ul li:first-child': {
          // backgroundColor:'#8ec6ff',
          backgroundImage: 'url("/images/subvote/latte.jpg")'
        },
        '& ul li:nth-child(2) ': {
          // backgroundColor:'#dfcba4',
          backgroundImage: 'url("/images/subvote/dragon_egg.jpg")'
        },
        '& ul li:nth-child(3)': {
          // backgroundColor:'#262626',
          backgroundImage: 'url("/images/subvote/blueberry_pearl.jpg")'
        }
      }
    }
  }));
export default function Vote(props) {
    const {userData} = props;
    const {membership=false} = userData;
    const [pollAnswers,setPollAnswers] = useState(null)
    const pollQuestion = 'What should the next Premium color be?'
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    useEffect(()=>{
        getPoll();
    },[]);

    const getPoll = () => {
        fetch(`/api/subscription/vote`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(resp => resp.json())
            .then(resp => {
                setPollAnswers(resp.options);
            });
    }

    const addVote = (color) => {
        fetch(`/api/subscription/vote`, {
            method: 'POST',
            body: JSON.stringify({color}),
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(async (resp)=>{
            if(resp.ok === false) {
              throw (await resp.text());
            } else {
              return resp.json();
            }
          })
            .then(resp => {
                let tmp  = [...pollAnswers];
                const newPollAnswers = tmp.map(answer => {
                    if (answer.option === color) answer.votes++
                    return answer
                })
                setPollAnswers(newPollAnswers);
            }).catch(e=>{
                enqueueSnackbar(e+' Your vote was not saved.',{ variant: 'error' })
            });
    }

  return <div style={{backgroundColor:'black', height:'100%'}}>
            <IconButton
              style={{position:'absolute',top:0,right:0,color:'white', zIndex:100}}
              color="inherit"
              onClick={() => {
                history.push('/');
              }}
            >
              <X color="#fff" />
            </IconButton>
            <HeroComp showButton={false}/>
            <div className={classes.root}>
                <Container maxWidth="sm">
                <Paper className={classes.paper}>
                    {!pollAnswers && membership && <div>Voting is currently closed.</div>}
                    {pollAnswers && membership && <Poll theme="white" customStyles={{theme:'white',align:'center', questionColor:"#fff"}} question={pollQuestion} answers={pollAnswers} onVote={addVote} /> }
                    {!membership && <div>Looks like you are not a premium member. Either <Link to="/modal/login/">login</Link> with your premium account or <Link to="/premium/">sign up</Link> for premium.</div>}
                </Paper>
                </Container>
            </div>
        </div>;
}
