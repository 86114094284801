/** @format */

import {makeStyles} from '@material-ui/core/styles';

export const PaymentSourceElementStyles = makeStyles({
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '6rem',
    lineHeight: '6rem',
    marginTop: '4rem',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

const PaymentStyles = makeStyles({
  cardInput: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  contBtn: {
    borderRadius: '8px',
    fontSize: '6rem!important',
    padding: '2rem 0',
  },
  label: {
    color: '#666',
    fontSize: '6rem',
    marginBottom: '1rem',
    width: '100%',
    '&.no-lbl': {
      marginBottom: 0,
    },
  },
  newPaymentSourceButton: {
    margin: '4rem 0',
  },
  otherPaymentMethodsText: {
    marginBottom: '3rem',
    fontWeight: 'bold',
  },
  paymentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& .header': {
      marginBottom: '3rem',
    },
    '& .stripeInp': {
      alignItems: 'center',
      backgroundColor: '#f6f6f6',
      border: '2px solid transparent',
      borderRadius: '3px',
      display: 'flex',
      fontSize: '5rem',
      height: '14rem',
      margin: '0 0 2rem 0',
      padding: '2rem',
      '& *': {
        width: '100%',
      },
      '&.focus': {
        borderColor: '#0099ff',
      },
      '&.invalid': {
        borderColor: 'red',
      },
      '&.complete': {
        borderColor: 'green',
      },
    },
  },
  pgWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  submitBtn: {
    marginBottom: '2rem',
    marginTop: '7.75rem',
  },
});

export default PaymentStyles;

export const CARD_OPTIONS = {
  classes: {
    base: 'stripeInp',
    complete: 'complete',
    empty: 'empty',
    focus: 'focus',
    invalid: 'invalid',
  },
  style: {
    base: {
      fontSize: '24px',
      fontWeight: '400',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    },
  },
};
