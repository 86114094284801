/** @format */

import React from 'react';
import {Link, useHistory} from 'react-router-dom';
// Material UI Components
import AppBar from '@material-ui/core/AppBar';
// import Avatar from '@material-ui/core/Avatar';
import ToolBar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

import {Menu} from 'react-feather';
// Custom Components & Styles
import MenuDrawer from '../menuDrawer';
import HeaderStyles from './styles.js';
import Cart from '../cart';
import Breadcrumbs from '../../header/breadcrumb.js';

import {useEffect, useState} from 'react';

const Header = props => {
  const pgStyles = HeaderStyles();
  const {cartData, cartUtils, userData} = props;
  const history = useHistory();
  const [open, setOpen] = useState(history.location.pathname === '/account');

  const openAccountModal = event => {
    if (history.location.pathname === '/') {
      history.push('/modal/login');
    } else if (!history.location.pathname.includes('modal')) {
      history.replace({
        pathname: history.location.pathname + '/modal/login',
        search: history.location.search,
      });
    }
  };

  // const ToolbarButton = (props) => {
  //   return (
  //     <Button
  //       className={pgStyles.toolbarButton}
  //       disableRipple
  //       {...props.btnProps}
  //     >
  //       {props.label}
  //     </Button>
  //   );
  // };

  // if (urlQuery.has('rid')) {
  //   if (!history.location.pathname.includes('modal')) {
  //     history.replace({
  //       pathname: '/modal' + history.location.pathname,
  //       search: history.location.search,
  //     });
  //   }
  // }

  return (
    <AppBar className={pgStyles.header} position="sticky">
      <ToolBar className={pgStyles.toolbar}>
        <Link to="/" className={pgStyles.printBedLogo}>
          <img
            src="https://cdn.sunsh1n3.com/Images/logos/pb-half-2023.svg"
            alt="PrintBed logo"
            className="logo"
            style={{
              paddingBottom: '2rem',
            }}
          />
          {userData.membership && (
            <img
              src={'https://cdn.sunsh1n3.com/Images/logos/pb-premium.webp?h=30'}
              alt="PrintBed logo"
              // className="logo"
              style={{padding: 25}}
            />
          )}
        </Link>
        <div style={{flexGrow: '1'}}></div>
        <Cart
          location={history.location}
          userData={userData}
          cartData={cartData}
          cartUtils={cartUtils}
        />
        {userData.loggedIn === true ? (
          <Button
            style={{color: 'white'}}
            onClick={() => {
              history.push('/account/');
              setOpen(true);
            }}>
            <Avatar
              variant="circular"
              src={userData.avatar || '/icons/default_avatar.svg'}
              className={pgStyles.avatarBtn}
            />
          </Button>
        ) : (
          <Button
            style={{color: 'white'}}
            onClick={() => {
              setOpen(true);
            }}>
            <Menu color="#fff" fontSize="large" />
          </Button>
        )}
        <MenuDrawer
          isDrawerOpen={open}
          userData={userData}
          cartData={cartData}
          cartUtils={cartUtils}
          onClose={setOpen}
        />
      </ToolBar>
      <Breadcrumbs isMobile={true}/>
    </AppBar>
  );
};

export default Header;
