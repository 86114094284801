/** @format */

import React, {useState} from 'react';
// Material UI Components
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';
// MUI Icons
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//#region Styled Components

const AccComp = styled(Accordion)(
  {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
  },
  {name: 'acc-comp'}
);

const AccDetails = styled(AccordionDetails)(
  {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0',
  },
  {name: 'acc-details'}
);

const AccSummary = styled(AccordionSummary)(
  {
    width: '100%',
    '&.Mui-expanded': {
      '& .icon-btn': {
        transform: 'rotate(0)',
      },
    },
    '& .icon-btn': {
      transform: 'rotate(-90deg)',
    },
    '& .Mui-expanded': {
      marginBottom: '0',
      marginTop: '0',
      '& .icon-btn': {
        transform: 'rotate(0)',
      },
    },
  },
  {name: 'tag-title'}
);

const ExpandIcon = styled(ExpandMoreIcon)(
  {
    transform: 'rotate(270deg)',
    '.Mui-expanded &': {
      transform: 'rotate(180deg)',
    },
  },
  {name: 'styled-expand-icon'}
);

const FilterLabel = styled(Typography)(
  {
    margin: '0',
    textTransform: 'capitalize',
    width: '100%',
  },
  {name: 'filter-label'}
);

const FilterWrapper = styled(Grid)(
  {
    width: '100%',
  },
  {name: 'filter-wrapper'}
);

const StyledCheckbox = styled(Checkbox)(
  {
    backgroundColor: '#ebebeb',
  },
  {name: 'styled-checkbox'}
);

//#endregion

export default function Filter(props) {
  const {
    onChange = () => {},
    selectedFilters = [],
    tagKey = '',
    title = '',
    values = [],
  } = props;

  let connectedTitle = title.toLowerCase();

  if (title.includes(' ')) {
    connectedTitle = connectedTitle.replace(' ', '-');
  }

  function isSelectedVal(targetVal) {
    let result = false;
    if (selectedFilters.some(filterObj => filterObj.title === tagKey)) {
      result =
        selectedFilters[
          selectedFilters.indexOf(
            selectedFilters.find(filterObj => filterObj.title === tagKey)
          )
        ].vals.includes(targetVal);
    }
    return result;
  }

  return (
    <FilterWrapper item>
      <AccComp
        className={`filter ${connectedTitle}`}
        elevation={0}
        key={`filter-${connectedTitle}`}
        square>
        <AccSummary
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            className: 'icon-btn',
          }}>
          <FilterLabel variant="h5" align="left">
            {title}
          </FilterLabel>
        </AccSummary>
        <AccDetails>
          {values.map((val, index) => {
            return (
              <FilterOpt
                checked={isSelectedVal(val)}
                key={`${tagKey}-opt-${val}`}
                label={val}
                onChange={() => onChange(tagKey, val)}
                tagKey={tagKey}
                val={val}
              />
            );
          })}
        </AccDetails>
      </AccComp>
    </FilterWrapper>
  );
}

export function FilterOpt(props) {
  const {
    checked = false,
    label = '',
    onChange = () => {},
    tagKey = '',
    val = '',
  } = props;

  return (
    <FormControlLabel
      value={val}
      label={label}
      labelPlacement="end"
      control={
        <Checkbox
          checked={checked}
          checkedIcon={
            <CheckIcon
              style={{
                backgroundColor: '#000',
                borderRadius: '5px',
                color: '#000',
                fill: '#fff',
              }}
            />
          }
          color="primary"
          disableRipple
          icon={
            <CheckBoxOutlineBlankIcon
              style={{
                backgroundColor: '#ebebeb',
                borderRadius: '5px',
                color: '#ebebeb',
                fill: '#ebebeb',
              }}
            />
          }
          onChange={onChange}
          value={val}
        />
      }
    />
  );
}
