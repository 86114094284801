/** @format */

import {makeStyles} from '@material-ui/core/styles';

const ProfileStyles = makeStyles({
  avatar: {
    width: '20rem',
    height: '20rem',
  },
  button: {
    marginBottom: '6rem',
    fontSize:'6rem'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontSize:'6rem'
  },
  title: {
    fontSize: '8rem',
  },
  userInfo: {
    margin: '6rem 0 8rem 0',
  },
  info: {
    marginBottom: '6rem',
    fontSize:'6rem'
  },
});

export default ProfileStyles;
