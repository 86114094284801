/** @format */

import React, {useState} from 'react';
// Feather icons
import {ChevronDown, X} from 'react-feather';
// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
// Styles
import {SelectStyles} from './styles';

export default function QtySelect(props) {
  const pgStyles = SelectStyles();
  const {curVal = 1, onSelect = () => {}, vals = [], wrapperProps = {}} = props;
  const [isOpen, setIsOpen] = useState(false);

  function handleSelection(newVal) {
    setIsOpen(false);
    onSelect(newVal);
  }

  return (
    <div {...wrapperProps}>
      <Button
        className={pgStyles.btn}
        onClick={() => setIsOpen(true)}
        variant="outlined">
        <Typography variant="body1">Qty:&nbsp;</Typography>
        <Typography className="val" variant="body1">
          {curVal}
        </Typography>
        <ChevronDown color="#707070" />
      </Button>
      <Dialog className={pgStyles.wrapper} open={isOpen}>
        <DialogTitle className={pgStyles.titleWrapper}>
          <Typography variant="body1">Qty: </Typography>
          <IconButton onClick={() => setIsOpen(false)} style={{padding: 0}}>
            <X color="#3d3d3d" />
          </IconButton>
        </DialogTitle>
        <List disablePadding>
          {vals.map((num, index) => {
            if (num === 0) {
              return;
            }

            return (
              <ListItem
                className={
                  pgStyles.itemWrapper + (num === curVal ? ' selected' : '')
                }
                key={'qty-' + num}
                disableGutters
                onClick={() => handleSelection(num)}>
                <ListItemIcon
                  className={
                    pgStyles.selectedIcon +
                    (num === curVal ? ' selected' : ' hidden') +
                    ' icon'
                  }>
                  <div id="sel-color">&nbsp;</div>
                </ListItemIcon>
                <ListItemText className={pgStyles.txt}>{num}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Dialog>
    </div>
  );
}
