/** @format */
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const SubmitButton = withStyles({
  root: {
    borderRadius: '8px',
    fontSize: '6rem',
    fontWeight: '400',
    padding: '2rem 0',
    width: '100%',
  },
  contained: {
    backgroundColor: '#1899ff',
    color: '#fff',
  },
  outlined: {
    backgroundColor: '#fff',
    borderColor: '#1899ff',
    color: '#1899ff',
  },
})(Button);

export default SubmitButton;
