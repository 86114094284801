/** @format */

import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core';

const StyledBtn = withStyles({
  root: {
    borderRadius: '4px',
    fontSize: '1.75rem',
    fontWeight: '600',
    height: 'min-content',
    padding: '1rem 2rem',
  },
  contained: {
    backgroundColor: '#1899ff',
    color: '#fff',
  },
  outlined: {
    backgroundColor: '#fff',
    borderColor: '#1899ff',
    color: '#1899ff',
  },
  text: {
    color: '#1899ff',
    fontWeight: 'bold',
    fontSize: 'inherit',
    textTransform: 'uppercase',
  },
})(Button);

export default StyledBtn;
