/** @format */

import React, {useState} from 'react';
import Marquee from 'react-fast-marquee';
// Custom components
// Material UI
import {styled} from '@material-ui/core/styles';

//#region Styled Comps
const ImgWrapper = styled('div')(
  {
    alignItems: 'center',
    borderRadius: '10px',
    display: 'flex',
    filter: 'brightness(33%)',
    flexDirection: 'row',
    height: '100%',
    margin: '0',
    overflow: 'clip',
    padding: '0',
    position: 'relative',
    '&:hover': {
      filter: 'brightness(100%)',
    },
    width: '100%',
  },
  {name: 'ticker-img-wrap'}
);

const StyledImg = styled('img')(
  {
    borderRadius: '10px',
    objectFit: 'cover',
    position: 'absolute',
    width: '100%',
  },
  {name: 'styled-img'}
);

const StyledMarquee = styled(Marquee)(
  {
    display: 'grid',
    gap: '2.5rem',
    gridTemplateColumns: 'repeat(5, 49.5rem)',
    gridTemplateRows: '100%',
    height: '23.67vh',
    overflow: 'clip',
    '& .marquee': {
      gap: '2.5rem',
    },
    '& .overlay': {
      pointerEvents: 'none',
    },
  },
  {name: 'styled-marquee'}
);

const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    gap: '2.5rem',
    position: 'absolute',
    width: '100%',
    zIndex: '0',
  },
  {name: 'ticker-wrap'}
);
//#endregion

const TEMP_IMG_NAMES = [
  'dog',
  'dragon',
  'hands',
  'mask',
  'paint',
  'plant',
  'pumpkin',
  'spider',
  'frog',
  'dog',
  'dragon',
  'hands',
  'mask',
  'paint',
  'plant',
  'pumpkin',
  'spider',
  'skull',
  'dragon',
  'frog',
];

export default function Ticker(props) {
  const {fileNames = TEMP_IMG_NAMES} = props;
  const [countArray, setCountArray] = useState([1, 2, 3, 4]);

  return (
    <Wrapper>
      {countArray.length === fileNames.length / 5
        ? countArray.map((countNum, index) => {
            return (
              <StyledMarquee
                direction="right"
                gradientColor={[0, 0, 0]}
                gradientWidth={100}
                key={`marquee-${countNum}`}>
                {fileNames
                  .slice(index * 5, countNum * 5)
                  .map((imgUrl, subIndex) => {
                    return (
                      <ImgWrapper key={`ticker-img-${subIndex}`}>
                        <StyledImg
                          // key={`ticker-img-${index}`}
                          src={`https://cdn.sunsh1n3.com/Images/ticker/${imgUrl}.png?w=600`}
                        />
                      </ImgWrapper>
                    );
                  })}
              </StyledMarquee>
            );
          })
        : null}
    </Wrapper>
  );
}
