/** @format */

import React from 'react';
// Custom components
import ManualTicker from './manualTicker';
// Material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

//#region Styled Comps
const CustomBtn = styled(Button)(
  {
    backgroundColor: '#fa2',
    border: '2px solid transparent',
    color: '#232f3e',
    margin: '8vh 0 10vh',
    padding: '2rem 6rem',
    zIndex: '1',
    '&:hover': {
      backgroundColor: '#fa2',
      border: '2px solid #000',
    },
    '& .MuiButton-label': {
      fontSize: '3rem',
      fontWeight: '600',
    },
  },
  {name: 'hero-comp-btn'}
);

const CustomTxt = styled(Typography)(
  {
    color: '#fff',
    zIndex: '1',
    '&.header': {
      fontSize: '5rem',
    },
    '&:hover': {
      cursor: 'default',
    },
    '&.sub-title': {
      fontSize: '3rem',
      fontWeight: '500',
      width: '50%',
    },
    '&.title-txt': {
      fontSize: '4.5rem',
      fontWeight: '600',
      marginTop: '10vh',
    },
    '&>sup': {
      fontSize: '1.25rem',
      verticalAlign: 'text-top',
    },
  },
  {name: 'hero-comp-txt'}
);

const PbLogo = styled('img', {label: 'pb-logo'})({
  marginTop: '10vh',
  zIndex: '1',
  width: '16rem',
});

const PremiumLogo = styled('img')(
  {
    margin: '4rem 0',
    zIndex: '1',
  },
  {name: 'premium-logo'}
);

const Wrapper = styled('div')(
  {
    alignItems: 'center',
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    overflow: 'hidden',
    position: 'relative',
  },
  {name: 'hero-comp-wrap'}
);
//#endregion
const Styles = {
  topspool: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '25vw',
  },
  bottomspool: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '25vw',
  },
};

export default function HeroComp(props) {
  const {onClick, showButton = true} = props;
  return (
    <Wrapper>
      <ManualTicker />
      <PbLogo
        src="https://cdn.sunsh1n3.com/Images/logos/pb-white-2023.svg?w=200"
        alt=""
      />
      <PremiumLogo src="https://cdn.sunsh1n3.com/Images/logos/pb-premium.webp?w=552&format=png" />
      {showButton && (
        <>
          <CustomTxt variant='h1' className="header">Premium Filament Subscription Box</CustomTxt>
          <CustomTxt align="center" className="sub-title">
            Premium members receive a tangle-free spool of unique PLA filament each month. Premium colors consist of a wide range of filaments beyond the basic colors, including glow-in-the-dark PLA. Plus, you’ll unlock several additional benefits when you subscribe, allowing you to unleash your 3D creative printing journey.          </CustomTxt>
          <CustomBtn onClick={onClick}>Start your PREMIUM</CustomBtn>
        </>
      )}
      <img style={Styles.topspool} src="/images/spooltop.png" alt="spool" />
      <img
        style={Styles.bottomspool}
        src="/images/spoolbottom.png"
        alt="spool"
      />
    </Wrapper>
  );
}
