/** @format */

import {makeStyles} from '@material-ui/core/styles';

const PaymentStyles = makeStyles({
  cardIcons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: '3rem',
    '& .card-logo': {
      marginRight: '3rem',
      borderRadius: '2px',
      height: '4rem',
      width: '7rem',
      display: 'flex',
    },
    '& .icon': {
      width: '100%',
      height: '100%',
    },
  },
  cardInput: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '4rem',
    marginBottom: '3rem',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.5rem',
    width: '100%',
    color: '#000',
    '&.no-lbl': {
      marginBottom: 0,
    },
  },
  paymentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& .header': {
      marginBottom: '3rem',
    },
    '& .stripeInp': {
      margin: '0',
      fontSize: '1.5rem',
      backgroundColor: '#f6f6f6',
      border: '2px solid #eee',
      borderRadius: '4px',
      padding: '1.75rem 2rem',
      height: '6rem',
      width: '100%',
      '&.invalid': {
        borderColor: 'red',
      },
      '&.complete': {
        borderColor: 'green',
      },
      '& .InputElement': {
        minHeight: '3.5rem',
        width: '100%',
      },
    },
  },
  splitInput: {
    display: 'flex',
    '& div': {
      width: '100%',
    },
  },
});

export default PaymentStyles;

export const CARD_OPTIONS = {
  classes: {
    base: 'stripeInp',
    complete: 'complete',
    empty: 'empty',
    focus: 'focus',
    invalid: 'invalid',
  },
  style: {
    base: {
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    },
  },
};
