/** @format */

import React, {useEffect, useState, useRef} from 'react';
// Material UI
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {useParams, useHistory} from 'react-router-dom';

import Profile from './profile';
import OrderHistory from '../../components/orderHistory/orders';
import PrefilledPaymentDetails from '../../components/prefilledPaymentDetails';
import PrefilledShippingDetails from '../../components/prefilledShippingDetails';
import Subscriptions from '../../components/userAccount/subscriptions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    fontSize: '2em',
    minHeight: 900,
    backgroundColor: '#eee',
  },
  breadCrumb: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  paper: {
    flex: 1,
    margin: 'auto',
    width: '80%',
    minHeight: 800,
    backgroundColor: 'white',
    borderRadius: 16,
    padding: '10px 100px',
  },
  tab: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  tabs: {
    borderBottom: '1px solid #eee',
    width: '100%',
  },
}));

const AccountPg = props => {
  const {userData} = props;
  const classes = useStyles();
  const {tab} = useParams();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(tab);
  const [userDetails, setUserDetails] = useState({});
  const [paymentSources, setPaymentSources] = useState([]);
  const shippingDetailsRef = useRef(null);
  const paymentDetailsRef = useRef(null);

  const handleChange = (event, newValue) => {
    history.replace(`/account/${newValue}`);
  };

  useEffect(() => {
    fetch(`/api/user/${userData.userId}/billing`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(
        result => {
          const {email, name, address, phone, metadata, default_source} =
            result;
          const {userId} = metadata;
          const [firstName, lastName] = name ? name.split(' ') : [];
          const newDetails = {
            firstName,
            lastName,
            name,
            email,
            phone,
            userId,
            defaultSourceId: default_source,
          };
          if (address) {
            const {city, country, line1, postal_code} = address;

            Object.assign(newDetails, {
              city,
              country,
              address: line1,
              zipCode: postal_code,
            });
          }

          setUserDetails(newDetails);
        },
        error => {
          console.log('error: ', error);
        }
      );

    fetch(`/api/user/${userData.userId}/payments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(
        result => {
          setPaymentSources(result);
        },
        error => {
          console.log('error: ', error);
        }
      );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
    // eslint-disable-next-line
  }, [tab]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <div className={classes.breadCrumb}>
          <Typography variant="h4">Account / {activeTab}</Typography>
        </div>
        <TabContext value={activeTab}>
          <TabList
            className={classes.tabs}
            onChange={handleChange}
            indicatorColor="primary"
          >
            <Tab className={classes.tab} label="Account" value="profile" />
            <Tab className={classes.tab} label="Shipping" value="shipping" />
            <Tab className={classes.tab} label="Payment" value="payment" />
            <Tab className={classes.tab} label="Subscriptions" value="subs" />
            <Tab className={classes.tab} label="Orders" value="orders" />
          </TabList>
          <TabPanel value="profile">
            <Profile {...props} />
          </TabPanel>
          <TabPanel value="shipping">
            <PrefilledShippingDetails
              customerInfo={userDetails}
              userId={userData.userId}
              ref={shippingDetailsRef}
              isFormOnSide={true}
            />
          </TabPanel>
          <TabPanel value="payment">
            <PrefilledPaymentDetails
              defaultPaymentSourceId={userDetails.defaultSourceId}
              paymentSources={paymentSources}
              setPaymentSources={setPaymentSources}
              customerInfo={userDetails}
              ref={paymentDetailsRef}
              userData={userData}
            />
          </TabPanel>
          <TabPanel value="subs">
            <Subscriptions
              customerInfo={userDetails}
              paymentDetailsRef={paymentDetailsRef}
              paymentSources={paymentSources}
              selectedPaymentSourceId={userDetails.defaultSourceId}
              setPaymentSources={setPaymentSources}
              userData={userData}
            />
          </TabPanel>

          <TabPanel value="orders">
            <OrderHistory {...props} />
          </TabPanel>
        </TabContext>
      </Paper>
    </div>
  );
};

export default AccountPg;
