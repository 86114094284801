/** @format */

import React, {useEffect, useState} from 'react';
// Custom components
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';
import Separator from './separator';

//#region Styled Comps
const BenefitText = styled(Typography)(
  {
    color: '#fff',
    fontSize: '3.5rem',
    fontWeight: '600',
    '& .subtitle': {
      fontSize: '2.25rem',
      fontWeight: '400',
      marginTop: '1.5rem',
    },
    '&.disabled': {
      color: '#999',
    },
  },
  {name: 'benefit-txt'}
);

const RotatingSpool = styled('img')(
  {
    height: '62vh',
    position: 'absolute',
    top: '-31vh',
    zIndex: '1',
  },
  {name: 'rotating-spool'}
);

const TextGrid = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    marginTop: '6rem',
    width: '100%',
  },
  {name: 'txt-grid'}
);

const TextWrapper = styled('div')(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20vh',
    padding: '0 10vw',
    width: '100%',
    backGroundColor: 'black',
  },
  {name: 'txt-wrap'}
);

const TitleText = styled(Typography)(
  {
    color: '#fff',
    '&.grey': {
      color: '#333',
    },
    '&.sub-title': {
      fontSize: '2.5rem',
      fontWeight: '400',
      color: 'white',
      marginTop: '4rem',
    },
    '&.title': {
      fontSize: '6.25rem',
      fontWeight: '700',
    },
  },
  {name: 'title-txt'}
);

const Wrapper = styled('div')(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'min-content',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: 'black',
  },
  {name: 'benefits-wrap'}
);
//#endregion

const BENEFITS = [
  {
    title: 'Monthly Premium Spool',
    subtitle: 'Early Access Colors',
    disabled: false,
  },
  {
    title: '10% Off Products*',
    subtitle: '*Excludes 10 Packs and Printer Parts',
    disabled: false,
  },
  {
    title: 'Free Ground Shipping',
    subtitle: 'On all orders',
    disabled: false,
  },
  {
    title: 'Premium Perks',
    subtitle: 'Vote for new colors',
    disabled: false,
  },
  {
    title: 'Vending Machine',
    subtitle: 'Now Available',
    disabled: false,
  },
  {
    title: 'US Only',
    subtitle: 'Only available to US customers.',
    disabled: false,
  },
];

export default function Benefits(props) {
  useEffect(() => {
    window.onscroll = function () {
      scrollRotate();
    };
  }, []);
  function scrollRotate() {
    let img = document.getElementById('rotating-spool');
    if (img !== null) {
      img.style.transform = `rotate(${window.scrollY}deg)`;
    }
  }
  return (
    <Wrapper>
      <Separator bgColor=" linear-gradient(0deg, transparent 70%, #d8a530 120%)" />
      <RotatingSpool id="rotating-spool" src="/images/spool.png" />
      <TextWrapper>
        <img
          src="https://cdn.sunsh1n3.com/Images/ticker/PremiumPerks%20gold.png?w=600"
          alt="Premium Perks"
        />
        <TitleText className="sub-title grey">
        Every month we release a new and exclusive Premium color that ships to your door for just $25/month. With this subscription, you'll also unlock the benefits of free ground shipping, 10% off products, and other Premium perks. Premium spools ship within two business days of starting your subscription and will renew approximately every 30 days. Additionally, Premium members will have the ability to purchase any of the previous months' filament subscription box Premium colors (while supplies last). Be the first to unlock all PrintBed has to offer, with the Premium monthly subscription.
        </TitleText>
        <TextGrid>
          {BENEFITS.map((benefitObj, index) => {
            return (
              <BenefitText
                align="center"
                className={benefitObj.disabled ? 'disabled' : ''}
                // component="div"
                variant="h3"
                key={`benefit-${index}`}>
                {benefitObj.title ?? 'Benefit'}
                &nbsp;
                <p className="subtitle">
                  {benefitObj.subtitle ?? 'Description'}
                </p>
                &nbsp;
              </BenefitText>
            );
          })}
        </TextGrid>
      </TextWrapper>
    </Wrapper>
  );
}
