/** @format */

import React from 'react';
// Custom components
import ItemCard from '../bestSellers/itemCard';
// Material UI
import {styled} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const Spacer = styled('div')(
  {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '270px',
  },
  {name: 'spacer'}
);

//#endregion

export default function SearchResults(props) {
  const {itemArray, cartUtils} = props;
  let paddingItems = [];
  if(itemArray.length<4 && itemArray.length !== 0) {
    let single = 4-itemArray.length;
    for(let i = 0;i<single;i++) {
      paddingItems.push('');
    }
  }
 
  return (
    <Grid container spacing={2} justifyContent="center" style={{minHeight:'100vh'}}>
      {itemArray?.map((item, index) => {
        return <ItemCard key={`search-result-${index}`} cartUtils={cartUtils} {...item} />;
      })}
      {paddingItems.map((i,idx)=>{
        return <Spacer key={idx} />
      })}
    </Grid>
  );
}
