/** @format */

import {makeStyles} from '@material-ui/core/styles';
import { height, width } from '@material-ui/system';

const ModalStyles = makeStyles(() => ({
  backButton: {
    color: 'white',
    minWidth: '6rem',
    width: '6rem',
    padding: 0,
    position: 'fixed',
    top: '12rem',
    left: '6rem',
    '& svg': {
      fontSize: '6rem',
    },
  },
  bodyText: {
    color: '#FAC02E',
    marginBottom: '8rem',
    fontSize: '6rem',
  },
  infoText: {
    color: '#FAC02E',
    marginBottom: '8rem',
    fontSize: '6rem',
  },
  dialog: {
    width: '100%',
    background:
      'linear-gradient(180deg, #000000 0%, #003643 100%) 0% 0% no-repeat',
    opacity: 1,
    boxSizing: 'border-box',
    padding: '15rem 4rem 0 4rem',
    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
  dialogPaper: {
    width: '100% !important',
    height: '100% !important',
    minHeight: '100%',
    padding: '12rem 6rem',
  },
  forgotPasswordLink: {
    color: 'white',
    padding: 0,
    fontSize:'4rem',
    margin: '1rem 0',
  },
  form: {
    color: 'white',
    '& .input': {
      height: '18rem',
      fontSize: '6rem',
      padding: 0,
      marginBottom: '6rem',
      borderRadius: 8,
    },
  },
  logo: {
    width: '7rem',
    position: 'fixed',
    top: 0,
  },
  rememberMe: {
    color: '#fff',
    fontWeight: '400',
    margin: '-1rem 0 1rem 0',
    '& .MuiFormControlLabel-label': {
      fontSize:'6rem'
    },
    '& .MuiSvgIcon-root':{
      width:'6rem',
      height:'6rem'
    },
    '& .rm-checkbox': {
      paddingLeft: 0,
      '& input': {
        borderRadius: '6px',
      },
    },
  },
  subtitle: {
    fontSize: '6rem',
    color: '#FAC02E',
    marginBottom: '3rem',
  },
  title: {
    fontSize: '10rem',
    color: 'white',
    margin: '2rem 0',
  },
  formSwitchText: {
    color: '#f7f7f7',
    marginBottom: '2rem',
    fontWeight: '400',
    fontSize: '6rem',
    '& a': {
      color: 'white',
      marginLeft: '1rem',
    },
  },
}));
export default ModalStyles;
