/** @format */

import React from 'react';
// Custom components
import {BannerBtn, Default} from '../index';
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

//#region Styled components
const MainTxt = styled(Typography)(
  {
    color: '#000',
    fontFamily: 'Barlow Condensed, Roboto, sans-serif',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '1',
    marginBottom: '3rem',
    userSelect: 'none',
    width: '50rem',
    '&.line1': {
      fontSize: '3rem',
      marginBottom: '0',
      '&.mbl': {
        fontSize: '6rem',
      },
    },
    '&.line2': {
      fontSize: '8rem',
      fontWeight: '700',
      marginBottom: '3.5rem',
      '&.mbl': {
        fontSize: '10rem',
        width:'100%'
      },
    },
    '&.line3': {
      fontSize: '3.25rem',
      fontWeight: '600',
      marginBottom: '5rem',
      '&.mbl': {
        fontSize: '6.25rem',
        width:'100%'
      },
    },
    '&.line4': {
      fontSize: '3rem',
      marginBottom: '5rem',
      '&.mbl': {
        fontSize: '9rem',
        width:'100%',
        textAlign:'justify'
      },
    },
  },
  {name: 'main-txt'}
);
//#endregion

export default function MerchBanner({isMobile}) {
  return (
    <Default
      justifyTxt="flex-end"
      bgColor="#c6f605"
      mainImgSrc="https://cdn.sunsh1n3.com/home/Merch.png"
      // mainImgStyles={{
      //   flex: 2,
      // }}
      textLoc={isMobile?"top":"left"}
      isMobile={isMobile}
      txtStyles={{
        marginRight: isMobile ? '0 ' : '-15%',
      }}>
      <MainTxt align="center" className={`line1 ${isMobile ? 'mbl' : ''}`}>
        New Products
      </MainTxt>
      <MainTxt  variant="h2" align="center" className={`line2 ${isMobile ? 'mbl' : ''}`}>
        MERCH &amp; MORE
      </MainTxt>
      <MainTxt align="center" className={`line3 ${isMobile ? 'mbl' : ''}`}>
        T-Shirts, Acrylic Posters, &amp; More
      </MainTxt>
      <MainTxt align="center" className={`line4 ${isMobile ? 'mbl' : ''}`}>
      You can represent PrintBed in more ways than just our 3D printer filament in your printer. Buy a graphic t-shirt to wear or hang a full color acrylic poster in your office to inspire new designs.
      </MainTxt>
      <BannerBtn
        colorVariant="black"
        custHeight={isMobile ? '20rem' : undefined}
        custWidth={isMobile ? '' : undefined}
        fontSize={isMobile ? '8rem' : '2.75rem'}
        fontWeight="400"
        to="/shop/merch">
        Browse Collection
      </BannerBtn>
    </Default>
  );
}
