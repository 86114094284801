/** @format */

import React from 'react';
// Custom components
// Material UI
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PlusIcon from '@material-ui/icons/AddCircle';
import MinusIcon from '@material-ui/icons/RemoveCircle';
// Styles
import ItemStyles from '../styles';

export default function MiniOption(props) {
  const pgStyles = ItemStyles();
  const {
    handleOptionSelect = () => {},
    isDisabled = false,
    isSelected = false,
    item,
  } = props;

  return (
    <Tooltip
      key={item.tags.color + '-tooltip'}
      className={pgStyles.optionContainer}
      // disableHoverListener={!isDisabled}
      // disableTouchListener={!isDisabled}
      // disableFocusListener={!isDisabled}
      title={
        item.tags.colorName.toTitleCase() +
        (isDisabled ? ' - Out of Stock' : '')
      }
      placement="top">
      <div style={{position: 'relative'}}>
        {isSelected && (
          <IconButton
            size="small"
            style={{position: 'absolute', top: 0, left: 0, zIndex: 10}}
            onClick={() => {
              handleOptionSelect(item, false);
            }}>
            <MinusIcon style={{fontSize: '.8em'}} />
          </IconButton>
        )}
        {isSelected && (
          <IconButton
            color="primary"
            size="small"
            style={{position: 'absolute', top: 0, right: 0, zIndex: 10}}
            onClick={() => {
              handleOptionSelect(item, true);
            }}>
            <PlusIcon style={{fontSize: '.8em'}} />
          </IconButton>
        )}
        <Button
          className={
            pgStyles.optionChoice + ' color' + (isSelected ? ' selected' : '')
          }
          onClick={() => {
            handleOptionSelect(item);
          }}
          title={item.tags.color}
          style={{padding: 0}}
          disabled={isDisabled}>
          <div
            className="color-div"
            style={{
              backgroundImage: `url('${item.imgSrc[0]}?w=${Math.round(
                70 * window.devicePixelRatio
              )}')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
            &nbsp;
          </div>
        </Button>
      </div>
    </Tooltip>
  );
}
