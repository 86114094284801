/** @format */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    fontSize: '2em',
    minHeight: 900,
    backgroundColor: '#eee',
  },
  breadCrumb: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    flex: 1,
    position:'relative',
    margin: 'auto',
    width: '80%',
    minHeight: 800,
    backgroundColor: 'white',
    borderRadius: 16,
    padding: '10px 100px',
    '& iframe': {
      position:'absolute',
      top:'10%',
      left:'5%',
      width:'90%',
      height:'80%'
    }
  },
  tabs: {
    width: '100%',
    fontWeight: 'bold',
    borderBottom: '1px solid #eee',
  },
}));

const Tutorials = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <h1>Forge PLA+</h1>
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/x8qx9frSNfo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </Paper>
    </div>
  );
};

export default Tutorials;
