import React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { getColor } from '../../pages/pod/slicer/utils';


const styles = makeStyles(
    {
      wrapper: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
      },
      radio: {
        '& .MuiIconButton-label > div > :last-child': {
        transform: 'scale(2)'
        },
        '& .Mui-checked > .MuiIconButton-label > div > :last-child': {
            transform: 'scale(0)'
        }
      }
    },
    {name: 'color-radio-buttons'}
  );

const StyledRadio = styled(Radio)(({value})=>({
    color: getColor(value),
    scale:1.2,
}));

export default function ColorPicker({colors=[],color, onChange=()=>{}}) {
    const classes = styles();
  return (
    <FormControl>
      <RadioGroup defaultValue="Black" value={color} aria-label="color" name="customized-radios" className={classes.wrapper} onChange={onChange}>
        {colors.map((color,index)=>{
            return  <FormControlLabel key={index} value={color} size="medium"  className={classes.radio} control={<StyledRadio  color="default" size="medium"/>} label={color} />

        })}
      </RadioGroup>
    </FormControl>
  );
}
