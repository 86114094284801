/** @format */

import React, { useState, useRef, useEffect } from 'react';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import {styled} from '@material-ui/styles';
import { Button, ButtonBase } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useSnackbar } from 'notistack';

// Custom comps
import AddressCard from '../shipping/addressCard';
import AddressForm from '../shipping/addressForm';

// Styles
import SubStyles from './styles.js';


const StyledTxt = styled(Typography)(
  {
    '& .date-span': {
      justifySelf: 'right',
    },
    '&.indent': {
      marginLeft: '1rem',
    },
    '&.info-wrapper': {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize:'6rem',
      justifyContent: 'space-between',
      width: '100%',
    },
    '&.subheader': {
      margin: '2rem 0 1rem',
    },
  },
  {name: 'subs-styled-txt'}
);

const ActiveSubTxt = styled(Typography)(
  {
    color: props => (props.isactive === 'true' ? 'green' : 'red'),
  },
  {name: 'active-sub-txt'}
);

const CollapseHeader = styled('div')(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: 'max-content',
    justifyContent: 'space-between',
    margin: '0',
    width: '100%',
  },
  {name: 'collapse-header'}
);

const EditPaymentBtn = styled(ButtonBase)(
  {
    borderRadius: '8px',
    color: '#0062ff',
    padding: '1rem 2rem',
    position: 'relative',
  },
  {name: 'edit-pay-btn'}
);

const ExpandBtn = styled(ButtonBase)(
  {
    borderRadius: '8px',
    color: '#8a8a8a',
    height: '100%',
    padding: '1rem 2rem',
    position: 'relative',
    right: '-2rem',
    '&.MuiIconButton-label': {
      maxHeight: '100%',
    },
    '& .txt': {
      saturation: '80%',
    },
  },
  {name: 'expand-btn'}
);

const StyledExpandIcon = styled(ExpandMoreIcon)(
  {
    color: 'initial',
    marginLeft: '1rem',
    '&.isOpen': {
      transform: 'rotate(180deg)',
    },
    '&.loading': {
      visibility: 'hidden',
    },
  },
  {name: 'styled-expand-icon'}
);

export default function PrefilledPaymentDetails(props) {
  const { userData} = props;
  const { loggedIn, userId } = userData;
  const pgStyles = SubStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pbSub,setPbSub] = useState(null);
  const [isEditAddress,setIsEditAddress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  //#region useEffect's
  useEffect(() => {
    fetch(`/api/subscription`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(resp => resp.json())
      .then(result => {
        // console.log('result: ', result);
        updateSubObj(result);
      });
  }, []);

  const updateSubObj = (result) => {
    if (result && result.id) {
      const subId = result.id;
      const paymentObj = result.payment;
      const subStatus = result.valid;
      const paymentType = result.paymentType;
      const paymentAddr = result.address;
      const shippingAddress = result.address?.address;
      const startDate = new Date(result.start);
      const endDate = new Date(result.end);
      const subActive = result.valid;
      let subObj = {
        id: subId,
        payment: paymentObj,
        status: subStatus,
        paymentAddress: paymentAddr,
        paymentType: paymentType,
        startDate: startDate,
        endDate: endDate,
        isActive: subActive,
        shippingAddress: shippingAddress,
      };
      setPbSub(subObj);
      setLoading(false);
    }
  }

  const shippingAddress = {address:pbSub?.shippingAddress}
  const cancelSub = () => {
    let allow = window.confirm("Are you sure you want to cancel your subscription?"); 
    if(allow === true) {
      fetch(`/api/subscription`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
      })
        .then(resp => resp.json())
        .then(result => {
          if (result.membershipValidUntil) {
            enqueueSnackbar(`We're sorry to see you go. Your membership will remain active until ${new Date(result.membershipValidUntil).toDateString().slice(4)}.`,{variant:'info'});
          } else {
            enqueueSnackbar(`We're sorry but there was an issue canceling your subscription please contact support via email support@printbed.com`,{variant:'error'});
          }
        }).catch(e=>{
          enqueueSnackbar(`We're sorry but there was an issue canceling your subscription please contact support via email support@printbed.com`,{variant:'error'});

        });
      }
  };


  const changeLocalPickup = (e) => {
    if(pbSub.paymentAddress.serviceCode !==0) {
      let allow = window.confirm("Are you sure you want to change your subcription to local pickup?"); 
      console.log(allow, typeof allow);
      if(allow===true) {
        confirmedLocal(0);
      }
      // setOpts({open:true,title:"Change Subscription to Local Pickup?", msg:"Are you sure you want to change your subscription to be local pickup?", onConfirm: confirmedLocal(0), onCancel:()=>setOpts({open:false})})
    } else {
      enqueueSnackbar(`Please edit the address to change to shipping.`,{variant:'info'});
    }
  }

  const confirmedLocal = (serviceCode) => {
    const body = {
      customerInfo:{
        serviceCode
      }
    };

    fetch(`/api/subscription`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body:JSON.stringify(body)
    })
      .then(resp => resp.json())
      .then(result => {
        // setSubObj(result);
        // setOpts({open:false});
        updateSubObj(result);
      });
  }

  const handleEditSubmit = (data) => {
    console.log(data)
    const body = {
      customerInfo:{
        "address1": data.address.line1,
        "address2": data.address.line2,
        "city": data.address.city,
        "country": data.address.country,
        "email": data.email,
        "firstName": data.firstName,
        "lastName": data.lastName,
        "phone": data.phone,
        "serviceCode": 95,
        "zip": data.address.postal_code
      }
    };

    fetch(`/api/subscription`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body:JSON.stringify(body)
    })
      .then(resp => resp.json())
      .then(result => {
        // setSubObj(result);
        updateSubObj(result);
        setIsEditAddress(false);
      });
  }

  return (
    <div
      className={pgStyles.pgWrapper}
      style={{
        // backgroundColor: showNewPaymentSourceForm ? '#fff' : '#f6f6f6',
        // padding: showNewPaymentSourceForm ? '0 2rem' : 0,
      }}
    >
      <Typography
        variant="h2"
        className={pgStyles.title}
        style={{
          // marginBottom: showNewPaymentSourceForm ? '4rem' : '1.5rem',
          // marginLeft: showNewPaymentSourceForm ? '0' : '2rem',
        }}
      >
        Subscription Details
      </Typography>
      {loading === true ? <CircularProgress variant="indeterminate" /> :
        (pbSub.hasOwnProperty("shippingAddress")!==true)?
        <div className={pgStyles.addressWrapper}>You have no Subscriptions.</div>:
        <>
          {isEditAddress? 
          <AddressForm
              hasCancelBtn={true}
              onCancel={() => setIsEditAddress(false)}
              onSubmit={newInfo => handleEditSubmit(newInfo)}
              // shippingInfo={}
              submitBtnTitle="Save"
              title="Shipping Details"
            />: 
            <div className={pgStyles.addressWrapper}>
              Shipping To:
              <AddressCard shippingInfo={shippingAddress} />
              <div className={pgStyles.buttonWrapper}>
                <Button color="primary" variant="contained" fullWidth={true} onClick={()=>setIsEditAddress(true)} style={{margin:'3rem 0'}}>Edit Address.</Button>
                {pbSub?.paymentAddress?.serviceCode===0?
                <><Typography>Local Pickup* </Typography><Typography variant="caption">*Edit address to change to shipping.</Typography></>
                :
                <Button color="primary" variant="contained" fullWidth={true} onClick={changeLocalPickup} style={{marginTop:0}}>Change to Local Pickup.</Button>
                }
              </div>
            </div>}
          <div style={{height:'5rem'}}></div>
          <CollapseHeader>
            <StyledTxt variant="h2">
              Status:&nbsp;
              <ActiveSubTxt component="span" isactive={pbSub.isActive.toString()}>
                {pbSub.isActive ? 'Active' : 'Inactive'}
              </ActiveSubTxt>
            </StyledTxt>
            {pbSub.id &&
            <ExpandBtn
              onClick={() => {
                setIsOpen(!isOpen);
              }}>
              <span className="txt">
                {!loading ? (isOpen ? 'Less Info' : 'More Info') : 'Loading...'}
              </span>
              <StyledExpandIcon
                className={!loading ? (isOpen ? 'isOpen' : '') : 'loading'}
              />
            </ExpandBtn>}
          </CollapseHeader>
          <Collapse in={isOpen} timeout="auto">
            <StyledTxt className="info-wrapper">
              Initial Subscription Date:&nbsp;
              <span className="date-span">
                {pbSub.startDate.toDateString().slice(4)}
              </span>
            </StyledTxt>
            <StyledTxt className="info-wrapper">
              Current bill cycle end:&nbsp;
              <span className="date-span">
                {pbSub.endDate.toDateString().slice(4)}
              </span>
            </StyledTxt>
            <Button variant="contained" onClick={cancelSub} style={{marginTop:50, fontSize:'6rem'}}>Cancel My subscription.</Button>
          </Collapse>
        </>
      }
    </div>
  );
}
