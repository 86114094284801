/** @format */

import {makeStyles} from '@material-ui/core/styles';

const CartStyles = makeStyles({
  animatedButton: {
    height: 50,
    backgroundColor: '#f4bd2f',
    borderRadius: 4,
    width: '100%',
    display: 'flex',
    '&:hover': {
      '& $animatedButtonLeftSide': {
        width: '150%',
      },
      '& $animatedButtonRightSide': {
        width: '50%',
      },
    },
  },
  animatedButtonLeftSide: {
    fontSize: '2rem',
    fontWeight: 'bold',
    backgroundColor: '#f4bd2f',
    height: '100%',
    width: '100%',
    transition: 'width .5s',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '1rem',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  animatedButtonRightSide: {
    fontSize: '2rem',
    fontWeight: 'bold',
    backgroundColor: '#f0ac00',
    height: '100%',
    width: '100%',
    transition: 'width .5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '1rem',
    borderRadius: '3.58744% 4px 4px 3.58744% / 16.6667% 4px 4px 16.6667%',
  },
  btnClose: {
    position: 'absolute',
    top: '3rem',
    right: '3rem',
    padding: '0',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  card: {
    margin: '1rem 0',
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '4px',
    width: '100%',
    backgroundColor: '#fff',
    '& .name': {
      display: 'flex',
      flexDirection: 'column',
      '& h6': {
        marginBottom: '1.5rem',
      },
      '& button': {
        width: 'min-content',
        padding: 0,
        justifyContent: 'left',
        color: '#F46036',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
    },
  },
  cardActions: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    paddingRight: '2rem',
    margin: '0',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    '& .qty-btn': {
      margin: 0,
      padding: '0',
      color: '#000',
      '&.add': {
        paddingTop: '2rem',
      },
      '&.remove': {
        paddingBottom: '2rem',
      },
      '&.Mui-disabled': {
        opacity: '0.25',
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0)',
      },
    },
    '& #qty-txt': {
      margin: 0,
      fontSize: '1.55rem',
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexGrow: '1',
    padding: '0',
    height: '100%',
    '& .text': {
      // Here it calculates % relative to WIDTH of parent unit
      marginTop: '10.5%',
    },
  },
  cardIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    margin: '0 0 6rem 0',
    '& .card-logo': {
      marginRight: '1rem',
      borderRadius: 8,
      width: '60px',
      display: 'flex',
    },
    '& .icon': {
      width: '100%',
      height: '100%',
    },
  },
  cardMedia: {
    maxHeight: '10vh',
    maxWidth: '20%',
    objectFit: 'contain',
    padding: '1rem',
    alignSelf: 'center',
  },
  checkoutButton: {
    margin: '5rem auto',
    borderRadius: 4,
    backgroundColor: '#f4bd2f',
    width: '100%',
    maxWidth: 750,
    height: 50,
    fontSize: '2rem',
    fontWeight: '600',
    padding: 0,
    '&:hover': {
      backgroundColor: '#ffc439',
      filter: 'brightness(.95)',
    },
    '& a': {
      color: 'inherit',
    },
  },
  checkoutButtons: {
    maxWidth: 750,
    width: '100%',
    margin: '0 auto',
  },
  container: {
    borderRadius: '4px',
    backgroundColor: '#f6f6f6',
    borderColor: '#eee',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  disabledButtons: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  orderInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 3rem',
    overflow: 'hidden',
  },
  scrollBar: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .vert-scroll': {
      height: '100%',
      right: '0',
      borderRadius: '3px',
    },
  },
  scrollBarInner: {
    width: 'calc(100% + 17px)',
    overflow: 'visible',
    alignItems: 'flex-start',
    overflow: 'visible',
    paddingRight: '2rem',
    width: 'calc(100% + 17px)',
  },
  titles: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.5rem',
    '& .title-qty': {
      color: '#A4AAB8',
      marginLeft: '1rem',
    },
  },
  totals: {
    color: '#555',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2.5rem 0',
    '& .grand-total': {
      color: '#000',
      fontWeight: 'bold',
    },
  },
});

export default CartStyles;
