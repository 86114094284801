/** @format */

import React, {useState} from 'react';
// Material UI Components
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
// Custom imports
import {SunValidatedInput} from '@sunsh1n3/sun-validated-input';
// Styles
import ModalStyles from './styles';

export default function ForgotPasswordForm(props) {
  const {formSwitch = () => {}} = props;
  const [email, setEmail] = useState(null);
  const [emailHasAccount, setEmailHasAccount] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const pgStyles = ModalStyles();

  const submitEmail = e => {
    e.preventDefault();

    const body = {
      email: email,
    };

    fetch('/api/auth/password/reset', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(resp => {
      if (!resp.ok) {
        if (resp.status === 404) {
          setEmailHasAccount(false);
        } // else generic error
      } else {
        setEmailHasAccount(true);
      }
      setIsFormSubmitted(true);
      //return resp.json();
    });
  };

  function EmailSent(props) {
    return (
      <>
        <Typography variant="h3" className="title" noWrap>
          Email Sent
        </Typography>
        <Typography className="form-switch-txt">
          We have sent you a reset password link on your registered email.
        </Typography>
        <Button
          fullWidth
          size="large"
          variant="contained"
          className="submit"
          onClick={e => formSwitch('login')}
        >
          Sign In
        </Button>
      </>
    );
  }

  function NoEmailFound(props) {
    return (
      <>
        <Typography variant="h3" className="title" noWrap>
          Wrong Email
        </Typography>
        <Typography className="form-switch-txt">
          The email "{email}" doesn't match any of our PrintBed accounts.
        </Typography>
        <Typography className="form-switch-txt">
          Would you like to{' '}
          <Link
            href="#"
            onClick={e => formSwitch('register')}
            className="form-switch-txt"
            underline="always"
          >
            create an account
          </Link>{' '}
          with us?
        </Typography>
        <Button
          fullWidth
          size="large"
          variant="contained"
          className="submit try-again"
          onClick={e => {
            setEmailHasAccount(true);
            setIsFormSubmitted(false);
          }}
        >
          Try Again
        </Button>
      </>
    );
  }

  return (
    <div className={pgStyles.formDiv + ' forgot-password'}>
      {isFormSubmitted ? (
        emailHasAccount ? (
          <EmailSent />
        ) : (
          <NoEmailFound />
        )
      ) : (
        <>
          <Typography variant="h3" className="title" noWrap>
            Forgot Password
          </Typography>
          <Typography className="form-switch-txt">
            Enter the email associated with your account and we'll send an email
            with instructions to reset your password.
          </Typography>
          <form className="form-wrapper" noValidate onSubmit={submitEmail}>
            <SunValidatedInput
              className="input"
              disableUnderline={true}
              required={true}
              fullWidth={true}
              id="email"
              name="email"
              type="email"
              placeholder="Email address"
              autoComplete="email"
              errorText="Invalid email address"
              showErrorArrow={false}
              showValidIcon={false}
              showErrorTooltip
              errorPopperProps={{
                disablePortal: true,
                style: {
                  width: '100%',
                },
              }}
              onChange={e => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              className="submit"
            >
              Send
            </Button>
          </form>
        </>
      )}
    </div>
  );
}
