/** @format */

import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// Custom
import AddressCard from './addressCard';
import AddressForm from './addressForm';
import ShippingStyles from './styles.js';
// Styled components
import CheckoutButton from '../styled/checkout-button';
import NewOptBtn from '../styled/checkout-new-opt-btn';

export default function Shipping(props) {
  const {formChange, submitShippingInfo, title, userData} = props;
  const pgStyles = ShippingStyles();

  const [isEditAddress, setIsEditAddress] = useState(false);
  const [shippingInfo, setShippingInfo] = useState(null);
  const [showNewAddressInput, setShowNewAddressInput] = useState(false);
  //#region useEffect's

  useEffect(() => {
    if (userData.loggedIn) {
      fetch(`/api/user/${userData.userId}/billing`)
        .then(resp => resp.json())
        .then(stripeUserData => {
          if (
            stripeUserData &&
            stripeUserData.shipping &&
            stripeUserData.shipping.address
          ) {
            setShippingInfo({
              ...stripeUserData.shipping,
              name: stripeUserData.name,
            });
            // setLoading(false);
          } else {
            console.log('Error calling user billing service');
            setIsEditAddress(true);
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShowNewAddressInput(isEditAddress);
  }, [isEditAddress]);

  useEffect(() => {
    if (formChange) formChange(showNewAddressInput);
    // eslint-disable-next-line
  }, [showNewAddressInput]);

  //#endregion

  const submitForm = () => {
    submitShippingInfo(shippingInfo);
  }

  function SelectAddressForm() {
    return (
      <>
        {userData.loggedIn && shippingInfo ? (
          <>
            <Typography className={pgStyles.title} variant="h4">
              {title}
            </Typography>
            <AddressCard shippingInfo={shippingInfo} />
            {submitShippingInfo && (
              <CheckoutButton
                className={pgStyles.deliverBtn}
                onClick={submitForm}
                variant="contained"
              >
                Deliver to this address
              </CheckoutButton>
            )}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <CircularProgress
              variant="indeterminate"
              style={{alignSelf: 'center'}}
            />
          </div>
        )}

        <NewOptBtn
          className={clsx(pgStyles.newAddrWrapper, 'card-style')}
          fullWidth
          label="Edit address"
          onClick={() => setIsEditAddress(true)}
        />
      </>
    );
  }

  const validateShippingInfo = (newInfo) => {
    const {address, email, name, phone} = newInfo;
    const {city, country, line1, line2, postal_code, state} = address;
    let data = {
      streetLines: [line1,line2],
      city,
      country,
      zip:postal_code
    }
    fetch(`/api/shipping/address/validate`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(res => {
        console.log(res)
      });
  }

  //#region Handlers
  function handleEditSubmit(newInfo) {
    const {address, email, name, phone} = newInfo;
    const {city, country, line1, line2 = "", postal_code, state} = address;

    const newUserData = {
      address: {
        city,
        country,
        line1,
        line2,
        postal_code,
        state,
      },
      email,
      phone,
      shipping: {
        address: {
          city,
          country,
          line1,
          line2,
          postal_code,
          state,
        },
        name,
        phone,
      },
      name,
    };

    fetch(`/api/user/${userData.userId}/billing`, {
      method: 'PUT',
      body: JSON.stringify(newUserData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(res => {
        setShippingInfo(res.shipping);
        setIsEditAddress(false);
      });
  }

  //#endregion

  return (
    <div className={pgStyles.wrapper}>
      {showNewAddressInput ? (
        <AddressForm
          hasCancelBtn={true}
          onCancel={() => setIsEditAddress(false)}
          onSubmit={newInfo => handleEditSubmit(newInfo)}
          shippingInfo={shippingInfo}
          submitBtnTitle="Save"
          title="Shipping Details"
        />
      ) : userData.loggedIn ? (
        <SelectAddressForm />
      ) : (
        <AddressForm
          hasCancelBtn={false}
          onCancel={() => setShowNewAddressInput(false)}
          onSubmit={newInfo => submitShippingInfo(newInfo)}
          shippingInfo={null}
          title="Shipping Address"
        />
      )}
    </div>
  );
}
