/** @format */

import {makeStyles} from '@material-ui/core/styles';
import { borderRadius, lineHeight } from '@material-ui/system';

const TabStyles = makeStyles({
  divider: {
    marginTop: 0,
  },
  tabContent: {
    padding: '0 19.6vw',
    paddingRight: '25vw',
    '& button': {
      height: "6rem",
      lineHeight:'2rem',
      // width: "28.25rem",
      padding:'2rem',
      backgroundColor: "rgb(0, 84, 221)",
      color: 'white',
      border: 'none',
      borderRadius:'1rem',
      '& a': {
        color:'white'
      }
    }
  },
  tabHeaders: {
    padding: '0 19.6vw',
    '& .MuiTab-root': {
      padding: '2.5rem 2rem',
    },
  },
});

export default TabStyles;
