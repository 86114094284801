/** @format */

import {makeStyles} from '@material-ui/core/styles';

const TabStyles = makeStyles({
  orders: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '10vh',
    '& .time-wrapper': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '13.3vh',
      '& .range-picker': {
        marginLeft: '1.5rem',
        width: '9vw',
        '& .MuiInputBase-root': {
          boxShadow: '0px 4px 8px #0000000A',
        },
      },
    },
    '& .orders-wrapper': {
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column',
      '& .order-card': {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #BDBDBD',
        marginBottom: 20,
        borderRadius: '8px',
        '& .order-info': {
          height: '9.3vh',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          '& .info-sect': {
            margin: '0 4rem',
            '&:last-child': {
              flexGrow: '1',
              textAlign: 'right',
            },
          },
          '& .info-title': {
            fontSize: '2rem',
            fontWeight: '500',
            textTransform: 'uppercase',
          },
        },
        '& .card-content': {
          backgroundColor: '#fff',
          padding: '0',
          '& .status-txt': {
            fontSize: '3rem',
            fontWeight: '500',
            marginLeft: '4rem',
            marginTop: '3rem',
            marginBottom: '4.07vh',
            '& a': {
              fontSize: '2rem',
              color: 'black',
              float: 'right',
              marginRight: '4rem',
              textDecoration: 'underline',
            },
          },
          '& .item-wrapper': {
            display: 'flex',
            marginLeft: '3.23vw',
            marginBottom: '3.98vh',
            '& .item-img': {
              backgroundColor: 'transparent',
              color: 'transparent',
              width: '9.375vw',
              height: '9.375vw',
              marginRight: '2rem',
              margin: 'auto',
            },
            '& .item-info': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              '& .item-price': {
                color: '#CC0000',
              },
              '& .buttons': {
                display: 'flex',
                justifyContent: 'left',
                '& .btn': {
                  border: '1px solid #0099FF',
                  '&.blue': {
                    backgroundColor: '#0099FF',
                    color: 'white',
                    marginRight: '1rem',
                  },
                  '&.grey': {
                    backgroundColor: '#f7f7f7',
                    color: '#0099FF',
                  },
                  '& .MuiButton-startIcon': {
                    maxHeight: '2rem',
                    margin: '0',
                    marginLeft: '1rem',
                    marginRight: '0.5rem',
                    padding: '0',
                  },
                  '& .MuiSvgIcon-root': {
                    height: '2rem',
                    width: '2rem',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  buyAgain: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '10vh',
  },
  openOrders: {},
  digitalOrders: {},
  canceledOrders: {},
});

export default TabStyles;
