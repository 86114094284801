/** @format */

import React from 'react';
// Material UI Components
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// Custom imports
import Drip404Styles from './styles';
// import { ReactComponent as DripImg } from '../../images/drip-404-v2-vb-fix.svg';

const DripBackdrop = props => {
  const pgStyles = Drip404Styles();

  const handleClick = e => {
    window.location.href = "/";
  };

  return (
    <div className={pgStyles.wrapper}>
      {/* <div className={pgStyles.bg}>
        {' '}
        {' '}
      </div> */}
      <img
        src="/images/drip-404-v2-vb-fix.svg"
        alt="404-drip"
        className={pgStyles.bg}
        viewBox={'0 0 ' + window.innerWidth + ' ' + window.innerHeight}
      />
      {/* <DripImg
        height='100%'
        preserveAspectRatio='xMidYMid slice'
        style={{
          position: 'absolute',
          top: '0',
          overflowX: 'clip',
        }}
      /> */}
      <div className={pgStyles.textWrapper}>
      <Typography variant="h2" className={pgStyles.title}>
          404
        </Typography>
        <Typography variant="h4" className={pgStyles.title}>
          Oops... There's nothing in here
        </Typography>
        <Typography variant="body1" className={pgStyles.text}>
          Sorry, but the page you are looking for doesn't exist.
        </Typography>
        <Button
          variant="outlined"
          disableElevation
          className={pgStyles.tryAgainBtn}
          onClick={handleClick}
        >
          Go Home
        </Button>
      </div>
    </div>
  );
};

export default DripBackdrop;
