/** @format */

import React, {useState} from 'react';
// MUI Component Imports
// Custom component imports
import ShippingInput from '../shipping/shippingInput';
import NewOptBtn from '../styled/checkout-new-opt-btn';

// Stripe imports
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
// Styles
import PaymentStyles, {CARD_OPTIONS} from './styles';
// Styled Components
import StyledLabel from '../styled/input-label';
import SubmitButton from '../styled/submit-button';

export default function PaymentForm(props) {
  const {
    hasCancelBtn = true,
    onCancel = () => {},
    onSubmit = () => {},
    submitBtnTxt = 'Submit',
    userData,
  } = props;
  // Stripe handled card info
  const [cardInfo, setCardInfo] = useState({
    number: {
      isValid: false,
      isEmpty: true,
    },
    exp: {
      isValid: false,
      isEmpty: true,
    },
    cvc: {
      isValid: false,
      isEmpty: true,
    },
  });
  // Other card info
  const [cardName, setCardName] = useState(
    userData.name || `${userData.fname} ${userData.lname}`
  );
  const [zipCode, setZipCode] = useState();
  const [country, setCountry] = useState('US');
  const pgStyles = PaymentStyles();
  const stripe = useStripe();
  const elements = useElements();

  async function getPayment() {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (userData.address && userData.address.country) {
      setCountry(userData.address.country);
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    let token = await stripe.createToken(cardNumberElement, {
      name: cardName,
      address_zip: zipCode,
      address_country: country,
    });

    token = token.token;

    return {
      tokenId: token.id,
      card: {
        brand: token.card.brand,
        exp_month: token.card.exp_month,
        exp_year: token.card.exp_year,
        id: token.card.id,
        last4: token.card.last4,
      },
    };
  }

  const handleChange = event => {
    /* not a standard JS event
        Stripe Event props
          elementType: string
          empty: bool
          complete: bool
          error: obj
            props: message, code, type. type is always 'validation_error'
          brand: string
        */
    let dataType = '';
    switch (event.elementType) {
      case 'cardNumber':
        dataType = 'number';
        break;
      case 'cardExpiry':
        dataType = 'exp';
        break;
      case 'cardCvc':
        dataType = 'cvc';
        break;
      default:
        break;
    }
    let newInputInfo = cardInfo;
    newInputInfo[dataType].isEmpty = event.empty;
    newInputInfo[dataType].isValid = event.complete;
    setCardInfo(newInputInfo);
  };

  return (
    <>
      <div className={pgStyles.paymentWrapper}>
        <ShippingInput
          addedClasses={pgStyles.shippingInput}
          id="inp-name"
          label="Name"
          name="ccname"
          onChange={e => setCardName(e.target.value)}
          style={{marginBottom: '3rem'}}
          value={cardName}
        />
        <div className={pgStyles.cardInput}>
          <StyledLabel
            className={pgStyles.label}
            disableAnimation
            htmlFor="ccnumber"
          >
            Number*
          </StyledLabel>
          <CardNumberElement
            id="inp-number"
            name="ccnumber"
            onChange={handleChange}
            options={CARD_OPTIONS}
            required
          />
        </div>
        <div className={pgStyles.cardInput}>
          <StyledLabel
            className={pgStyles.label}
            disableAnimation
            htmlFor="ccexpiration"
          >
            Expires*
          </StyledLabel>
          <CardExpiryElement
            id="inp-exp"
            name="ccexpiration"
            options={CARD_OPTIONS}
            onChange={handleChange}
            required
          />
        </div>
        <div className={pgStyles.cardInput}>
          <StyledLabel
            className={pgStyles.label}
            disableAnimation
            htmlFor="cccvc"
          >
            Security Code*
          </StyledLabel>
          <CardCvcElement
            id="inp-cvc"
            name="cccvc"
            options={CARD_OPTIONS}
            onChange={handleChange}
            required
          />
        </div>
        <ShippingInput
          id="inp-zip"
          inputProps={{pattern: 'd'}}
          name="cczip"
          label="Zip Code"
          onChange={e => setZipCode(e.target.value)}
          placeholder="00000"
          value={zipCode}
        />
      </div>

      <NewOptBtn
            className={pgStyles.newPaymentSourceButton}
            label="Use Paypal"
            onClick={e => {
              // setSelectedPaymentSourceId('paypal');
              onSubmit({ paypal: true });
            }}
          />
      <div style={{flexGrow: 1}}></div>
      <SubmitButton
        className={pgStyles.submitBtn}
        color="primary"
        fullWidth
        onClick={async () => onSubmit(await getPayment())}
        variant="contained"
      >
        {submitBtnTxt}
      </SubmitButton>
      {hasCancelBtn && (
        <SubmitButton onClick={onCancel} variant="outlined">
          Cancel
        </SubmitButton>
      )}
    </>
  );
}
