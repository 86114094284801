/** @format */

import React from 'react';
// Custom components
// Material UI
import {styled} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// Material UI Icons
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';

//#region Styled components

const Wrapper = styled('div')(
  {
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
  },
  {name: 'extra-info'}
);

const SocialBtn = styled(IconButton)(
  {
    paddingTop: '0',
    paddingBottom: '0',
  },
  {name: 'social-btn'}
);

const SocialBtnsWrapper = styled('div')(
  {
    alignItems: 'flex-end',
    display: 'flex',
  },
  {name: 'social-btns'}
);

const Text = styled(Typography)(
  {
    alignItems: 'flex-end',
    display: 'flex',
    lineHeight: '1.75',
  },
  {name: 'extra-info-txt'}
);
//#endregion

export default function ExtraInfo(props) {
  const {material = 'PLA'} = props;
  return (
    <Wrapper className="info-wrapper">
      <Text variant="body1" className="txt">
        {'Category: '}
        {material}
      </Text>

      <div>
        <Typography variant="body1" display="inline">
          Share:
        </Typography>

        <SocialBtnsWrapper>
          <SocialBtn
            className="social-button"
            href={`https://www.facebook.com/sharer/sharer.php?u=https://www.printbed.com${window.location.pathname}`}
            target="_blank"
            rel="noopener noreferrer">
            <FacebookIcon />
          </SocialBtn>
          <SocialBtn
            className="social-button"
            href={encodeURI(
              `http://twitter.com/share?text=High Qualtiy PLA for 3d Printing&url=https://www.printbed.com${window.location.pathname}&hashtags=3dprinting,filament,printbed`
            )}
            target="_blank"
            rel="noopener noreferrer">
            <TwitterIcon />
          </SocialBtn>
          {/* <SocialBtn
                      className='social-button'
                      href='https://www.instagram.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <InstagramIcon />
                    </SocialBtn> */}
          <SocialBtn
            href={`http://pinterest.com/pin/create/link/?url=https://www.printbed.com${window.location.pathname}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-button">
            <PinterestIcon />
          </SocialBtn>
        </SocialBtnsWrapper>
      </div>
    </Wrapper>
  );
}
