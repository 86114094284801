/** @format */

import {makeStyles} from '@material-ui/core/styles';

const CheckoutPageStyles = makeStyles(
  {
    backButton: {
      minWidth: '3.5rem',
      width: '3.5rem',
      padding: 0,
      position: 'absolute',
      top: '3.75rem',
      left: '3rem',
      '& svg': {
        fontSize: '3.5rem',
      },
    },
    nextButton: {
      margin: '2rem 0',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: '10rem 10rem 4rem 10rem',
    },
    pickupContainer: {
      display:'flex',
      justifyContent: 'left',
      paddingBottom:30,
      textAlign:'left'
    },
    pickUp:{
      boxSizing: 'border-box',
      width: 173,
      height: 80,
      overflow: 'hidden',
      borderRadius: 12,
      border: '2px solid #53affe',
      color:'black',
      display:'flex',
      backgroundColor:'white',
      marginRight: 20,
      boxShadow:'rgba(0, 0, 0, 0.15) 4px 6px 8px 0px'
    },
    pickUpInActive: {
      boxSizing: 'border-box',
      width: 173,
      height: 80,
      overflow: 'hidden',
      borderRadius: 12,
      border: '2px solid rgb(245, 245, 245)',
      color:'black',
      display:'flex',
      backgroundColor:'rgb(245, 245, 245)',
      marginRight: 20,
    },
  },
  {name: 'checkoutPg'}
);

export default CheckoutPageStyles;
