/** @format */

import React, {useState} from 'react';
// Material UI Component Imports
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FilledInput from '@material-ui/core/FilledInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// Material UI Icon Imports
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {InputStyles} from './styles';

export default function ShippingInput({
  type = 'text',
  placeholder = '',
  errorText = 'Invalid entry',
  required = false,
  label = '',
  id = null,
  isError = false,
  fullWidth = true,
  onChange = e => {
    console.log('default ShippingInput onChange func');
  },
  onBlur = e => {
    console.log('default ShippingInput onBlur func');
  },
  value = '',
  multiline = false,
  rows = 1,
  addedClasses = '',
  inputProps = {},
  onKeyDown,
}) {
  const inpStyles = InputStyles();
  let inputId = null;
  if (id === null) {
    if (label === '') {
      inputId = 'custom-input-' + Math.floor(Math.random() * 100).toString();
    } else {
      inputId = `custom-${label}-input`;
    }
  } else {
    inputId = id;
  }
  return (
    <div className={inpStyles.wrapper + ' ' + addedClasses}>
      <InputLabel
        className={inpStyles.label + (label === '' ? ' no-lbl' : '')}
        htmlFor={inputId}
        disableAnimation
      >
        {label}
        {required ? '*' : ''}
      </InputLabel>
      <FilledInput
        multiline={multiline}
        rows={rows}
        id={inputId}
        className={inpStyles.actualInput}
        type={type}
        placeholder={placeholder}
        disableUnderline={true}
        error={isError}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        inputProps={{...inputProps}}
      />
      <FormHelperText
        className={inpStyles.errorTxt + ' ' + (isError ? 'invalid' : 'valid')}
        error={isError}
      >
        {errorText}
      </FormHelperText>
    </div>
  );
}

export function ShippingSelect({
  errorText = 'Invalid entry',
  required = false,
  label = '',
  id = null,
  isError = false,
  fullWidth = true,
  passedOnChange = e => {
    console.log('default ShippingInput onChange func');
  },
  onBlur = e => {
    console.log('default ShippingInput onBlur func');
  },
  values = ['none'],
  addedClasses = '',
  value = '',
}) {
  const inpStyles = InputStyles();
  const [country, setCountry] = useState(values[0]);
  value = {
    value: country.name,
    code: country.code,
  };
  isError = false;
  let inputId = null;
  if (id === null) {
    if (label === '') {
      inputId = 'custom-input-' + Math.floor(Math.random() * 100).toString();
    } else {
      inputId = `custom-${label}-input`;
    }
  } else {
    inputId = id;
  }
  return (
    <div className={inpStyles.wrapper + ' ' + addedClasses}>
      <InputLabel
        className={inpStyles.label + (label === '' ? ' no-lbl' : '')}
        htmlFor={inputId}
        disableAnimation
      >
        {label}
        {required ? '*' : ''}
      </InputLabel>
      <Select
        variant="filled"
        value={country.code}
        onChange={e => {
          const selectedCountry = values.find(c => c.code === e.target.value);
          setCountry(selectedCountry);
          passedOnChange(e);
        }}
        id={inputId}
        className={inpStyles.selector}
        IconComponent={ExpandMoreIcon}
        error={isError}
        disableUnderline
        MenuProps={{
          variant: 'menu',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {values.length > 0
          ? values.map(val => {
              return (
                <MenuItem key={val.code + '-' + val.name} value={val.code}>
                  {val.name}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </div>
  );
}

export function ShippingCheckBox({
  errorText = 'Invalid entry',
  required = false,
  label = '',
  id = null,
  isError = false,
  fullWidth = true,
  passedOnChange = e => {
    console.log('default ShippingInput onChange func');
  },
  onBlur = e => {
    console.log('default ShippingInput onBlur func');
  },
  defaultChecked = false,
  addedClasses = '',
  name = '',
}) {
  const inpStyles = InputStyles();
  const [checked, setChecked] = useState(defaultChecked);
  isError = false;
      // eslint-disable-next-line
  let inputId = null;
  if (id === null) {
    if (label === '') {
      inputId = 'custom-input-' + Math.floor(Math.random() * 100).toString();
    } else {
      inputId = `custom-${label}-input`;
    }
  } else {
    inputId = id;
  }

  const handleChange = e => {
    let tmp = e.target.checked;
    setChecked(tmp);
    passedOnChange(tmp);
  };
  return (
    <div className={inpStyles.wrapper + ' ' + addedClasses}>
      <FormControlLabel
        className={inpStyles.checkBoxLabel}
        control={
          <Checkbox
            id={id}
            color="primary"
            className={inpStyles.checkBox}
            checked={checked}
            onChange={handleChange}
            name={name}
          />
        }
        label={label}
      />
    </div>
  );
}
