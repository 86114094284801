const structuredDataSingle = (item,url) => {
    if(!item) {
        return JSON.stringify({});
    }
    let data = {
        "@context": "http://schema.org",
        "@type": "Product",
        "name": item.name,
        "description": item.desc,
        "sku": item.id,
        "image": item.imgSrc[0],
        "brand": {
          "@type": "Brand",
          "name": "PrintBed"
        },
        "weight": {
          "@type": "QuantitativeValue",
          "value": 1,
          "unitText": "kg"
        },
        "itemCondition": "http://schema.org/NewCondition",
        "color": item.tags.color,
        "offers": {
          "@type": "Offer",
          "priceCurrency": "USD",
          "price": item.price/100,
          "availability": item.qoh>0?"http://schema.org/InStock":"https://schema.org/OutOfStock",
          "url": url,    
          "priceValidUntil": "2024-12-31",
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "name": "Standard Return Policy",
            "url": "https://www.printbed.com/return-policy",
            "applicableCountry": {
              "@type": "Country",
              "name": "US"
            },
            "returnPolicyCategory": "http://schema.org/MerchantReturnFiniteReturnWindow",
            "merchantReturnDays": "30",
            "returnFees": "http://schema.org/ReturnShippingFees",
            "returnMethod": "http://schema.org/ReturnByMail",
            "returnShippingFeesAmount": {
              "@type": "MonetaryAmount",
              "value": "5.00",
              "currency": "USD"
            }
          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "10.00",
              "currency": "USD"
            },
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 0,
                "maxValue": 0,
                "unitCode": "DAY"
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 5,
                "unitCode": "DAY"
              }
            },
            "shippingDestination": {
              "@type": "DefinedRegion",
              "addressCountry": "US"
            }
          }
        },
        // "review": {
        //   "@type": "Review",
        //   "author": {
        //     "@type": "Person",
        //     "name": "Customer Name"
        //   },
        //   "datePublished": "2024-01-01",
        //   "reviewBody": "This is a placeholder review. Replace with actual customer feedback.",
        //   "reviewRating": {
        //     "@type": "Rating",
        //     "ratingValue": "5",
        //     "bestRating": "5"
        //   }
        // },
        // "aggregateRating": {
        //   "@type": "AggregateRating",
        //   "ratingValue": "4.5",
        //   "reviewCount": "20"
        // }
      }
      

    return JSON.stringify(data);
};


const faqStructuredData = () => {
  const data = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Are all your products made in the USA using ethical labor?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely. We source everything from the USA, from the plastics to the boxes. We have a small staff that makes the products and fulfills your orders here in Michigan."
      }
    },
    {
      "@type": "Question",
      "name": "If I purchase on a weekend will it arrive the next day?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Unfortunately not. We do not ship on the weekends. We are a small staff and we take the weekends off. Your order will ship first thing the next business day."
      }
    },
    {
      "@type": "Question",
      "name": "How secure is my payment information?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We use Stripe as our payment processor which is used by many web retailers. You can learn more about Stripe security <a href='https://docs.stripe.com/security'>here</a>."
      }
    },
    {
      "@type": "Question",
      "name": "Do you offer overnight shipping?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, all our orders ship overnight for just $10. This is to the continental U.S."
      }
    },
    {
      "@type": "Question",
      "name": "When will my subscription ship?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Your Premium subscription will ship within 1 business day of placing your initial order. Your next month's roll will ship on your next billing date or on the following business day if it's on the weekend or holiday."
      }
    },
    {
      "@type": "Question",
      "name": "How do I cancel my subscription?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can click on your avatar in the top right after you login and click on profile. Go to the subscriptions tab and click more info. Click on cancel my subscription."
      }
    },
    {
      "@type": "Question",
      "name": "I have a problem with my order. How do I contact you to get it corrected?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Please send us an email at support@printbed.com with your order number and we will handle the issue as quickly as possible."
      }
    },
    {
      "@type": "Question",
      "name": "If I buy the same color filament in a year, will it match the color I have now?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our primary focus is on high-quality 3D printing spools so we ensure the colors of filaments always stay the same, no matter when you place an order."
      }
    },
    {
      "@type": "Question",
      "name": "How is Forge PLA+ different from standard PLA?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Forge PLA+ is stronger and more heat resistant than conventional PLA so it may need a slightly higher heating temperature. Forge PLA+ can also be heated and allowed to cool slowly to increase its strength and make it more durable and workable."
      }
    },
    {
      "@type": "Question",
      "name": "Will your filaments work in my printer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Most desktop 3D printers that use Fused Deposition Modeling (FDM) can handle PLA, Forge PLA+, ABS, and PETG, which we offer."
      }
    },
    {
      "@type": "Question",
      "name": "What is the strongest filament you offer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The strongest filament we offer is ABS which is a high-strength and heat-resistant material, making it an excellent choice for printing items such as prototypes. It’s also ideal for high-impact applications like phone cases, tool handles, housings, brackets, mounts, and more."
      }
    },
    {
      "@type": "Question",
      "name": "The Limited Edition PLA that I wanted is out of stock, will it be coming back?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our Limited Edition colors are available for a limited time only, so grab them while you can! Some of these colors may become part of our standard collection based on popular demand."
      }
    },
    {
      "@type": "Question",
      "name": "How should I store my 3D printing filament?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The key to preserving your 3D printing filament during storage is to keep it dry. It’s therefore best to store filaments in an airtight container in a cool, dry place out of sunlight. This will prevent the material from absorbing moisture from the surrounding air. For long-term storage, vacuum sealing is an excellent choice as it creates an airtight seal around the spool."
      }
    }
  ]
  };
  return JSON.stringify(data);
}

export {structuredDataSingle, faqStructuredData};