/** @format */

import {makeStyles} from '@material-ui/core/styles';

const HeaderStyles = makeStyles(theme => ({
  avatarBtn: {
    width: '4rem',
    height: '4rem',
  },
  cartBadge: {
    backgroundColor: '#FF617D',
  },
  cartContainer: {
    padding: '17rem 3rem 5rem 5rem',
    width: '36vw',
    height: '100vh',
    backgroundColor: '#f6f6f6',
  },
  header: {},
  likesBadge: {
    backgroundColor: '#FF617D',
  },
  printBedLogo: {
    height: '100%',
    position: 'relative',
    '& .logo': {
      height: '100%',
      marginTop:-5,
    },
    '& .logolg': {
      height: 600,
      marginTop: -200,
      position: 'absolute',
      left: -70,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#000',
    // backgroundColor: '#fff',
    height: 80,
  },
  toolbarButton: {
    fontSize: '2rem',
    color: '#FFF',
    // color: '#221F1F',
    opacity: '50%',
    height: '100%',
    flexGrow: '1',
    display: 'flex',
    '&:hover': {
      opacity: '100%',
    },

    '&:focus': {
      opacity: '100%',
    },
  },
  toolbarIcon: {
    color: '#FFFFFF',
    // color: '#221F1F',
  },
  navLinks: {
    alignItems: 'unset',
    height: '100%',
  },
  navLink: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    minWidth: 100,
    paddingTop: '1rem',
    width: '13rem',
    '.active& button': {
      opacity: 1,
    },
    '& span': {
      fontFamily: "'Rubik', sans-serif;",
      fontSize: '2.2rem',
    },
    '&::after': {
      visibility: 'hidden',
      alignSelf: 'flex-end',
      width: '100%',
      height: '1rem',
      content: '""',
      borderRadius: '4px 4px 0px 0px',
      '.active&': {
        visibility: 'visible',
        // Page-specific coloring
        '.about&': {
          backgroundColor: '#de4297',
        },
        '.shop&': {
          backgroundColor: '#24a9df',
        },
        '.QUEU3D&': {
          backgroundColor: '#37b349',
        },
        '.pages&': {
          backgroundColor: '#ffc930',
        },
        '.elements&': {
          backgroundColor: '#ed4036',
        },
        '.premium&': {
          backgroundColor: '#eb1c23',
        },
        '.process&': {
          backgroundColor: '#ffc9a0',
        },
        '.blog&': {
          backgroundColor: '#ae42de',
        },
      },
    },
  },
}));

export default HeaderStyles;
