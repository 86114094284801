/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
// Custom components
import {BannerBtn, Default} from '../index';
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';


const TextWrapper = styled('div')({
  margin:'0 40rem',
  '&.mbl':{
    margin:'0 6rem',
  }
})
//#region Styled components
const LtdTxt = styled(Typography)(
  {
    color: '#000',
    fontFamily: 'Barlow Condensed, sans-serif',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '1',
    marginBottom: '3rem',
    userSelect: 'none',
    width: '100%',
    '&.mbl': {
      fontSize: '6rem',

    },
    '&.line1': {
      marginBottom: '0',
    },
    '&.line2': {
      fontSize: '8rem',
      fontWeight: '700',
      marginBottom: '1rem',
      '&.mbl': {
        fontSize: '10rem',
      },
    },
    '&.line3': {
      fontSize: '3rem',
      fontWeight: 'normal',
      '& a':{
        color:'#000',
        fontFamily: 'Barlow Condensed, sans-serif',
        textDecoration:'underline'
      },
      '&.mbl': {
        fontSize: '9rem',
        textAlign:'justify',
      },
    },
  },
  {name: 'ltd-txt'}
);
//#endregion

export default function LimitedTogether({isMobile}) {
  return (
    <Default
      bgColor="#acffea"
      mainImgSrc="https://cdn.sunsh1n3.com/home/limitedtogetherv3.png"
      padding="10% 5%"
      isMobile={isMobile}
      textLoc="top">
      <TextWrapper className={isMobile ? 'mbl' : ''}>
        <LtdTxt align="center" className={`line1 ${isMobile ? 'mbl' : ''}`}>
          Starting at $25
        </LtdTxt>
        <LtdTxt variant="h2" align="center" className={`line2 ${isMobile ? 'mbl' : ''}`}>
          Get Creative with Limited Edition Colors
        </LtdTxt>
        <LtdTxt align="center" className={`line3 ${isMobile ? 'mbl' : ''}`}>
        Our Limited Edition colors are only available for a short time. Although these Limited Edition 3D printing spools are unlikely to come back once we run out of stock, popular Limited Edition colors may become part of our standard collection on special demand. You can even be among the first to buy our innovative 3D filaments if you choose our <Link to="/shop/PLA%20Ltd/Mystery-1.75mm-1kg/">Mystery Spool</Link>. 
        </LtdTxt>
      </TextWrapper>
      <BannerBtn
        colorVariant="black"
        custHeight={isMobile ? '20rem' : undefined}
        custWidth={isMobile ? '' : 'content-fit'}
        fontSize={isMobile ? '8rem' : '2.75rem'}
        to="/shop/PLA Ltd/">
        Order Limited Edition Colors Now
      </BannerBtn>
    </Default>
  );
}
