/** @format */

import {makeStyles} from '@material-ui/core/styles';

const FooterStyles = makeStyles({
  footerContainer: {
    backgroundColor: '#221F1F',
    color: '#FFFFFF',
    width: '100%',
    overflow: 'hidden',
    zIndex: 10,
  },
  printBedTM: {
    lineHeight: '2em',
    fontWeight: '400',
  },
  divider: {
    backgroundColor: '#5A5A5A',
    height: 1,
    width: '80%',
    margin: 'auto',
  },
  socialButton: {
    color: '#FFFFFF',
  },
  footerSection: {
    margin: '2em 7vw',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '&#footer-top': {
      alignItems: 'center',
    },
    '&#copyright-txt': {
      justifyContent: 'center',
      margin: '3rem 0',
    },
  },
  categories: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '4rem 7vw',
  },
  category: {
    '& .title': {
      marginBottom: '1.5rem',
    },
  },
  input: {
    color: '#FFFFFF',
    height: '2em',
  },
  buttonGroup: {
    marginTop: '.5em',
  },
  subscribeDiv: {
    flexGrow: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& #subscribe-txt': {
      lineHeight: '5.5em',
      marginRight: '1rem',
      marginLeft: '11.6vw',
    },
  },
  emailInput: {
    border: '1px solid transparent',
    borderRadius: 4,
    padding: '0',
    width: '15vw',
    height: '5rem',
    color: '#fff',
    paddingLeft: '2rem',
    fontWeight: '400',
    '&.Mui-focused': {
      border: '1px solid #fff',
    },
  },
  subscribeButton: {
    color: '#FFFFFF',
    border: '1px solid #FFFFFF',
    margin: 2,
  },
  copyrightText: {
    textAlign: 'center',
    color: '#999',
  },
  greyText: {
    color: '#bbb',
    lineHeight: '2.5rem',
    '& a': {
      display: 'flex',
    },
  },
});

export default FooterStyles;
