/** @format */

import {makeStyles} from '@material-ui/core/styles';

const PaypalCheckoutResultsPageStyles = makeStyles({
  container: {
    padding: '14rem 2rem 4rem',
  },
});

export default PaypalCheckoutResultsPageStyles;
