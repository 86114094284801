/** @format */

import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import BlogStyles from './styles';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

const Blog = ({isMobile}) => {
  const pgStyles = BlogStyles(isMobile)();
  const {slug} = useParams();

  const [post, setPost] = useState();
  const [checked, setChecked] = React.useState([0]);
  useEffect(() => {
    function fetchProducts() {
      fetch('/api/blog/post/'+slug)
        .then(response => response.json())
        .then(data => setPost(data));
      // setProducts(result);
    }
    fetchProducts();
    return function () {};
  }, []);


  return (
    <div style={{width: '100%', height: '100%'}}>
      {/* <div className={pgStyles.bg}></div> */}
      {post && post.metaTitle  && <Helmet>
            <title>
            {post.metaTitle}
            </title>
            <meta
              name="description"
              content={post.metaDesc}
            />
          </Helmet>}
      <div className={pgStyles.container}>
        <div style={{width:isMobile?'100%':'80%'}}>
            {post &&
            <div className={pgStyles.posts}>
                <div className="cat">{post.category.toTitleCase()}</div>
                <h1>{post.title}</h1>
                <h4>{new Date(post.date).toLocaleDateString()}</h4>
                <div>{parse(post.content)}</div>
            </div>}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Blog;
