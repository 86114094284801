/** @format */

import React from 'react';
// Material UI Components
import Typography from '@material-ui/core/Typography';
// Styled Components
import SavedInfoCard from '../styled/saved-info-card';
// Styles
import {PaymentSourceElementStyles} from './styles';

const PaymentSourceElement = props => {
  const {paymentSourceInfo, updateSelectedPaymentSourceId, isSelected} = props;
  const {id, brand, last4, name, exp_month, exp_year} = paymentSourceInfo;
  const pgStyles = PaymentSourceElementStyles();

  const bullet = String.fromCharCode(8226);
  const bullets = bullet + bullet + bullet + bullet;

  return (
    <SavedInfoCard
      onClick={() =>
        updateSelectedPaymentSourceId({
          id,
          brand,
          last4,
          name,
          exp_month,
          exp_year,
        })
      }
      selected={isSelected}
      title={brand + ' ' + bullets + last4}
    >
      <div className={pgStyles.cardInfo}>
        <Typography variant="inherit">{name}</Typography>
        <Typography variant="inherit">
          Exp. {exp_month < 10 ? '0' + exp_month : exp_month}/
          {exp_year.toString().substring(2)}
        </Typography>
      </div>
    </SavedInfoCard>
  );
};

export default PaymentSourceElement;
