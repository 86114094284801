/** @format */

import {makeStyles} from '@material-ui/core/styles';

const ModalStyles = makeStyles(theme => ({
  btnClose: {
    display: 'flex',
    alignItems: 'center',
    color: '#f7f7f7',
    position: 'absolute',
    right: '5rem',
    top: '5rem',
    '& a': {
      marginRight: '1.5rem',
    },
  },
  forgotPassword: {
    '& .submit': {
      marginTop: '2rem',
    },
  },
  formDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    opacity: 1,
    width: '100%',
    height: '100%',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    border: '0px solid transparent',
    padding: '0 21.875vw 0',
    paddingTop: '17%',
    backdropFilter: 'blur(2px)',
    overflowX: 'visible',
    '&:focus': {
      border: '0px solid transparent',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    '&.forgot-password': {
      '& .submit': {
        marginTop: '2rem',
        lineHeight: '1.95',
      },
    },
    '& .eye:hover': {
      cursor: 'pointer',
    },
    '& .forgot-pwd-link': {
      color: '#0099FF',
      padding: '0',
      marginTop: '2rem',
    },
    '& .form-switch-txt': {
      color: '#f7f7f7',
      margin: '3rem 0',
      fontWeight: '400',
      '& .link': {
        color: '#f7f7f7',
        marginLeft: '1.5rem',
        '&:hover': {
          color: '#0099ff',
          cursor: 'pointer',
        },
      },
    },
    '& .form-wrapper': {
      width: '100%',
      position: 'relative',
    },
    '& .input': {
      marginBottom: '2rem',
      '&.Mui-error': {
        backgroundColor: '#FFD4C7',
      },
    },
    '& .password-reqs': {
      position: 'absolute',
      width: '15vw',
      top: '17rem',
      left: '105%',
      marginRight: '2rem',
      padding: '3rem',
      backgroundColor: '#FFF9E0',
      borderRadius: '8px',
      tabindex: '0',
      '&.reset': {
        top: '0',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    '& .remember-me': {
      color: '#fff',
      fontWeight: '400',
      marginTop: '2rem',
      '& .rm-checkbox': {
        '& input': {
          borderRadius: '6px',
        },
      },
    },
    '& .submit': {
      backgroundColor: '#0099FF',
      color: '#fff',
      fontSize: '2rem',
      fontWeight: '400',
      lineHeight: '1.25',
      marginTop: '4rem',
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
      '&:hover': {
        backgroundColor: '#0044aa',
      },
      '&.try-again': {
        backgroundColor: 'red',
      },
      '.forgot-password &': {
        marginTop: '2rem',
      },
    },
    '& .title': {
      color: '#FAC02E',
      width: '100%',
    },
    '& #error-tooltip': {
      height: '100%',
      marginLeft: '2rem',
      maxWidth: '50rem',
      '& .MuiTooltip-tooltip': {
        maxWidth: '50vw',
        padding: '1.5rem 4rem',
        borderRadius: '10px',
      },
    },
    '& .icon.eye': {
      color: '#666',
    },
  },
  loginTxt: {
    color: '#f7f7f7',
    marginBottom: '4rem',
    '& .login-link': {
      marginLeft: '1.5rem',
      '&:hover': {
        color: '#0099ff',
        cursor: 'pointer',
      },
    },
  },
  modal: {
    border: '0px solid transparent',
  },
  paper: {
    marginTop: '10vh',
    '& .bg-img': {
      position: 'absolute',
      zIndex: -1,
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      backgroundImage: 'radial-gradient(circle,#0099FF,#000)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    // '& .form-div': {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'flex-start',
    //   opacity: 1,
    //   width: '100%',
    //   height: '100%',
    //   transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    //   border: '0px solid transparent',
    //   padding: '0 21.875vw 0',
    //   paddingTop: '17%',
    //   backdropFilter: 'blur(2px)',
    //   overflowX: 'visible',
    //   '&:focus': {
    //     border: '0px solid transparent',
    //   },
    //   backgroundColor: 'rgba(0, 0, 0, 0.55)',
    //   '&.forgot-password': {
    //     '& .submit': {
    //       marginTop: '2rem',
    //       lineHeight: '1.95',
    //     },
    //   },
    //   '& .eye:hover': {
    //     cursor: 'pointer',
    //   },
    //   '& .forgot-pwd-link': {
    //     color: '#0099FF',
    //     padding: '0',
    //     marginTop: '2rem',
    //   },
    //   '& .form-switch-txt': {
    //     color: '#f7f7f7',
    //     margin: '3rem 0',
    //     fontWeight: '400',
    //     '& .link': {
    //       color: '#f7f7f7',
    //       marginLeft: '1.5rem',
    //       '&:hover': {
    //         color: '#0099ff',
    //         cursor: 'pointer',
    //       },
    //     },
    //   },
    //   '& .form-wrapper': {
    //     width: '100%',
    //     position: 'relative',
    //   },
    //   '& .input': {
    //     marginBottom: '2rem',
    //     '&.Mui-error': {
    //       backgroundColor: '#FFD4C7',
    //     },
    //   },
    //   '& .password-reqs': {
    //     position: 'absolute',
    //     width: '15vw',
    //     top: '17rem',
    //     left: '105%',
    //     marginRight: '2rem',
    //     padding: '3rem',
    //     backgroundColor: '#FFF9E0',
    //     borderRadius: '8px',
    //     tabindex: '0',
    //     '&.reset': {
    //       top: '0',
    //     },
    //     '&:hover': {
    //       cursor: 'default',
    //     },
    //   },
    //   '& .remember-me': {
    //     color: '#fff',
    //     fontWeight: '400',
    //     marginTop: '2rem',
    //     '& .rm-checkbox': {
    //       '& input': {
    //         borderRadius: '6px',
    //       },
    //     },
    //   },
    //   '& .submit': {
    //     backgroundColor: '#0099FF',
    //     color: '#fff',
    //     fontSize: '2rem',
    //     fontWeight: '400',
    //     lineHeight: '1.25',
    //     marginTop: '4rem',
    //     paddingTop: '1.5rem',
    //     paddingBottom: '1.5rem',
    //     '&:hover': {
    //       backgroundColor: '#0044aa',
    //     },
    //     '&.try-again': {
    //       backgroundColor: 'red',
    //     },
    //     '.forgot-password &': {
    //       marginTop: '2rem',
    //     },
    //   },
    //   '& .title': {
    //     color: '#FAC02E',
    //     width: '100%',
    //   },
    //   '& #error-tooltip': {
    //     height: '100%',
    //     marginLeft: '2rem',
    //     maxWidth: '50rem',
    //     '& .MuiTooltip-tooltip': {
    //       maxWidth: '50vw',
    //       padding: '1.5rem 4rem',
    //       borderRadius: '10px',
    //     },
    //   },
    //   '& .icon.eye': {
    //     color: '#666',
    //   },
    // },
  },
}));

export default ModalStyles;

export const LoginFormStyles = makeStyles({
  form: {
    width: '100%',
  },
  input: {
    border: '2px solid #eee',
    borderRadius: '10px',
    backgroundColor: '#f6f6f6',
    fontWeight: '400',
    marginBottom: '2rem',
    '&:hover': {
      backgroundColor: '#f6f6f6',
      borderColor: '#0099FF',
    },
    '&.Mui-focused': {
      backgroundColor: '#f6f6f6',
    },
    '& .MuiInputLabel-root': {
      color: '#aaa',
    },
  },
  rememberMe: {
    color: '#fff',
    fontWeight: '400',
    marginTop: '2rem',
    '& .rm-checkbox': {
      '& input': {
        borderRadius: '6px',
      },
    },
  },
  submit: {
    marginTop: '4rem',
  },
});

export const RegisterAfterCheckoutStyles = makeStyles({
  wrapper: {
    height: '50vh',
    width: '100%',
    display: 'flex',
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '-3rem !important',
    padding: '4rem 10rem',
    borderRadius: 16,
    backgroundImage: "url('/images/register-after-checkout-bg.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& .hidden': {
      display: 'none',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    color: 'white',
    width: '40%',
  },
  passwords: {
    display: 'flex',
    position: 'relative',
    '& .input': {
      width: '75%',
      marginBottom: '1rem',
    },
    '& .password-reqs': {
      position: 'absolute',
      right: 0,
    },
  },
  submitButton: {
    backgroundColor: '#0099FF',
    color: 'white',
    width: '75%',
  },
  registeredText: {
    textAlign: 'center',
    width: '100%',
  },
});
