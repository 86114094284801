/** @format */

import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
// Custom components
// Material UI
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {makeStyles, withStyles} from '@material-ui/core';
// Styles

//#region Function version
//#region Styling

const styles = makeStyles(
  {
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  {name: 'file-picker'}
);

const StyledBtn = withStyles({
  root: {
    borderRadius: '4px',
    fontSize: '1.75rem',
    fontWeight: '600',
    height: 'min-content',
    padding: '1rem 2rem',
  },
  contained: {
    backgroundColor: '#1899ff',
    color: '#fff',
  },
  outlined: {
    backgroundColor: '#fff',
    borderColor: '#1899ff',
    color: '#1899ff',
  },
})(Button);

const StyledInput = withStyles({
  input: {
    padding: '1.5rem',
  },
  root: {
    marginLeft: '1rem',
  },
})(OutlinedInput);

// #endregion

const FilePicker = forwardRef((props, ref) => {
  const {getFile = () => {}} = props;
  const pgStyles = styles();
  const fileInput = useRef();
  const [fileObj, setFileObj] = useState(null);

  function handleFileSelect(e) {
    const file = fileInput.current.files[0];
    if (file.size > 1048576) {
      console.log('Error file too big');
    } else {
      setFileObj(file);
      getFile(file);
    }
  }

  function handlePassedClick() {
    fileInput.current.click();
  }

  useImperativeHandle(ref, () => {
    return {handlePassedClick: handlePassedClick};
  });

  return (
    <div className={pgStyles.wrapper}>
      <StyledBtn
        disableElevation
        onClick={e => handlePassedClick()}
        variant="outlined"
        style={{display: 'none'}}
      >
        {fileObj ? 'Choose File' : 'Upload Image'}
      </StyledBtn>
      <input
        onChange={handleFileSelect}
        ref={fileInput}
        style={{display: 'none'}}
        type="file"
      />
      {fileObj ? (
        <StyledInput
          placeholder="No file selected"
          readOnly
          value={fileObj?.name || ''}
        />
      ) : null}
    </div>
  );
});
export default FilePicker;

//#endregion

