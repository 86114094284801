/** @format */

import React, {useState, useEffect} from 'react';
// Custom Components
import BulkComp from '../../components/item/bulk/index.js';
import BulkMobile from '../../components/mobile/item/bulk/index.js';
import {useLocation} from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TAGS_WHITELIST = {
  material: ['PLA', 'FORGE', 'PLA Ltd', 'PETG'],
  diameter: ['1.75mm', '2.85mm'],
};

function tagInclusionCheck(tags) {
  for (const tagName in tags) {
    if (TAGS_WHITELIST.hasOwnProperty(tagName.toString())) {
      if (!TAGS_WHITELIST[tagName].includes(tags[tagName])) {
        return false;
      }
    }
  }
  return true;
}

export default function MinisPage(props) {
  const {cartUtils, isMobile} = props;
  const relatedItems = [];
  const [individualSpools, setIndividualSpools] = useState([]);
  const [masterBulk, setMasterBulk] = useState(null);
  const query = useQuery();
  const defaultTags = {
    color: 'Black',
    material: 'PLA',
    weight: '1kg',
    diameter: '1.75mm',
    colorName: 'Black',
  };

  useEffect(() => {
    if (relatedItems[0] === undefined) {
      fetch(
        `/api/items?q=${encodeURIComponent(
          'tags.weight=1kg&tags.material=PLA&tags.material=PLA%20Ltd&tags.material=Bulk'
        )}&filter=name,tags,desc,price,qoh,extraInfo,data,imgSrc,previewVideos&filterInclusion=true`
      )
        .then(resp => resp.json())
        .then(data => {
          let tempData = data;
          let tempItems = [];
          tempData.forEach(item => {
            if (tagInclusionCheck(item.tags)) {
              tempItems.push(item);
            } else if (item.tags.color === 'BulkBox') {
              setMasterBulk(item);
            }
          });

          if (query.get('discord') !== 'true') {
            tempItems = tempItems.filter(
              item => item.data.discordOnly !== true
            );
          }

          setIndividualSpools(tempItems);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {individualSpools[0] !== undefined && masterBulk !== null ? (
        isMobile?<BulkMobile
        baseItem={masterBulk}
        individualSpools={individualSpools}
        cartUtils={cartUtils}
      />:
        <BulkComp
          baseItem={masterBulk}
          individualRolls={individualSpools}
          cartUtils={cartUtils}
        />
      ) : (
        <div style={{flexGrow: 1}}>rest</div>
      )}
    </>
  );
}
