/** @format */

import React from 'react';
// react-router imports
import {Route, Switch, useHistory} from 'react-router-dom';
// Material UI
import Button from '@material-ui/core/Button';
// Component imports
import Orders from '../../components/mobile/orders';
import Payment from '../../components/mobile/payment';
import Profile from '../../components/mobile/profile';
import Shipping from '../../components/mobile/shipping';
import Subscription from '../../components/mobile/subscriptions';
// Feather Icons
import {ArrowLeft} from 'react-feather';
import AccountPageStyles from './styles';

const Account = props => {
  const {userData} = props;
  const pgStyles = AccountPageStyles();
  const history = useHistory();

  const goBack = () => {
    history.replace('/');
  };

  return (
    <div className={pgStyles.root}>
      <Button className={pgStyles.backButton} onClick={goBack}>
        <ArrowLeft />
      </Button>
      <Switch>
        <Route path={'/account/orders'}>
          <Orders userData={userData} />
        </Route>
        <Route path={'/account/payment'}>
          <Payment userData={userData} />
        </Route>
        <Route path={'/account/subscription'}>
          <Subscription userData={userData} />
        </Route>
        <Route path={'/account/profile'}>
          <Profile userData={userData} />
        </Route>
        <Route path={'/account/shipping'}>
          <Shipping userData={userData} />
        </Route>
      </Switch>
    </div>
  );
};

export default Account;
