/** @format */

import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

const StyledLabel = withStyles(
  {
    root: {
      color: '#666',
      fontSize: '6rem',
      marginBottom: '2rem',
    },
  },
  {index: 10000}
)(InputLabel);

export default StyledLabel;
