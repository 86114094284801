/** @format */

import React from 'react';
// Custom components
import {BannerBtn, Default} from '../index';
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

//#region Styled components
const MainTxt = styled(Typography)(
  {
    color: '#fff',
    fontFamily: 'Barlow Condensed, sans-serif',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '1',
    marginBottom: '3rem',
    userSelect: 'none',
    width: '50rem',
    '&.line1': {
      marginBottom: '0',
    },
    '&.line2': {
      fontSize: '8rem',
      fontWeight: '700',
      marginBottom: '1rem',
    },
    '&.line3': {
      fontSize: '3rem',
      fontWeight: '600',
    },
  },
  {name: 'main-txt'}
);
//#endregion

export default function MainBanner({isMobile}) {
  return (
    <Default
      bgColor="#000"
      isMobile={isMobile}
      mainImgSrc="https://cdn.sunsh1n3.com/home/Main/Main.png"
      mainImgStyles={{
        objectPosition: 'center bottom',
        width: '50%',
      }}
      padding="10% 5% 0"
      textLoc="top">
      <MainTxt align="center" className="line1">
        Welcome to
      </MainTxt>
      <img
        src="https://cdn.sunsh1n3.com/home/Main/Main_Logo.png"
        style={{
          padding: '0 25%',
          width: '75%',
        }}
      />
      <MainTxt align="center" className="line3">
        Made in the USA
      </MainTxt>
      <BannerBtn colorVariant="yellow" fontWeight="400" to="/shop">
        Shop Now
      </BannerBtn>
    </Default>
  );
}
