/** @format */

import React from 'react';
import PremiumComp from '../../components/premium';
import PremiumCompMobile from '../../components/mobile/premium';

export default function PremiumPg(props) {
  const {isMobile}=props;
  return isMobile?<PremiumCompMobile  {...props}/>:<PremiumComp {...props}/>;
}
