import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {styled} from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const StyledBreadCrumbs =  styled(Breadcrumbs)(
    {
        backgroundColor:'white',
        position:'relative', 
        color: 'grey', 
        fontSize: '1.5rem', 
        padding: '.8rem',
        '&.mbl': {
            fontSize:'6rem'
        }
    },
    {name: 'breadcrumbs'}
  );


export default function ({isMobile = false}) {
    const location = useLocation();
    const crumbs = location.pathname.split('/').filter((x) => x);
    if(crumbs.length<=0 || location.pathname.indexOf('queu3d') >= 0) {
        return null;
    } else {
        return (
            <>
                <script type="application/ld+json">
                    {structuredData(crumbs)}
                </script>
                <StyledBreadCrumbs separator={<NavigateNextIcon fontSize={isMobile?"large":"small"} />} className={isMobile?'mbl':''}>
                    <Link to={'/'}>Home</Link> 
                    {crumbs.map((crumb,index)=>{ 
                        const to = `/${crumbs.slice(0, index + 1).join("/")}/`;
                        return <Link to={to}>{crumb.replace(/-/g,' ').toTitleCase()}</Link> 
                    })}
                </StyledBreadCrumbs>
            </>
        )
    }
}

const structuredData = (crumbs=[]) => {
    let data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": crumbs.map((crumb,index)=>{
            const url = `https://www.printbed.com/${crumbs.slice(0, index + 1).join("/")}/`;
          return {
            "@type": "ListItem",
            "position": index+1,
            "name": crumb.replace(/-/g,' ').toTitleCase(),
            "item": url
          }  
        })
      }

    return JSON.stringify(data);
  }