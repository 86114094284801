/** @format */

import React from 'react';
import FeatureStyles from './styles';
import Carousel from 'react-material-ui-carousel';
import Grid from '@material-ui/core/Grid';

const Pages = () => {
  const pgStyles = FeatureStyles();
  return (
    <div className={pgStyles.bg}>
      <Carousel
        style={{minHeight: 1000}}
        animation="slide"
        indicators={false}
        interval={8000}
      >
        <div style={{flexGrow: 1, backgroundColor: '#444444', height: '100%'}}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={6}
              className={pgStyles.containers}
              style={{flexDirection: 'column'}}
            >
              <div style={{maxWidth: '65%'}}>
                <p
                  style={{
                    fontSize: '20rem',
                    color: '#7C7C7C',
                    margin: 0,
                    lineHeight: '20rem',
                  }}
                >
                  Precision
                </p>
                <p
                  style={{
                    fontSize: '15rem',
                    color: '#FFCA05',
                    margin: 0,
                    lineHeight: '15rem',
                  }}
                >
                  Guaranteed
                </p>
                <p style={{fontSize: '2.5rem', color: 'white', margin: 0}}>
                  When making our material, we monitor it using precision
                  equipment and super fancy mathematics. You will love the
                  reliability and consistency of every spool!
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              className={pgStyles.containers}
              style={{flexDirection: 'column', alignItems: 'center'}}
            >
              <p
                style={{
                  fontSize: '20rem',
                  color: '#FFCA05',
                  margin: 0,
                  lineHeight: '20rem',
                }}
              >
                ±0.02<sup style={{fontSize: '10rem'}}>MM</sup>
              </p>
            </Grid>
          </Grid>
        </div>
        <div style={{flexGrow: 1, backgroundColor: '#F7F7F7', height: '100%'}}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={6}
              className={pgStyles.containers}
              style={{flexDirection: 'column'}}
            >
              <div style={{maxWidth: '65%'}}>
                <p
                  style={{
                    fontSize: '20rem',
                    color: '#444444',
                    margin: 0,
                    lineHeight: '20rem',
                  }}
                >
                  Vividly
                </p>
                <p
                  style={{
                    fontSize: '20rem',
                    color: '#444444',
                    margin: 0,
                    lineHeight: '20rem',
                  }}
                >
                  consistent
                </p>
                <p
                  style={{
                    fontSize: '20rem',
                    color: '#FF6F31',
                    margin: 0,
                    lineHeight: '20rem',
                  }}
                >
                  color
                </p>
                <p style={{fontSize: '2.5rem', color: 'black', margin: 0}}>
                  You can rely on our rich and brilliant colors to be dependably
                  true and unvarying, even if you want to order more of that
                  same beautiful shade years from now.
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              className={pgStyles.containers}
              style={{flexDirection: 'column', alignItems: 'end'}}
            >
              <div
                style={{
                  backgroundColor: '#FF25B3',
                  width: '90%',
                  height: 122,
                  borderTopLeftRadius: 61,
                  borderBottomLeftRadius: 61,
                  marginTop: -61,
                  marginBottom: 40,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: '#047CC7',
                  width: '90%',
                  height: 122,
                  borderTopLeftRadius: 61,
                  borderBottomLeftRadius: 61,
                  marginBottom: 40,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: '#00AB4E',
                  width: '90%',
                  height: 122,
                  borderTopLeftRadius: 61,
                  borderBottomLeftRadius: 61,
                  marginBottom: 40,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: '#25BEFF',
                  width: '90%',
                  height: 122,
                  borderTopLeftRadius: 61,
                  borderBottomLeftRadius: 61,
                  marginBottom: 40,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: '#FF3F21',
                  width: '90%',
                  height: 122,
                  borderTopLeftRadius: 61,
                  borderBottomLeftRadius: 61,
                  marginBottom: 40,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: '#FF6F31',
                  width: '90%',
                  height: 122,
                  borderTopLeftRadius: 61,
                  borderBottomLeftRadius: 61,
                  marginBottom: 40,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: '#FFF',
                  width: '90%',
                  height: 122,
                  borderTopLeftRadius: 61,
                  borderBottomLeftRadius: 61,
                }}
              ></div>
            </Grid>
          </Grid>
        </div>
        <div style={{flexGrow: 1, backgroundColor: '#00ab4e', height: '100%'}}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={6}
              className={pgStyles.containers}
              style={{flexDirection: 'column'}}
            >
              <div style={{maxWidth: '65%'}}>
                <p
                  style={{
                    fontSize: '20rem',
                    color: '#00C65A',
                    margin: 0,
                    lineHeight: '20rem',
                  }}
                >
                  99.9%
                </p>
                <p
                  style={{
                    fontSize: '20rem',
                    color: '#FFCA05',
                    margin: '0 0 20px 0',
                    lineHeight: '20rem',
                  }}
                >
                  Biospool
                </p>
                <p style={{fontSize: '2.5rem', color: 'white', margin: 0}}>
                  Earth is our favorite planet! To prove it, everything we sell
                  is made from eco-friendly materials - right down to the spool
                  itself!
                </p>
              </div>
            </Grid>
            <Grid item xs={6} className={pgStyles.containers}>
              <img src="/images/flower.svg" alt="Bio Spool" />
            </Grid>
          </Grid>
        </div>
        <div style={{flexGrow: 1, backgroundColor: '#000', height: '100%'}}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={6}
              className={pgStyles.containers}
              style={{flexDirection: 'column'}}
            >
              <div style={{maxWidth: '85%', alignItems: 'flex-end'}}>
                <p
                  style={{
                    fontSize: '15rem',
                    color: '#444444',
                    margin: 0,
                    lineHeight: '20rem',
                  }}
                >
                  $5 Overnight
                </p>
                <p
                  style={{
                    fontSize: '16rem',
                    color: '#FF6F31',
                    margin: '0 0 20px 0',
                    lineHeight: '20rem',
                  }}
                >
                  everywhere
                </p>
                <p style={{fontSize: '2.5rem', color: 'white', margin: 0}}>
                  Our material is made in the USA. You want it fast, with
                  premium quality and inexpensive, too? You got it! Shipped
                  Overnight everywhere in the continental USA, for just $5.
                </p>
              </div>
            </Grid>
            <Grid item xs={6} className={pgStyles.containerImg}>
              <img
                src="/images/features/shippingspool.png"
                alt="Overnight Shipping"
              />
            </Grid>
          </Grid>
        </div>
      </Carousel>
    </div>
  );
};

export default Pages;
