/** @format */

import React from 'react';
// Material UI Component Imports
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function ItemTabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography variant="body1" component="div">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
