/** @format */

import React, { useEffect, useState, useRef } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {io} from 'socket.io-client';
// Custom components

// Material UI
import {styled} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//#region Styled Comps
const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    backgroundColor:'black'
  },
  {name: 'vending-wrap'}
);
//#endregion

let countDown;

const VideoWrapper = styled('div')(
    {
        position: 'relative',
        // paddingBottom: '56.25%',
        // paddingTop: 0,
        backgroundColor:'black',
        height: '100%',
        '& iframe': {
            position: 'absolute',
            // top: '33%',
            left: 14,
            border:0,
            width: '100%',
            height: '100%',
            transform: 'rotate(-90deg)',
        }
    },
    {name: 'video-wrap'}
  );


const VideoWrapperZoom = styled('div')(
  {
      position: 'relative',
      paddingBottom: '56.25%',
      marginLeft: 350,
      height: 800,
      overflow:'hidden',
      width:260,
      borderRadius:20,
      '& iframe': {
          position: 'absolute',
          top: 0,
          left: -100,
          border:0,
          width: 1080,
          // height: '100%',
          // transform: 'rotate(-90deg)',
      },
      transform: 'rotate(-90deg)',
  },
  {name: 'video-wrap'}
);

const SUBSCRIPTION_CHECKOUT_LINK = '/subscription/';


const ColorButton = styled(Button)({
  color: 'white',
  borderColor:'white'
});

const StyledButton = styled(Button)({
  width: 46
});

export default function PremiumComp(props) {
    const frameRef = useRef();
  const {userData} = props;
  const {orderId} = useParams();
  const history = useHistory();
  const [open,setOpen] = useState(localStorage.getItem("vending")!=="true");
  const [disabled,setDisabled] = useState(true);
  const [vend,setVend] = useState({status:"No Credits"});
  const {enqueueSnackbar} = useSnackbar();

  useEffect(()=>{

    async function fetchData() {
        await socketInit();
        getVend();
    };
    fetchData();
  },[]);

  const socketInit = async () => {
    const wsUrl = window.location.origin
    .replace('https', 'wss')
    .replace('http', 'ws');
  
    const opts = {
        reconnectionDelayMax: 10000,
        path: '/api/vend/sockets/',
        reconnection: false,
        transports:
        process.env.NODE_ENV !== 'production'
            ? ['polling']
            : ['polling', 'websocket'], //cannot proxy more that one ws using the hpm so resort to polling in dev.
    };
  
    const socket = io(wsUrl, opts);
    socket.on('connect',()=>{
      socket.emit('onOrder',{orderId});
    });
   
    socket.on('orderUpdate',(data)=>{
        getVend();
    });
  }

  const getVend = () => {
    fetch(`/api/vend`, {
        method: 'POST',
        body: JSON.stringify({orderId}),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(resp => resp.json())
        .then(res => {
            setVend(res);
        }).catch((error)=>{
            
        });
  };

  useEffect(()=>{
    setDisabled(vend.status !== 'active');
  },[vend]);

  const goHome =  () => {
    history.push('/');
  }

  const buyMystery = () => {
    history.push('/shop/PLA Ltd/Mystery-1.75mm-1kg/');
  }

  const handleVend = channel => (e) => {
    setDisabled(true);
    const url = `/api/vend/${vend.id}/dispense/${channel}`
    fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(resp => resp.json())
        .then(res => {
            setTimeout(()=>{
                setVend(res);
            },3000);
        }).catch((error)=>{
            
        });
  }
  
  const handleClose = () => {
    localStorage.setItem("vending",true);
    setOpen(false);
  }

  return (
    <Wrapper>
        <Grid container spacing={3}>
            <Grid item xs={6} style={{backgroundImage:'url(/images/BG_Spool.jpg)', backgroundSize:'cover', nackgroundRepeat:'none',textAlign: 'center'}}>
              <Grid container spacing={3}>
                <Grid item xs={12} style={{height:300}}></Grid>
                <Grid item xs={12} style={{}}>
                  <img src="/images/VendingMachine_Logo.png" style={{width:'80%'}}/>
                </Grid>
                <Grid item xs={12} style={{backgroundImage:'url(/images/Box.png)', display:'flex',height: 300, backgroundSize:'contain', backgroundRepeat:'no-repeat',backgroundPosition:'center',justifyContent:'center',textAlign: 'center'}}>
                 <table style={{height:300, width:558}}>
                  <tbody>
                    <tr>
                      <td style={{width:175, height:50}}><Typography variant="h4" style={{color:'white'}}>Credits</Typography></td>
                      <td style={{height:50}}>
                      {vend.status === 'active'?<Typography variant="h4" style={{color:'white'}}>TIME LEFT TO GET SPOOLS</Typography>:<Typography variant="h4" style={{color:'white',textTransform: 'capitalize'}}>{vend.status}</Typography>}
                      </td>
                    </tr>
                    <tr>
                      <td  style={{width:175}}><Typography variant="h1" style={{color:'white'}}>{vend.status !== 'complete'?vend.qty-vend.choices?.length||0:0}</Typography></td>
                      <td style={{height:250}}>
                        {vend.status === 'active' && <Timer ts={vend.ts} choiceTime={vend.choiceTime} />}
                        {vend.status === 'complete' && <ColorButton variant="outlined" size="large" onClick={goHome}>Continue Shopping</ColorButton>}
                        {vend.status === 'No Credits' && <ColorButton variant="outlined" size="large" onClick={buyMystery}>Buy Mystery Spools</ColorButton>}
                      </td>
                    </tr>
                  </tbody>
                 </table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} style={{minHeight:900,position:'relative'}} >
              <Grid container>
                <Grid item xs={12} style={{textAlign:'center', height:880}}>
                  <VideoWrapper>
                      {/* <div style={{position:'absolute', bottom:0, left:0, width:'100%', height:100, backgroundColor:'black', zIndex:10}}></div> */}
                      <iframe
                          src={`https://player.twitch.tv/?channel=printbedvending&parent=${window.location.hostname}`}
                          height="1080"
                          // width="1080"
                          style={{backgroundColor:'#000'}}
                          allowFullScreen>
                      </iframe>
                  </VideoWrapper>
                </Grid>
                <Grid item xs={12} style={{textAlign:'center', zIndex:10}}>
                  <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <StyledButton value={1} disabled={disabled} onClick={handleVend(1)}>1</StyledButton>
                    <StyledButton value={2} disabled={disabled} onClick={handleVend(2)}>2</StyledButton>
                    <StyledButton value={3} disabled={disabled} onClick={handleVend(3)}>3</StyledButton>
                    <StyledButton value={4} disabled={disabled} onClick={handleVend(4)}>4</StyledButton>
                    <StyledButton value={5} disabled={disabled} onClick={handleVend(5)}>5</StyledButton>
                    <StyledButton value={6} disabled={disabled} onClick={handleVend(6)}>6</StyledButton>
                    <StyledButton value={7} disabled={disabled} onClick={handleVend(7)}>7</StyledButton>
                    <StyledButton value={8} disabled={disabled} onClick={handleVend(8)}>8</StyledButton>
                    <StyledButton value={9} disabled={disabled} onClick={handleVend(9)}>9</StyledButton>
                    <StyledButton value={10} disabled={disabled} onClick={handleVend(10)}>10</StyledButton>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">How to use the Vending Machine</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color:'black'}}>
          <em>Vending machine available on desktop ONLY.</em>
          <ol>
            <li>Must be a Premium member and logged in to your account.</li>
            <li>Place an order for a Mystery spool/s.</li>
            <li>After checkout, you will be prompted to “Go to the Vending Machine.”</li>
            <li>Choose which number (1-10) you would like for your Mystery Spool.</li>
          </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
          </DialogActions>
      </Dialog>
    </Wrapper>
  );
}




const Timer = ({ts=new Date().getTime(), choiceTime=120000}) => {
    const [minute,setMinute] = useState();
    const [seconds,setSeconds] = useState();

    useEffect(()=>{
        clearInterval(countDown);
        time();
    },[ts])

    const time = () => {
            // Set the date we're counting down to
        let countDownDate = new Date(ts).getTime();
        countDown = setInterval(()=>{
            var now = new Date().getTime();
        
            // Find the distance between now and the count down date
            var distance = now - countDownDate;
            distance = choiceTime - distance;
            // Time calculations for days, hours, minutes and seconds
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
            setMinute(Math.abs(minutes));
            setSeconds(Math.abs(seconds));
            // If the count down is finished, write some text
            if (distance < 0) {
              clearInterval(countDown);
            }
        },1000);
    }

    return <div style={{color:'white', fontSize:'10rem'}}>{minute}:{seconds<10?'0'+seconds:seconds}</div>

}