/** @format */

import {withStyles} from '@material-ui/core/styles';
import {SunValidatedInput} from '@sunsh1n3/sun-validated-input';

const StyledInput = withStyles(
  {
    root: {
      backgroundColor: '#f6f6f6',
      border: '2px solid transparent',
      borderRadius: '3px',
      fontSize: '6rem',
      height: '12rem',
      '&.Mui-focused': {
        border: '2px solid #0099ff',
      },
    },
  },
  {index: 1}
)(SunValidatedInput);

export default StyledInput;
