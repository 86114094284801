/** @format */

import React from 'react';
// Feather icons
import {ArrowLeft, ShoppingBag} from 'react-feather';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// Custom components
import SubmitButton from '../styled/submit-button';
// Styles
import {EmptyCartStyles} from './styles';

function VwToPx(vw) {
  let oneVw = (window.innerWidth / 100.0).toFixed(2);
  return oneVw * vw;
}

export default function EmptyCart(props) {
  const pgStyles = EmptyCartStyles();
  const {close} = props;
  return (
    <div className={pgStyles.container}>
      <IconButton className={pgStyles.btnClose} onClick={close}>
        <ArrowLeft color="#3d3d3d" />
      </IconButton>
      <div className={pgStyles.iconWrapper}>
        <ShoppingBag color="#bbb" size={VwToPx(28)} />
      </div>
      <div className={pgStyles.textWrapper}>
        <Typography className="titletxt" variant="h3">
          Your cart is empty
        </Typography>
        <Typography className="subtxt" variant="body1"></Typography>
      </div>
      <div className="spacer"></div>
      <SubmitButton className={pgStyles.bigCloseBtn} onClick={close}>
        Continue Shopping
      </SubmitButton>
    </div>
  );
}
