/** @format */

import React, {useEffect, useState} from 'react';
// Material UI Components
import Typography from '@material-ui/core/Typography';
// Custom imports
import {SunValidator} from '@sunsh1n3/sun-input-validation';
import {RequirementFunc} from './password-reqs';
import SubmitButton from '../styled/submit-button';
import StyledInput from '../styled/input';
// Styles
import ModalStyles from './styles';

const PassForm = props => {
  const {rid, email, setIsSuccess} = props;
  const pgStyles = ModalStyles();
  const [showPasswordReqs, setShowPasswordReqs] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isValidForm, setIsValidForm] = useState(false);

  const [passwordReqs, setPasswordReqs] = useState([
    {
      text: '8 characters',
      isValid: false,
      showIcon: false,
      key: 'req-length',
    },
    {
      text: '1 capital letter',
      isValid: SunValidator.containsCapital(password),
      showIcon: false,
      key: 'req-capital',
    },
    {
      text: '1 lowercase letter',
      isValid: false,
      showIcon: false,
      key: 'req-lowercase',
    },
    {
      text: '1 number',
      isValid: false,
      showIcon: false,
      key: 'req-number',
    },
    {
      text: '1 symbol (*&%$#@ etc.)',
      isValid: false,
      showIcon: false,
      key: 'req-symbol',
    },
  ]);

  const reqUpdateFuncs = [
    SunValidator.containsEnoughChars,
    SunValidator.containsCapital,
    SunValidator.containsLowercase,
    SunValidator.containsNumber,
    SunValidator.containsSpecialChar,
  ];

  const submitPassword = e => {
    e.preventDefault();
    if (isValidForm) {
      const body = {
        email: email,
        rid: rid,
        password: password,
        password2: password2,
      };

      fetch('/api/auth/password/reset', {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(resp => {
          if (!resp.ok) {
            if (resp.status === 404) {
              // setEmailHasAccount(false);
            } // else generic error
          } else {
            // setEmailHasAccount(true);
            setIsSuccess(true);
          }
          return resp.json();
        })
        .catch(e => {
          console.log('e: ', e);
        });
    }
  };

  const handlePasswordChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    let newReqs = passwordReqs;
    for (let i = 0; i < passwordReqs.length; i++) {
      newReqs[i].showIcon = newPassword !== '';
      newReqs[i].isValid = reqUpdateFuncs[i](newPassword);
    }
    setPasswordReqs(newReqs);
  };

  const isPasswordMatchErr = e => {
    let newVal = e.target.value;
    return newVal !== password && newVal !== '';
  };

  useEffect(() => {
    setIsValidForm(
      SunValidator.isValidPassword(password) && password === password2
    );
  }, [password, password2]);

  return (
    <>
      <Typography variant="h3" className={pgStyles.title} noWrap>
        Reset Password
      </Typography>
      <Typography className={pgStyles.bodyText}>
        Enter your new password below.
      </Typography>
      <form className={pgStyles.form} noValidate onSubmit={submitPassword}>
        <StyledInput
          className="input"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="password"
          name="password"
          autoComplete="new-password"
          placeholder="New password"
          type="password"
          errorText={'Invalid password'}
          showErrorTooltip={false}
          showValidIcon={false}
          onChange={handlePasswordChange}
          onFocus={e => setShowPasswordReqs(true)}
          onBlur={e => setShowPasswordReqs(false)}
        />
        <div style={{display: !showPasswordReqs ? 'none' : ''}}>
          <Typography variant="h6">Password must contain at least:</Typography>
          {passwordReqs.map((r, i) => {
            return RequirementFunc(r);
          })}
        </div>
        <StyledInput
          className="input"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="password2"
          name="password2"
          autoComplete="off"
          placeholder="Confirm password"
          type="password"
          onChange={e => {
            setPassword2(e.target.value);
          }}
          errorFunc={isPasswordMatchErr}
          errorText="Passwords do not match"
          errorTooltipOnlyOnFocus={true}
          showErrorTooltip={true}
          showErrorArrow={false}
          showErrorIcon={false}
          errorTooltipPosition="right"
          showValidIcon={false}
          errorPopperProps={{
            disablePortal: true,
          }}
          overrideValidation={true}
          valid={password === password2}
        />
        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="submit"
        >
          Reset Password
        </SubmitButton>
      </form>
    </>
  );
};

export default function ResetPasswordForm(props) {
  const {formSwitch} = props;
  const pgStyles = ModalStyles();
  const [isSuccess, setIsSuccess] = useState(false);
  function ResetSuccess(props) {
    return (
      <>
        <Typography variant="h3" className={pgStyles.title}>
          Password Successfully Reset
        </Typography>
        <Typography className={pgStyles.bodyText}>
          You can now use your new password to sign in to your account!
        </Typography>
        <SubmitButton
          fullWidth
          variant="contained"
          className="submit"
          color="primary"
          onClick={e => formSwitch('login')}
        >
          Sign In
        </SubmitButton>
      </>
    );
  }

  return isSuccess ? (
    <ResetSuccess />
  ) : (
    <PassForm setIsSuccess={setIsSuccess} {...props} />
  );
}
