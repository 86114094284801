/** @format */
// import Tap from '@tapfiliate/tapfiliate-js';

export default function paypalButtons({shippingData, totals, serviceCode}) {
  const paypal = window?.paypal;
  const currencyCode = 'USD';
  if (paypal) {
    paypal
      .Buttons({
        style: {
          color: 'silver',
        },
        createOrder: (data, actions) => {
          return fetch('/api/cart')
            .then(resp => resp.json())
            .then(cart => {
              const {
                grandTotal,
                subtotal,
                shipping,
                tax,
                discount = 0,
              } = totals;
              const {address, address2, city, name, phone, state, zipCode} =
                shippingData;
              let freeShipping = true; // This should be good to get taken out. Free shipping should pass in $0 for shipping,
              // otherwise, grandTotal will be off and Paypal will Error
              const items = cart.lines.map(line => {
                if (
                  freeShipping === true &&
                  line.data &&
                  line.data.freeGround !== true
                ) {
                  freeShipping = false;
                }
                return {
                  name: line.name,
                  unit_amount: {
                    currency_code: currencyCode,
                    value: line.price,
                  },
                  quantity: line.qty,
                  description: line.id,
                  sku: line.itemId,
                };
              });

              //@todo move to sales service
              return actions.order.create({
                application_context: {
                  shipping_preference: 'SET_PROVIDED_ADDRESS',
                },
                purchase_units: [
                  {
                    amount: {
                      currency_code: currencyCode,
                      value: grandTotal,
                      breakdown: {
                        item_total: {
                          currency_code: currencyCode,
                          value: subtotal,
                        },
                        discount: {
                          currency_code: currencyCode,
                          value: discount,
                        },
                        shipping: {
                          currency_code: currencyCode,
                          value: shipping,
                        },
                        tax_total: {
                          currency_code: currencyCode,
                          value: tax,
                        },
                      },
                    },
                    items: items,
                    shipping: {
                      name: {full_name: name},
                      address: {
                        address_line_1: address,
                        address_line_2: address2,
                        country_code: 'US',
                        phone: phone,
                        postal_code: zipCode,
                        admin_area_1: state,
                        admin_area_2: city,
                      },
                    },
                  },
                ],
              });
            });
        },
        onApprove: (data, actions) => {
          data.serviceCode = serviceCode;
          return fetch('/api/checkout/paypal/captureResults', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(resp => {
              return resp.json();
            })
            .then(order => {
              const {amount, tax = 0, shippingCost = 0} = order.payment;
              let commission = (amount - tax - shippingCost) / 100;
              window.tap.conversion(order.id, commission);
              window.location.href = `/checkout/paypalResults?status=success&orderId=${order.payment.refId}`;
            });
        },
        onError: (data, actions) => {
          window.location.href = '/checkout/paypalResults?status=error';
        },
      })
      .render('#paypal-button-container');
  } else {
    // console.log('window?.paypal: ', paypal)
  }
}
