/** @format */

import React from 'react';
// Custom components
// Material UI
import {styled} from '@material-ui/core/styles';

//#region Styled components

const Wrapper = styled('div')(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    maxHeight: '18.5vh',
    width: '100%',
  },
  {name: 'small-wrap'}
);

//#endregion

export default function Small(props) {
  return <Wrapper></Wrapper>;
}
