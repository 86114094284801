/** @format */

import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {SunValidator} from '@sunsh1n3/sun-input-validation';
import {SunValidatedInput} from '@sunsh1n3/sun-validated-input';
import {RequirementFunc} from './password-reqs';
import {RegisterAfterCheckoutStyles} from './styles';

const RegisterAfterCheckout = props => {
  const classes = RegisterAfterCheckoutStyles();
  const {customerInfo, saleId} = props;
  const {email, firstName, lastName} = customerInfo;
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPasswordReqs, setShowPasswordReqs] = useState(false);
  const [didRegister, setDidRegister] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [passwordReqs, setPasswordReqs] = useState([
    {
      text: '8 characters',
      isValid: false,
      showIcon: false,
      key: 'req-length',
    },
    {
      text: '1 capital letter',
      isValid: SunValidator.containsCapital(password),
      showIcon: false,
      key: 'req-capital',
    },
    {
      text: '1 lowercase letter',
      isValid: false,
      showIcon: false,
      key: 'req-lowercase',
    },
    {
      text: '1 number',
      isValid: false,
      showIcon: false,
      key: 'req-number',
    },
    {
      text: '1 symbol (*&%$#@ etc.)',
      isValid: false,
      showIcon: false,
      key: 'req-symbol',
    },
  ]);

  const handlePasswordChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    let newReqs = passwordReqs;
    for (let i = 0; i < passwordReqs.length; i++) {
      newReqs[i].showIcon = newPassword !== '';
      newReqs[i].isValid = reqUpdateFuncs[i](newPassword);
    }
    setPasswordReqs(newReqs);
  };

  const isPasswordMatchErr = e => {
    let newVal = e.target.value;
    return newVal !== password && newVal !== '';
  };

  const reqUpdateFuncs = [
    SunValidator.containsEnoughChars,
    SunValidator.containsCapital,
    SunValidator.containsLowercase,
    SunValidator.containsNumber,
    SunValidator.containsSpecialChar,
  ];

  const register = () => {
    let isFormValid = true;

    passwordReqs.forEach(req => {
      if (!req.isValid) {
        isFormValid = false;
      }
    });

    if (isFormValid && !isSubmitDisabled) {
      setIsSubmitDisabled(true);

      fetch('/api/auth/register', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          userId: email,
          password: password,
          password2: password2,
          fname: firstName,
          lname: lastName,
          saleId: saleId,
        }),
      }).then(() => {
        setDidRegister(true);
        setIsSubmitDisabled(false);
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={`${classes.form} ${didRegister ? 'hidden' : ''}`}>
        <Typography variant="h3">Create an account?</Typography>
        <Typography variant="body1">
          Create an account for {email} <br /> for free shipping on your next
          order
        </Typography>
        <div className={classes.passwords}>
          <div className={classes.passwordInputs}>
            <SunValidatedInput
              className="input"
              disableUnderline={true}
              required={true}
              fullWidth={false}
              id="password"
              name="password"
              autoComplete="new-password"
              placeholder="Password"
              type="password"
              errorText={'Invalid password'}
              showErrorTooltip={false}
              showValidIcon={false}
              onChange={handlePasswordChange}
              onFocus={e => setShowPasswordReqs(true)}
              onBlur={e => setShowPasswordReqs(false)}
              visibilityIconVariant="outlined"
            />
            <SunValidatedInput
              className="input"
              disableUnderline={true}
              required={true}
              fullWidth={false}
              id="password2"
              name="password2"
              autoComplete="off"
              placeholder="Confirm Password"
              type="password"
              onChange={e => {
                setPassword2(e.target.value);
              }}
              errorFunc={isPasswordMatchErr}
              errorText="Passwords do not match"
              errorTooltipOnlyOnFocus={true}
              showErrorTooltip={true}
              showErrorArrow={false}
              showErrorIcon={false}
              errorTooltipPosition="right"
              showValidIcon={false}
              errorPopperProps={{
                disablePortal: true,
              }}
              overrideValidation={true}
              valid={password === password2}
              visibilityIconVariant="outlined"
            />
            <Button className={classes.submitButton} onClick={register}>
              Submit
            </Button>
          </div>
          <div
            className={'password-reqs' + (showPasswordReqs ? '' : ' hidden')}
          >
            <Typography variant="h6">
              Password must contain at least:
            </Typography>
            {passwordReqs.map((r, i) => {
              return RequirementFunc(r);
            })}
          </div>
        </div>
      </div>
      <div
        className={`${classes.registeredText} ${!didRegister ? 'hidden' : ''}`}
      >
        <Typography variant="h2">
          You have registered an account for {email}.
        </Typography>
        <Typography variant="h3">
          Please check your email to finish making your account.
        </Typography>
      </div>
    </div>
  );
};

export default RegisterAfterCheckout;
