/** @format */

import React from 'react';
import CheckoutComponent from '../../components/checkout';

const Checkout = props => {
  return <CheckoutComponent key="checkout-comp" {...props} />;
};

export default Checkout;
