/** @format */

import React from 'react';
import FaqComp from '../../components/faq';
import { faqStructuredData } from '../../utils/structuredJSON';
import { Helmet } from 'react-helmet';
export default function FaqPg(props) {
  return <>
    <Helmet>
      <script type="application/ld+json">
          {faqStructuredData()}
      </script>
    </Helmet>
    <FaqComp {...props}/>;
  </>
}
