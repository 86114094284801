/** @format */

import React from 'react';
// Material UI imports
import {withStyles} from '@material-ui/core/styles';
// Custom Component Imports
import ShippingInput, {
  ShippingSelect,
  ShippingCheckBox,
} from './shipping-input';
import {isValidEmailEvent} from '../../utils/inputValidation';
// Styling import
import ShippingStyles from './styles';


let oldAddress;

class ShippingDetails extends React.PureComponent {
  constructor(props) {
    super();
    this.state = {
      customerInfo: {
        firstName: {value: '', isValid: false},
        lastName: {value: '', isValid: false},
        email: {value: '', isValid: false},
        country: {
          value: 'United States of America',
          code: 'USA',
          isValid: true,
        },
        address: {value: '', isValid: false},
        address2: {value: '', isValid: true},
        zipCode: {value: '', isValid: false},
        city: {value: '', isValid: false},
        phone: {value: '', isValid: false},
        note: {value: '', isValid: true},
        international: false,
      },
      localPickup: props.localPickup || false,
      emailList: false,
      onUpdate: props.updateFunc || (() => {}),
      showErrors: props.showErrors || false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.localPickup === true && this.props.localPickup !== true) {
      const newCustomerInfo = this.state.customerInfo;
      oldAddress = {...newCustomerInfo};
      newCustomerInfo.address.isValid = true;
      newCustomerInfo.city.isValid = true;
      newCustomerInfo.zipCode.isValid = true;
      newCustomerInfo.address2.isValid = true;
      this.setState({customerInfo: newCustomerInfo, ...this.state});
    } else if (this.props.localPickup === true && nextProps.localPickup !== true) {
      let newCustomerInfo = this.state.customerInfo;
      if(oldAddress) {
        newCustomerInfo = oldAddress;
      } else {
        newCustomerInfo.address.isValid = false;
        newCustomerInfo.address2.isValid = true;
        newCustomerInfo.city.isValid = false;
        newCustomerInfo.zipCode.isValid = false;
      }
      this.setState({customerInfo: newCustomerInfo, ...this.state});
    }
  }
  // TODO
  // Make API call to get valid shipping countries
  countries = [
    {name: 'United States of America', code: 'USA'},
    {name: 'Canada', code: 'CA'},
  ];

  name() {
    return 'Shipping';
  }

  isFormValid = () => {
    if(this.state.localPickup === true) {
      return (this.state.customerInfo.phone.isValid && this.state.customerInfo.email.isValid && this.state.customerInfo.firstName.isValid)
    } else {
      for (const key in this.state.customerInfo) {
        if (!this.state.customerInfo[key].isValid && key !== 'international' && key !== 'isPOBox') {
          console.log('Invalid form. ', key, ' is not valid.');
          return false;
        }
      }
    }

    return true;
  };

  getCustomerInfo = () => {
    let custVals = {};
    for (const key in this.state.customerInfo) {
      custVals[key] = this.state.customerInfo[key].value;
    }
    custVals.name = `${this.state.customerInfo.firstName.value} ${this.state.customerInfo.lastName.value}`;
    return custVals;
  };

  handleChange = event => {
    event.preventDefault();
    const dataType = event.target.id.split('-')[1];
    let newValue = event.target.value;
    const newCustomerInfo = this.state.customerInfo;
    let intl = false;
    switch (dataType) {
      case 'zipCode':
        let CAZip = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
        intl = CAZip.test(newValue);
        newCustomerInfo.international = intl;
        break;
      case 'address':
      case 'address2':
        var isPOBox = new RegExp(
          '\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b',
          'i'
        );
        if (isPOBox.test(newValue)) {
          newCustomerInfo.isPOBox = {value:true};
        }
        break;
      case 'phone':
        newValue = this.formatPhoneNum(newValue);
        break;
    }
    newCustomerInfo[dataType].value = newValue;
    this.setState({customerInfo: newCustomerInfo, ...this.state});
    this.handleValidation(event);
  };

  formatPhoneNum = text => {
    if (!text) return text; // empty/null check
    const numsOnly = text.replaceAll(/[^\d]/g, '');
    const numCount = numsOnly.length;
    if (numCount > 0) {
      if (numCount < 4) {
        return numsOnly;
      } else if (numCount < 7) {
        return `(${numsOnly.slice(0, 3)}) ${numsOnly.slice(3)}`;
      } else {
        return `(${numsOnly.slice(0, 3)}) ${numsOnly.slice(
          3,
          6
        )}-${numsOnly.slice(6, 10)}`;
      }
    }
    return numsOnly;
  };

  isOnlyNumbers = text => {
    const numsOnly = /^[0-9]*$/g;
    return numsOnly.test(text);
  };


  handleValidation = event => {
    const dataType = event.target.id.split('-')[1];
    const value = event.target.value;
    const trimVal = value.replaceAll(' ', '');
    let valid = false;
    const newCustomerInfo = this.state.customerInfo;
    var isPOBox = new RegExp(
      '\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b',
      'i'
    );
    switch (dataType) {
      case 'email':
        valid = isValidEmailEvent(event);
        break;
      case 'firstName':
      case 'lastName':
        valid = trimVal.length >= 2;
        break;
      case 'zipCode':
        valid = trimVal.length >= 5;
        break;
      case 'address':
          if (isPOBox.test(trimVal)) {
            newCustomerInfo.isPOBox = {value:true};
          }
          valid = value !== '';
          break;
      case 'address2':
        if (isPOBox.test(trimVal)) {
          newCustomerInfo.isPOBox = {value:true};
        }
        valid = true;
        break;
      case 'note':
        valid = true;
        break;
      case 'phone':
        const numsOnly = value.replaceAll(/[^\d]/g, '');
        valid = numsOnly.length === 10;
        break;
      default:
        valid = value !== '';
        break;
    }
    
    newCustomerInfo[dataType].isValid = valid;
    this.setState({customerInfo: newCustomerInfo, ...this.state});
    this.state.onUpdate(newCustomerInfo);
  };

  calcShowErrs = valName => {
    const isEmptyVal = this.state.customerInfo[valName].value === '';
    return (
      (isEmptyVal && this.state.showErrors) ||
      (!isEmptyVal && !this.state.customerInfo[valName].isValid)
    );
  };

  render = () => {
    const {classes} = this.props || {};
    return (
      <div className={classes.wrapper} key="shipping-wrapper">
        {this.props.localPickup === true?
          <>
            <div className={classes.nameFields + ' inp'} key="name-fields">
              <ShippingInput
                label="First Name"
                id="inp-firstName"
                required
                value={this.state.customerInfo.firstName.value}
                onChange={this.handleChange}
                onBlur={this.handleValidation}
                isError={this.calcShowErrs('firstName')}
                errorText="This field is required"
              />
              <ShippingInput
                required
                label="Last Name"
                id="inp-lastName"
                value={this.state.customerInfo.lastName.value}
                onChange={this.handleChange}
                onBlur={this.handleValidation}
                isError={this.calcShowErrs('lastName')}
                errorText="This field is required"
              />
            </div>
              <ShippingInput
                addedClasses="inp no-lbl"
                required
                type="email"
                label="Email Address"
                id="inp-email"
                value={this.state.customerInfo.email.value}
                onChange={this.handleChange}
                onBlur={this.handleValidation}
                isError={this.calcShowErrs('email')}
                errorText={
                  this.state.customerInfo.email.value === ''
                    ? 'This field is required'
                    : 'Invalid email address'
                }
              />
              <ShippingInput
                addedClasses="inp"
                required
                type="tel"
                label="Phone Number"
                id="inp-phone"
                value={this.state.customerInfo.phone.value}
                onChange={this.handleChange}
                onBlur={this.handleValidation}
                isError={this.calcShowErrs('phone')}
                errorText={
                  this.state.customerInfo.phone.value === ''
                    ? 'This field is required'
                    : 'Invalid phone number'
                }
              />
          </>:
          <>
            <div className={classes.nameFields + ' inp'} key="name-fields">
              <ShippingInput
                label="First Name"
                id="inp-firstName"
                required
                value={this.state.customerInfo.firstName.value}
                onChange={this.handleChange}
                onBlur={this.handleValidation}
                isError={this.calcShowErrs('firstName')}
                errorText="This field is required"
              />
              <ShippingInput
                required
                label="Last Name"
                id="inp-lastName"
                value={this.state.customerInfo.lastName.value}
                onChange={this.handleChange}
                onBlur={this.handleValidation}
                isError={this.calcShowErrs('lastName')}
                errorText="This field is required"
              />
            </div>
            <ShippingInput
              addedClasses="inp no-lbl"
              required
              type="email"
              label="Email Address"
              id="inp-email"
              value={this.state.customerInfo.email.value}
              onChange={this.handleChange}
              onBlur={this.handleValidation}
              isError={this.calcShowErrs('email')}
              errorText={
                this.state.customerInfo.email.value === ''
                  ? 'This field is required'
                  : 'Invalid email address'
              }
            />
            <ShippingCheckBox
              checked={this.state.emailList}
              name="emailList"
              label="Subscribe to newsletter"
            />
            <ShippingSelect
              addedClasses="inp"
              required
              label="Country"
              id="inp-country"
              value={this.state.customerInfo.country}
              passedOnChange={e => {
                const newValue = e.target.value;
                const newCustomerInfo = {...this.state.customerInfo};
                newCustomerInfo['country'] = {
                  value: this.countries.find(c => c.code === newValue).name,
                  code: newValue,
                  isValid: true,
                };
                newCustomerInfo.international = newValue === 'CA';
                this.setState({customerInfo: newCustomerInfo});
              }}
              values={[...this.countries]}
              isError={
                this.state.showErrors && !this.state.customerInfo.country.isValid
              }
              errorText="This field is required"
            />
            <ShippingInput
              addedClasses="inp"
              required
              label="Street Address"
              id="inp-address"
              value={this.state.customerInfo.address.value}
              onChange={this.handleChange}
              onBlur={this.handleValidation}
              isError={this.calcShowErrs('address')}
              errorText="This field is required"
            />
            <ShippingInput
              addedClasses="inp"
              label="Street Address 2"
              id="inp-address2"
              value={this.state.customerInfo.address2.value}
              onChange={this.handleChange}
              onBlur={this.handleValidation}
            />
            <ShippingInput
              addedClasses="inp"
              required
              label="City"
              id="inp-city"
              type="note"
              value={this.state.customerInfo.city.value}
              onChange={this.handleChange}
              onBlur={this.handleValidation}
              isError={this.calcShowErrs('city')}
              errorText={
                this.state.customerInfo.city.value === ''
                  ? 'This field is required'
                  : 'Invalid City'
              }
            />
            <ShippingInput
              required
              type="text"
              addedClasses="inp"
              label="Zip Code"
              id="inp-zipCode"
              value={this.state.customerInfo.zipCode.value}
              onChange={this.handleChange}
              onBlur={this.handleValidation}
              isError={this.calcShowErrs('zipCode')}
              errorText={
                this.state.customerInfo.zipCode.value === ''
                  ? 'This field is required'
                  : 'Invalid Zip Code'
              }
            />
            <ShippingInput
              addedClasses="inp"
              required
              type="tel"
              label="Phone Number"
              id="inp-phone"
              value={this.state.customerInfo.phone.value}
              onChange={this.handleChange}
              onBlur={this.handleValidation}
              isError={this.calcShowErrs('phone')}
              errorText={
                this.state.customerInfo.phone.value === ''
                  ? 'This field is required'
                  : 'Invalid phone number'
              }
            />
            <ShippingInput
              addedClasses="inp"
              multiline
              rows={4}
              label="Order Note"
              id="inp-note"
              value={this.state.customerInfo.note.value}
              onChange={this.handleChange}
              onBlur={this.handleValidation}
            />
        </>}
      </div>
    );
  };
}

export default withStyles(ShippingStyles)(ShippingDetails);
