/** @format */

import React, {useEffect, useState} from 'react';
// import Tap from "@tapfiliate/tapfiliate-js";
import clsx from 'clsx';
import {detect} from 'detect-browser';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';

// Material UI imports
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import PlaneIcon from '@material-ui/icons/AirplanemodeActiveOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
// Feather Icon imports
import {ArrowLeft} from 'react-feather';
// Component imports
import Shipping from '../../components/mobile/shipping';
import Payment from '../../components/mobile/payment';
import CheckoutResult from '../../components/mobile/checkoutResult';
import SubscriptionConfirm from '../../components/mobile/orderConfirm/subscription';
import LocalPickup from '../../components/mobile/localpickup';

// Style import
import CheckoutPageStyles from './styles';

const browser = detect();

const Checkout = props => {
  const {userData, cartUtils=()=>{}, updateMemberShip} = props;
  const history = useHistory();
  const pgStyles = CheckoutPageStyles();
  const {enqueueSnackbar,closeSnackbar} = useSnackbar();
  const [checkoutStep, setCheckoutStep] = useState(0);
  const [checkoutStatus, setCheckoutStatus] = useState('error');
  const [shippingData, setShippingData] = useState({});
  const [paymentInfo, setPaymentInfo] = useState();
  const [isForm, setIsForm] = useState(false);
  const [isReviewOrder, setIsReviewOrder] = useState(false);
  const [localPickup,setLocalPickup] = useState('ground');
  const [enabled,setEnabled] = useState(false);
  useEffect(() => {
    setIsForm(userData.loggedIn !== true);
    // cartUtils.getTotals();
    fetch(`/api/items/?q=${encodeURIComponent('options.material=PLA+Sub&active=true')}`)
    .then(resp => {
      return resp.json();
    })
    .then(async resp => {
      // console.log(resp)
      if(resp && resp[0]) {
        const {qoh}= resp[0]
        if(qoh>5) {
          const currentSub = await checkSub();
          if (currentSub === true) {
            const action = key => (
              <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'6rem', marginRight:'2rem'}}>
                  Dismiss
              </Button>
              );
            enqueueSnackbar('You already have an active subscription.', {variant: 'error', persist: true,action});
          } else {
            setEnabled(true);
          }
        } else {
          closedSubscriptions();
        }
      }
    });
    // eslint-disable-next-line
  }, []);


  const checkSub = () => {
    return fetch('/api/subscription', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(resp => {
      return resp.json();
    }).then(result => {
      return result.valid;
    });
  }

  useEffect(() => {
    setIsForm(!userData.loggedIn);
    // eslint-disable-next-line
  }, [checkoutStep]);

  function handleShippingSubmit(newInfo) {
    if(enabled === true) {
      setShippingData(newInfo);
      cartUtils.international = newInfo.address.country === 'CA';
      cartUtils.getShipping(newInfo.address.postal_code);
      isReviewOrder ? setCheckoutStep(2) : setCheckoutStep(1);
    } else {
      closedSubscriptions();
    }
  }

  function closedSubscriptions () {
    const action = key => (
      <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'6rem', marginRight:'2rem'}}>
          Dismiss
      </Button>
      );
    enqueueSnackbar('We are currently not taking subscriptions due to high demand. Please check back soon.', {variant: 'error', persist: true,action});
  }

  function handlePaymentSubmit(submittedToken) {
    // console.log(submittedToken)
    setIsReviewOrder(true);
    setPaymentInfo(submittedToken);
    setCheckoutStep(2);
  }

  function goBack() {
    history.push('/');
  }

  async function checkout(couponData) {
    setCheckoutStatus('processing');
    if (paymentInfo.tokenId || paymentInfo.card.id) {
      let payment = {};
      if (paymentInfo.tokenId) {
        payment['token'] = paymentInfo.tokenId;
      }
      if (paymentInfo.card.id) {
        payment[paymentInfo.card.object||'card'] = paymentInfo.card.id;
      }
      const shipping = shippingData.address.line1
        ? {
            address: shippingData.address.line1,
            address2: shippingData.address.line2,
            city: shippingData.address.city,
            state: shippingData.address.state,
            zipCode: shippingData.address.postal_code,
            email: shippingData.email,
            phone: shippingData.phone,
            name: shippingData.name,
            country: shippingData.country,
            firstName: shippingData.firstName,
            lastName: shippingData.lastName,
            notes:shippingData.notes || ''
          }
        : shippingData;

      shipping.serviceCode = 95;

      const body = {
        payment,
        customerInfo: shipping,
        couponName: '',
        paymentService: 'stripe',
      };

      if(cartUtils.international === true) {
        body.customerInfo.shippingQuote = cartUtils.shipping;
        body.customerInfo.serviceCode = 92;
      }

      fetch('/api/subscription', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(resp => {
          setCheckoutStatus(resp.ok ? 'success' : 'error');
          return resp.json();
        })
        .then(result => {
          // console.log('result: ', result)
          const {amount,tax=0,shippingCost=0} = result.payment;
          let commission = ((amount-tax-shippingCost)/100);
          window.tap.conversion(result.id, commission);
          try{
            updateMemberShip();
          } catch(e) {
            
          }
        })
        .then(setCheckoutStep(3));
    }
  }
  
  const bulkShipping = (method) => {
    const action = key => (
      <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'2rem', marginRight:'2rem'}}>
          Dismiss
      </Button>
    );
    enqueueSnackbar(`Bulk Items will ship Fedex Home Delivery and take 2-7 business days. Your other items will ship ${method}`, {variant: 'info', persist: true,action});

  }

  function getStepPage(step) {
    switch (step) {
      case 3:
        return <CheckoutResult status={checkoutStatus} />;
      case 2:
        return (
          <SubscriptionConfirm
            payment={paymentInfo}
            shipping={shippingData}
            cartUtils={cartUtils}
            editPayment={() => {
              setIsForm(!userData.loggedIn);
              setCheckoutStep(1);
            }}
            editShipping={() => {
              setIsForm(!userData.loggedIn);
              setCheckoutStep(0);
            }}
            onSubmit={couponData => {
              checkout(couponData);
            }}
          />
        );
      case 1:
        return (
          <Payment
            formChange={setIsForm}
            paymentInfo={handlePaymentSubmit}
            userData={{...userData, ...shippingData}}
            createSource={true}
            subscription={true}
          />
        );
      case 0:
      default:
        return (
          <><div className={pgStyles.pickupContainer}>
          <ButtonBase
            focusRipple
            className={localPickup==='ground'?pgStyles.pickUp:pgStyles.pickUpInActive}
            onClick={()=>{
              cartUtils.setStandardShipping();
              setLocalPickup('ground');
              if(cartUtils.bulkItem === true) {
                bulkShipping('SmartPost');
              }
              // updateTotals();
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <LocalShippingIcon style={{width:'8rem',height:'8rem'}}/>
              </Grid>
              <Grid item xs={8} style={{textAlign:'left'}}>
                <Typography variant="h2">Ship</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">FedEx SmartPost</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">2-7 Business Days</Typography>
              </Grid>
            </Grid>
          </ButtonBase>
          <ButtonBase
            focusRipple
            className={localPickup==='local'?pgStyles.pickUp:pgStyles.pickUpInActive}
            onClick={()=>{
              cartUtils.setLocalPickup();
              setLocalPickup('local');
              // updateTotals();
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <LocalMallIcon style={{width:'8rem',height:'8rem'}}/>
              </Grid>
              <Grid item xs={8} style={{textAlign:'left'}}>
                <Typography variant="h2">Pickup</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">Mon - Fri</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">8:30am - 4pm</Typography>
              </Grid>
            </Grid>
          </ButtonBase>
        </div>
        {localPickup === 'local' ? 
          <LocalPickup
            formChange={setIsForm}
            onSubmit={handleShippingSubmit}
            localPickup={localPickup==='local'}
            submitBtnTitle="Next"
            title="Pickup Info"
            userData={userData}
          />:
          <Shipping
            formChange={setIsForm}
            submitShippingInfo={handleShippingSubmit}
            localPickup={localPickup}
            title="Select Shipping Address"
            userData={userData}
          />}</>
        );
    }
  }

  const browserPadding = ['safari', 'crios', 'ios', 'chrome'];

  return (
    <div
      className={clsx(
        pgStyles.root,
        browserPadding.includes(browser.name) ? 'safari-pad' : ''
      )}
      style={{backgroundColor: isForm ? '#fff' : '#f6f6f6'}}
    >
      <Button className={pgStyles.backButton} onClick={goBack}>
        <ArrowLeft />
      </Button>
      {/* <h1>Premium Subscription</h1> */}
      {getStepPage(checkoutStep)}
    </div>
  );
};

export default Checkout;
