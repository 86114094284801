/** @format */

import React, {useEffect, useState, useRef} from 'react';
import Poll from 'react-polls';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import HeroComp from '../../components/premium/hero';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
  const useStyles = makeStyles(theme => ({
    root: {
      position:'absolute',
      top:0,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width:'100%',
      paddingTop: '2rem',
      paddingBottom: '2rem',
      fontSize: '2em',
      minHeight: 900,
      justifyContent:'center'
    //   backgroundColor: '#eee',
    },
    paper: {
      backgroundColor:'black',
      color:'white',
      padding:20,
      marginTop:200,
      '& a': {
        color:'white',
        textDecoration:'underline'
      },
      '& article': {
        backgroundColor:'black',
        color:'white',
        '& ul li:first-child': {
          // backgroundColor:'#dfcba4',
          backgroundImage: 'url("/images/subvote/latte.jpg")'
        },
        '& ul li:nth-child(2) ': {
          // backgroundColor:'#dfcba4',
          backgroundImage: 'url("/images/subvote/dragon_egg.jpg")'
        },
        '& ul li:nth-child(3)': {
          // backgroundColor:'#262626',
          backgroundImage: 'url("/images/subvote/blueberry_pearl.jpg")'
        }
      }
    }
  }));

  const jungleGreen = '#8ec6ff';
  const blackCherry = '#590014';
  const charcoal = '#23221d';
export default function Vote(props) {
    const [pollAnswers,setPollAnswers] = useState(null)
    const pollQuestion = 'What should the next Premium color be?'
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const {userData} = props;
    const {membership=false} = userData;

    useEffect(()=>{
        getPoll();
    },[]);

    const getPoll = () => {
        fetch(`/api/subscription/vote`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(resp => resp.json())
            .then(resp => {
                setPollAnswers(resp.options);
            });
    }

    const addVote = (color) => {
        fetch(`/api/subscription/vote`, {
            method: 'POST',
            body: JSON.stringify({color}),
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(async (resp)=>{
              if(resp.ok === false) {
                throw (await resp.text());
              } else {
                return resp.json();
              }
            })
            .then(resp => {
                let tmp  = [...pollAnswers];
                const newPollAnswers = tmp.map(answer => {
                    if (answer.option === color) answer.votes++
                    return answer
                })
                setPollAnswers(newPollAnswers);
            }).catch(e=>{
                enqueueSnackbar(e + ' Your vote was not saved.',{ variant: 'error' })
            });
    }

  return <>
            <HeroComp showButton={false}/>
            <div className={classes.root}>
                <Container maxWidth="sm">
                <Paper className={classes.paper}>
                    {!pollAnswers && membership && <div>Voting is currently closed.</div>}
                    {pollAnswers && membership && <Poll customStyles={{theme:'white',align:'center', questionColor:"#fff"}}  question={pollQuestion} onVote={addVote} answers={pollAnswers} /> }
                    {!membership && <div>Looks like you are not a premium member. Either <Link to="/modal/login/">login</Link> with your premium account or <Link to="/premium/">sign up</Link> for premium.</div>}
                </Paper>
                </Container>
            </div>
        </>;
}
