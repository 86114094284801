/** @format */

import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {detect} from 'detect-browser';
// Custom components
import DescModalBtn from '../desc-modal';
import BulkOption from './bulkOption.js';
import QtySelect from '../select.js';
import SelectedBulkSpools from './selectedBulkSpools.js';
import {SirvViewer} from '../image-viewer.js';
import SubmitButton from '../../styled/submit-button.js';
// Feather Icons
import {ArrowLeft, ShoppingCart} from 'react-feather';
// Material UI
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';
// Styles
import ItemStyles from '../styles';

const QTY_LIMIT_ARRAY = Array(20).fill(0);

//#region Styled Comps

const DiameterBtn = styled(Button)(
  {
    border: '2px solid transparent',
    borderRadius: 4,
    backgroundColor: '#f5f5f5',
    fontWeight: '500',
    fontSize: '4rem',
    height: '5vh',
    margin: '0 0.5rem 0.5rem 0',
    padding: '0.925vh 1.25rem',
    '&.selected': {
      borderColor: '#24a9df',
    },
  },
  {name: 'diameter-btn'}
);

const ListBtn = styled(Button)(
  {
    color: '#2434ff',
    fontSize: '4rem',
  },
  {name: 'selected-list-btn'}
);

const StyledTxt = styled(Typography)(
  {
    fontSize: '4rem',
    fontWeight: '500',
    '&.MuiTypography-h5': {
      fontSize: '6rem',
      fontWeight: '600',
    },
    '&.MuiTypography-h6': {
      fontSize: '6rem',
    },
    '&.bold': {
      fontWeight: '600',
    },
  },
  {name: 'styled-txt'}
);

//#endregion

//#endregion

export default function BulkComp(props) {
  const BROWSER = detect();
  const pgStyles = ItemStyles();
  const {baseItem, cartUtils, individualSpools, itemId} = props;
  const {addItemToCart = () => {}} = cartUtils || {};
  const [quantity, setQuantity] = useState(1);
  const [selectedDiameter, setSelectedDiameter] = useState('1.75mm');
  const [selectedRolls, setSelectedRolls] = useState([]);
  const [showList, setShowList] = useState(false);
  const allDiameters = [];
  const history = useHistory();

  // Populating all diameter values
  individualSpools.forEach(i => {
    if (!allDiameters.includes(i?.tags?.diameter)) {
      allDiameters.push(i.tags.diameter);
    }
  });

  //#region Functions

  function handleDiameterChange(newDiameter) {
    setSelectedDiameter(newDiameter);
  }

  function handleSpoolQtyChange(targetSpool, changeAmnt) {
    let tempSpools = [...selectedRolls];
    if (changeAmnt > 0) {
      tempSpools.push(targetSpool);
    } else if (changeAmnt < 0) {
      if (tempSpools.includes(targetSpool)) {
        tempSpools.splice(tempSpools.indexOf(targetSpool), 1);
      }
    }

    setSelectedRolls(tempSpools);
  }

  function getSelectedCount(item) {
    if (!selectedRolls.includes(item)) {
      return 0;
    } else {
      return selectedRolls.filter(i => i === item).length;
    }
  }

  function getSelectedText() {
    let colorSelection = {};
    selectedRolls.forEach(item => {
      if (!colorSelection[item.tags.colorName]) {
        colorSelection[item.tags.colorName] = 0;
      }
      colorSelection[item.tags.colorName]++;
    });

    return (
      <table style={{width: '100%'}}>
        <tbody>
          {selectedRolls.map((roll, index) => {
            if (selectedRolls.indexOf(roll) === index) {
              return (
                <tr key={`selected-roll-${index}`}>
                  <td style={{width: '50%'}}>
                    <StyledTxt variant="body1">{`${roll?.tags?.colorName} (${roll?.tags?.diameter})`}</StyledTxt>
                  </td>
                  <td>
                    <StyledTxt variant="body1">
                      qty:&nbsp;
                      {
                        selectedRolls.filter(item => item.name === roll.name)
                          .length
                      }
                    </StyledTxt>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    );
  }

  function isSelected(item) {
    return selectedRolls.includes(item);
  }

  //#endregion

  const browserPadding = ['safari', 'crios', 'ios', 'chrome'];
  return (
    <div
      className={clsx(
        pgStyles.wrapper,
        browserPadding.includes(BROWSER) ? 'safari-pad' : ''
      )}
      key={'item-wrapper-' + itemId}>
      <IconButton
        className={pgStyles.backArrow}
        onClick={() => {
          history.goBack();
        }}>
        <ArrowLeft color="#3d3d3d" />
      </IconButton>
      <div className={pgStyles.imgs}>
        <SirvViewer currentItem={baseItem} />
      </div>
      <Paper className={pgStyles.details} elevation={0}>
        <div className={pgStyles.topTitles}>
          <div className={pgStyles.titles}>
            <StyledTxt variant="h5" className="color">
              10 Pack of 1kg Spools
            </StyledTxt>
          </div>
          <StyledTxt variant="h5" className="price">
            ${(baseItem.price / 100.0).toFixed(2)}
          </StyledTxt>
        </div>
        <DescModalBtn
          desc={baseItem.desc}
          wrapperProps={{
            style: {
              marginTop: '-3rem',
              marginBottom: '2rem',
            },
          }}
        />
        <StyledTxt variant="h6">Diameter:</StyledTxt>
        <div className={pgStyles.list}>
          {allDiameters.map((val, index) => {
            return (
              <DiameterBtn
                className={val === selectedDiameter ? 'selected' : ''}
                key={`diameter-${index}`}
                onClick={() => handleDiameterChange(val)}>
                {val}
              </DiameterBtn>
            );
          })}
        </div>
        <StyledTxt variant="h6">Select 10 colors:</StyledTxt>
        <div className={pgStyles.list + ' minis'}>
          {individualSpools.map((item, index) => {
            if (item?.tags?.diameter === selectedDiameter) {
              return (
                <BulkOption
                  handleOptionSelect={(targetMini, changeAmnt) => {
                    handleSpoolQtyChange(targetMini, changeAmnt);
                  }}
                  isSelected={isSelected(item)}
                  isDisabled={
                    item.qoh <= 0 ||
                    (selectedRolls.length >= 10 && !isSelected(item))
                  }
                  item={item}
                  key={item.id}
                  totalRollsSelected={selectedRolls.length}
                  thisRollSelectedCount={getSelectedCount(item)}
                />
              );
            }
          })}
        </div>
        <StyledTxt variant="h6">
          Selected:&nbsp;
          <ListBtn onClick={() => setShowList(!showList)} variant="text">
            {showList ? 'Collapse' : 'Show List'}
          </ListBtn>
          <StyledTxt component="span">
            {showList ? getSelectedText() : null}
          </StyledTxt>
        </StyledTxt>
        <SelectedBulkSpools
          handleRollClick={targetMini => handleSpoolQtyChange(targetMini, -1)}
          selectedRolls={selectedRolls}
        />
        <QtySelect
          curVal={quantity}
          onSelect={e => setQuantity(e)}
          vals={QTY_LIMIT_ARRAY}
          wrapperProps={{
            style: {
              marginTop: '4rem',
            },
          }}
        />
        <StyledTxt className="bold" style={{marginTop: 10}}>
          Ground Shipping Only*
        </StyledTxt>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}>
          <SubmitButton
            className={pgStyles.addToCartButton}
            disabled={!(selectedRolls.length === 10)}
            endIcon={<ShoppingCart size={16} />}
            onClick={e => {
              addItemToCart({
                id: baseItem.id,
                quantity: parseInt(quantity),
                options: {
                  material: 'Bulk',
                  weight: '1kg',
                  diameter: '1.75mm',
                  color: selectedRolls
                    .map(mini => mini.tags.colorName)
                    .join(', '),
                  temps: selectedRolls.map(mini => mini.data.temp),
                },
                sides: selectedRolls.map(mini => mini.id),
              });
            }}
            variant="contained">
            Add to Cart
          </SubmitButton>
        </div>
        <StyledTxt variant="caption">
          *The 10 Pack will always ship ground and will arrive separate of any
          other products ordered. This item does not qualify for Overnight
          Everywhere shipping.
        </StyledTxt>
      </Paper>
    </div>
  );
}
