/** @format */

import {withStyles} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';

const StyledSelect = withStyles(
  {
    root: {
      border: '2px solid transparent',
      borderRadius: '8px',
      fontSize: '6rem',
    },
  },
  {index: 1}
)(Select);

export default StyledSelect;
