/** @format */

import React, {useState} from 'react';
// Material UI Components
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
// react-router imports for modal
import {Route, Switch, Redirect, useHistory} from 'react-router-dom';
// Feather Icons
import {ArrowLeft} from 'react-feather';
// Custom Components
import LoginForm from './login-form';
import RegisterComp from './register-comp';
import ForgotPasswordForm from './forgot-password-form';
import ResetPasswordForm from './reset-password-form';
// Styles
import ModalStyles from './styles';

const StyledDialog = withStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    background: 'transparent',
    borderRadius: '16px',
    margin: 0,
    width: '61.4vw',
    height: '86.5vh',
  },
  scrollPaper: {
    alignItems: 'flex-start',
    paddingTop: '1rem',
  },
})(Dialog);

export default function LoginModal({match, ...props}) {
  const pgStyles = ModalStyles();
  /* eslint-disable */
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  /* eslint-enable */

  const history = useHistory();
  const urlQuery = new URLSearchParams(history.location.search);

  const handleClose = e => {
    const baseModalUrl = '/modal';
    if (history.location.pathname.includes(baseModalUrl)) {
      const modalUrlIndex = history.location.pathname.indexOf(baseModalUrl);
      const noModalPath = history.location.pathname.substring(0, modalUrlIndex);
      history.replace(noModalPath + history.location.search);
    }
  };

  const handleFormSwitch = newPgName => {
    let urlSearch = history.location.search;
    if (urlQuery.has('rid') || urlQuery.has('email') || urlQuery.has('vid')) {
      urlSearch = '';
    }
    history.replace(match.url + '/' + newPgName + urlSearch);
  };

  return (
    <StyledDialog
      className={pgStyles.dialog}
      style={
        history.location.pathname === '/modal/register'
          ? {borderBottom: '1.6rem solid #FF1907'}
          : {borderBottom: '1.6rem solid #FFC905'}
      }
      open={true}
      PaperProps={{
        elevation: 0,
        className: pgStyles.dialogPaper,
      }}
    >
      <Button className={pgStyles.backButton} onClick={handleClose}>
        <ArrowLeft />
      </Button>
      <img
        src="/images/PrintBed-half.svg"
        alt="PrintBed logo"
        className={pgStyles.logo}
      />
      <Switch>
        <Route path={match.url + '/login'}>
          <LoginForm formSwitch={handleFormSwitch} closeModal={handleClose} />
        </Route>
        <Route path={match.url + '/register'}>
          <RegisterComp
            formSwitch={handleFormSwitch}
            status={urlQuery.get('status') || null}
            err={urlQuery.get('err') || null}
          />
        </Route>
        <Route path={match.url + '/forgot-password'}>
          <ForgotPasswordForm formSwitch={handleFormSwitch} />
        </Route>
        <Route path={[match.url + '/reset-password','/reset-password']}>
          <ResetPasswordForm
            formSwitch={handleFormSwitch}
            rid={urlQuery.get('rid') || null}
            email={urlQuery.get('email') || null}
          />
        </Route>
        <Route path="*">
          <Redirect to={match.url + '/login'} />
        </Route>
      </Switch>
    </StyledDialog>
  );
}
