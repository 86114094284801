/** @format */

import React, {useState} from 'react';
// Custom components
// Feather icons
import {X} from 'react-feather';
// HTML parser
import parse from 'html-react-parser';
// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// Styles
import {DescModalStyles} from './styles';

export default function DescModalBtn(props) {
  const pgStyles = DescModalStyles();
  const [isOpen, setIsOpen] = useState(false);
  const {desc = '', wrapperProps = {}} = props;

  return (
    <div className={pgStyles.wrapper} {...wrapperProps}>
      <Button
        className={pgStyles.dialogBtn}
        onClick={() => setIsOpen(true)}
        variant="text">
        More information
      </Button>
      <Dialog
        onClose={() => setIsOpen(false)}
        open={isOpen}
        PaperProps={{
          className: pgStyles.paper,
        }}>
        <DialogTitle className={pgStyles.dialogTitle}>
          <IconButton onClick={() => setIsOpen(false)} style={{padding: 0}}>
            <X color="#3d3d3d" />
          </IconButton>
        </DialogTitle>
        <div className={pgStyles.txtWrapper}>
          <Typography className={pgStyles.txtTitle} variant="h6">
            Product Details
          </Typography>
          {parse(desc)}
        </div>
      </Dialog>
    </div>
  );
}
