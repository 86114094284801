/** @format */

import React from 'react';
import {Chats, CreditCard, Truck} from 'phosphor-react';
// Custom components
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

//#region Styled Components

const Wrapper = styled('div')(
  {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: 'min-content',
    justifyContent: 'center',
    width: '100%',
  },
  {name: 'sub-banner-wrap'}
);

const ItemSubtitle = styled(Typography)(
  {
    color: '#676767',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '1.2em',
  },
  {name: 'item-subtitle'}
);

const ItemTextWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    marginLeft: '1rem',
    width: '100%',
  },
  {name: 'item-txt-wrap'}
);

const ItemTitle = styled(Typography)(
  {
    color: '#000',
    fontSize: '1.75rem',
    fontWeight: 600,
    lineHeight: '1.2em',
    textTransform: 'uppercase',
  },
  {name: 'item-title'}
);

const ItemWrapper = styled('div')(
  {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    height: '12.75rem',
    justifyContent: 'flex-start',
    width: '31.25rem',
  },
  {name: 'item-wrapper'}
);

const ColumnWrapper = styled('div')(
  {
    height: 'min-content',
    width: '100%',
    display:'flex',
    flexDirection:'column',
    alignContent:'center',
    alignItems:'center'
  },
  {name: 'best-sellers-wrap'}
);

//#endregion

const subBannerObjs = {
  shipping: {
    icon: <Truck color="#265ef9" height="100%" weight="light" width="7.5rem" />,
    title: '10$ OVERNIGHT SHIPPING',
    subtitle: 'In the continental U.S.',
  },
  payment: {
    icon: (
      <CreditCard color="#265ef9" height="100%" weight="light" width="7.5rem" />
    ),
    title: 'SAFE PAYMENT',
    subtitle: 'For all orders',
  },
  support: {
    icon: <Chats color="#265ef9" height="100%" weight="light" width="7.5rem" />,
    title: '24/7 HELP CENTER',
    subtitle: 'Dedicated 24/7 support',
  },
};

function SubBannerItem(props) {
  const {subBannerObj} = props;

  return (
    <ItemWrapper>
      {subBannerObj.icon}
      <ItemTextWrapper>
        <ItemTitle>{subBannerObj.title}</ItemTitle>
        <ItemSubtitle>{subBannerObj.subtitle}</ItemSubtitle>
      </ItemTextWrapper>
    </ItemWrapper>
  );
}

export default function SubBanner(props) {
  return (
    <ColumnWrapper>
    <Wrapper>
      {Object.keys(subBannerObjs).map((sboKey, index) => {
        return (
          <SubBannerItem
            key={`subbanner-${index}`}
            subBannerObj={subBannerObjs[sboKey]}
          />
        );
      })}
    </Wrapper>
    </ColumnWrapper>
  );
}
