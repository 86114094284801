/** @format */
import React from 'react';

export default function RadioIcon(props) {
  const {hidden = false} = props;
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: '#f2f2f2',
        border: '1px solid #3d3d3d',
        borderRadius: '50%',
        display: 'flex',
        height: '6rem',
        justifyContent: 'center',
        padding: '.5rem',
        width: '6rem',
      }}
    >
      <div
        style={{
          backgroundColor: '#F3AA2B',
          border: '1px solid white',
          borderRadius: '50%',
          height: '100%',
          width: '100%',
          visibility: hidden ? 'hidden' : 'visible',
        }}
      >
        &nbsp;
      </div>
    </div>
  );
}
