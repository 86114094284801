/** @format */

import React, {useState} from 'react';
// npm package imports
import parse from 'html-react-parser';
// Material UI Components
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
// import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
// Material UI Icons
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
// Custom Components
import {SunValidatedInput} from '@sunsh1n3/sun-validated-input';
// Styles
import FaqStyles from './styles';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const StyledBase = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 'none',
    '&.Mui-expanded': {
      margin: 0,
      marginBottom: '7.5rem',
    },
  },
  expanded: {
    marginBottom: '7.5rem ',
  },
})(Accordion);


export default function FaqComp({isMobile}) {
  const pgStyles = FaqStyles();
  return (
    <div className={pgStyles.wrapper}>
      <div className={pgStyles.banner} style={{height:isMobile?240:''}}>
        <img
          className="Sirv banner-img"
          alt="FAQ banner"
          data-src="https://cdn.sunsh1n3.com/home/aboutus.jpg" 
        />
        <div className={pgStyles.banner}  style={{backgroundColor:'rgba(0,0,0,.3)'}}>
          <Typography align="center" className={isMobile?"title":"titleh1"} variant="h1">
            Welcome to PrintBed
          </Typography>
          <Typography align="left" className="desc" variant="body1" style={{color:'white',width:isMobile?'90%':'60%',fontSize:isMobile?'6rem':''}}>
              PrintBed is a Michigan-based supplier of high-quality <Link to="/" style={{color:'white', textDecoration:'underline'}}>3D printer filaments</Link> manufactured and sourced in the USA. Our focus is to provide our customers with filament that is reliable and made with renewable resources, giving it the lowest <Link to="/process" style={{color:'white', textDecoration:'underline'}}>carbon footprint</Link> possible. Our material is always tangle-free, ensuring high performance during printing and color consistency for every spool.
          </Typography>
          </div>
      </div>
      <div className={pgStyles.mainContent} >
        <div className={pgStyles.contentHeader} style={{maxWidth:isMobile?'90%':'60%'}}>
          <Typography align="left" className="title" variant="h2" style={{fontSize:isMobile?'9rem':''}}>Our Origins</Typography>
          <Typography align="left" className="desc" variant="body1" style={{fontSize:isMobile?'6rem':''}}>
          PrintBed started with a team of creatives and designers who were eager to explore the opportunities of 3D printing. We began working together in research and development, creating a system that now enables us to produce perfectly wound spools. As a result, our customers are able to bring their concepts to life with precision and accuracy.          
          </Typography>
          <Typography align="left" className="title" variant="h2" style={{fontSize:isMobile?'9rem':''}}>Our Commitment to You</Typography>
          <Typography align="left" className="desc" variant="body1" style={{fontSize:isMobile?'6rem':''}}>
          At PrintBed, our commitment to customer service is unrivaled. You can reach us by phone, email, or social media and we’ll respond as soon as possible. Orders are delivered overnight, ensuring you don’t have to wait to create your prototypes or other original 3D prints. We ensure optimal quality control so you can be sure that there are no variations in shades between the batches you purchase from one order to the next.</Typography>
          <Typography align="left" className="title" variant="h2" style={{fontSize:isMobile?'9rem':''}}>Sign Up for Premium
          </Typography>
          <Typography align="left" className="desc" variant="body1" style={{fontSize:isMobile?'6rem':''}}>
          PrintBed Premium subscription gives you access to our exclusive premium colors. Each month, Premium members receive a new and unique PLA filament color delivered to their doorstep. These exclusive colors allow you to unleash your creativity and create truly individualized 3D prints. <Link to="/subscription">Subscribe now</Link> and receive other premium membership perks including 10% off products, free ground shipping, voting ability, and be the first to try our R&D mystery filaments.          </Typography>
        </div>
      </div>
    </div>
  );
}
