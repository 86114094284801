/** @format */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const NewOptButton = withStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '6rem',
    fontWeight: '600',
    padding: '4rem',
    width: '100%',
  },
  contained: {
    backgroundColor: '#f5be2e',
    boxShadow: 'none',
    color: '#000',
  },
  icon: {
    width:'6rem',
    height:'6rem'
  },
  label: {
    flexGrow: 1,
    lineHeight: 'normal',
  },
  outlined: {
    backgroundColor: '#000',
    color: '#f5be2e',
  },
})(({classes, label, onClick}) => (
  <Button className={classes.root} fullWidth onClick={onClick}>
    <Typography align="left" className={classes.label} variant="h2">
      {label}
    </Typography>
    <ChevronRightIcon className={classes.icon} />
  </Button>
));

export default NewOptButton;
