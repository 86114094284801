/** @format */

import React, {useEffect, useState} from 'react';
import {CaretLeft, CaretRight} from 'phosphor-react';
// Custom components
import {BannerBtn} from '../homeBanners';
import ItemCard from './itemCard';
import Box from '@material-ui/core/Box';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';
import BestSellersStyle from './styles';

//#region Styled Components

const defaultItems = [
  {
    name: ' ',
    price: 2500,
    imgSrc: '/images/PrintBed.svg',
    qoh: 1,
    tags: [],
    data: {},
  },
  {
    name: ' ',
    price: 2500,
    imgSrc: '/images/PrintBed.svg',
    qoh: 1,
    tags: [],
    data: {},
  },
  {
    name: ' ',
    price: 2500,
    imgSrc: '/images/PrintBed.svg',
    qoh: 1,
    tags: [],
    data: {},
  },
  {
    name: ' ',
    price: 2500,
    imgSrc: '/images/PrintBed.svg',
    qoh: 1,
    tags: [],
    data: {},
  },
];
const BtnWrapper = styled(Box)(
  {
    display: 'flex',
    height: 'min-content',
    justifyContent: 'center',
    marginBottom: '2rem',
    width: '100%',
  },
  {name: 'btn-wrap'}
);

const DisplayWrapper = styled(Box)(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '0 10rem',
  },
  {name: 'display-wrap'}
);

const ItemsWrapper = styled('div')(
  {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    justifyContent: 'center',
    width: '100%',
    minHeight: '20rem',
  },
  {name: 'item-wrap'}
);

const StoreTxt = styled(Typography)(
  {
    color: '#111',
    fontWeight: '500',
    fontSize: '2.25rem',
    '&.mbl': {
      fontSize: '6rem',
    },
  },
  {name: 'best-sellers-store-txt'}
);

const StoreTxtMobile = styled(Typography)(
  {
    color: '#111',
    fontWeight: '500',
    fontSize: '4rem',
  },
  {name: 'best-sellers-store-txt'}
);

const StyledBtn = styled(IconButton)(
  {
    display: 'flex',
    height: '100%',
    width: 'min-content',
  },
  {name: 'styled-btn'}
);

const TitleTxt = styled(Typography)(
  {
    color: '#111',
    fontSize: '6rem',
    fontWeight: '700',
    '&.mbl': {
      fontSize: '10rem',
    },
  },
  {name: 'best-sellers-title-txt'}
);

const SubText = styled(Typography)(
  {
    color: '#111',
    fontSize: '2rem',
    margin: '2rem 40rem',
    '&.mbl': {
      fontSize: '4.5rem',
      width: '100%',
      // margin: '2rem 6rem 10rem 6rem',
      padding: '0 5%',
      textAlign: 'justify',
    },
    // fontWeight: '700',
  },
  {name: 'best-sellers-sub-txt'}
);

const TitleWrapper = styled(Typography)(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  {name: 'best-sellers-title-wrap'}
);

const Wrapper = styled('div')(
  {
    height: 'min-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
  },
  {name: 'best-sellers-wrap'}
);

//#endregion

export default function BestSellers({isMobile}) {
  const [page, setPage] = useState(1);
  const [allItems, setAllItems] = useState(defaultItems);
  const [displayItems, setDisplayItems] = useState([]);
  const pgStyles = BestSellersStyle();

  useEffect(() => {
    if (navigator.userAgent === 'ReactSnap') {
      return;
    }
    function fetchProducts() {
      fetch(
        '/api/items/tags?key=showOnHome&value=true&filter=tags,qoh,price,name,options,imgSrc,data&filterInclusion=true'
      )
        .then(response => response.json())
        .then(data => {
          let filteredSortedItems = data
            .filter(
              item =>
                item.tags.weight === '1kg' ||
                (item.tags.weight === '200g' && item.tags.color === 'Minis')
            )
            .sort((a, b) => {
              return a.qoh < b.qoh;
            });
          setAllItems(filteredSortedItems);
        });
    }
    fetchProducts();
  }, []);

  useEffect(() => {
    updateDisplayItems();
  }, [allItems, page]);

  function changePage(increment) {
    if (page === 1 && increment < 0) {
      setPage(Math.floor(allItems.length / 4));
    } else if (page + increment > allItems.length / 4) {
      setPage(1);
    } else {
      setPage(page + increment);
    }
  }

  function updateDisplayItems() {
    let startNum = (page - 1) * 4;
    setDisplayItems(allItems.slice(startNum, startNum + 4));
  }

  return (
    <span className={pgStyles.wrapper}>
      {/* <StoreTxt className={isMobile ? 'mbl' : ''}>PrintBed Store</StoreTxt> */}
      <TitleTxt variant="h2" className={isMobile ? 'mbl' : ''}>
        Our Best-Selling Products
      </TitleTxt>
      <SubText className={isMobile ? 'mbl' : ''}>
        Our best sellers include PLA, Forge PLA+, ABS, and PETG 3D printing
        spools. Other popular products include 3D printing equipment such as
        direct drive extruders, Micro Swiss FlowTech Hotend for Creality, and
        heater block upgrades. Plus, you can buy PrintBed merchandise including
        emblem t-shirts and creators' posters.
      </SubText>
      <BannerBtn
        colorVariant="yellow"
        custHeight={isMobile ? '15rem' : undefined}
        custWidth={isMobile ? '' : undefined}
        fontSize={isMobile ? '6rem' : '2.75rem'}
        fontWeight="500"
        to="/shop">
        Shop 3D Printing Rolls
      </BannerBtn>
      <Box>
        <DisplayWrapper>
          <StyledBtn
            className={`pgPrev ${isMobile ? 'mbl' : ''}`}
            data-ismobile={isMobile}
            onClick={() => changePage(-1)}>
            <CaretLeft color="#000" height="8.5rem" width="8.5rem" />
          </StyledBtn>
          <ItemsWrapper>
            {displayItems.map((item, index) => {
              return <ItemCard key={index} isMobile={isMobile} {...item} />;
            })}
          </ItemsWrapper>
          <StyledBtn
            className={`pgNext ${isMobile ? 'mbl' : ''}`}
            data-ismobile={isMobile}
            onClick={() => changePage(1)}>
            <CaretRight color="#000" height="8.5rem" width="8.5rem" />
          </StyledBtn>
        </DisplayWrapper>
      </Box>
    </span>
  );
}
