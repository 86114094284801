/** @format */

import React from 'react';
// Material UI Components
import Typography from '@material-ui/core/Typography';
import {withStyles, makeStyles, createStyles} from '@material-ui/styles';
// Material UI Icons
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

class RequirementClass extends React.Component {
  constructor(props) {
    super();
    this.state = {
      text: props.text || 'No requirement given',
      showIcon: props.showIcon || false,
      isValid: props.isValid || true,
      key: props.key || Math.floor(Math.random() * 1000),
    };
  }

  update(newShowIcon, newIsValid) {
    this.setState({
      text: this.state.text,
      showIcon:
        this.state.showIcon !== newShowIcon ? newShowIcon : this.state.showIcon,
      isValid:
        this.state.isValid !== newIsValid ? newIsValid : this.state.isValid,
    });
  }

  render() {
    const {classes} = this.props || {};
    return (
      <div className={classes.wrapper} key={this.state.key}>
        {this.state.isValid ? (
          <CheckCircleIcon
            htmlColor="#00d800"
            className={classes.reqIcon + (this.state.showIcon ? '' : ' hidden')}
          />
        ) : (
          <CancelOutlinedIcon
            htmlColor="#D53001"
            className={classes.reqIcon + (this.state.showIcon ? '' : ' hidden')}
          />
        )}
        <Typography variant="body1">{this.state.text}</Typography>
      </div>
    );
  }
}

export const ReqStyles = theme =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    reqIcon: {
      marginRight: '1rem',
    },
  });

export default withStyles(ReqStyles)(RequirementClass);

const ReqFuncStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  reqIcon: {
    marginRight: '1rem',
  },
});
export function RequirementFunc(props) {
  const styles = ReqFuncStyles();
  return (
    <div
      className={styles.wrapper}
      key={props.key || Math.floor(Math.random() * 1000)}
    >
      {props.isValid ? (
        <CheckCircleIcon
          htmlColor="#00d800"
          className={styles.reqIcon + (props.showIcon ? '' : ' hidden')}
        />
      ) : (
        <CancelOutlinedIcon
          htmlColor="#D53001"
          className={styles.reqIcon + (props.showIcon ? '' : ' hidden')}
        />
      )}
      <Typography variant="body1">{props.text}</Typography>
    </div>
  );
}
