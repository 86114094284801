/** @format */

import React from 'react';
// Custom components
// Material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

const BG_IMG_URL =
  'https://cdn.sunsh1n3.com/Images/printbed/backgrounds/footer-bg.jpg?format=png&w=1920';

//#region Styled Components
const BgStyler = styled('div')(
  {
    backdropFilter: 'blur(100px)',
    backgroundColor: '#000833',
    height: '100%',
    opacity: '0.66',
    position: 'absolute',
    width: '100%',
  },
  {name: 'bg-styler'}
);

const CustomBtn = styled(Button)(
  {
    backgroundColor: '#000',
    border: '2px solid white',
    color:'white',
    borderRadius: '8px',
    padding: '2rem 14rem',
    '& .MuiButton-label': {
      color: '#fff',
      fontSize: '3rem',
      fontWeight: '600',
    },
    '& :hover': {
      color:'#d8a530',
      borderColor:'#d8a530'
    }
  },
  {name: 'footer-btn'}
);

const Wrapper = styled('div')(
  {
    alignItems: 'center',
    backgroundColor: 'black',
    // backgroundImage: `url(${BG_IMG_URL})`,
    display: 'flex',
    flexDirection: 'column',
    height: '20.7vh',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  {name: 'footer-wrap'}
);
//#endregion

export default function PremiumFooter(props) {
  const {onClick} = props;
  return (
    <Wrapper>
      {/* <BgStyler></BgStyler> */}
      <CustomBtn onClick={onClick}>Join now for 25$/Month</CustomBtn>
    </Wrapper>
  );
}
