/** @format */

import React, {useEffect, useState} from 'react';
// import Tap from "@tapfiliate/tapfiliate-js";
import ReactGA from 'react-ga4';
import clsx from 'clsx';
import {detect} from 'detect-browser';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';

// Material UI imports
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import PlaneIcon from '@material-ui/icons/AirplanemodeActiveOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
// Feather Icon imports
import {ArrowLeft} from 'react-feather';
// Component imports
import Shipping from '../../components/mobile/shipping';
import Payment from '../../components/mobile/payment';
import CheckoutResult from '../../components/mobile/checkoutResult';
import OrderConfirm from '../../components/mobile/orderConfirm';
import LocalPickup from '../../components/mobile/localpickup';
// Style import
import CheckoutPageStyles from './styles';

const browser = detect();

const Checkout = props => {
  const {userData, cartUtils} = props;
  const history = useHistory();
  const pgStyles = CheckoutPageStyles();
  const {enqueueSnackbar,closeSnackbar} = useSnackbar();
  const [checkoutStep, setCheckoutStep] = useState(0);
  const [checkoutStatus, setCheckoutStatus] = useState('error');
  const [shippingData, setShippingData] = useState({});
  const [paymentInfo, setPaymentInfo] = useState();
  const [isForm, setIsForm] = useState(false);
  const [isReviewOrder, setIsReviewOrder] = useState(false);
  const [localPickup,setLocalPickup] = useState('ground');
  

  useEffect(() => {
    if(userData.membership === true) {
      cartUtils.membership = userData.membership;
    }
  },[userData.membership])
  useEffect(() => {
    if(cartUtils) {
      setIsForm(userData.loggedIn !== true);
      cartUtils.getTotals(cartUtils.cart);
      console.log('getTotals')
    }
    // eslint-disable-next-line
  }, [cartUtils]);

  useEffect(() => {
    setIsForm(!userData.loggedIn);
    // eslint-disable-next-line
  }, [checkoutStep]);

  function handleShippingSubmit(newInfo) {
    setShippingData(newInfo);
    cartUtils.international = newInfo.address.country === 'CA';
    cartUtils.getShipping(newInfo.address.postal_code);
    isReviewOrder ? setCheckoutStep(2) : setCheckoutStep(1);
  }

  function handlePaymentSubmit(submittedToken) {
    setIsReviewOrder(true);
    setPaymentInfo(submittedToken);
    setCheckoutStep(2);
  }

  function goBack() {
    history.push('/');
  }

  async function checkout(couponData) {
    setCheckoutStatus('processing');
    if (paymentInfo.tokenId || paymentInfo.card.id) {
      let payment = {};
      if (paymentInfo.tokenId) {
        payment['token'] = paymentInfo.tokenId;
      }
      if (paymentInfo.card.id) {
        payment['source'] = paymentInfo.card.id;
      }
      const shipping = shippingData.address.line1
        ? {
            address: shippingData.address.line1,
            address2: shippingData.address.line2,
            city: shippingData.address.city,
            state: shippingData.address.state,
            zipCode: shippingData.address.postal_code,
            email: shippingData.email,
            phone: shippingData.phone,
            name: shippingData.name,
            country: shippingData.country,
            firstName: shippingData.firstName,
            lastName: shippingData.lastName,
            notes:shippingData.notes || '',
          }
        : shippingData;

      shipping.serviceCode = cartUtils.serviceCode;

      const body = {
        payment: payment,
        customerInfo: shipping,
        couponName: couponData ? couponData.name : '',
      };

      if(cartUtils.international === true) {
        body.customerInfo.shippingQuote = cartUtils.shipping;
        body.customerInfo.serviceCode = 92;
      }

      fetch('/api/checkout', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(resp => {
          setCheckoutStatus(resp.ok ? 'success' : 'error');
          return resp.json();
        })
        .then(result => {
          const {amount,tax=0,shippingCost=0} = result.payment;
          let commission = ((amount-tax-shippingCost)/100);
          window.tap.conversion(result.id, commission);
          if (process.env.NODE_ENV === 'production') {
            try{
              window.gtag("event", "purchase", {
                transaction_id: result.id,
                value: (amount-tax-shippingCost)/100,
                tax: tax/100,
                shipping: shippingCost/100,
                currency: "USD",
                items:result.lines.map(item=>{
                  return {
                    item_id: item.itemId,
                    item_name:item.name,
                    item_category: item.options.material,
                    price: Number(item.price),
                    quantity: item.qty
                  }
                })
              });
            } catch(e) {}
            ReactGA.gtag('event', 'conversion', {'send_to': 'AW-360964627/8ZknCIuf94gDEJPEj6wB', 'value': amount/100, 'currency': 'USD', 'transaction_id': result.id});
          }
        })
        .then(setCheckoutStep(3));
    }
  }
  
  const bulkShipping = (method) => {
    const action = key => (
      <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'2rem', marginRight:'2rem'}}>
          Dismiss
      </Button>
    );
    enqueueSnackbar(`Bulk Items will ship Fedex Home Delivery and take 2-7 business days. Your other items will ship ${method}`, {variant: 'info', persist: true,action});

  }

  function getStepPage(step) {
    switch (step) {
      case 3:
        return <CheckoutResult status={checkoutStatus} />;
      case 2:
        return (
          <OrderConfirm
            payment={paymentInfo}
            shipping={shippingData}
            editPayment={() => {
              setIsForm(!userData.loggedIn);
              setCheckoutStep(1);
            }}
            editShipping={() => {
              setIsForm(!userData.loggedIn);
              setCheckoutStep(0);
            }}
            cartUtils={cartUtils}
            onSubmit={couponData => {
              checkout(couponData);
            }}
          />
        );
      case 1:
        const totals = cartUtils.getTotals(cartUtils.cart)
        return (
          <Payment
            formChange={setIsForm}
            paymentInfo={handlePaymentSubmit}
            userData={{...userData, ...shippingData}}
            totals={totals}
          />
        );
      case 0:
      default:
        if(!cartUtils) {
          return <div style={{textAlign:'center',height:'50vh'}}><img src="/images/loading.gif" alt="loading" style={{width:"25%"}}/></div>
        }
        return (
          <><div className={pgStyles.pickupContainer}>
          <ButtonBase
            focusRipple
            className={localPickup==='ground'?pgStyles.pickUp:pgStyles.pickUpInActive}
            style={{border:'1px solid #ccc'}}
            onClick={()=>{
              cartUtils.setStandardShipping();
              setLocalPickup('ground');
              if(cartUtils.bulkItem === true) {
                bulkShipping('SmartPost');
              }
              // updateTotals();
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <LocalShippingIcon style={{width:'8rem',height:'8rem'}}/>
              </Grid>
              <Grid item xs={8} style={{textAlign:'left'}}>
                <Typography variant="h2">Ship</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4">FedEx SmartPost</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4">2-7 Business Days</Typography>
              </Grid>
            </Grid>
          </ButtonBase>
          <ButtonBase
            style={{display:cartUtils.freeShipping===true?'none':'block', border:'1px solid #ccc'}}
            focusRipple
            className={localPickup==='overnight'?pgStyles.pickUp:pgStyles.pickUpInActive}
            onClick={()=>{
              cartUtils.setOvernightShipping();
              setLocalPickup('overnight');
              if(cartUtils.bulkItem === true) {
                bulkShipping('Overnight');
              }
              // updateTotals();
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <PlaneIcon style={{width:'8rem',height:'8rem'}}/>
              </Grid>
              <Grid item xs={8} style={{textAlign:'left'}}>
                <Typography variant="h2">Ship*</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4">FedEx Overnight</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4">In Contiguous US</Typography>
              </Grid>
            </Grid>
          </ButtonBase>
          <ButtonBase
            focusRipple
            className={localPickup==='local'?pgStyles.pickUp:pgStyles.pickUpInActive}
            style={{border:'1px solid #ccc'}}
            onClick={()=>{
              cartUtils.setLocalPickup();
              setLocalPickup('local');
              // updateTotals();
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <LocalMallIcon style={{width:'8rem',height:'8rem'}}/>
              </Grid>
              <Grid item xs={8} style={{textAlign:'left'}}>
                <Typography variant="h2">Pickup</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4">Mon - Fri</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4">8:30am - 4pm</Typography>
              </Grid>
            </Grid>
          </ButtonBase>
        </div>
        <div style={{marginTop:'-2rem',marginBottom:'8rem'}}>
          <Typography variant="h3">
              *Orders Placed before 5pm EST will ship same day Fedex Overnight.  We do not do Saturday delivery so orders placed on Friday will be delivered Monday. Orders placed over the weekend will ship the following Monday.
          </Typography>
        </div>
        {localPickup === 'local' ? 
          <LocalPickup
            formChange={setIsForm}
            onSubmit={handleShippingSubmit}
            localPickup={localPickup==='local'}
            submitBtnTitle="Next"
            title="Pickup Info"
            userData={userData}
          />:
          <Shipping
            formChange={setIsForm}
            submitShippingInfo={handleShippingSubmit}
            localPickup={localPickup}
            title="Select Shipping Address"
            userData={userData}
          />}</>
        );
    }
  }

  const browserPadding = ['safari', 'crios', 'ios', 'chrome'];

  return (
    <div
      className={clsx(
        pgStyles.root,
        browserPadding.includes(browser.name) ? 'safari-pad' : ''
      )}
      style={{backgroundColor: isForm ? '#fff' : '#f6f6f6'}}
    >
      <Button className={pgStyles.backButton} onClick={goBack}>
        <ArrowLeft />
      </Button>
      {getStepPage(checkoutStep)}
    </div>
  );
};

export default Checkout;
