/** @format */

import React from 'react';
// Custom components
// Material UI
import {styled} from '@material-ui/core/styles';

const MINIS_BOX_BACK =
  'https://cdn.sunsh1n3.com/Images/minis/Final_MiniMISC/Box%20Back.png';
const MINIS_BOX_FRONT =
  'https://cdn.sunsh1n3.com/Images/minis/Final_MiniMISC/Box%20Front.png';

//#region Styles
const BoxBack = styled('div')(
  {
    backgroundImage: `url(${MINIS_BOX_BACK}?w=426)`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '392px',
    position: 'relative',
    width: '100%',
  },
  {name: 'selected-minis-box-back'}
);

const BoxFront = styled('div')(
  {
    backgroundImage: `url(${MINIS_BOX_FRONT}?w=426)`,
    backgroundPosition: 'bottom center',
    backgroundSize: 'auto auto',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    left: '0',
    maxWidth: '100%',
    pointerEvents: 'none',
    position: 'absolute',
    top: '1px',
    width: '100%',
  },
  {name: 'selected-minis-box-front'}
);

const MiniRoll = styled('img')(
  {
    minWidth: 0,
    maxWidth: '20%',
    objectFit: 'scale-down',
    position: 'relative',
    '&.second-row': {
      top: '4rem',
    },
    '&:hover': {
      cursor: 'pointer',
      transform: 'translateY(-2rem)',
      transition: '0.05s ease-in-out',
    },
  },
  {name: 'selected-minis-roll-img'}
);

const RollWrapper = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row-reverse',
  flexWrap: 'wrap-reverse',
  height: '100%',
  justifyContent: 'end',
  overflow: 'hidden',
  padding: '25% 10% 10%',
  width: '100%',
});

const Wrapper = styled('div')(
  {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  {name: 'selected-minis-wrap'}
);
//#endregion

export default function SelectedMinis(props) {
  const {handleRollClick = () => {}, selectedRolls = []} = props;
  return (
    <Wrapper>
      <BoxBack>
        <RollWrapper>
          {selectedRolls.map((item, index) => {
            return (
              <MiniRoll
                className={index > 5 ? 'second-row' : ''}
                key={`selected-roll-${index}`}
                onClick={() => handleRollClick(item)}
                src={
                  item.imgSrc[1] ??
                  'https://cdn.sunsh1n3.com/Images/minis/Final_MiniSide/Mini_Black.png'
                }
              />
            );
          })}
        </RollWrapper>
        <BoxFront />
      </BoxBack>
    </Wrapper>
  );
}
