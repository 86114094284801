/** @format */

import React, {useState, useEffect} from 'react';
// Material UI Component imports
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material UI icon imports
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// Component imports
import RegisterAfterCheckout from '../login/register-after-checkout';
// Style imports
import GoodCheckoutStyles from './styles';

const CheckoutResult = props => {
  const pgStyles = GoodCheckoutStyles();
  const {status, customerInfo, isLoggedIn, saleId, membership,vending} = props;

  const [icon, setIcon] = useState(null);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [isEmailRegistered, setIsEmailRegistered] = useState(true);

  useEffect(() => {
    async function checkIfEmailIsRegistered() {
      if (!isLoggedIn && customerInfo !== undefined) {
        const {email} = customerInfo;
        const resp = await fetch(`/api/auth/isRegistered/${email}`).then(res =>
          res.json()
        );

        setIsEmailRegistered(resp);
      }
    }

    checkIfEmailIsRegistered();
    checkVending();
  
  }, [isLoggedIn, customerInfo]);

  const checkVending = async () => {
    console.log(saleId)
    
  }

  useEffect(() => {
    switch (status) {
      case 'success':
        setIcon(<CheckCircleOutlineIcon className="icon good" />);
        setTitle('Thank You for Your Order!');
        setText(
          'Your order is complete. Please check your email for more details.'
        );
        setButtonText('Continue Shopping');
        localStorage.removeItem('coupon');
        break;
      case 'processing':
        setIcon(<CircularProgress className="icon processing" size="12rem" />);
        setTitle('Processing...');
        setText('');
        setButtonText('');
        break;
      case 'error':
      default:
        setIcon(<HighlightOffIcon className="icon bad" />);
        setTitle('Something Went Wrong');
        setText(
          'Something went wrong while submitting your order. Please try again.'
        );
        setButtonText('Try again');
        break;
    }
  }, [status]);

  return (
    <div className={pgStyles.wrapper}>
      {icon}

      <Typography variant="h3">{title}</Typography>
      <Typography variant="body1">{text}</Typography>
      <Button
        className={'btn' + (status === 'processing' ? ' removed' : '')}
        onClick={props.onClick}
      >
        {buttonText}
      </Button>
      {!isLoggedIn && !isEmailRegistered && (
        <RegisterAfterCheckout customerInfo={customerInfo} saleId={saleId} />
      )}
      {membership === true && vending === true && 
      <Button className={'btn' + (status === 'processing' ? ' removed' : '')} href={"/vending/"+saleId}>Go to Vending Machine</Button>}
    </div>
  );
};

export default CheckoutResult;
