/** @format */

import React, {useState, useEffect} from 'react';
// Custom Components
import Minis from '../../components/item/minis/index.js';
import MinisMobile from '../../components/mobile/item/minis';
import {useLocation} from 'react-router-dom';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const MinisPage = props => {
  const {cartUtils, isMobile} = props;
  const relatedItems = [];
  const [individualMinis, setIndividualMinis] = useState([]);
  const [masterMini, setMasterMini] = useState(null);
  const query = useQuery();
  const defaultTags = {
    color: 'Black',
    material: 'PLA',
    weight: '200g',
    diameter: '1.75mm',
    colorName: 'Black',
  };

  useEffect(() => {
    if (relatedItems[0] === undefined) {
      fetch(`/api/items/tags?key=weight&value=${defaultTags.weight}`)
        .then(resp => resp.json())
        .then(data => {
          let tempItems = [];
          data.forEach(item => {
            if (item.tags.color !== 'Minis') {
              tempItems.push(item);
            } else {
              setMasterMini(item);
            }
          });

          if(query.get('discord') !== "true") {
            tempItems = tempItems.filter(item => item.data.discordOnly !== true);
          }

          setIndividualMinis(tempItems);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {individualMinis[0] !== undefined && masterMini !== null ? (
        isMobile?<MinisMobile
          baseItem={masterMini}
          individualMinis={individualMinis}
          cartUtils={cartUtils}
        />:<Minis
        baseItem={masterMini}
        individualMinis={individualMinis}
        cartUtils={cartUtils}
      />
      ) : (
        <div style={{flexGrow: 1}}>rest</div>
      )}
    </>
  );
};

export default MinisPage;
