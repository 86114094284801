/** @format */

import {makeStyles} from '@material-ui/core/styles';

const GoodCheckoutStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: '1',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '-10vh',
    '& .icon': {
      fontSize: '12rem',
      marginBottom: '4rem',
      '&.good': {
        color: '#00C48C',
      },
      '&.bad': {
        color: 'red',
      },
      '&.processing': {
        color: '#de4297',
      },
    },
    '& .btn': {
      color: '#fff',
      backgroundColor: '#0084f4',
      padding: '1.5rem 4rem',
      '&.removed': {
        color: 'transparent',
        backgroundColor: 'transparent',
      },
    },
    '& > *': {
      marginBottom: '3rem',
    },
  },
});

export default GoodCheckoutStyles;
