
import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons/Close';

const ConfirmDialog = ({ onCancel = () => { }, onConfirm = () => { }, open = false, msg = '', title = '', data = '' }) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      {/* <Box position="absolute" top={0} right={0}>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </Box> */}
      <DialogContent>
        <Typography>{msg}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={() => onConfirm(data)}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
