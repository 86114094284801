/** @format */

import {makeStyles} from '@material-ui/core/styles';
import { fontSize } from '@material-ui/system';

const OrderConfirmStyles = makeStyles(
  {
    actualCard: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#fff',
      border: '1px solid #eee',
      borderRadius: '4px',
      marginBottom: '1rem',
      fontSize:'6rem',
      padding: '2rem',
      '& p': {
        fontSize:'4rem',
      },
      '& h6': {
        fontSize:'6rem',
      }
    },
    cardBtn: {
      width: 'min-content',
      '& .MuiSvgIcon-root': {
        width: '6rem',
        height: '6rem'
      }
    },
    cardContent: {
      flexGrow: 1,
      padding: 0,
    },
    cardTitle: {
      fontSize: '6rem',
      fontWeight: '600',
      lineHeight: 'normal',
      marginTop: '3rem',
      marginBottom: '1rem',
    },
    fakeCard: {
      alignItems: 'center',
      backgroundColor: '#fff',
      border: '1px solid #eee',
      borderRadius: '4px',
      display: 'flex',
      fontSize:'6rem',
      flexDirection: 'column',
      marginBottom: '1rem',
      padding: '2rem',
      '& .row': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        fontSize:'6rem'
      },
    },
    label: {
      color: '#666',
      display: 'block',
      marginBottom: '1rem',
      fontSize: '6rem',
      minWidth: 'max-content',
    },
    shippingInfo: {
      display: 'block',
      fontWeight: '600',
      fontSize:'6rem'
    },
    submitBtn: {
      borderRadius: '8px',
      fontSize: '6rem',
      padding: '2rem 0',
    },
    title: {
      marginBottom: '1.5rem',
      marginLeft: '2rem',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& .spacer': {
        flexGrow: 1,
      },
    },
  },
  {name: 'order-confirm-comp'}
);

export default OrderConfirmStyles;
