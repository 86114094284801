/** @format */

import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {OrderStyles, SearchStyles} from './styles';
import parse from 'html-react-parser';

const Orders = props => {
  const orderStyles = OrderStyles().orders;
  const searchStyles = SearchStyles();
  const [timeRange, setTimeRange] = useState('week');
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    let dateRange = 0;
    switch (timeRange) {
      // TODO
      // Add db/api/product lookups
      case 'day':
        dateRange = 1;
        break;
      case 'week':
        dateRange = 7;
        break;
      case 'month':
        dateRange = 31;
        break;
      case '3 months':
        dateRange = 93;
        break;
      case '6 months':
        dateRange = 186;
        break;
      case 'year':
        dateRange = 365;
        break;
      case 'all':
        let startDate = new Date("1/1/2021");
        let Difference_In_Time = new Date().getTime() - startDate.getTime();
        let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
        dateRange = Difference_In_Days;
        break;
      default:
        break;
    }
    fetch(`/api/sales?days=${dateRange}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(
        result => {
          setOrders(result.orders);
        },
        error => {
          console.log('error: ', error);
        }
      );
  }, [timeRange]);

  const updateTimeRange = newTimeRange => {
    setTimeRange(newTimeRange);
  };

  const getColors = colors => {
    let colorArray = colors.split(',');
    let colorObj = {};
    colorArray.forEach(color => {
      color = color.trim();
      if (!colorObj[color]) {
        colorObj[color] = 0;
      }
      colorObj[color]++;
    });

    if (colorArray.length > 1) {
      return (
        <table style={{width: '100%'}}>
          <tbody>
            {Object.keys(colorObj).map((key, index) => {
              return (
                <tr key={index}>
                  <td style={{width: '75%'}}>
                    <Typography variant="h3">{key}</Typography>
                  </td>
                  <td>
                    <Typography variant="h3">x{colorObj[key]}</Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return  <Typography
      variant="h3"
      align="left"
      color="textPrimary"
      className="item-desc"
    >
      {colors}
    </Typography>;
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      key={props.index}
      className={orderStyles}
    >
      <Paper component="form" className={searchStyles.root}>
        <IconButton
          type="submit"
          className={searchStyles.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          className={searchStyles.input}
          placeholder="Search all orders"
          inputProps={{'aria-label': 'search google maps'}}
        />
      </Paper>
      {props.value === props.index && (
        <>
          <div className="time-wrapper">
            <Typography>{orders.length} Orders placed in the </Typography>
            <Select
              fullWidth
              margin="dense"
              onChange={e => updateTimeRange(e.target.value.toLowerCase())}
              className="range-picker"
              disableUnderline={true}
              IconComponent={ExpandMoreIcon}
              defaultValue="week"
            >
              <MenuItem value="day" style={{fontSize:'6rem'}}>Past Day</MenuItem>
              <MenuItem value="week" style={{fontSize:'6rem'}}>Past Week</MenuItem>
              <MenuItem value="month" style={{fontSize:'6rem'}}>Past Month</MenuItem>
              <MenuItem value="3 months" style={{fontSize:'6rem'}}>Past 3 Months</MenuItem>
              <MenuItem value="6 months" style={{fontSize:'6rem'}}>Past 6 Months</MenuItem>
              <MenuItem value="year" style={{fontSize:'6rem'}}>Past Year</MenuItem>
              <MenuItem value="all" style={{fontSize:'6rem'}}>All Time</MenuItem>
            </Select>
          </div>
          <div className="orders-wrapper">
            {orders.map((order, index) => {
              return (
                <Card
                  className="order-card"
                  key={`${order}-${index}`}
                  elevation={0}
                >
                  <CardContent className="card-content">
                    <Typography className="status-txt">
                      Status: {order.status.toTitleCase()}{' '}
                      {order.trackingNum && (
                        <a
                          href={`https://www.fedex.com/fedextrack/?tracknumbers=${order.trackingNum}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Tracking # {order.trackingNum}
                        </a>
                      )}
                      {order.trackingInfo && order.trackingInfo.map(shipment=>{
                        return(
                          <Button 
                            className="btn" 
                            style={{width:'100%',margin:'2rem'}}
                            variant="outlined" 
                            fullWidth={true} 
                            href={`https://www.fedex.com/fedextrack/?tracknumbers=${shipment.trackingNum}`}
                            target="_blank"
                            rel="noreferrer">
                              Tracking # {shipment.trackingNum}
                          </Button>
                      )})}
                    </Typography>
                    {order.lines.map((item, index) => {
                      return (
                        <div className="item-wrapper" key={`item-${index}`}>
                          <img
                            src={item.images + '?w=125'}
                            alt={item.name}
                            className="item-img"
                          />
                          <div className="item-info">
                            <Typography
                              variant="h2"
                              className="item-title"
                              align="left"
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              variant="h3"
                              align="left"
                              color="textPrimary"
                              className="item-desc"
                            >
                              {/* {parse(item.description)} */}
                            </Typography>

                            {getColors(item.options.color)}
                            <Typography
                              variant="h2"
                              align="left"
                              className="item-price"
                            >
                              ${item.qty * item.price}
                            </Typography>
                          </div>
                          <br/>
                          <div className="buttons">
                            <Link
                              href={`/shop/${
                                item.options.material
                              }?color=${item.options.color.toLowerCase()}&amount=${item.options.weight.toLowerCase()}&diameter=${item.options.diameter.toLowerCase()}/`}
                            >
                              <Button className="blue btn" fullWidth={true}>
                                Buy Again
                              </Button>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Orders;
