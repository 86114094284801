/** @format */

import React, {useState,useRef, useEffect} from 'react';

const AnimatehHeight = props => {
    const containerRef = useRef(null);
    const [height, setHeight] = useState(0);
    const {children, delay=2, disable=false} = props;
  
    useEffect(() => {
      if (containerRef.current) {
        const resizeObserver = new ResizeObserver((entries) => {
          // We only have one entry, so we can use entries[0].
          const observedHeight = entries[0].contentRect.height
          setHeight(observedHeight)
        });
  
        resizeObserver.observe(containerRef.current);
  
        return () => {
          // Cleanup the observer when the component is unmounted
          resizeObserver.disconnect();
        }
      }
    }, [])

    if(disable) {
      return <div style={{height:'auto', width:'100%'}}>{children}</div>;
    } else {
      return (
        <div style={{width:'100%', transition: 'max-height '+delay+'s', overflow: 'hidden', maxHeight: height}}>
        <div ref={containerRef} style={{height:'auto', width:'100%'}}> 
            {children}
        </div>
        </div>
      );
    }
};

export default AnimatehHeight;



