/** @format */

import React, {useState,useRef,useEffect} from 'react';
// Custom components
import CheckoutButton from '../styled/checkout-button';
import CouponComp from '../coupon';

// Material UI
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// Material UI icons
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// Styles
import OrderConfirmStyles from './styles';
import paypalButtons from '../paypalButtons/subscriptions';

export default function OrderConfirm(props) {
  const pgStyles = OrderConfirmStyles();
  const {
    editPayment,
    editShipping,
    onSubmit,
    payment,
    cartUtils,
    shipping,
  } = props;
  // const totals = cartUtils.getTotals(address.postal_code);
  const paypalButtonsRef = useRef();
  const membership = {
    total:"25.00"
  }
  const [couponData, setCouponData] = useState(null);
  const address = shipping.address || null;
  let card = payment.card || payment;
  const {paypal} = payment;

  if(card.card) {
    let cardId = card.id;
    card = card.card;
    card.id = cardId;
  }
  const [totals, setTotals] = useState(
    cartUtils.getTotals(cartUtils.cart)
  );

  useEffect(() => {
    setTotals(cartUtils.getTotals(cartUtils.cart));
    // eslint-disable-next-line
  }, [couponData]);

  function handleSubmit() {
    onSubmit(couponData);
  }

  useEffect(() => {
    if (paypal === true && paypalButtonsRef.current?.childElementCount === 0) {
      paypalButtons({ shippingData: shipping });
    }
  }, []);

  function getSingleLineShipping(includeName = true) {
    let returnStr = includeName ? shipping.name : '';
    returnStr += ` ${address.line1}`;
    if (address.line2 && address.line2.length > 0) {
      returnStr += ` ${address.line2}`;
    }
    if (address.city) {
      returnStr += `, ${address.city}`;
    }
    if (address.state) {
      returnStr += `, ${address.state}`;
    }
    if (address.postal_code) {
      returnStr += ` ${address.postal_code}`;
    }
    return returnStr;
  }

  function getFormattedExpMonth() {
    if (card.exp_month < 10) {
      return '0' + card.exp_month.toString();
    }
    return card.exp_month;
  }

  const bullet = String.fromCharCode(8226);
  const bullets = bullet + bullet + bullet + bullet;

  return (
    <div className={pgStyles.wrapper}>
      <Typography className={pgStyles.title} variant="h4">
        Premium Membership
      </Typography>
      <div className={pgStyles.fakeCard}>
        <div className="row">
          <Typography
            className={pgStyles.label}
            noWrap
            style={{color: '#000', marginBottom: 0}}
          >
            Shipping to: &nbsp;
          </Typography>
          <Typography className={pgStyles.shippingInfo} noWrap>
            {getSingleLineShipping()}
          </Typography>
        </div>
      </div>
      {/* <CouponComp setCouponData={setCouponData} /> */}
      <div className={pgStyles.fakeCard}>
        
        <div className="row">
          <Typography variant="h2">Order Total:</Typography>
          <Typography variant="h2">${membership.total}</Typography>
        </div>
      </div>
      <Typography className={pgStyles.cardTitle}>{cartUtils.localPickup ? 'Local Pickup':'Shipping Address'}</Typography>
      <Card
        className={pgStyles.actualCard}
        elevation={0}
        onClick={cartUtils.localPickup ? null:editShipping}
      >
        <CardContent className={pgStyles.cardContent}>
          <Typography variant="h6">{shipping.name}</Typography>
          <Typography>{address.line1}</Typography>
          {address.line2 && address.line2.length > 0 ? (
            <Typography>{address.line2}</Typography>
          ) : null}
          <Typography>
            {address.city},&nbsp;{address.state}&nbsp;{address.postal_code}
          </Typography>
          {/* <Typography>{getSingleLineShipping(false)}</Typography> */}
        </CardContent>
        <CardActionArea className={pgStyles.cardBtn}>
          <ChevronRightIcon />
        </CardActionArea>
      </Card>
      <Typography className={pgStyles.cardTitle}>Payment Method</Typography>
      {paypal === true?
      <Card className={pgStyles.actualCard} elevation={0} onClick={editPayment}>
      <CardContent className={pgStyles.cardContent}>
        <Typography variant="h6">
          Paypal
        </Typography>
        <Typography>
        </Typography>
      </CardContent>
      <CardActionArea className={pgStyles.cardBtn}>
        <ChevronRightIcon />
      </CardActionArea>
    </Card>
      :
      <Card className={pgStyles.actualCard} elevation={0} onClick={editPayment}>
        <CardContent className={pgStyles.cardContent}>
          <Typography variant="h6">
            {card.brand}&nbsp;{bullets}
            {card.last4}
          </Typography>
          <Typography>
            Exp.&nbsp;{getFormattedExpMonth()}/
            {card.exp_year.toString().substring(2)}
          </Typography>
        </CardContent>
        <CardActionArea className={pgStyles.cardBtn}>
          <ChevronRightIcon />
        </CardActionArea>
      </Card>
      }
      <div className="spacer"></div>
      <div
        id="paypal-subscription-container"
        ref={paypalButtonsRef}
      />
      {paypal !== true &&
      <CheckoutButton
        className={pgStyles.submitBtn}
        onClick={() => handleSubmit()}
        variant="contained"
      >
        Place your order
      </CheckoutButton>}
    </div>
  );
}
