import React, { useState } from 'react';
import { SnackbarContent  } from 'notistack'


const Notification = React.forwardRef((props, ref) => {

  return (
    <div ref={ref} role="alert" {...props} message={<img src="https://via.placeholder.com/300" alt="banner" />}>
      
    </div>
  )
})

export default Notification