/** @format */

import {makeStyles} from '@material-ui/core/styles';
import { fontSize } from '@material-ui/system';

const FaqStyles = makeStyles(theme => ({
  banner: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '42.59vh',
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
    '& .banner-img': {
      backgroundColor: 'rgba(0,0,0,1)',
      left: 0,
      maxHeight: '100%',
      objectFit: 'cover',
      objectPosition: 'center bottom',
      // opacity: '50%',
      position: 'absolute',
      top: '0',
      width: '100%',
      zIndex: '-99',
    },
    '& .inp': {
      height: '5.25rem',
      marginTop: '4rem',
      width: '57.5rem',
    },
    '& .title': {
      color: '#fff',
      marginTop: '16rem',
      width: '100%',
      
    },
    '& .titlemobile': {
      color: '#fff',
      marginTop: '20rem',
      fontSize:'9rem',
      width: '100%',
      
    },
  },
  contentHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '7.5rem 0',
    maxWidth: '35.83%',
    '& .desc': {
      marginTop: '3rem',
    },
    '& .titleh1': {
        fontSize:'5rem',
        fontWeight: 600,
        lineHeight: 1.235,
    }
  },
  mainContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  qNotFound: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12rem',
    '& .btn': {
      backgroundColor: '#0099FF',
      color: '#fff',
      fontSize: '2rem',
      fontWeight: '500',
      marginBottom: '14.25rem',
      marginTop: '3rem',
      padding: '1.5rem 7rem',
    },
  },
  questions: {
    width: '35.83%',
    '& .qtxt': {
      color: '#666',
    },
  },
  styledAcc: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  styledAccMobile: {
    '&.Mui-expanded': {
      margin: 0,
    },
    fontSize:'6rem',
    '& p' : {
      fontSize:'6rem'
    },
    '& ul' : {
      marginLeft: '10rem !important' 
    },
    '& li' : {
      fontSize:'6rem'
    },
    '& .MuiSvgIcon-root': {
      width:'6rem',
      height:'6rem'
    }

  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export default FaqStyles;
