/** @format */

import React from 'react';
// Custom components
import StepCard from './stepCard';
import StepDetailCard from './stepDetailCard';
// Material UI
// Styles

export default class Step {
  constructor(stepInfo = {}, cardOnClick) {
    this._name = stepInfo.name || 'Default Step';
    this._align = stepInfo.align || 'left';
    this._details =
      stepInfo.details ||
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, culpa nesciunt ex, ipsam sed facilis, corporis recusandae dolor enim adipisci eius laudantium? Ad reiciendis eum assumenda libero aliquam perferendis qui?';
    this._imgPath =
      stepInfo.imgPath || 'https://cdn.sunsh1n3.com/Images/process2/Step01.jpg';
    this._num = stepInfo.num || 0;
    let splitName = this._name.split(' ');
    this._stepId = '';
    splitName.forEach((str, index) => {
      if (index > 0) {
        this._stepId += `-${str}`;
      } else {
        this._stepId = str;
      }
    });
    this._stepId += `-${this._num.toString()}`;
    this._detailId = this._stepId + '-d';
    this.cardOnClick =
      cardOnClick ||
      (() => {
        console.log('Default step onClick');
      });
  }

  //#region Simple Getters

  get details() {
    return this._details;
  }

  get detailId() {
    return this._detailId;
  }

  get imgPath() {
    return this._imgPath;
  }

  get name() {
    return this._name;
  }

  get num() {
    return this._num;
  }

  get stepId() {
    return this._stepId;
  }

  //#endregion

  get card() {
    return (
      <StepCard
        id={this._stepId}
        key={this._stepId}
        onClick={() => this.cardOnClick(this._num)}
        stepName={this._name}
        stepNum={this._num}
        align={this._align}
        srcPath={this._imgPath}
      />
    );
  }

  get detailCard() {
    return (
      <StepDetailCard
        details={this._details}
        id={this._detailId}
        key={this._detailId}
        stepName={this._name}
        stepNum={this._num}
        align={this._align}
        srcPath={this._imgPath}
      />
    );
  }
}
