/** @format */

import {makeStyles} from '@material-ui/core/styles';

const OrderStyles = makeStyles(theme => ({
  orders: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 50,
    '& .time-wrapper': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize:'6rem',
      justifyContent: 'center',
      minHeight: 60,
      '& p': {
        fontSize:'6rem',
      },
      '& .MuiSelect-icon': {
        width:'6rem',
        height:'6rem'
      },
      '& .range-picker': {
        marginLeft: '1.5rem',
        width: '45vw',
        fontSize:'6rem',
        backgroundColor: '#11111115',
        lineHeight: '10rem',
        padding: '3px 5px',
        borderRadius: '1rem',
        '& .MuiInputBase-root': {
          boxShadow: '0px 4px 8px #0000000A',
        }
      },
    },
    '& .orders-wrapper': {
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column',
      '& .order-card': {
        display: 'flex',
        flexDirection: 'column',
        // border: '1px solid #BDBDBD',
        marginBottom: 20,
        borderRadius: '8px',
        '& .order-info': {
          height: '9.3vh',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          '& .info-sect': {
            margin: '0 4rem',
            '&:last-child': {
              flexGrow: '1',
              textAlign: 'right',
            },
          },
          '& .info-title': {
            fontSize: '6rem',
            fontWeight: '500',
            textTransform: 'uppercase',
          },
        },
        '& .card-content': {
          backgroundColor: '#fff',
          padding: '0',
          '& .status-txt': {
            fontSize: '5rem',
            fontWeight: '500',
            marginLeft: '4rem',
            marginTop: '5rem',
            marginBottom: '4.07vh',
            '& a': {
              fontSize: '6rem',
              color: 'black',
              float: 'right',
              marginRight: '4rem',
              textDecoration: 'underline',
            },
          },
          '& .item-wrapper': {
            display: 'flex',
            flexFlow: 'wrap',
            marginLeft: '3.23vw',
            marginRight: '3.23vw',
            marginBottom: '3.98vh',
            '& .buttons': {
              width: '100%',
              '& .btn': {
                border: '1px solid #0099FF',
                fontSize: '6rem',
                height: 53,
                '&.blue': {
                  backgroundColor: '#0099FF',
                  color: 'white',
                  marginRight: '1rem',
                },
                '&.grey': {
                  backgroundColor: '#f7f7f7',
                  color: '#0099FF',
                },
                '& .MuiButton-startIcon': {
                  maxHeight: '2rem',
                  margin: '0',
                  marginLeft: '1rem',
                  marginRight: '0.5rem',
                  padding: '0',
                },
                '& .MuiSvgIcon-root': {
                  height: '2rem',
                  width: '2rem',
                },
              },
            },
            '& .item-img': {
              backgroundColor: 'transparent',
              color: 'transparent',
              // flex:1,
              // maxWidth: '125px',
              maxHeight: '125px',
              marginRight: '2rem',
            },
            '& .item-title': {
              color: '#265EFE',
            },
            '& .item-desc': {
              maxHeight: '6em',
              overflowY: 'hidden',
            },
            '& .item-info': {
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              '& .item-price': {
                color: '#CC0000',
              },
            },
          },
        },
      },
    },
  },
  buyAgain: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '8vh',
  },
  openOrders: {},
  digitalOrders: {},
  canceledOrders: {},
}));

const SearchStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    margin: '24px 16px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '1rem',
    border: '1px solid #707070',
    fontSize:'6rem',
    '& MuiPaper-elevation1': {
      boxShaddow: 'none',
    },
    '& .MuiSvgIcon-root': {
      width:'6rem',
      height:'6rem',
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize:'6rem',
  },
  iconButton: {
    padding: 10,
    fontSize:'6rem',
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export {OrderStyles, SearchStyles};
