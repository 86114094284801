/** @format */

import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ShippingDetails from '../shippingDetails';
import PrefilledShippingStyles from './styles.js';

const PrefilledShippingDetails = forwardRef((props, ref) => {
  const shippingRef = useRef(null);
  const classes = PrefilledShippingStyles();
  const {customerInfo,userId} = props;

  const [currentCustomerInfo, setCurrentCustomerInfo] = useState({});
  const [newAddressFormInfo, setNewAddressFormInfo] = useState({});
  const [showNewAddressInput, setShowNewAddressInput] = useState(null);

  // Fills in customer info once it has been fetched and passed in to props
  useEffect(() => {
    const newCustomerInfo = {};
    Object.keys(customerInfo).forEach(key => {
      newCustomerInfo[key] = customerInfo[key];
    });
    setCurrentCustomerInfo(newCustomerInfo);
  }, [customerInfo]);

  // This is required to allow us to use these functions as `ref.current.functionName()`
  // when using this component on a page (such as checkout)
  useImperativeHandle(ref, () => ({
    isFormValid() {
      return true;
    },
    getCustomerInfo() {
      return currentCustomerInfo;
    },
  }));

  const updateNewAddressFormInfo = newCustomerInfo => {
    let newVals = {};
    for (const key in newCustomerInfo) {
      newVals[key] = newCustomerInfo[key].value;
    }
    setNewAddressFormInfo(newVals);
  };

  const updateShippingInfo = async () => {
    if (shippingRef.current.isFormValid()) {
      const {
        address,
        address2,
        city,
        country,
        email,
        firstName,
        lastName,
        phone,
        zipCode,
      } = newAddressFormInfo;
      const name = `${firstName} ${lastName}`;

      const body = {
        address: {
          city,
          country,
          line1: address,
          postal_code: zipCode,
        },
        email,
        phone,
        shipping: {
          address: {
            city,
            country,
            line1: address,
            postal_code: zipCode,
          },
          name,
          phone,
        },
        name,
      };

      await fetch(`/api/user/${userId}/billing`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setCurrentCustomerInfo({...newAddressFormInfo, userId});
      setShowNewAddressInput(false);
    } else {
      console.log('Form is not valid');
    }
  };

  return (
    <div
      className={
        props.isFormOnSide ? classes.wrapperRows : classes.wrapperColumns
      }
    >
      <div className={classes.infoWrapper}>
        <Typography className={classes.info}>
          {currentCustomerInfo.firstName} {currentCustomerInfo.lastName}
        </Typography>

        <Typography className={classes.info}>
          {currentCustomerInfo.email}
        </Typography>

        <div className={classes.group}>
          <Typography className={classes.info}>
            {currentCustomerInfo.address}
          </Typography>
         {currentCustomerInfo.address2 && <Typography className={classes.info}>
            {currentCustomerInfo.address2}
          </Typography>}
          <Typography className={classes.info}>
            {currentCustomerInfo.city}
          </Typography>
          <Typography className={classes.info}>
            {currentCustomerInfo.zipCode}
          </Typography>
          <Typography className={classes.info}>
            {currentCustomerInfo.country}
          </Typography>
        </div>

        <Typography className={classes.info}>
          {currentCustomerInfo.phone}
        </Typography>

        <Button
          className={`${classes.newAddressButton} ${
            showNewAddressInput === null
              ? ''
              : showNewAddressInput
              ? classes.newAddressOpen
              : classes.newAddressClosed
          }`}
          onClick={() => setShowNewAddressInput(!showNewAddressInput)}
        >
          Edit Address
          <AddCircleOutlineIcon className={classes.plusIcon} />
        </Button>
      </div>
      {showNewAddressInput && (
        <div>
          <ShippingDetails
            updateFunc={updateNewAddressFormInfo}
            ref={shippingRef}
          />
          <Button className={classes.updateButton} onClick={updateShippingInfo}>
            Update
          </Button>
        </div>
      )}
    </div>
  );
});

export default PrefilledShippingDetails;
