/** @format */

import React from 'react';
// Custom components
import {BannerBtn, Default} from '../index';
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

//#region Styled components
const MainTxt = styled(Typography)(
  {
    color: '#000',
    fontFamily: 'Barlow Condensed, Roboto, sans-serif',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '1',
    marginBottom: '3rem',
    userSelect: 'none',
    width: '50rem',
    '&.med-txt': {
      fontSize: '3rem',
      fontWeight: '600',
      marginBottom: '5rem',
      width:'80%',
      '&.mbl': {
        fontSize: '9rem',
        width:'100%',
        textAlign:'justify',
        fontWeight:'normal'
      },
    },
    '&.big-txt': {
      fontSize: '8rem',
      fontWeight: '700',
      marginBottom: '2.5rem',
      '&.mbl': {
        width:'100%',
        fontSize: '12rem',
      },
    },
    '&.small-txt': {
      fontSize: '3rem',
      fontWeight: '400',
      '&.mbl': {
        fontSize: '9rem',
      },
      '&.top-line': {
        marginBottom: '0',
      },
    },
  },
  {name: 'main-txt'}
);
//#endregion

export default function StarterKitBanner({isMobile}) {
  return (
    <Default
      bgColor="#fefefe"
      isMobile={isMobile}
      mainImgSrc="https://cdn.sunsh1n3.com/home/Starter%20Kit.png"
      padding="10% 5% 8% 5%"
      textLoc="top">
      <MainTxt
        align="center"
        className={`small-txt top-line ${isMobile ? 'mbl' : ''}`}>
        Save With a Starter Kit
      </MainTxt>
      <MainTxt align="center" className={`big-txt ${isMobile ? 'mbl' : ''}`}>
        STARTER KIT
      </MainTxt>
      <MainTxt align="center" className={`med-txt ${isMobile ? 'mbl' : ''}`}>
        The PrintBed starter kit is a convenient and cost-effective way to enter the world of 3D printing with everything you need to begin. It consists of a mini bundle, Forge PLA+ 3D filament, and a PrintBed t-shirt. You save more than $20! So grab one for yourself or as a great gift for someone just starting out with 3D printing.
      </MainTxt>
      <BannerBtn
        colorVariant="blue"
        custHeight={isMobile ? '20rem' : undefined}
        custWidth={isMobile ? '' : undefined}
        fontSize={isMobile ? '8rem' : '2.75rem'}
        fontWeight="400"
        to="/shop/Kit/Black-SM-1kg/">
        Order a Starter Kit Now
      </BannerBtn>
    </Default>
  );
}
