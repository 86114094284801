/** @format */

import React from 'react';
import {Minus, Plus} from 'react-feather';
// Custom components
// Material UI
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';
// Styles
import ItemStyles from '../styles';

//#region Styled Comps

const StyledTxt = styled(Typography)(
  {
    '&.MuiTypography-body2': {
      fontSize: '4rem',
    },
  },
  {name: 'styled-txt'}
);

//#endregion

export default function MiniOption(props) {
  const pgStyles = ItemStyles();
  const {
    handleOptionSelect = () => {},
    isDisabled = false,
    isSelected = false,
    item,
    thisRollSelectedCount = 0,
    totalRollsSelected = 0,
  } = props;

  return (
    <Tooltip
      key={item.tags.color + '-tooltip'}
      className={pgStyles.miniOptContainer}
      disableHoverListener={isDisabled}
      disableTouchListener={isDisabled}
      disableFocusListener={isDisabled}
      title={item.tags.colorName}
      placement="top">
      <Card
        className={
          pgStyles.miniOptChoice + ' color' + (isSelected ? ' selected' : '')
        }
        elevation={0}>
        <CardContent className={pgStyles.optionCard + ' content'}>
          <StyledTxt variant="body2">{item.tags.colorName}</StyledTxt>
          <div
            className="color-div"
            style={{
              backgroundImage: `url('${item.imgSrc[0]}?w=${Math.round(
                window.innerWidth * window.devicePixelRatio
              )}')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
            &nbsp;
          </div>
        </CardContent>
        <CardActions
          className={pgStyles.optionCard + ' actions'}
          disableSpacing>
          <Button
            className={pgStyles.miniQtyBtn + ' minus'}
            disabled={!isSelected || isDisabled}
            onClick={() => {
              handleOptionSelect(item, -1);
            }}
            style={{padding: 0}}>
            <Minus />
          </Button>
          <StyledTxt variant="body2">{thisRollSelectedCount}</StyledTxt>
          <Button
            className={pgStyles.miniQtyBtn + ' plus'}
            disabled={totalRollsSelected >= 5 || isDisabled}
            style={{padding: 0}}
            onClick={() => {
              handleOptionSelect(item, 1);
            }}>
            <Plus />
          </Button>
        </CardActions>
      </Card>
    </Tooltip>
  );
}
