/** @format */

import React, { useState, useRef, useEffect } from 'react';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// Custom comps
import PaymentSourceElement from './paymentSourceElement';
import PaymentFormElement from './paymentForm';
// Styled components
import CheckoutButton from '../styled/checkout-button';
import NewOptBtn from '../styled/checkout-new-opt-btn';
// Stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// Styles
import PaymentStyles from './styles.js';

const stripeKey = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function PrefilledPaymentDetails(props) {
  const { formChange, paymentInfo, userData, totals, subscription=false } = props;
  const { loggedIn, userId } = userData;
  const pgStyles = PaymentStyles();

  const [loading, setLoading] = useState(true);
  const [paymentSources, setPaymentSources] = useState([]);
  const [selectedPaymentSourceInfo, setSelectedPaymentSourceInfo] = useState();
  const [selectedPaymentSourceId, setSelectedPaymentSourceId] = useState('');
  const [showNewPaymentSourceForm, setShowNewPaymentSourceForm] = useState(
    loggedIn !== true
  );

  //#region useEffect's

  useEffect(() => {
    fetch(`/api/user/${userId}/payments`)
      .then(resp => resp.json())
      .then(sources => {
        if (sources && sources.length > 0) {
          if(subscription===true) {
            sources = sources.filter(source=>source.object!=='card');
          }
          setPaymentSources(sources);
          setSelectedPaymentSourceInfo(sources[0]);
          setSelectedPaymentSourceId(sources[0].id);
        } else {
          setShowNewPaymentSourceForm(true);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formChange) formChange(showNewPaymentSourceForm);
    // eslint-disable-next-line
  }, [showNewPaymentSourceForm]);

  //#endregion

  async function refreshPaymentSources() {
    await fetch(`/api/user/${userId}/payments`)
      .then(resp => resp.json())
      .then(sources => {
        if (sources && sources.length > 0) {
          setPaymentSources(sources);
          setSelectedPaymentSourceId(sources[0].id);
          setSelectedPaymentSourceInfo(sources[0]);
        }
      });
  }

  async function handleSubmit(paymentToken) {
    if (loggedIn) {
      if (paymentToken.tokenId) {
        const body = { token: paymentToken.tokenId };
        const response = await fetch(`/api/user/${userId}/payments`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          setShowNewPaymentSourceForm(false);
          await refreshPaymentSources();
        }
      }
    } else {
      paymentInfo(paymentToken);
    }
  }

  const makePaymentSourceElement = paymentSource => {
    if (paymentSource.card) {
      paymentSource.card.id = paymentSource.id;
      return makePaymentSourceElement(paymentSource.card);
    }
    const { id } = paymentSource;

    return (
      <PaymentSourceElement
        isSelected={id === selectedPaymentSourceId}
        paymentSourceInfo={paymentSource}
        updateSelectedPaymentSourceId={srcInfo => {
          setSelectedPaymentSourceId(srcInfo.id);
          setSelectedPaymentSourceInfo(srcInfo);
        }}
        key={id}
      />
    );
  };

  return (
    <div
      className={pgStyles.pgWrapper}
      style={{
        backgroundColor: showNewPaymentSourceForm ? '#fff' : '#f6f6f6',
        padding: showNewPaymentSourceForm ? '0 2rem' : 0,
      }}
    >
      <Typography
        variant="h2"
        className={pgStyles.title}
        style={{
          marginBottom: showNewPaymentSourceForm ? '4rem' : '1.5rem',
          marginLeft: showNewPaymentSourceForm ? '0' : '2rem',
        }}
      >
        Payment Details
      </Typography>
      {loading && <CircularProgress variant="indeterminate" />}
      {showNewPaymentSourceForm ? (
        <Elements stripe={stripeKey}>
          <PaymentFormElement
            hasCancelBtn={loggedIn}
            onCancel={() => setShowNewPaymentSourceForm(false)}
            onSubmit={handleSubmit}
            submitBtnTxt={loggedIn ? 'Save' : 'Continue'}
            userData={userData}
          />
        </Elements>
      ) : (
        <>
          {paymentSources.map(source => makePaymentSourceElement(source))}
          <NewOptBtn
            className={pgStyles.newPaymentSourceButton}
            label="Add a payment method"
            onClick={e => {
              setShowNewPaymentSourceForm(true);
            }}
          />
          <NewOptBtn
            className={pgStyles.newPaymentSourceButton}
            label="Use Paypal"
            onClick={e => {
              // setSelectedPaymentSourceId('paypal');
              paymentInfo({ paypal: true });
            }}
          />
          <div style={{ flexGrow: 1 }}></div>
          {paymentInfo && (
            <CheckoutButton
              className={pgStyles.contBtn}
              onClick={() => paymentInfo({ card: selectedPaymentSourceInfo })}
              variant="contained"
            >
              Continue
            </CheckoutButton>
          )}
        </>
      )}
    </div>
  );
}
