/** @format */

import React from 'react';
// Custom components
import {BannerBtn, Default} from '../index';
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

//#region Styled components
const MainTxt = styled(Typography)(
  {
    color: '#000',
    fontFamily: 'Barlow Condensed, Roboto, sans-serif',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '1',
    marginBottom: '3rem',
    userSelect: 'none',
    width: '75rem',
    '&.mbl': {
      width: '80rem',
    },
    '&.med-txt': {
      fontSize: '3rem',
      fontWeight: '600',
      marginBottom: '5rem',
      '&.mbl': {
        fontSize: '8rem',
      },
    },
    '&.big-txt': {
      fontSize: '4rem',
      fontWeight: '700',
      marginBottom: '2.5rem',
      '&.mbl': {
        fontSize: '12rem',
        width:'100%',
      },
    },
    '&.small-txt': {
      fontSize: '3rem',
      fontWeight: '400',
      marginBottom: '4rem',
      '&.mbl': {
        fontSize: '9rem',
        width:'100%',
        textAlign:'justify'
      },
      '&.top-line': {
        marginBottom: '1rem',
      },
    },
  },
  {name: 'main-txt'}
);

const MSLogo = styled('img')(
  {
    marginBottom: '1rem',
    padding: '0 10%',
    width: '75%',
  },
  {name: 'minis-logo'}
);
//#endregion

export default function MicroswissBanner({isMobile}) {
  return (
    <Default
      bgColor="#f5f5f5"
      mainImgSrc="https://cdn.sunsh1n3.com/home/microswiss/extruder.png"
      padding={isMobile?"5%":"10% 13vw"}
      textLoc={isMobile?"top":"right"}
      isMobile={isMobile}
      txtStyles={{
        marginRight: isMobile?null:'10%',
      }}>
      <MainTxt
        align="center"
        className={`small-txt top-line ${isMobile ? 'mbl' : ''}`}>
        Made in USA by
      </MainTxt>
      <MSLogo src="https://cdn.sunsh1n3.com/home/microswiss/microswiss.png" />
      <MainTxt variant="h2" align="center" className={`big-txt ${isMobile ? 'mbl' : ''}`}>
      Upgrade Your Printer With Micro Swiss
      </MainTxt>
      <MainTxt align="center" className={`small-txt ${isMobile ? 'mbl' : ''}`}>
      Tangle-free 3D printing spools are even better with an updated printer. PrintBed is now an official reseller of Micro Swiss. Get the upgrade you need for your 3D printer with Micro Swiss parts made in the USA. 
      </MainTxt>
      <BannerBtn
        colorVariant="black"
        custHeight={isMobile ? '20rem' : undefined}
        custWidth={isMobile ? '' : undefined}
        fontSize={isMobile ? '8rem' : '2.75rem'}
        fontWeight="400"
        to="/shop/upgrades">
        Order Now
      </BannerBtn>
    </Default>
  );
}
