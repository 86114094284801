/** @format */

import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import PaymentSourceElement from './paymentSourceElement';
import PaymentDetailsElement from '../paymentDetails';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PrefilledPaymentStyles from './styles.js';
import {useSnackbar} from 'notistack';

const PrefilledPaymentDetails = forwardRef((props, ref) => {
  const {
    customerInfo,
    totals,
    userData,
    defaultPaymentSourceId,
    paymentSources,
    setPaymentSources,
  } = props;
  const {userId} = userData;
  const paymentRef = useRef(null);
  const classes = PrefilledPaymentStyles();
  const {enqueueSnackbar} = useSnackbar();

  const [selectedPaymentSourceId, setSelectedPaymentSourceId] = useState(
    defaultPaymentSourceId
  );
  const [showNewPaymentSourceForm, setShowNewPaymentSourceForm] =
    useState(false);
  // const [makeDefaultPaymentSource, setMakeDefaultPaymentSource] = useState(false);

  useEffect(() => {
    setPaymentSources(paymentSources);
    setShowNewPaymentSourceForm(!paymentSources.length > 0);
    // eslint-disable-next-line
  }, [paymentSources]);

  useEffect(() => {
    setSelectedPaymentSourceId(defaultPaymentSourceId);
  }, [defaultPaymentSourceId]);

  useImperativeHandle(ref, () => ({
    isFormValid() {
      if (showNewPaymentSourceForm) {
        return paymentRef.current.isFormValid();
      } else {
        return selectedPaymentSourceId !== null;
      }
    },
    async getPayment() {
      if (showNewPaymentSourceForm) {
        const payment = await paymentRef.current.getPayment();
        return payment;
      } else {
        return {source: selectedPaymentSourceId};
      }
    },
  }));

  const createNewPaymentSource = async () => {
    if (paymentRef.current.isFormValid()) {
      const payment = await paymentRef.current.getPayment();
      if (payment.token) {
        const body = {
          token: payment.token,
          // setAsDefaultSource: makeDefaultPaymentSource,
        };

        const response = await fetch(`/api/user/${userId}/payments`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const newPaymentSource = await response.json();
        setSelectedPaymentSourceId(newPaymentSource.id);
        setPaymentSources([newPaymentSource, ...paymentSources]);
        setShowNewPaymentSourceForm(false);
      }
    } else {
      enqueueSnackbar('Form is invalid', {variant: 'error'});
    }
  };

  const deletePayment = paymentId => e => {
    e.stopPropagation();
    let url = `/api/user/${userId}/payments/${paymentId}`;
    fetch(url, {method: 'DELETE'})
      .then(resp => {
        return resp.json();
      })
      .then(async data => {
        if (data.deleted) {
          let tmp = paymentSources.filter(payment => {
            return payment.id !== paymentId;
          });
          setPaymentSources(tmp);
        }
      })
      .catch(e => {
        enqueueSnackbar(e.message, {variant: 'error'});
      });
  };

  const makePaymentSourceElement = paymentSource => {
    if(paymentSource.card) {
      paymentSource.card.id = paymentSource.id;
      return makePaymentSourceElement(paymentSource.card);
    }
    
    let {brand, last4, name, exp_month, exp_year, id} = paymentSource
    
    const paymentSourceInfo = {
      id,
      brand,
      last4,
      name,
      exp_month,
      exp_year,
    };

    return (
      <PaymentSourceElement
        isSelected={id === selectedPaymentSourceId}
        paymentSourceInfo={paymentSourceInfo}
        updateSelectedPaymentSourceId={paymentSourceId =>
          setSelectedPaymentSourceId(paymentSourceId)
        }
        onDelete={deletePayment(id)}
        key={id}
      />
    );
  };

  return (
    <div className={classes.wrapper}>
      <div>
        {paymentSources.map(source => makePaymentSourceElement(source))}
      </div>
      {paymentSources.length > 0 && (
        <Button
          className={`${classes.newPaymentSourceButton} ${
            showNewPaymentSourceForm === null
              ? ''
              : showNewPaymentSourceForm
              ? classes.newPaymentSourceOpen
              : classes.newPaymentSourceClosed
          }`}
          onClick={() => setShowNewPaymentSourceForm(!showNewPaymentSourceForm)}
        >
          Add Payment Source
          <AddCircleOutlineIcon className={classes.plusIcon} />
        </Button>
      )}
      {showNewPaymentSourceForm && (
        <div>
          <PaymentDetailsElement customerInfo={customerInfo} ref={paymentRef}  />
          <Button
            className={classes.confirmButton}
            onClick={createNewPaymentSource}
          >
            Save Card
          </Button>
          {props.goBack && (
            <Button className={classes.backButton} onClick={props.goBack}>
              Back
            </Button>
          )}
        </div>
      )}
    </div>
  );
});

export default PrefilledPaymentDetails;
