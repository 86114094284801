/** @format */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import RadioIcon from './radio-icon';

const SavedInfoCard = withStyles({
  contained: {
    backgroundColor: '#f5be2e',
    boxShadow: 'none',
    color: '#000',
  },
  contentWrapper: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  iconArea: {
    flexGrow: 0,
    marginRight: '2rem',
    width: 'min-content',
  },
  outlined: {
    backgroundColor: '#000',
    color: '#f5be2e',
  },
  title: {
    fontSize:'4rem',
    fontWeight: '600',
    lineHeight: 'normal',
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem',
    padding: '2rem',
  },
})(({children, classes, onClick = () => {}, selected = true, title}) => (
  <Card className={classes.wrapper} elevation={0} onClick={onClick}>
    <CardActionArea className={classes.iconArea}>
      <RadioIcon hidden={!selected} />
    </CardActionArea>
    <CardContent className={classes.contentWrapper}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </CardContent>
  </Card>
));

export default SavedInfoCard;
