/** @format */

import React from 'react';
// Custom components
import StyledBtn from '../styled/btn-blue';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
// Styles
import {makeStyles} from '@material-ui/core';

const styles = makeStyles(
  {
    actionArea: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '2.5rem',
      justifyContent: 'space-around',
    },
    avatar: {
      height: '150px',
      marginTop: '2rem',
      width: '150px',
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0',
    },
    paper: {
      height: 'max-content',
      maxHeight: '40vh',
      width: '25vw',
    },
    title: {
      fontSize: '3rem',
      fontWeight: 'bold',
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  {name: 'confirm-avatar-modal'}
);

export default function ConfirmAvatarModal(props) {
  const pgStyles = styles();
  const {
    avatarImg = null,
    isOpen = false,
    onSelect = () => {},
  } = props;

  function handleClose(event, reason) {
    onSelect(false);
  }

  return (
    <Dialog
      className={pgStyles.wrapper}
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        className: pgStyles.paper,
      }}
    >
      <DialogContent className={pgStyles.content}>
        <Typography variant="subtitle1">
          This will be your new avatar:
        </Typography>
        <Avatar
          className={pgStyles.avatar}
          src={avatarImg || '/icons/default_avatar.svg'}
          variant="circular"
        />
      </DialogContent>
      <DialogActions className={pgStyles.actionArea}>
        <StyledBtn onClick={() => onSelect(false)} variant="text">
          Cancel
        </StyledBtn>
        <StyledBtn onClick={() => onSelect(true)} variant="text">
          Confirm
        </StyledBtn>
      </DialogActions>
    </Dialog>
  );
}
