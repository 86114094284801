/** @format */

import {makeStyles} from '@material-ui/core/styles';
import { fontWeight, textAlign } from '@material-ui/system';

export const Styles = isMobile => makeStyles({
  bg: {
    backgroundImage: "url('https://cdn.sunsh1n3.com/home/blog/boats-7009838_1280.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 65%',
    fontSize:isMobile?'6rem':'2rem',
    width: '100%',
    textAlign:'center',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    padding:'5%',
    height: 400,
    '& h1': {
      fontWeight:isMobile?600:500,
      fontSize:isMobile?'12rem':null,
    },
    '& p': {
      fontSize:isMobile?'6rem':null,
    }
  },
  container: {
    width: '100%',
    display: 'flex',
    marginTop: 50,
    alignItems: 'center',
    justifyContent:'center',
    fontSize: isMobile?'6rem':'2em',
    '& h2': {
      fontSize:isMobile?'9rem':null,
    },
    '& p': {
      fontSize:isMobile?'6rem':null,
    }
  },
  categories: {
    marginLeft: 200,
    border: '1px solid #F7F7F7',
    padding: 20,
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  posts: {
    padding: 20,
    border: '1px solid #F7F7F7',
    margin:'auto',
    width: '100%',
    wordWrap: 'break-word',
    position: 'relative',
    marginBottom:'6rem',
    // width:200,
    '& .cat': {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: 5,
      backgroundColor: '#AE42DE',
      color: 'white',
    },
    '& img':{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width:'90%'
    }
  },
});

export default Styles;
