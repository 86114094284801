/** @format */

import {makeStyles} from '@material-ui/core/styles';

const DropdownStyles = makeStyles({
  avatarBtn: {
    width: '4rem',
    height: '4rem',
  },
  popper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F7F7F7',
    border: '1px solid #CAC9CA',
    borderRadius: '8px',
    zIndex: 1000,
    width: '16.2vw',
    '& .field': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '2rem',
      borderBottom: '1px solid #00000024',
      '&:last-child': {
        border: 'none',
      },
      '& .column': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    '& .spacer': {
      height: 'auto',
      width: '4rem',
      marginRight: '4rem',
    },
  },
  textButton: {
    padding: '0',
    fontSize: '2rem',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  toolbarButton: {
    fontSize: '2rem',
    color: '#FFF',
    opacity: '100%',
    height: '100%',
    flexGrow: '1',
    display: 'flex',
  },
  infoWrapper: {
    '& .avatar': {
      height: '6rem',
      width: '6rem',
      color: '#0062ff',
    },
    '& .name-email': {
      display: 'flex',
      width:'100%',
      flexDirection: 'column',
      marginLeft: '2rem',
      '& .email': {
        color: '#666666',
        width:'80%',
        overflow:'hidden',
        textOverflow: 'ellipsis'
      },
    },
  },
});

const CheckboxStyles = makeStyles({
  checkBoxLabel: {
    alignItems: 'safe',
    color: '#000',
    marginBottom: '1.5rem',
    '&.no-lbl': {
      marginBottom: 0,
    },
  },
  checkBox: {
    color: '#000',
    '&$checked': {
      color: '#000',
    },
  },
});

export {DropdownStyles,CheckboxStyles};
