/** @format */

import React, {useState} from 'react';
// Material UI Components
import Popper from '@material-ui/core/Popper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {withStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
// Custom Imports
import SunNavButton from '@sunsh1n3/sun-nav-button';
// Styling
import {DropdownStyles} from './styles';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const TextButton = withStyles({
  root: {
    padding: '0',
    fontSize: '2rem',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})(({classes, text = '', onClick = () => {}}) => (
  // Replace w/ React Router link
  <Button
    disableRipple
    variant="text"
    color="primary"
    className={classes.root}
    onClick={onClick}
  >
    {text}
  </Button>
));

export default function UserAccountDropdown(props) {
  const {userData} = props;
  const {enqueueSnackbar} = useSnackbar();
  const styles = DropdownStyles();
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const modalOpen = Boolean(dropdownAnchor);

  const logout = e => {
    localStorage.clear();
    cookies.remove('JWT');
    cookies.remove('USER');
    window.location.href = '/';
  };
  const toggleAccountModal = event => {
    setDropdownAnchor(dropdownAnchor ? null : event.currentTarget);
  };

  const closeAccountModal = () => {
    if (dropdownAnchor) {
      setDropdownAnchor(null);
    }
  };

  const changePassword = () => {
    const body = {
      email: userData.email,
    };

    fetch('/api/auth/password/reset', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(resp => {
      if (!resp.ok) {
        enqueueSnackbar('An Error occured please try again.', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Please check your email to change your password.', {
          variant: 'success',
        });
      }
    });
  };

  return (
    <div style={{visibility:userData.loggedIn?'visible':'hidden'}}><ClickAwayListener onClickAway={closeAccountModal}>
      <IconButton
        className={styles.toolbarButton}
        disableRipple
        onClick={toggleAccountModal}
      >
        <Avatar
          variant="circular"
          src={userData.avatar || '/icons/default_avatar.svg'}
          className={styles.avatarBtn}
        />
        <Popper
          className={styles.popper}
          anchorEl={dropdownAnchor}
          open={modalOpen}
          placement="bottom-end"
        >
          <div className={styles.infoWrapper + ' field'}>
            <Avatar
              variant="circular"
              src={userData.avatar}
              className="avatar"
            />
            <div className="name-email">
              <Typography variant="h6">{userData.displayName}</Typography>
              <Typography variant="body1" className="email">
                {userData.email}
              </Typography>
            </div>
          </div>
          <div className="field">
            <div className="spacer"></div>
            <SunNavButton
              className={styles.textButton}
              color="primary"
              variant="text"
              label="Edit profile"
              to="/account/profile/"
            />
          </div>
          <div className="dd-email field">
            <div className="spacer"></div>
            <div className="column">
              <SunNavButton
                className={styles.textButton}
                color="primary"
                variant="text"
                label="Order History"
                to="/account/orders"
              />
            </div>
          </div>
          <div className={styles.password + ' field'}>
            <div className="spacer"></div>
            <div className="column">
              {/* <Typography variant="h6">Password</Typography> */}
              {/* <TextButton text='Change password' /> */}
              <SunNavButton
                className={styles.textButton}
                color="primary"
                variant="text"
                onClick={changePassword}
                label="Change Password" /*to='/account/profile/' or something */
              />
            </div>
          </div>
          <div className={styles.logout + ' field'}>
            <div className="spacer"></div>
            <div className="column">
              <TextButton text="Sign Out" onClick={logout} />
            </div>
          </div>
        </Popper>
      </IconButton>
    </ClickAwayListener></div>
  );
}
