/** @format */

import React from 'react';
// Custom components
// Material UI
import classNames from "classnames";
import Typography from '@material-ui/core/Typography';
// Styles
import {StepDetailStyles} from './styles';

export default function StepDetailCard(props) {
  const pgStyles = StepDetailStyles();
  const {
    details = '',
    id,
    srcPath = '',
    stepName = 'Plastic',
    stepNum = 0,
    align = 'left'
  } = props;

  return (
    <div
      id={id}
      // bg={srcPath}
      className={pgStyles.wrapper}
      style={
        {
          // backgroundImage: `url(${srcPath}?w=${Math.round(window.innerWidth*window.devicePixelRatio)})`,
          // backgroundSize: 'cover'
        }
      }
    >
      <img data-src={srcPath} alt="" className="Sirv" />
      <div className={classNames(pgStyles.detailsWrapper,pgStyles[align])}>
        <Typography align='left' className={pgStyles.stepTitle}>
          Step 0{stepNum}:&nbsp;
        </Typography>
        <Typography align='left' className={pgStyles.stepName}>
          {stepName}
        </Typography>
        <Typography align='left' className={pgStyles.details} variant='body1'>
          {details}
        </Typography>
      </div>
    </div>
  );
}
