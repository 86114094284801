/** @format */

const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
// Password Reqs
const numberTest = /\d/;
const capitalTest = /[A-Z]/;
const lowercaseTest = /[a-z]/;
const specialCharTest = /\W/;
const excludedCharTest = /\S/;
const lengthTest = /.{8,}/;

/**
 * When given a string of characters, returns whether or not that string is a valid email address
 * @param {string} text String of characters to check
 * @return {boolean} Boolean value representing whether or not the given string is a valid email address
 */
function isValidEmail(text) {
  return emailRegEx.test(text);
}

/**
 * Tests whether the given string of characters passes the given password requirements
 * Current minimum password requirements:
 * 1 number
 * 1 capital letter
 * 1 special character
 * 8 characters in total
 * no excluded characters (whitespace chars)
 * @param {string} text String of characters to check
 * @return {boolean} Boolean value representing whether or not the given string meets the password requirements
 */
function isValidPassword(text) {
  return (
    numberTest.test(text) &&
    capitalTest.test(text) &&
    lowercaseTest.test(text) &&
    specialCharTest.test(text) &&
    excludedCharTest.test(text) &&
    lengthTest.test(text)
  );
}

/**
 * Checks to see if the value of the given event's target is a valid email address
 * @param {Event} event The Event object (usually from an input) whose target value is tested
 * @return {boolean} Boolean representing whether or not the target of the given event has a value that is a valid email address
 */
function isValidEmailEvent(event) {
  return emailRegEx.test(event.target.value);
}

/**
 * Checks to see if the value of the given event's target meets the minimum password requirements
 * @param {Event} event The Event object (usually from an input) whose target value is tested
 * @return {boolean} Boolean representing whether or not the target of the given event has a value that meets the password requirements
 */
function isValidPasswordEvent(event) {
  return (
    numberTest.test(event.target.value) &&
    capitalTest.test(event.target.value) &&
    lowercaseTest.test(event.target.value) &&
    specialCharTest.test(event.target.value) &&
    excludedCharTest.test(event.target.value) &&
    lengthTest.test(event.target.value)
  );
}

/**
 * Checks whether the given string of characters contains a number
 * @param {string} text String of characters to check
 */
function containsNumber(text) {
  return numberTest.test(text);
}

/**
 * Checks whether the given string of characters contains a capital letter
 * @param {string} text String of characters to check
 */
function containsCapital(text) {
  return capitalTest.test(text);
}

/**
 * Checks whether the given string of characters contains a capital letter
 * @param {string} text String of characters to check
 */
function containsLowercase(text) {
  return lowercaseTest.test(text);
}

/**
 * Checks whether the given string of characters contains a special character (!@#$%^&* etc.)
 * @param {string} text String of characters to check
 */
function containsSpecialChar(text) {
  return specialCharTest.test(text);
}

/**
 * Checks whether the given string of characters contains no whitespace characters
 * @param {string} text String of characters to check
 */
function containsExcludedChar(text) {
  return excludedCharTest.test(text);
}

/**
 * Checks whether the given string of characters contains at least 8 characters
 * @param {string} text String of characters to check
 */
function containsEnoughChars(text) {
  return lengthTest.test(text);
}

export {
  isValidEmail,
  isValidPassword,
  isValidEmailEvent,
  isValidPasswordEvent,
  containsNumber,
  containsCapital,
  containsLowercase,
  containsSpecialChar,
  containsExcludedChar,
  containsEnoughChars,
};
