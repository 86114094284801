/** @format */

import {makeStyles} from '@material-ui/core/styles';

const GoodCheckoutStyles = makeStyles({
  root: {
    height: '100%',
    minHeight:'50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '&.safari-pad': {
      paddingBottom: '70px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: '1',
    justifyContent: 'space-between',
    width: '100%',
    maxHeight: '85%',
    textAlign: 'center',
    '& .icon': {
      fontSize: '22rem',
      marginBottom: '4rem',
      '&.good': {
        color: '#00C48C',
      },
      '&.bad': {
        color: 'red',
      },
      '&.processing': {
        color: '#de4297',
      },
    },
    '& .btn': {
      justifySelf: 'flex-end',
      '&.removed': {
        color: 'transparent',
        backgroundColor: 'transparent',
      },
    },
    '& > *': {
      marginBottom: '3rem',
    },
  },
});

export default GoodCheckoutStyles;
