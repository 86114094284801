/** @format */

import React, {createRef} from 'react';
// Custom components
import AvatarEditor from '../../components/avatarEditor';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles, withStyles} from '@material-ui/core';

//#region Styling

const useStyles = makeStyles(theme => ({
  avatarBtn: {
    height: '150px',
    marginBottom: '1.5rem',
    width: '150px',
    cursor: 'pointer',
  },
  title: {
    marginBottom: '2rem',
  },
  root: {},
}));

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: 'transparent',
    color: '#1899ff',
    height: '3rem',
    minHeight: '0',
    minWidth: '0',
    padding: '1rem 1rem 0 0',
    visibility: 'hidden',
    width: '3rem',
  },
  root: {
    '&:hover': {
      '& .MuiBadge-badge': {
        visibility: 'visible!important',
      },
    },
  },
}))(Badge);

const StyledEdit = withStyles({
  root: {
    '&:hover': {
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      visibility: 'visibile',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(EditIcon);

const StyledAvatar = withStyles({
  root: {
    border: '2px solid transparent',
    '&:hover': {
      border: '2px solid #1899ff',
    },
  },
})(Avatar);

//#endregion

const Profile = props => {
  const classes = useStyles();
  const {userData} = props;
  const editorRef = createRef();

  return (
    <div>
      <Typography className={classes.title} variant="h4">
        Your Account
      </Typography>
      <Typography gutterBottom variant="h5">
        Avatar
      </Typography>
      <StyledBadge badgeContent={<StyledEdit />} overlap="rectangular">
        <StyledAvatar
          className={classes.avatarBtn}
          onClick={e => editorRef.current.handlePassedClick()}
          src={userData.avatar || '/icons/default_avatar.svg'}
          variant="circular"
        />
      </StyledBadge>
      <AvatarEditor ref={editorRef} userId={userData.userId} />
      <Typography gutterBottom variant="h5">
        Name
      </Typography>
      <Typography paragraph>{userData.displayName}</Typography>
      <Typography gutterBottom variant="h5">
        Email
      </Typography>
      <Typography paragraph>
        <a href={`mailto://${userData.email}`}>{userData.email}</a>
      </Typography>
    </div>
  );
};

export default Profile;
