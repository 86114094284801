/** @format */

import React from 'react';
import ShippingStyles from './styles';

const Pages = () => {
  const pgStyles = ShippingStyles();
  return (
    <div className={pgStyles.bg}>
      <div className={pgStyles.text}>
        <h2>
          $10 <span>OVERNIGHT</span> EVERYWHERE
        </h2>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p>We know, you want it fast and you want it cheap!</p>
        <p>Our product is made in the USA and</p>
        <p>shipped in the USA, overnight,</p>
        <p>and for ten bucks.</p>
      </div>
    </div>
  );
};

export default Pages;
