/** @format */

import {makeStyles} from '@material-ui/core/styles';

const CheckoutStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10vh',
    flexGrow: '1',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '60vw',
    paddingTop: '7vh',
    '& .details': {
      flex: '1',
    },
    '& .summary': {
      flex: '1',
    },
  },
  otherPaymentMethodsText: {
    maxWidth: 750,
    marginBottom: '3rem',
    fontWeight: 'bold',
  },
  stepButton: {
    backgroundColor: '#F5BE2E',
    width: '100%',
    borderRadius: '3px',
    fontSize: '2rem',
    marginTop: '3.9vh',
  },
  stepper: {
    width: '75%',
    margin: 'auto',
  },
  pickupContainer: {
    display:'flex',
    justifyContent: 'left',
    paddingTop:30,
    textAlign:'left'
  },
  pickUp:{
    boxSizing: 'border-box',
    width: 173,
    height: 114,
    overflow: 'hidden',
    borderRadius: 12,
    border: '2px solid #53affe',
    color:'black',
    display:'flex',
    backgroundColor:'white',
    marginRight: 20,
    boxShadow:'rgba(0, 0, 0, 0.15) 4px 6px 8px 0px'
  },
  pickUpInActive: {
    boxSizing: 'border-box',
    width: 173,
    height: 114,
    overflow: 'hidden',
    borderRadius: 12,
    border: '2px solid rgb(245, 245, 245)',
    color:'black',
    display:'flex',
    backgroundColor:'rgb(245, 245, 245)',
    marginRight: 20,
  },
});

export default CheckoutStyles;
