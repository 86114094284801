/** @format */

import React, { useState, forwardRef } from 'react';
// MUI Component Imports
import InputLabel from '@material-ui/core/InputLabel';
// Stripe imports
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
// Custom component imports
import ShippingInput from '../shippingDetails/shipping-input';
import { ReactComponent as AmexIcon } from '../../images/amex-bg-minimized.svg';
import { ReactComponent as VisaIcon } from '../../images/visa-bg-minimized.svg';
import { ReactComponent as McIcon } from '../../images/mc-bg-minimized.svg';
// Styling Imports
import PaymentStyles, { CARD_OPTIONS } from './styles';

const stripeKey = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CardLogo = props => {
  return <div className="card-logo">{props.component}</div>;
};

const PaymentDetails = forwardRef((props, ref) => {
  const { customerInfo } = props;
  const [cardName, setCardName] = useState(customerInfo.name);
  const [zipCode, setZipCode] = useState();
  const [inputInfo, setInputInfo] = useState({
    number: {
      isValid: false,
      isEmpty: true,
    },
    exp: {
      isValid: false,
      isEmpty: true,
    },
    cvc: {
      isValid: false,
      isEmpty: true,
    },
    zipCode: {
      isValid: true,
      isEmpty: false,
    },
  });

  const classes = PaymentStyles();
  const stripe = useStripe();
  const elements = useElements();

  React.useImperativeHandle(ref, () => ({
    isFormValid() {
      for (const key in inputInfo) {
        if (!inputInfo[key].isValid) {
          return false;
        }
      }
      return true;
    },
    async getPayment() {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      const cardNumberElement = elements.getElement(CardNumberElement);

      const token = await stripe.createToken(cardNumberElement, {
        name: cardName,
        address_line1: customerInfo.address,
        address_city: customerInfo.city,
        address_country: customerInfo.country,
        address_zip: zipCode,
      });

      return { token: token.token.id };
    },
  }));

  const handleChange = event => {
    /* not a standard JS event
    Stripe Event props
      elementType: string
      empty: bool
      complete: bool
      error: obj
        props: message, code, type. type is always 'validation_error'
      brand: string
    */
    let dataType = '';
    switch (event.elementType) {
      case 'cardNumber':
        dataType = 'number';
        break;
      case 'cardExpiry':
        dataType = 'exp';
        break;
      case 'cardCvc':
        dataType = 'cvc';
        break;
      default:
        break;
    }
    let newInputInfo = inputInfo;
    newInputInfo[dataType].isEmpty = event.empty;
    newInputInfo[dataType].isValid = event.complete;
    setInputInfo(newInputInfo);
    // inputInfo[dataType].isEmpty = event.empty;
    // inputInfo[dataType].isValid = event.complete;
  };

  const nameChange = event => {
    event.preventDefault();
    setCardName(event.target.value);
  };

  const zipCodeChange = event => {
    event.preventDefault();
    const newZip = event.target.value.slice(0, 6);

    const CAZip = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
    const USZip = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
    if (newZip.length <= 6) {
      setZipCode(newZip); 
    } 
    
    if (!isNaN(Number(newZip)) && USZip.test(newZip)) {
      setInputInfo({ ...inputInfo, zipCode: { isValid: true } });
    } else if(CAZip.test(newZip)) {
      setInputInfo({ ...inputInfo, zipCode: { isValid: true } });
    } else { 
      setInputInfo({ ...inputInfo, zipCode: { isValid: false } });
    }
  };

  return (
    <div className={classes.paymentWrapper}>
      <div className={classes.cardIcons}>
        <CardLogo component={<AmexIcon className="icon" />} />
        <CardLogo component={<VisaIcon className="icon" />} />
        <CardLogo component={<McIcon className="icon" />} />
      </div>
      <div className={classes.cardInput}>
        <InputLabel
          className={classes.label}
          htmlFor="ccnumber"
          disableAnimation
        >
          Card Number*
        </InputLabel>
        <CardNumberElement
          label="Credit Card Number"
          name="ccnumber"
          id="inp-number"
          required
          onChange={handleChange}
          options={CARD_OPTIONS}
        />
      </div>

      <ShippingInput
        label="Name on Card"
        name="ccname"
        id="inp-name"
        value={cardName}
        onChange={nameChange}
      />
      <div className={classes.splitInput}>
        <div className={classes.cardInput}>
          <InputLabel
            className={classes.label}
            htmlFor="ccexpiration"
            disableAnimation
          >
            Expires*
          </InputLabel>
          <CardExpiryElement
            label="Expiration"
            name="ccexpiration"
            required
            id="inp-exp"
            options={CARD_OPTIONS}
            onChange={handleChange}
          />
        </div>
        <div className={classes.cardInput}>
          <InputLabel
            className={classes.label}
            htmlFor="cccvc"
            disableAnimation
          >
            Security Code*
          </InputLabel>
          <CardCvcElement
            label="CVC/Security Code"
            name="cccvc"
            required
            id="inp-cvc"
            options={CARD_OPTIONS}
            onChange={handleChange}
          />
        </div>
        <ShippingInput
          name="cczip"
          label="Zip Code"
          id="inp-zip"
          value={zipCode}
          onChange={zipCodeChange}
          // inputProps={{ pattern: 'd' }}
        />
      </div>
    </div>
  );
});

const PaymentDetailsElement = forwardRef((props, ref) => (
  <Elements stripe={stripeKey}>
    <PaymentDetails ref={ref} {...props} />
  </Elements>
));

export default PaymentDetailsElement;
