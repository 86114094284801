/** @format */

import {makeStyles} from '@material-ui/core/styles';

const PrefilledShippingStyles = makeStyles({
  wrapperColumns: {
    display: 'flex',
    flexDirection: 'column',
    margin: '.5rem 0',
  },
  wrapperRows: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '.5rem 0',
  },
  infoWrapper: {
    minWidth: '40%',
  },
  info: {
    margin: '1rem 0',
  },
  group: {
    margin: '1rem 0',
    '& .MuiTypography-root': {
      margin: '0',
    },
  },
  newAddressButton: {
    color: '#174cff',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2rem 0',
    width: '95%',
    '&$newAddressOpen $plusIcon': {
      animation: '$plusToX 500ms',
      transform: 'rotateZ(45deg)',
    },
    '&$newAddressClosed $plusIcon': {
      animation: '$xToPlus 500ms',
      transform: 'rotateZ(0deg)',
    },
  },
  updateButton: {
    backgroundColor: '#f3bb2c',
    width: '95%',
  },

  newAddressOpen: {},
  newAddressClosed: {},
  plusIcon: {},
  '@keyframes plusToX': {
    '0%': {
      transform: 'translateY(0) rotateZ(0deg)',
    },
    '50%': {
      transform: 'translateY(-5px)rotateZ(22.5deg)',
    },
    '100%': {
      transform: 'translateY(0) rotateZ(45deg)',
    },
  },
  '@keyframes xToPlus': {
    '0%': {
      transform: 'translateY(0) rotateZ(45deg)',
    },
    '50%': {
      transform: 'translateY(-5px)rotateZ(22.5deg)',
    },
    '100%': {
      transform: 'translateY(0) rotateZ(0deg)',
    },
  },
});

export default PrefilledShippingStyles;
