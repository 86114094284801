/** @format */

import {makeStyles} from '@material-ui/core/styles';

const CartStyles = makeStyles({
  btnClose: {
    alignSelf: 'flex-start',
    marginLeft: '1rem',
    padding: '0',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  card: {
    // margin: '1rem 0',
    fontSize:'6rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '4px',
    // height: '100%',
    marginTop:'4rem',
    width: '100%',
    backgroundColor: '#fff',
    paddingRight: '2rem',
    '& .name': {
      display: 'flex',
      flexDirection: 'column',
      '& h6': {
        marginBottom: '0',
      },
      '& button': {
        width: 'min-content',
        padding: 0,
        justifyContent: 'left',
        color: '#F46036',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
    },
  },
  cardActions: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    paddingRight: '2rem',
    margin: '0',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .qty-btn': {
      margin: 0,
      padding: '0',
      color: '#000',
      width:'6rem',
      '& .MuiSvgIcon-root': {
        height:'8rem',
        fontSize:'8rem',
      },
      '&.add': {
        paddingTop: '50%',
      },
      '&.remove': {
        paddingBottom: '50%',
      },
      '&.Mui-disabled': {
        opacity: '0.25',
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0)',
      },
    },
    '& #qty-txt': {
      margin: 0,
      fontSize: '8rem',
    },
    '& .btn-wrapper': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: '1',
    padding: '0',
    height: '100%',
    '& .text': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
  },
  cardMedia: {
    maxHeight: '10vh',
    maxWidth: '20%',
    marginRight: '1rem',
    objectFit: 'contain',
    padding: '1rem',
    alignSelf: 'center',
  },
  checkoutButton: {
    borderRadius: '8px',
    backgroundColor: '#F5BE2E',
    fontSize: '6rem',
    fontWeight: '600',
    marginBottom: '7rem',
    width: '100%',
    height: 40,
    '& a': {
      color: 'inherit',
    },
  },
  checkoutButtons: {
    width: '79.3%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#f6f6f6',
    borderColor: '#eee',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    width: '100%',
    '&.safari-pad': {
      paddingBottom: '70px',
    },
  },
  orderInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6rem 8rem',
    overflow: 'hidden',
    width:'100%'
  },
  scrollBar: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .vert-scroll': {
      height: '100%',
      right: '0',
      borderRadius: '3px',
    },
  },
  scrollBarInner: {
    width: '100%',
    overflow: 'visible',
    alignItems: 'flex-start',
    '& #delete': {
      border: '1px solid transparent',
      borderRadius: '4px 4px 4px 4px',
      height: '97%',
      left: 1,
      top: 1,
      width: '94%',
    },
    '& #delete-button': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '& #delete-container': {
      marginBottom: '2rem',
      maxWidth: '100%',
      paddingRight: '2rem',
    },
    '& #delete-content': {
      maxWidth: '100%',
      width: '99%',
    },
  },
  swipeWrapper: {
    padding: 0,
  },
  titles: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.5rem',
    marginLeft: '2rem',
    '& .title-qty': {
      color: '#A4AAB8',
      marginLeft: '1rem',
    },
  },
  totals: {
    color: '#555',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2.5rem 0',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    '& .grand-total': {
      color: '#000',
      fontWeight: 'bold',
    },
  },
});

export const DrawerStyles = makeStyles({
  paper: {
    height: '100vh',
    padding: '7.5rem 2rem 0',
    width: '100vw',
    backgroundColor: '#F6F6F6',
  },
});

export const EmptyCartStyles = makeStyles({
  bigCloseBtn: {
    marginBottom: '7rem',
  },
  btnClose: {
    alignSelf: 'flex-start',
    marginLeft: '1rem',
    padding: '0',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#f6f6f6',
    borderColor: '#eee',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    width: '100%',
    '& .spacer': {
      flexGrow: '1',
    },
  },
  iconWrapper: {
    marginTop: '29.375rem',
  },
  textWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3rem',
    '& .subtxt': {
      color: '#666',
      marginTop: '2rem',
    },
  },
});

export default CartStyles;
