/** @format */

import React, {useEffect} from 'react';
// Custom components
// Material UI
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import {withStyles} from '@material-ui/styles';
// Notifications
import {useSnackbar} from 'notistack';
// Styles
import CouponStyles from './styles';
import {useState} from 'react';

export default function CouponComp(props) {
  const pgStyles = CouponStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [couponCode, setCouponCode] = useState(
    localStorage.getItem('coupon') || ''
  );

  const applyCoupon = () => {
    fetch(`/api/coupons/${couponCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async resp => {
        if (resp.ok !== true) {
          throw await resp.text();
        }
        return resp.json();
      })
      .then(result => {
        console.log('result: ', result);
        props.setCouponData(result);
      })
      .catch(e => {
        console.log(e);
        enqueueSnackbar(e, {variant: 'error'});
      });
  };

  useEffect(() => {
    if (couponCode) {
      applyCoupon();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={pgStyles.wrapper}>
      <CouponInput
        placeholder="Enter code"
        onChange={e => setCouponCode(e.target.value)}
      />
      <Button
        className={pgStyles.submitBtn}
        disableElevation
        onClick={applyCoupon}
        variant="contained"
      >
        Apply Coupon
      </Button>
    </div>
  );
}

const CouponInput = withStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: '3px',
    flexGrow: 1,
    height: '12rem',
    marginRight: '1rem',
    padding: '1.25rem 2rem',
  },
  input: {
    font: 'Roboto',
    fontSize: '6rem',
    fontWeight: '400',
    height: '100%',
    letterSpacing: 'normal',
    lineHeight: '12rem',
    padding: '0',
  },
})(InputBase);
