/** @format */

export default function paypalButtons({shippingData}) {
  const paypal = window.paypal;
  paypal
    .Buttons({
      style: {
        color: 'silver',
      },
      createSubscription: (data, actions) => {
        return actions.subscription.create({
            'plan_id': process.env.REACT_APP_PAYPAL_PREMIUM_PLAN
        });
      },
      onInit(data, actions)  {
        actions.disable();
        fetch('/api/subscription', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(resp => {
          return resp.json();
        }).then(result => {
            if (result.valid !== true) {
              actions.enable();
            }
        });
  
      },
      onApprove: (data, actions) => {
        const body = {
            payment:data,
            customerInfo: shippingData,
            paymentService: 'paypal'
          };
        return fetch('/api/subscription', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                  'Content-Type': 'application/json',
                },
              }).then(resp => {
                return resp.json();
              }).then(order => {
                window.location.href = `/checkout/paypalResults?status=success&orderId=${order.payment.refId}`
              });
      },
      onError: (data, actions) => {
        window.location.href = '/checkout/paypalResults?status=error';
      },
    })
    .render('#paypal-subscription-container');
}
