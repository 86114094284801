/** @format */

import React from 'react';
// Material UI Components
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// react-router-dom imports
import {Link} from 'react-router-dom';
// Custom imports
import {CardStyles} from './styles';

const defaultTags = {
  color: 'Black',
  material: 'PLA',
  weight: '1kg',
  diameter: '1.75mm',
  colorName: 'Black',
};

const ProductCard = props => {
  const pgStyles = CardStyles();
  const {name, price, imgSrc, options, qoh, tags, data} = props;

  const getItemLink = () => {
    let returnLink = '';
    if (tags.weight !== undefined && tags.weight === '200g') {
      returnLink = '/shop/minis/';
    } else if (tags.material === 'Bulk') {
      returnLink = '/shop/Bulk/';
    } else {
      returnLink = `/shop/${getTagVal('material')}/${getTagVal(
        'color'
      )}-${getTagVal('diameter')}-${getTagVal('weight')}/`;
    }
    return returnLink;
  };

  const defaultOpts = {
    material: 'PLA',
    color: 'black',
    diameter: '1.75mm',
    amount: '1kg',
  };

  const getOptionValue = optName => {
    if (options[optName] !== undefined && options[optName] !== null) {
      return options[optName];
    } else {
      return defaultOpts[optName];
    }
  };

  function getTagVal(tagName) {
    if (
      Object.keys(tags).includes(tagName) ||
      Object.keys(defaultTags).includes(tagName)
    ) {
      return tags[tagName] ?? defaultTags[tagName];
    } else {
      // Error: tagName isn't recognized or doesn't exist
    }
  }

  const getBadge = () => {
    if (qoh <= 0) {
      return (
        <Typography className={pgStyles.outOfStock}>Out of Stock</Typography>
      );
    } else if (tags.material === 'PLA Ltd') {
      return (
        <Typography className={pgStyles.limited}>New and Limited</Typography>
      );
    } else if (tags.material === 'PLA Sub') {
      return <Typography className={pgStyles.premium}>Premium Only</Typography>;
    } else {
      return null;
    }
  };
  return (
    <Grid item sm={6} lg={3}>
      <Card className={pgStyles.card} elevation={0}>
        <CardActionArea className={pgStyles.productImg}>
          <Link to={getItemLink()}>
            {getBadge()}
            <CardMedia
              component="img"
              alt={name}
              image={
                typeof imgSrc === typeof []
                  ? imgSrc[0] +
                    `?w=${Math.round(135 * window.window.devicePixelRatio)}`
                  : imgSrc +
                    `w=${Math.round(135 * window.window.devicePixelRatio)}`
              }
              title={name}
              style={{width: 135, height: 135}}
              className={pgStyles.cardMedia}
              onError={e => (e.target.src = '/images/PrintBed.svg')}
            />
          </Link>
        </CardActionArea>

        <CardContent>
          <Link to={getItemLink()} className={pgStyles.textLink}>
            {data.temp ? (
              <>
                <Typography variant="body1">{tags.colorName}</Typography>
                <Typography variant="body1">
                  {tags.material} {tags.weight}
                </Typography>
              </>
            ) : (
              <Typography variant="body1">{name}</Typography>
            )}
          </Link>
          <Typography
            variant="body1"
            className={pgStyles.productPrice}
            style={{color: 'black'}}>
            ${Math.floor(price / 100)}
            <sup style={{fontSize: '.6em'}}>{getDecimal(price)}</sup>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ProductCard;

const getDecimal = n => {
  let d = ((n / 100) % 1) * 100;
  if (d < 10) {
    return d + '0';
  } else {
    return d;
  }
};
