/** @format */

import {makeStyles} from '@material-ui/core/styles';

const CouponStyles = makeStyles(
  {
    submitBtn: {
      backgroundColor: '#000',
      borderRadius: '4px',
      color: '#fff',
      fontWeight: '400',
      fontSize:'5rem',
      padding: '1.25rem 4rem',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      height: '12rem',
      marginTop: '1rem',
      marginBottom: '2rem',
      width: '100%',
    },
  },
  {name: 'coupon-comp'}
);

export default CouponStyles;
