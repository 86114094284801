/** @format */

import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
}

const GoogleAnalytics = ({userData}) => {
  const location = useLocation();
  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: document.title });
  // }, [location]);  
  useEffect(() => {
    if (userData && userData.userId) {
      ReactGA.set({userId: userData.userId});
    }
    new MutationObserver(function(mutations) {
        const title = mutations[0].target.nodeValue;
        // console.log(mutations[0].target.nodeValue);
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: title });

    }).observe(
        document.querySelector('title'),
        { subtree: true, characterData: true, childList: true }
    );
  },[]);

  ReactGA.gtag('config', process.env.REACT_APP_GOOGLE_ADS);
  return null;
};

export default GoogleAnalytics;
