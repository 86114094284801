/** @format */

import {makeStyles} from '@material-ui/core/styles';

const Drip404Styles = makeStyles(theme => ({
  bg: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    // //backgroundImage: "url('/images/drip-404-v2-vb-fix.svg')",
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'contain',
    // backgroundPosition: 'top',
    // width: '100%',
    // height: '100%',
    objectPosition: 'top center',
    objectFit: 'fill',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    // '& img': {
    //   height: '100%',
    //   objectPosition: 'top left',
    //   objectFit: 'fill',
    // },
  },
  text: {color: '#999', marginBottom: '5rem'},
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {fontSize: '3.75rem'},
  tryAgainBtn: {
    backgroundColor: '#fff',
    border: '1px solid transparent',
    color: '#0099FF',
    fontSize: '2rem',
    padding: '1.5rem 9rem',
    lineHeight: 'normal',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #0099ff',
    },
  },
  wrapper: {
    position: 'relative',
    height: '80vh',
    width: '100%',
    backgroundColor: '#e3e3e3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default Drip404Styles;
