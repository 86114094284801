/** @format */

import React from 'react';
import DripBackdrop from '../../components/404';

const Drip404 = props => {
  return <DripBackdrop />;
};

export default Drip404;
