/** @format */

import {makeStyles} from '@material-ui/core/styles';

const AccountPageStyles = makeStyles({
  backButton: {
    minWidth: '3.5rem',
    width: '3.5rem',
    padding: 0,
    position: 'fixed',
    top: '2rem',
    left: '2rem',
    '& svg': {
      fontSize: '3.5rem',
    },
  },
  root: {
    padding: '8rem 6rem 4rem 6rem',
  },
});

export default AccountPageStyles;
