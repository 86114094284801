/** @format */

import React, {useEffect, useState} from 'react';
// Material UI Component Imports
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// Material UI Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
// Custom Scrollbars import
import {Scrollbars} from 'react-custom-scrollbars';
// Styling import
import CartStyles from './styles';
// Custom imports
import SunNavButton from '@sunsh1n3/sun-nav-button';
import {ReactComponent as AmexIcon} from '../../images/cc_logos/amex.svg';
import {ReactComponent as VisaIcon} from '../../images/cc_logos/visa.svg';
import {ReactComponent as McIcon} from '../../images/cc_logos/mc.svg';
import {ReactComponent as DiscoverIcon} from '../../images/cc_logos/discover.svg';
import {ReactComponent as StripeIcon} from '../../images/cc_logos/stripe.svg';
import {ReactComponent as PaypalIcon} from '../../images/cc_logos/paypal.svg';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import AirplaneIcon  from '@material-ui/icons/AirplanemodeActive';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';

const Cart = props => {
  const pgStyles = CartStyles();
  const {
    hasCheckoutButton = false,
    title = 'Shopping Cart',
    close = () => {},
    cartData,
    cartUtils,
    couponData,
    localPickup,
    userData
  } = props;
  const [totals, setTotals] = useState(
    cartUtils ? cartUtils.getTotals(cartData) : 0
  );

  if (couponData) {
    cartData.couponData = couponData;
  }

  if(userData?.taxExempt === true) {
    cartData.taxExempt = true;
  }

  useEffect(() => {
    updateTotals();
    // eslint-disable-next-line
  }, [cartData,localPickup,couponData,cartUtils]);

  const updateTotals = () => {
    if(cartUtils) {
      setTotals(cartUtils.getTotals(cartData));
    }
  };

  useEffect(() => {
    if (cartUtils?.zip && cartUtils?.zip.length >= 5) {
      async function fetchData() {
        await cartUtils.getShipping();
        updateTotals();
      }
      fetchData();
    }
        // eslint-disable-next-line
  }, [cartUtils?.international, cartUtils?.zip]);

  const CardLogo = props => {
    return <div className="card-logo">{props.component}</div>;
  };

  const AnimatedButton = ({total}) => {
    return (
      <div className={pgStyles.animatedButton}>
        <div className={pgStyles.animatedButtonLeftSide}>Checkout</div>
        <div className={pgStyles.animatedButtonRightSide}>${total}</div>
      </div>
    );
  };
  
  const getShippingIcon = (item) =>{
    let icon;
    if(localPickup === 'local') {
      icon = (<LocalMallIcon style={{position:'absolute',bottom:5,left:15}} title="This will ship ground."/>);
    } else if (localPickup === 'ground' || item.data.freeGround === true) {
      icon =  (<LocalShippingIcon style={{position:'absolute',bottom:5,left:15}}/>);
    } else  if(localPickup === 'overnight') {
      icon = (<AirplaneIcon style={{position:'absolute',bottom:5,left:15}}/>);
    }
    return icon;
  }

  return (
    <div className={pgStyles.container}>
      <IconButton disableRipple className={pgStyles.btnClose} onClick={close}>
        <CloseIcon />
      </IconButton>
      <div className={pgStyles.orderInfo}>
        <div className={pgStyles.titles}>
          <Typography variant="h4" align="left">
            {title}
          </Typography>
          <Typography variant="h4" align="left" className="title-qty">
            ({cartData.length})
          </Typography>
        </div>

        <Scrollbars
          className={pgStyles.scrollBar}
          autoHeight={true}
          autoHeightMax="80vh"
          autoHeightMin="0"
          style={{width: '100%'}}
          renderView={props => (
            <div {...props} className={pgStyles.scrollBarInner}></div>
          )}
        >
          {cartData.map(item => {
            return (
              <Card key={item.id} className={pgStyles.card} variant="outlined">

                {getShippingIcon(item,localPickup)}
                <CardMedia
                  component="img"
                  alt={item.name}
                  image={item.images}
                  title={item.name}
                  className={pgStyles.cardMedia}
                  onError={e => (e.target.src = '/images/PrintBed.svg')}
                />
                <CardContent
                  className={pgStyles.cardContent}
                  style={{
                    padding: 0,
                  }}
                >
                  <div className="name text">
                    <Typography variant="h6" >{item.name}</Typography>
                    {item.error &&<Typography variant="caption" style={{color:'red',marginTop:'-1.5rem'}}>{item.error}</Typography>}
                    {item.sides && item.sides.length > 0 &&<Typography variant="caption" style={{marginTop:'-1.5rem'}}>{item.options.color.toTitleCase()}</Typography>}
                    <Button
                      variant="text"
                      disableRipple
                      size="small"
                      onClick={e => {
                        cartUtils.deleteItem(item.id);
                      }}
                    >
                      <Typography variant="subtitle2">Remove</Typography>

                    </Button>
                  </div>
                  <Typography className="text">
                    $
                    {(
                      Number.parseFloat(item.price) *
                      Number.parseFloat(item.qty)
                    ).toFixed(2)}
                  </Typography>
                  <CardActions className={pgStyles.cardActions}>
                    <IconButton
                      disableRipple
                      className="qty-btn add"
                      onClick={e => cartUtils.addItemToCart({id: item.itemId})}
                    >
                      <AddCircleIcon />
                    </IconButton>
                    <Typography id="qty-txt">{item.qty}</Typography>
                    <IconButton
                      disableRipple
                      className="qty-btn remove"
                      disabled={item.qty <= 1}
                      onClick={e => cartUtils.removeOneItem(item.itemId)}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </CardActions>
                </CardContent>
              </Card>
            );
          })}
        </Scrollbars>
        <div className={pgStyles.totals}>
          <Typography>Subtotal: </Typography>
          <Typography> ${totals.subtotal}</Typography>
        </div>
        {cartUtils?.localPickup !== true && 
        <>
          <Divider />
          <div className={pgStyles.totals}>
            <Typography>Shipping: </Typography>
            <Typography>
              {totals.shipping > 0 ? ` $${totals.shipping}` : ' Free Shipping'}
            </Typography>
          </div>
        </>}
        {totals.discount > 0  && (
          <>
            <Divider />
            <div className={pgStyles.totals}>
              <Typography>{cartUtils?.membership === true?'Premium Member ':''}Discount: </Typography>
              <Typography>- ${totals.discount}</Typography>
            </div>
          </>
        )}
        <Divider />
        {totals.tax > 0 && (
          <>
            <div className={pgStyles.totals}>
              <Typography>Tax: </Typography>
              <Typography>{totals.tax}</Typography>
            </div>
            <Divider />
          </>
        )}
        <div className={pgStyles.totals}>
          <Typography>Total: </Typography>
          <Typography className="grand-total"> ${totals.grandTotal}</Typography>
        </div>
        {hasCheckoutButton ? <Divider /> : null}
      </div>
      {hasCheckoutButton ? (
        <>
        <div className={pgStyles.checkoutButtons}>
          <SunNavButton
            to="/checkout"
            className={
              pgStyles.checkoutButton + (hasCheckoutButton ? '' : ' removed')
            }
            disableRipple
            afterNav={close}
            disabled={cartData.length <= 0}
            label="Buy Now"
          >
            <AnimatedButton total={totals.grandTotal} />
          </SunNavButton>
          <div className={pgStyles.cardIcons}>
            <CardLogo component={<McIcon className="icon" />} />
            <CardLogo component={<VisaIcon className="icon" />} />
            <CardLogo component={<DiscoverIcon className="icon" />} />
            <CardLogo component={<AmexIcon className="icon" />} />
            <CardLogo component={<StripeIcon className="icon" />} />
            <CardLogo component={<PaypalIcon className="icon" />} />
          </div>
        </div></>
      ) : null}
    </div>
  );
};

export default Cart;
