/** @format */

import React, {useState} from 'react';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
// Custom imports
import StyledInput from '../styled/input';
import StyledLabel from '../styled/input-label';
import SubmitButton from '../styled/submit-button';
import AccountStyles from './styles.js';
import {useHistory} from 'react-router-dom';


const Profile = props => {
  const {avatar, email, fname, lname, userId} = props.userData;
  const pgStyles = AccountStyles();
  const [firstName, setFirstName] = useState(fname);
  const [lastName, setLastName] = useState(lname);
  const history = useHistory();


  const updateUser = () => {
    const body = {
      fname: firstName,
      lname: lastName,
    };
    fetch(`/api/user/${userId}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // Add a snackbar for success?
  };

  return (
    <div className={pgStyles.root}>
      <h1 className={pgStyles.title}>Account Details</h1>
      <Avatar
        variant="circular"
        src={avatar || '/icons/default_avatar.svg'}
        className={pgStyles.avatar}
      />
      <div className={pgStyles.userInfo}>
        <StyledLabel>First Name</StyledLabel>
        <StyledInput
          className={pgStyles.info}
          value={firstName}
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="firstName"
          name="firstName"
          placeholder="First Name"
          showValidIcon={false}
          autoFocus
          onChange={e => setFirstName(e.target.value)}
        />
        <StyledLabel>Last Name</StyledLabel>
        <StyledInput
          className={pgStyles.info}
          value={lastName}
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="lastName"
          name="lastName"
          placeholder="Last Name"
          showValidIcon={false}
          autoFocus
          onChange={e => setLastName(e.target.value)}
        />
        <StyledLabel>Email</StyledLabel>
        <Typography className={pgStyles.info}>{email}</Typography>
      </div>
      <SubmitButton
        className={pgStyles.button}
        onClick={updateUser}
        variant="contained"
        color="primary"
      >
        Update
      </SubmitButton>
      <SubmitButton
        className={pgStyles.button}
        variant="outlined"
        color="primary"
        onClick={()=>{
          history.push('/')
        }}
      >
        Cancel
      </SubmitButton>
    </div>
  );
};

export default Profile;
