/** @format */

import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import CheckoutResultComponent from '../../components/checkoutResult';
import {MYSTERYSPOOL} from '../../utils/constants';

const CheckoutResult = props => {
  const history = useHistory();
  const {userData} = props;
  const {loggedIn} = userData;

  const getQuery = () => new URLSearchParams(window.location.search);
  const paypalRefId = getQuery().get('orderId');
  const paypalStatus = getQuery().get('status');

  const [status, setStatus] = useState(paypalStatus);
  const [customerInfo, setCustomerInfo] = useState();
  const [saleId, setSaleId] = useState();
  const [vending,setVending] = useState(false);

  useEffect(() => {
    if (status === 'success') {
      fetch('/api/sales?limit=1')
        .then(resp => resp.json())
        .then(data => {
          const order = data.orders[0];

          if (paypalRefId === order.payment.refId) {
            setSaleId(order.id);
            setCustomerInfo(order.shipping);
            order.lines.forEach(line=>{
              if(line.itemId === MYSTERYSPOOL) {
                setVending(true);
              }
              if(line.sides && Array.isArray(line.sides)) {
                line.sides.forEach(side=>{
                  if(side === MYSTERYSPOOL) {
                    setVending(true);
                  }
                });
              }
            });
          } else {
            setStatus('error');
          }
        });
    }
        // eslint-disable-next-line
  }, []);

  return (
    <CheckoutResultComponent
      key="checkout-result-comp"
      status={status}
      customerInfo={customerInfo}
      isLoggedIn={loggedIn}
      saleId={saleId}
      membership={userData.membership}
      vending={vending}
      onClick={() => history.push('/')}
    />
  );
};

export default CheckoutResult;
