/** @format */

import React, {useEffect} from 'react';
import Step from './step';
// Custom components
import StepsDetails from './details';
// Styles
import ProcessStyles from './styles';

const PROCESS_STEPS = [
  {
    name: 'Mix It',
    num: 1,
    imgPath: 'https://cdn.sunsh1n3.com/Images/process/Step01_.jpg',
    details: 'Our high-quality PLA is sourced from NatureWorks here in the U.S. NatureWorks PLA is environmentally friendly and produced with greenhouse gases, giving it the lowest carbon footprint of all 3D printing materials. ',
  },
  {
    name: 'Shape It',
    num: 2,
    imgPath: 'https://cdn.sunsh1n3.com/Images/process/Step02_.jpg',
    details: 'From start to finish, our filament is extruded through precision die equipment, sized in a vacuum tank, and measured with a 3-axis laser micrometer. Our filament is held to a tight ±0.02mm average tolerance, and passed through mechanical restraints to ensure your filament will never jam your printer.',
  },
  {
    name: 'Measure It',
    num: 3,
    align:'right',
    imgPath: 'https://cdn.sunsh1n3.com/Images/process/Step03_.jpg',
    details: 'Unlike many filament manufactures who use two-axis laser micrometer, our filament is measured with a high accuracy, three-axis laser micrometer. A two-axis laser micrometer can allow errors to pass through without detection resulting in an extrusion rate deviation of up to 27%. Three-axis lasers are 3X more accurate only allowing a max deviation of 9% in extrusion rate.',
  },
  {
    name: 'Perfect It',
    num: 4,
    imgPath: 'https://cdn.sunsh1n3.com/Images/process/Step04_.jpg',
    details: 'We use real time color sensing technology that measures every inch during the extrusion process to ensure superior color accuracy for every spool.',
  },
  {
    name: 'Spool It',
    num: 5,
    imgPath: 'https://cdn.sunsh1n3.com/Images/process/Step05_.jpg',
    details: 'We take our time to produce the best possible spools. Using a proprietary system, our spools are wound to perfection for neat and tangle free printing.',
  },
  {
    name: 'Pack It',
    num: 6,
    imgPath: 'https://cdn.sunsh1n3.com/Images/process/Step06_.jpg',
    details: 'Our spools are created from recycled paper instead of the plastic spools most other companies use. On top of our eco-friendly spools, we also ship using recycled cardboard boxes.',
  },
  {
    name: 'Overnight It',
    num: 7,
    imgPath: 'https://cdn.sunsh1n3.com/Images/process/Step07_.jpg',
    align: 'right',
    details: 'Place your order by 5pm EST and it will ship Overnight Everywhere within the contiguous USA, for just $10.',
  },
];

export default function FeatureProcess(props) {
  const pgStyles = ProcessStyles();
  const stepObjs = PROCESS_STEPS.map(
    step => new Step(step, stepId => cardClick(stepId))
  );

  function cardClick(stepNum) {
    // setSelectedStep(stepNum);
  }

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className={pgStyles.wrapper}>
      <StepsDetails selectedStep={0} stepObjs={stepObjs} />
    </div>
  );
}
