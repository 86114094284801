/** @format */

import React, {useEffect, useState} from 'react';
// Custom Component Imports
import ShippingInput from '../shipping/shippingInput';
import {isValidEmailEvent} from '../../../utils/inputValidation';
import SubmitButton from '../styled/submit-button';
import {Typography} from '@material-ui/core';
import {AddressFormStyles} from './styles';

export default function AddressForm(props) {
  const {
    hasCancelBtn = false,
    onCancel = () => {},
    onSubmit = () => {},
    shippingInfo = null,
    submitBtnTitle = 'Save',
    title = 'Shipping Details',
  } = props;

  const [customerInfo, setCustomerInfo] = useState({
    firstName: {value: '', isValid: false},
    lastName: {value: '', isValid: false},
    email: {value: '', isValid: false},
    phone: {value: '', isValid: false},
    address1: {value: '14920 23 Mile Rd', isValid: true},
    address2: {value: '', isValid: true},
    city: {value: 'Shelby Twp', isValid: true},
    state: {value: 'MI', isValid: true},
    zipCode: {value: '48315', isValid: true},
    country: {value: 'US', isValid: true},
  });
  const [fName, setfName] = useState(
    shippingInfo ? shippingInfo.name.split(' ')[0] : ''
  );
  const [lName, setlName] = useState(
    shippingInfo ? shippingInfo.name.split(' ')[1] : ''
  );
  const [email, setEmail] = useState(shippingInfo ? shippingInfo.email : '');
  const [phone, setPhone] = useState(shippingInfo ? shippingInfo.phone : '');
  const address1 = '14920 23 Mile Rd';
  const address2 = '';
  const city = 'Shelby Twp';
  const state = 'MI';
  const zipCode = 48315;

  const [showErrs, setShowErrs] = useState(false);

  useEffect(() => {
    if (!shippingInfo) {
      setfName('');
      setlName('');
      setEmail('');
      setPhone('');
      setShowErrs(false);
    }
    // eslint-disable-next-line
  }, []);

  function handleSubmit() {
    setShowErrs(true);
    if (validateInfo()) {
      onSubmit({
        name: fName + ' ' + lName,
        firstName: fName,
        lastName: lName,
        email: email,
        phone: phone,
        address: {
          city: city,
          country: 'US',
          line1: address1,
          line2: address2,
          postal_code: zipCode,
          state,
        },
      });
    }
  }

  function validateInfo() {
    return (
      fName !== '' &&
      lName !== '' &&
      phone !== '' &&
      email !== ''
    );
  }

  const isOnlyNumbers = text => {
    const numsOnly = /^[0-9]*$/g;
    return numsOnly.test(text);
  };

  const handleValidation = event => {
    const dataType = event.target.id.split('-')[1];
    const value = event.target.value;
    const trimVal = value.replace(/\s+/g, '');
    let valid = false;
    switch (dataType) {
      case 'email':
        valid = isValidEmailEvent(event);
        break;
      case 'firstName':
      case 'lastName':
        valid = trimVal.length >= 2;
        break;
      case 'zipCode':
        valid = trimVal.length >= 5 && isOnlyNumbers(value);
        break;
      case 'note':
        valid = true;
        break;
      case 'phone':
        const numsOnly = value.replace(/[^\d+$]/g, '');
        valid = numsOnly.length === 10;
        break;
      case 'address2':
        valid = true;
        break;
      default:
        valid = value !== '';
        break;
    }
    const newCustomerInfo = customerInfo;
    newCustomerInfo[dataType].isValid = valid;
    setCustomerInfo(newCustomerInfo);
    if(validateInfo() === true) {
      handleSubmit();
    }
  };

  const pgStyles = AddressFormStyles();

  return (
      <>
    <div style={{ position:'relative',overflow:'hidden',width:'100%', height: 200}}>
        <div style={{ position:'relative',overflow:'hidden',width:'100%', height: '100%'}}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5867.162543167144!2d-82.978213!3d42.670228!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3ded9f50052e811!2sPrintBed!5e0!3m2!1sen!2sus!4v1650480586456!5m2!1sen!2sus"
                width="400" 
                height="300" 
                style={{border:0}} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title="GoogleMaps">
            </iframe>
        </div>
    </div>
    <br/>
    <div>
    <form className={pgStyles.wrapper} onSubmit={onSubmit}>
      <Typography className={pgStyles.title} variant="h4">
        {title}{' '}
      </Typography>
      <ShippingInput
        errorText="This field is required"
        id="inp-firstName"
        isError={showErrs && fName === ''}
        label="First Name"
        value={fName}
        onChange={e => setfName(e.target.value)}
        onBlur={handleValidation}
        required
      />
      <ShippingInput
        required
        label="Last Name"
        id="inp-lastName"
        value={lName}
        onChange={e => setlName(e.target.value)}
        onBlur={handleValidation}
        isError={showErrs && lName === ''}
        errorText="This field is required"
      />
      <ShippingInput
        required
        label="Email Address"
        id="inp-email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        onBlur={handleValidation}
        isError={showErrs && email === ''}
        errorText="This field is required"
      />
      <ShippingInput
        required
        label="Phone Number"
        id="inp-phone"
        value={phone}
        onChange={e => setPhone(e.target.value)}
        onBlur={handleValidation}
        isError={showErrs && phone === ''}
      />
      <SubmitButton
        className={pgStyles.submitBtn}
        disabled={!validateInfo()}
        onClick={e => handleSubmit()}
        variant="contained"
      >
        {submitBtnTitle}
      </SubmitButton>
      {hasCancelBtn && (
        <SubmitButton onClick={onCancel} variant="outlined">
          Cancel
        </SubmitButton>
      )}
    </form>
    </div>
    </>
  );
}
