/** @format */

import React from 'react';
// Custom components
import {BannerBtn, Default} from '../index';
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

//#region Styled components
const ForgeTxt = styled(Typography)(
  {
    color: '#fff',
    fontFamily: 'Barlow Condensed, sans-serif',
    fontSize: 'inherit',
    fontWeight: '400',
    marginBottom: '3rem',
    userSelect: 'none',
    width: '50rem',
    '&.mbl': {
      fontSize: '9rem',
      width: '100%',
      textAlign: 'justify'
    },
  },
  {name: 'forge-txt'}
);
//#endregion

export default function ForgeBanner({isMobile}) {
  return (
    <Default
      bgColor="#181818"
      isMobile={isMobile}
      mainImgSrc="https://cdn.sunsh1n3.com/home/Forge/Forge.png"
      textLoc={isMobile?"top":"right"}>
      <img
        src="https://cdn.sunsh1n3.com/home/Forge/Forge_Logo.png"
        style={{
          padding: '0 25%',
          width: '100%',
        }}
      />
      <ForgeTxt align="center" className={isMobile ? 'mbl' : ''}>
        Forge PLA+ is a stronger version of our standard PLA. It is a heat
        resistant, high impact strength filament. It can also be annealed for
        increased strength and durability.
      </ForgeTxt>
      <BannerBtn
        colorVariant="orange"
        custHeight={isMobile ? '20rem' : undefined}
        custWidth={isMobile ? '' : undefined}
        fontSize={isMobile ? '8rem' : '2.75rem'}
        to="/shop/FORGE">
        Start Creating with Forge
      </BannerBtn>
      <div style={{height:isMobile?'9rem':0}}></div>
    </Default>
  );
}
