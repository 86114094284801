/** @format */

import React, {useEffect, useState} from 'react';
// Custom components
// Material UI
import Snackbar from '@material-ui/core/Snackbar';
import {withStyles} from '@material-ui/styles';
// Styles
import BannerContent from './content';
// import HolidayBannerStyles from './styles';

const StyledSnackbar = withStyles({
  root: {
    backgroundColor: '#10619bd1',
    borderRadius: '6.5rem',
    boxShadow: '0px 32px 40px rgba(252,77,76,0.25)',
    height: '13rem',
    overflow: 'hidden',
    width: '60rem',
  },
  anchorOriginTopCenter: {
    top: '13rem',
  },
})(Snackbar);

const StyledMobileSnackbar = withStyles({
  root: {
    backgroundColor: '#10619bd1',
    borderRadius: '20rem',
    boxShadow: '0px 32px 40px rgba(252,77,76,0.25)',
    height: '40rem',
    overflow: 'hidden',
    width: '90%',
    '& p': {
      fontSize:'6rem !important',
    }
  },
  anchorOriginTopCenter: {
    top: '13rem',
  },
})(Snackbar);

export default function HolidayClosureBanner(props) {
  const {holliday, isMobile} = props;
  const [isOpen, setIsOpen] = useState(true);

  function handleClose() {
    setIsOpen(false);
  }

  return holliday.note === null ? null : (
    isMobile?<StyledMobileSnackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      autoHideDuration={7000}
      onClose={handleClose}
      open={isOpen}
    >
      <BannerContent isMobile={isMobile}  image={holliday.image} text={holliday.note} />
    </StyledMobileSnackbar>:<StyledSnackbar
    anchorOrigin={{
      horizontal: 'center',
      vertical: 'top',
    }}
    autoHideDuration={7000}
    onClose={handleClose}
    open={isOpen}
  >
    <BannerContent isMobile={isMobile} image={holliday.image} text={holliday.note} />
  </StyledSnackbar>
  );
}
