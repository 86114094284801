/** @format */

import React from 'react';
// Custom components
// Material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

//#region Styled Comps

const CutoutTxt = styled('span')(
  {
    '-webkit-background-clip': 'text',
    background:
      'linear-gradient(0deg, rgb(30, 210, 252) 0%, rgb(1, 95, 223) 100%);',
    color: 'transparent',
    fontFamily: '"Poppins", "Roboto", serif',
    fontSize: '21.5rem',
  },
  {name: 'cutout-txt'}
);

const DescTxt = styled(Typography)(
  {
    color: '#333',
    fontSize: '2.25rem',
    width: '29vw',
  },
  {name: 'desc-txt'}
);

const PriceTxt = styled(Typography)(
  {
    alignItems: 'center',
    color: '#19e',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: '"Poppins", "Roboto", serif',
    fontSize: '9.5rem',
    fontWeight: '700',
  },
  {name: 'price-txt'}
);

const StartBtn = styled(Button)(
  {
    backgroundColor: '#19e',
    color: '#fff',
    marginTop: '12rem',
    padding: '2rem 6rem',
    '&:hover': {
      backgroundColor: '#1480eb',
    },
    '& .MuiButton-label': {
      fontFamily: '"Poppins", "Roboto", serif',
      fontSize: '3rem',
    },
  },
  {name: 'start-btn'}
);

const Wrapper = styled('div')(
  {
    alignItems: 'center',
    backgroundColor: '#ddd',
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    justifyContent: 'center',
  },
  {name: 'price-comp-wrap'}
);

//#endregion

export default function PriceComp(props) {
  const {onClick} = props;
  return (
    <Wrapper>
      <PriceTxt>
        $<CutoutTxt>25</CutoutTxt>/Month
      </PriceTxt>
      <DescTxt align="center">
        <b>PrintBed Premium Membership</b> offers the ultimate solution for 3D
        printing enthusiasts looking to upgrade their experience. With access to
        exclusive discounts and special selection of high-quality spools, you
        can take your 3D printing to the next level and achieve professional
        results every time.
      </DescTxt>
      <StartBtn onClick={onClick}>
        Start your&nbsp;<b>PREMIUM</b>
      </StartBtn>
    </Wrapper>
  );
}
