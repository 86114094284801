/** @format */

import {makeStyles} from '@material-ui/core/styles';

export const Styles = makeStyles({
  root: {
    width: '100%',
    height: 700,
  },
  containers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1000px',
    '& p': {
      fontWeight: 'bold',
      fontFamily: "'Poppins', sans-serif; !important",
    },
  },
  containerImg: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '1000px',
  },
  bg: {
    // backgroundImage: "url('/images/features/BG_Banner.png')",
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'top',
    // backdropFilter: 'blur(17px)',
    // WebkitBackdropFilter: 'blur(17px)',
    width: '100%',
    height: '976px',
  },

  carouselButton: {
    backgroundColor: '#0099FF',
    color: 'white',
    padding: '1.25rem 3.5rem',
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 410,
    justifyContent: 'center',
    '& .item-heading': {
      margin: '1rem 0 3rem',
      position: 'absolute',
      left: '10%',
    },
    '& .item-desc': {
      margin: '6rem 0 3rem',
      position: 'absolute',
      left: '10%',
    },
  },
  cover: {
    background: 'none',
    '& div': {
      background: 'none',
    },
    '& figure': {
      boxShadow: 'none',
    },
    '& figure img': {
      boxShadow: 'none',
    },
  },
});

export default Styles;
