/** @format */

import React from 'react';
import {Card, CardActionArea, Typography} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {ReactComponent as AmexIcon} from '../../images/amex-bg-minimized.svg';
import {ReactComponent as VisaIcon} from '../../images/visa-bg-minimized.svg';
import {ReactComponent as McIcon} from '../../images/mc-bg-minimized.svg';
import {PaymentSourceElementStyles} from './styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const PaymentSourceElement = props => {
  const {
    paymentSourceInfo,
    updateSelectedPaymentSourceId,
    isSelected,
    onDelete,
  } = props;
  const {id, brand, last4, name, exp_month, exp_year} = paymentSourceInfo;
  const classes = PaymentSourceElementStyles();
  const CardLogo = () => {
    switch (brand) {
      case 'Visa':
        return <VisaIcon className={classes.cardIcon} />;
      case 'Amex':
        return <AmexIcon className={classes.cardIcon} />;
      case 'MasterCard':
        return <McIcon className={classes.cardIcon} />;
      default:
        return null;
    }
  };

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.cardActionArea}
        onClick={() => updateSelectedPaymentSourceId(id)}
      >
        <div className={classes.icons}>
          <CardLogo />
          <CheckCircleOutlineIcon
            className={`${classes.checkCircleOutlineIcon} ${
              isSelected && classes.visibleIcon
            }`}
          />
        </div>
        <div className={classes.text}>
          <Typography>
            {brand.toUpperCase()} XXX-XXXX-{last4}
          </Typography>
          <Typography>{name}</Typography>
          <Typography>
            {exp_month}/{exp_year}
          </Typography>
        </div>
      </CardActionArea>
      <IconButton
        aria-label="delete"
        className={classes.delete}
        onClick={onDelete}
      >
        <DeleteIcon />
      </IconButton>
    </Card>
  );
};

export default PaymentSourceElement;
