/** @format */

import {makeStyles} from '@material-ui/styles';

const ProcessStyles = makeStyles(
  {
    detailsWrapper: {
      backgroundColor: 'transparent',
      height: '100%',
      left: '0',
      overflowY: 'scroll',
      position: 'absolute',
      top: '0',
      width: '100%',
    },
    modal: {
      height: '100%',
      margin: '0',
      width: 'inherit',
    },
    modalPaper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      left: '0',
      margin: '0',
      marginRight: '-17px',
      maxWidth: '100%',
      overflowY: 'scroll',
      position: 'absolute',
      top: '80px',
      width: '100%',
    },
    stepsWrapper: {
      flexGrow: '1',
      height: '100%',
      width: '100%',
    },
    titleWrapper: {
      color: '#fff',
      marginBottom: '20vh',
      width: '100%',
      '& *': {
        fontWeight: '500',
        '&:first-child': {
          marginBottom: '0.5rem',
        },
      },
    },
    wrapper: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1',
      width: '100%',
    },
  },
  {name: 'process-comp'}
);

export default ProcessStyles;

export const DetailStyles = makeStyles(
  {
    backBtn: {
      position: 'fixed',
      right: '5rem',
      top: '15rem',
      cursor: 'pointer',
    },
    wrapper: {
      backgroundColor: '#111',
      position: 'relative',
      width: '100%',
    },
  },
  {name: 'details-comp'}
);

export const OverviewStyles = makeStyles(
  {
    bg: {
      backgroundColor: '#111',
      backgroundImage:
        'linear-gradient(rgba(17, 17, 17, 0), rgba(17, 17, 17, 1) 40%), url("/images/process-bg.png")',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      left: '0',
      opacity: '0.15',
      position: 'absolute',
      top: '0',
      width: '100%',
    },
    stepsWrapper: {
      flexGrow: '1',
      height: '100%',
      position: 'relative',
      width: '100%',
    },
    titleWrapper: {
      color: '#fff',
      marginBottom: '10vh',
      position: 'relative',
      width: '100%',
      '& *': {
        fontWeight: '500',
        '&:first-child': {
          marginBottom: '0.5rem',
        },
      },
    },
    wrapper: {
      alignItems: 'center',
      backgroundColor: '#111',
      display: 'flex',
      flexDirection: 'column',
      padding: '10vh 35vw 0',
      position: 'relative',
      width: '100%',
    },
  },
  {name: 'steps-overview'}
);

export const StepCardStyles = makeStyles(
  {
    card: {
      borderRadius: '45px',
      height: '40vh',
      width: '33vw',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    img: {
      height: '100%',
      width: '100%',
    },
    stepName: {
      fontSize: 'inherit',
      fontWeight: '800',
    },
    stepNum: {
      color: '#fefefe',
      fontSize: 'inherit',
      fontWeight: '300',
    },
    titleWrapper: {
      color: '#fff',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '3rem',
      justifyContent: 'flex-start',
      marginBottom: '2rem',
    },
    wrapper: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10rem',
      width: '100%',
    },
  },
  {name: 'step-card'}
);

export const StepDetailStyles = makeStyles(
  {
    details: {
      color: '#fff',
      width: '57.5rem',
    },
    detailsWrapper: {
      position:'absolute',
    },
    left:{
      left: 50,
    },
    right: {
      right:50,
    },
    stepName: {
      color: '#f8f8f8',
      fontSize: '6rem',
      fontWeight: '800',
      width: '100%'
    },
    stepTitle: {
      color: '#f8f8f8',
      fontSize: '3rem',
      fontWeight: '300',
      width: '100%'
    },
    wrapper: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      objectFit: 'cover',
      margin: '0',
      // height: '92.6vh',
      // paddingLeft: '20vw',
      width: '100%',
    },
  },
  {name: 'detail-card'}
);
