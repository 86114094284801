/** @format */

import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TabStyles from './tabs/tabStyles';
import parse from 'html-react-parser';
const dateFormat = {
  weekday: undefined,
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export default props => {
  const orderStyles = TabStyles().orders;
  const [timeRange, setTimeRange] = useState('week');
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    let dateRange = 0;
    switch (timeRange) {
      // TODO
      // Add db/api/product lookups
      case 'day':
        dateRange = 1;
        break;
      case 'week':
        dateRange = 7;
        break;
      case 'month':
        dateRange = 31;
        break;
      case '3 months':
        dateRange = 93;
        break;
      case '6 months':
        dateRange = 186;
        break;
      case 'year':
        dateRange = 365;
        break;
      case 'all':
        let startDate = new Date("1/1/2021");
        let Difference_In_Time = new Date().getTime() - startDate.getTime();
        let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
        dateRange = Difference_In_Days;
        break;
      default:
        break;
    }
    fetch(`/api/sales?days=${dateRange}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(
        result => {
          setOrders(result.orders);
        },
        error => {
          console.log('error: ', error);
        }
      );
  }, [timeRange]);

  const updateTimeRange = newTimeRange => {
    setTimeRange(newTimeRange);
  };


  const getColors = colors => {
    let colorArray = colors.split(',');
    let colorObj = {};
    colorArray.forEach(color => {
      color = color.trim();
      if (!colorObj[color]) {
        colorObj[color] = 0;
      }
      colorObj[color]++;
    });

    const maxWidth = colorArray.reduce((max, obj) => {
      const contentWidth = obj.length; // Replace 'firstColumnKey' with the actual key in your object
      return contentWidth > max ? contentWidth : max;
    }, 0);

    if (colorArray.length > 1) {
      return (
        <table style={{width: '50%', margin:'4rem 0'}}>
          <th><td>Colors:</td></th>
          <tbody>
            {Object.keys(colorObj).map((key, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: `${maxWidth}ch` }}>
                    <Typography variant="body1">{key}</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">x{colorObj[key]}</Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return colors;
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      key={props.index}
      className={orderStyles}
    >
      {props.value === props.index && (
        <>
          <div className="time-wrapper">
            <Typography>Orders placed in the past </Typography>
            <Select
              fullWidth
              margin="dense"
              variant="outlined"
              onChange={e => updateTimeRange(e.target.value.toLowerCase())}
              className="range-picker"
              IconComponent={ExpandMoreIcon}
              defaultValue="week"
            >
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="3 months">3 Months</MenuItem>
              <MenuItem value="6 months">6 Months</MenuItem>
              <MenuItem value="year">Year</MenuItem>
              <MenuItem value="all">All Time</MenuItem>
            </Select>
          </div>
          <div className="orders-wrapper">
            {orders.map((order, index) => {
              return (
                <Card
                  className="order-card"
                  key={`${order}-${index}`}
                  elevation={0}
                >
                  <div className="order-info">
                    <section className="info-sect">
                      <Typography className="info-title">
                        Order Placed
                      </Typography>
                      <Typography variant="subtitle1">
                        {new Date(order.ts).toLocaleDateString(
                          undefined,
                          dateFormat
                        )}
                      </Typography>
                    </section>
                    <section className="info-sect">
                      <Typography className="info-title">Total</Typography>
                      <Typography variant="subtitle1">
                        ${(order.payment.amount / 100).toFixed(2)}
                      </Typography>
                    </section>
                    <section className="info-sect">
                      <Typography className="info-title">Ship To</Typography>
                      <Typography variant="subtitle1">
                        {order.shipping.address}
                      </Typography>
                      {order.shipping.address2 && <Typography variant="subtitle1">
                        {order.shipping.address2}
                      </Typography>}
                    </section>
                    <section className="info-sect">
                      <Typography className="info-title">
                        Order # {order.id.substr(-6)}
                      </Typography>
                      <Typography variant="subtitle1"> </Typography>
                    </section>
                  </div>
                  <CardContent className="card-content">
                    <Typography className="status-txt">
                      Status: {order.status.toTitleCase()}{' '}
                      {order.trackingNum && (
                        <a
                          href={`https://www.fedex.com/fedextrack/?tracknumbers=${order.trackingNum}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Tracking # {order.trackingNum}
                        </a>
                      )}
                      {order.trackingInfo && order.trackingInfo.map(shipment=>{
                        return (<a
                        href={`https://www.fedex.com/fedextrack/?tracknumbers=${shipment.trackingNum}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Tracking # {shipment.trackingNum}
                      </a>)})
                      }
                    </Typography>
                    {order.lines.map((item, index) => {
                      return (
                        <div className="item-wrapper" key={`item-${index}`}>
                          <img
                            src={
                              item.images +
                              `?w=${Math.round(window.devicePixelRatio * 280)}`
                            }
                            alt={item.name}
                            className="item-img"
                          />
                          <div className="item-info">
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              align="left"
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              variant="body1"
                              align="left"
                              color="textPrimary"
                            >
                              {item.description && parse(item.description)}
                            </Typography>
                            {getColors(item.options.color)}
                            <Typography
                              variant="h6"
                              align="left"
                            >
                              Qty: {item.qty}
                            </Typography>
                            <Typography
                              variant="h6"
                              align="left"
                              className="item-price"
                            >
                              ${item.price}
                            </Typography>
                            <div className="buttons">
                              <Link
                                href={`/shop/${
                                  item.options.material
                                }/${item.options.color?.toLowerCase()}-${item.options.weight?.toLowerCase() || ""}-${item.options.diameter?.toLowerCase()}`}
                              >
                                <Button
                                  className="blue btn"
                                  startIcon={<ShoppingCartOutlinedIcon />}
                                >
                                  Buy Again
                                </Button>
                              </Link>
                              {/* <Button className='grey btn'>
                                View item details
                              </Button> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
