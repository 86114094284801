/** @format */

import {makeStyles} from '@material-ui/core/styles';

const ProductStyles = makeStyles({
  flag: {
    height: '2.4rem',
    margin: '0 1rem',
  },
  madeInUsa: {
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '4rem',
    fontSize: '3rem',
  },
  header: {
    fontWeight:'bold',
    textAlign:'center'
  },
  subText: {
    padding: '0 12rem',
    width:'100%',
    fontWeight: 'normal',
    textAlign:'justify',
    color:'#5e5e5e'
  }
});

export default ProductStyles;

export const CardStyles = makeStyles({
  card: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    maxWidth: '40vw',
  },
  cardMedia: {
    height: '80%',
    width: '80%',
    objectFit: 'contain',
    // padding: '2rem',
  },
  itemDescription: {
    fontSize: '4rem',
  },
  outOfStock: {
    position: 'absolute',
    height: 21,
    width: 100,
    backgroundColor: 'black',
    color: 'white',
    fontSize: '1.8rem',
    top: 0,
    left: 16,
    fontSize: '5rem',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sale: {
    position: 'absolute',
    height: 21,
    width: 100,
    backgroundColor: 'lightblue',
    color: 'black',
    fontSize: '5em',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    top: 0,
    left: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  limited: {
    position: 'absolute',
    height: 21,
    width: 100,
    backgroundColor: 'lightpink',
    color: 'black',
    fontSize: '5rem',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    top: 0,
    left: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  premium: {
    position: 'absolute',
    height: 21,
    width: 100,
    backgroundColor: 'purple',
    color: 'white',
    fontSize: '5rem',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    top: 0,
    left: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceSlash: {
    position: 'relative',
    width: '100%',
    height: 0,
    borderTop: '2px solid #bbb',
    // transform: 'rotate(90deg)',
    top: 12,
    left: 2,
  },
  promoCode: {
    backgroundColor: '#FFDDDD',
    padding: 3,
    borderRadius: 3,
    color: '#EA0F14',
    cursor: 'pointer',
  },
  productImg: {
    borderRadius: '16px',
    height: '100%',
  },
  textLink: {
    textDecoration: 'none',
    color: 'black',
    '& .MuiTypography-body1': {
      fontSize:'6rem',
      fontWeight:'normal',
    },
  },
  productPrice: {
    fontSize:'6rem',
    fontWeight:'bold'
  }
});
