/** @format */

import React, {useState} from 'react';
// Material UI Components
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// Custom components
import RegisterForm from './register-form';
// Styles
import ModalStyles from './styles';

export default function RegisterComp(props) {
  const {formSwitch, status, err} = props;
  const [isEmailSent, setIsEmailSent] = useState(false);
  const pgStyles = ModalStyles();

  function EmailSentFunc() {
    setIsEmailSent(true);
  }

  function EmailSent(props) {
    return (
      <>
        <Typography variant="h3" className="title" noWrap>
          Email Sent
        </Typography>
        <Typography className="form-switch-txt">
          We have sent a confirmation email to your registered email.
        </Typography>
        <Button
          fullWidth
          size="large"
          variant="contained"
          className="submit"
          onClick={e => formSwitch('login')}
        >
          Sign In
        </Button>
      </>
    );
  }

  function RegisterSuccess(props) {
    let title;
    let msg;
    if (status === 'success') {
      title = 'Your email has been verified';
      msg = 'You can now sign in to your account!';
    } else if (status === 'err') {
      title = 'Invalid verification';
      msg = err;
    } else {
      title = 'Something went wrong.';
      msg = 'Sorry but something went wrong. Please try again.';
    }
    if (process.env.NODE_ENV === 'production') {
      try{
          window.gtag("event", "sign_up", {
            method:"Web Form"
          });
      } catch(e) {}
    }
    return (
      <>
        <Typography variant="h3" className="title">
          {title}
        </Typography>
        <Typography className="form-switch-txt">{msg}</Typography>
        <Button
          fullWidth
          size="large"
          variant="contained"
          className="submit"
          onClick={e => formSwitch('login')}
        >
          Sign In
        </Button>
      </>
    );
  }

  return (
    <div className={pgStyles.formDiv + ' register'}>
      {status ? (
        <RegisterSuccess />
      ) : isEmailSent ? (
        <EmailSent />
      ) : (
        <RegisterForm formSwitch={formSwitch} emailSentFunc={EmailSentFunc} />
      )}
    </div>
  );
}

export function RegisterSuccess(props) {
  const {formSwitch} = props;
  const pgStyles = ModalStyles();
  return (
    <div className={pgStyles.formDiv + ' forgot-password'}>
      <Typography variant="h3" className="title">
        Your email has been verified
      </Typography>
      <Typography className="form-switch-txt">
        You can now sign in to your account!
      </Typography>
      <Button
        fullWidth
        size="large"
        variant="contained"
        className="submit"
        onClick={e => formSwitch('login')}
      >
        Sign In
      </Button>
    </div>
  );
}
