/** @format */

import React, {useState, useEffect} from 'react';
import {
  createTheme,
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import {SnackbarProvider} from 'notistack';
// import Tap from "@tapfiliate/tapfiliate-js";
// Cookies import
import Cookies from 'universal-cookie';

// Custom imports
import CartUtils from './utils/cartUtils.js';
import Routes from './Routes';
import theme from './theme.json';
import './App.css';
import Notification from './components/noticeBanner';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const mainTheme = createTheme(theme);
const classNameGen = createGenerateClassName({
  productionPrefix: 'main',
});
const cookies = new Cookies();
let initialUser = {userId: 'Guest', loggedIn: false};
if(cookies.get('USER')) {
  initialUser = cookies.get('USER');
}
// User Data
try {
  if(localStorage && localStorage.getItem('user')) {
   initialUser = localStorage.getItem('user');
  }
} catch(e) {

}

if (initialUser && typeof initialUser === 'string') {
  initialUser = JSON.parse(initialUser);
  let JWT = cookies.get('JWT');
  // let JWT = initialUser.JWT;
  if (!JWT) {
    let cookieOpts = {path: '/', sameSite: 'strict', secure: true};
    if (process.env.NODE_ENV !== 'production') {
      delete cookieOpts.sameSite;
      delete cookieOpts.secure;
    }
    cookies.set('JWT', initialUser.jwt, cookieOpts);
  }
}

let newCart;

function App() {
  const [cartData, setCartData] = useState([]);
  const [userData, setUserData] = useState(initialUser);
  newCart = new CartUtils({}, setCartData);
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    const init = async () => {
      if (!userData.jwt) {
        await getGuestToken();
      }
      getCart();
    };

    init();
  }, [userData.jwt]);

  const getCart = () => {
    fetch('/api/cart', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(
        result => {
          newCart.setCart(result);
        },
        error => {
          console.log('error: ', error);
        }
      );
  };

  const getGuestToken = () => {
    return fetch('/api/auth/login/guest', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async resp => {
        return resp.json();
      })
      .then(data => {
        localStorage.setItem('user', JSON.stringify(data));
        cookies.set('JWT', data.jwt, {
          path: '/',
          sameSite: 'strict',
          secure: true,
        });
        setUserData(data);
      }).catch(err=>{
        // console.log("err msg ", err.msg)
        // console.log("err stack: ",err.stack)
      });
  };

  useEffect(() => {
    if (userData.userId && userData.loggedIn === true) {
      fetch(`/api/user/${userData.userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(resp => resp.json())
        .then(
          async result => {
            const membership = await getMembership();
            // const membership = false;
            setUserData({...userData, ...result, membership});
          },
          error => {
            console.log('error: ', error);
          }
        );
    }
    //eslint-disable-next-line
  }, []);

  const getMembership = () => {
    return fetch(`/api/subscription`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(
        result => {
          return result.valid;
        },
        error => {
          console.log('error: ', error);
        }
      );
  }

  const updateMemberShip = async () => {
    let valid = false;
    try {
      valid = await getMembership();
    } catch(e) {
      //getting membership errored out default to non member;
    }
    setUserData(prevUserData=>{
      return {...prevUserData,membership: valid};
    });
  }

  
    return (
      <ThemeProvider theme={mainTheme}>
        <StylesProvider generateClassName={classNameGen}>
          <SnackbarProvider
            maxSnack={3}
            style={{
              minWidth: 345,
              minHeight: 68,
              borderRadius: 34,
              fontSize: isMobile?'6rem':'2.5em',
            }}
            iconVariant={{
              error: (
                <img
                  src="/images/closeAlert.svg"
                  alt="close"
                  style={{marginRight: 10}}
                />
              ),
            }}
            components={{
              noticeBanner: Notification
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}>
            <Routes
              userData={userData}
              cartData={cartData}
              cartUtils={newCart}
              isMobile={isMobile}
              updateMemberShip={updateMemberShip}
            />
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    );
}

export default App;

// eslint-disable-next-line no-extend-native
String.prototype.toTitleCase = function () {
  return this.toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};