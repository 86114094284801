/** @format */

import {makeStyles} from '@material-ui/core/styles';

const OrderSummaryStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& .coupon-header': {
      marginBottom: '3rem',
    },
    '& .fake-inp-label': {
      margin: 0,
    },
  },
  couponSect: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '3rem',
  },
  inpCoupon: {
    paddingRight: '1rem',
  },
  applyCouponButton: {
    height: '6rem',
    color: 'white',
    backgroundColor: 'black',
    width: '100%',
    borderRadius: '3px',
    maxWidth: '8vw',
    marginTop: '3.5rem',
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
  cart: {
    backgroundColor: '#EEE',
    width: '96%',
    margin: 'auto',
    marginTop: 8,
    textAlign: 'center',
    borderRadius: 4,
  },
});

export default OrderSummaryStyles;
