/** @format */

import React, {useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
// Material UI Components
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
// Custom Components
import {SunValidator} from '../../../utils/sun-input-validation';
import {RequirementFunc} from './password-reqs';
import {isValidEmail, isValidPassword} from '@sunsh1n3/sun-input-validation';
import SubmitButton from '../styled/submit-button';
import StyledInput from '../styled/input';
// Styles
import ModalStyles from './styles';

export default function RegisterForm(props) {
  const pgStyles = ModalStyles();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isValidForm, setIsValidForm] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [showPasswordReqs, setShowPasswordReqs] = useState(false);
  const {formSwitch, emailSentFunc = () => {}} = props;
  const {enqueueSnackbar} = useSnackbar();

  let inputValidity = {
    name: false,
    email: false,
    password: false,
    password2: false,
  };
  const [passwordReqs, setPasswordReqs] = useState([
    {
      text: '8 characters',
      isValid: false,
      showIcon: false,
      key: 'req-length',
    },
    {
      text: '1 capital letter',
      isValid: SunValidator.containsCapital(password),
      showIcon: false,
      key: 'req-capital',
    },
    {
      text: '1 lowercase letter',
      isValid: false,
      showIcon: false,
      key: 'req-lowercase',
    },
    {
      text: '1 number',
      isValid: false,
      showIcon: false,
      key: 'req-number',
    },
    {
      text: '1 symbol (*&%$#@ etc.)',
      isValid: false,
      showIcon: false,
      key: 'req-symbol',
    },
  ]);

  const reqUpdateFuncs = [
    SunValidator.containsEnoughChars,
    SunValidator.containsCapital,
    SunValidator.containsLowercase,
    SunValidator.containsNumber,
    SunValidator.containsSpecialChar,
  ];

  const parseFullName = () => {
    let nameArray = fullName.split(' ');
    let nameObj = {};
    if (nameArray.length <= 2 && nameArray.length > 0) {
      nameObj['fname'] = nameArray[0];
    }
    if (nameArray.length >= 2) {
      if (nameArray.length > 2) {
        nameObj['lname'] = nameArray.pop();
        nameObj['fname'] = nameArray.reduce((acc, cur) => {
          return acc + ' ' + cur;
        });
      } else {
        nameObj['lname'] = nameArray[nameArray.length - 1];
      }
    }
    return nameObj;
  };

  const handlePasswordChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    let newReqs = passwordReqs;
    for (let i = 0; i < passwordReqs.length; i++) {
      newReqs[i].showIcon = newPassword !== '';
      newReqs[i].isValid = reqUpdateFuncs[i](newPassword);
    }
    setPasswordReqs(newReqs);
  };

  const isPasswordMatchErr = e => {
    let newVal = e.target.value;
    return newVal !== password && newVal !== '';
  };

  useEffect(() => {
    setIsValidForm(
      fullName !== '' &&
        isValidEmail(email) &&
        isValidPassword(password) &&
        password === password2
    );
  }, [fullName, email, password, password2]);

  const submitRegister = e => {
    e.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    if (isValidForm) {
      setIsSubmitDisabled(true);
      let names = parseFullName();
      fetch('/api/auth/register', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          userId: email,
          password: password,
          password2: password2,
          fname: names.fname,
          lname: names.lname,
        }),
      })
        .then(resp => {
          if (!resp.ok) {
            return resp.text();
          }
          setIsSubmitDisabled(false);
          emailSentFunc();
        })
        .then(resp => {
          throw resp;
        })
        .catch(e => {
          if (e) enqueueSnackbar(e, {variant: 'error'});
          setIsSubmitDisabled(false);
          // return e.json();
        });
    }
  };

  function goToLogin(e) {
    formSwitch('login');
  }

  return (
    <>
      <Typography variant="h3" className={pgStyles.title}>
        Welcome to PrintBed.
      </Typography>
      <Typography variant="h4" className={pgStyles.subtitle}>
        Create An Account
      </Typography>
      <Typography className={pgStyles.formSwitchText}>
        Already have an account? <Link onClick={goToLogin}>Sign In</Link>
      </Typography>
      <form className={pgStyles.form} noValidate onSubmit={submitRegister}>
        <StyledInput
          className="input"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="name"
          name="name"
          placeholder="Full Name"
          autoComplete="name"
          error={inputValidity.name}
          errorText="Your name is required"
          showErrorTooltip={false}
          showValidIcon={false}
          autoFocus
          onChange={e => setFullName(e.target.value)}
        />
        <StyledInput
          className="input"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          placeholder="Email"
          errorText="Invalid email"
          showErrorTooltip={false}
          showValidIcon={false}
          onChange={e => setEmail(e.target.value)}
        />
        <StyledInput
          className="input"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="password"
          name="password"
          autoComplete="new-password"
          placeholder="Password"
          type="password"
          errorText={'Invalid password'}
          showErrorTooltip={false}
          showValidIcon={false}
          onChange={handlePasswordChange}
          onFocus={e => setShowPasswordReqs(true)}
          onBlur={e => setShowPasswordReqs(false)}
          visibilityIconVariant="outlined"
        />
        <div style={{display: showPasswordReqs ? '' : 'none'}}>
          <Typography variant="h6">Password must contain at least:</Typography>
          {passwordReqs.map((r, i) => {
            return RequirementFunc(r);
          })}
        </div>
        <StyledInput
          className="input"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          id="password2"
          name="password2"
          autoComplete="off"
          placeholder="Confirm Password"
          type="password"
          onChange={e => {
            setPassword2(e.target.value);
          }}
          errorFunc={isPasswordMatchErr}
          errorText="Passwords do not match"
          errorTooltipOnlyOnFocus={true}
          showErrorTooltip={true}
          showErrorArrow={false}
          showErrorIcon={false}
          errorTooltipPosition="right"
          showValidIcon={false}
          errorPopperProps={{
            disablePortal: true,
          }}
          overrideValidation={true}
          valid={password === password2}
          visibilityIconVariant="outlined"
        />
        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="submit"
          onClick={submitRegister}
        >
          Sign Up
        </SubmitButton>
      </form>
    </>
  );
}
