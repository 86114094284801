/** @format */

import {makeStyles} from '@material-ui/core/styles';
import { fontSize } from '@material-ui/system';

const FooterStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 300,
    fontSize: '1.5rem',
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '12px red solid',
    padding: 20,
    '& h1': {
      fontSize: '5rem',
    },
  },
  footerLink: {
    display: 'flex',
    color: 'white',
    fontSize: '6rem',
    justifyContent: 'space-between',
    marginBottom: '2rem',
  },
  socialButton: {
    color: '#FFFFFF',
    margin: 0,
    padding: 10,
    paddingRight: 8,
    '& svg':{
      fontSize:21
    }
  },
  buttonGroup: {
    display: 'inline',
  },
  footerCopy: {
    fontSize:'6rem'
  }
}));

export default FooterStyles;
