/** @format */

import {makeStyles} from '@material-ui/core/styles';

const ShippingStyles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '30vw',
    width: '100%',
    '& .inp': {
      marginBottom: '.5rem',
    },
  },
  nameFields: {
    display: 'flex',
  },
  inputs: {
    marginBottom: '3rem',
  },
  header: {
    marginBottom: '3rem',
  },
});

export default ShippingStyles;

export const InputStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    width: '100%',
    paddingRight: '1.6vw',
    height: '100%',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.5rem',
    width: '100%',
    color: '#000',
    '&.no-lbl': {
      marginBottom: 0,
    },
  },
  actualInput: {
    fontSize: '2.5rem',
    height: '6rem',
    '&.MuiFilledInput-multiline': {
      height: 'auto',
      padding: '1.75rem 2rem',
    },
  },
  errorTxt: {
    '&.valid': {
      visibility: 'hidden',
    },
  },
  selector: {
    marginBottom: 'calc(1.5rem + 3px)',
  },
  checkBoxLabel: {
    alignItems: 'safe',
    color: '#000',
    marginBottom: '1.5rem',
    '&.no-lbl': {
      marginBottom: 0,
    },
  },
  checkBox: {
    color: '#eee',
    '&$checked': {
      color: '#efefef',
    },
  },
});
