/** @format */

import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {usePagination} from '@material-ui/lab/Pagination';
import {ImageStyles} from './styles';
import NextBtn from '../../images/right-chevron.svg';
import PrevBtn from '../../images/left-chevron.svg';
import FullscreenBtn from '../../images/fullscreen-square.svg';
import MinimizeBtn from '../../images/reverse-fullscreen-square.svg';

export default function ImageViewer(props) {
  const pgStyles = ImageStyles();
  const {currentItem, isSpool} = props;
  const [fullscreenView, setFullscreenView] = useState(false);
  const {items} = usePagination({
    count: currentItem.imgSrc.length || 3,
    showFirstButton: false,
    showLastButton: false,
    hideNextButton: false,
    hidePrevButton: false,
  });
  const prevBtnIndex = items.findIndex(i => i.type === 'previous');
  const nextBtnIndex = items.findIndex(i => i.type === 'next');
  const previewPages = items.filter(i => i.type === 'page');
  const previewSrcs = getPreviews();

  function getPreviews() {
    const vidCount = currentItem.previewVideos.length;
    const imgCount = currentItem.imgSrc.length;
    let previews = [];
    for (let i = 0; i < imgCount; i++) {
      let imgType;
      switch (currentItem.imgSrc[i].substr(-4)) {
        case 'spin': imgType = 'spin';
          break;
        case '.mp4': imgType = 'video';
          break;
        default:
          imgType = 'img';
          break;
      }
      previews.push({src: currentItem.imgSrc[i], type: imgType});
    }
    for (let i = 0; i < vidCount; i++) {
      previews.push({src: currentItem.previewVideos[i], type: 'video'});
    }
    return previews;
  }

  const toggleModal = () => {
    setFullscreenView(!fullscreenView);
  };

  const changeImage = (changeType, e) => {
    e.preventDefault();
    e.stopPropagation();

    switch (changeType) {
      case 'next':
        items[nextBtnIndex].onClick(e);
        break;
      case 'prev':
        items[prevBtnIndex].onClick(e);
        break;
      case 'fs':
        toggleModal();
        break;
      default:
        break;
    }
  };

  const handleItemClick = (item, e) => {
    e.stopPropagation();
    item.onClick(e);
  };

  return (
    <div className={pgStyles.wrapper}>
      <ul className={pgStyles.imagePreviews}>
        {items !== undefined && currentItem.imgSrc !== undefined
          ? previewPages.map(({page, type, selected, ...item}, index) => {
              const previewItem = previewSrcs[page - 1];
              return (
                <li
                  className={
                    pgStyles.thumbnailWrapper + (selected ? ' current' : '')
                  }
                  key={previewItem.type + '-preview-' + index}
                  onClick={e => handleItemClick(item, e)}
                >
                  {previewItem.type === 'img' && (
                    <img
                      src={previewItem.src + '?w=67'}
                      alt={currentItem.name + ' ' + previewItem.type + ' ' + index}
                      className={pgStyles.itemImage}
                    />
                  )}
                  {previewItem.type === 'spin' && (
                    <img
                      src={previewItem.src + '?image=72&gif.lossy=5&w=67'}
                      alt={
                        currentItem.name + ' ' + previewItem.type + ' ' + index
                      }
                      className={pgStyles.itemImage}
                    />
                  )}
                  {previewItem.type === 'video' && (
                    <img
                    src={previewItem.src + '?thumbnail=72&gif.lossy=5&w=67'}
                    alt={
                      currentItem.name +
                      ' ' +
                      previewItem.type +
                      ' ' +
                      index
                    }
                    className={pgStyles.itemImage}
                  />
                  )}
                </li>
              );
            })
          : null}
      </ul>

      <div className={pgStyles.mainImg}>
        <div className="img-wrapper">
          {previewSrcs[items.find(i => i.selected).page - 1].type === 'img' && (
            <img
              src={
                currentItem.imgSrc !== undefined && items.length > 0
                  ? previewSrcs[items.find(i => i.selected).page - 1].src
                  : ''
              }
              style={{height: '100%', width: 'auto'}}
              alt={isSpool?`Printbed ${currentItem.tags.color} ${currentItem.tags.material.toUpperCase()} filament`:currentItem.name}
            />
          )}
          {previewSrcs[items.find(i => i.selected).page - 1].type ===
            'spin' && (
            <div
              className="Sirv"
              data-src={
                previewSrcs[items.find(i => i.selected).page - 1].src +
                '?fullscreen=false'
              }
            ></div>
          )}
          {previewSrcs[items.find(i => i.selected).page - 1].type ===
            'video' && (
            <video
              autoPlay={false}
              controls
              src={previewSrcs[items.find(i => i.selected).page - 1].src}
            >
              {/* <source
                src={previewSrcs[items.find((i) => i.selected).page - 1].src}
                type='video/mp4'
              /> */}
              Something went wrong
            </video>
          )}
        </div>
        <div className={pgStyles.navBtns}>
          <div className="fullscreen">
            <Button
              className="btn"
              disableRipple
              onClick={e => changeImage('fs', e)}
              key="fs-btn"
            >
              <img src={FullscreenBtn} alt="View fullscreen" />
            </Button>
          </div>
          <Button
            className="btn"
            disableRipple
            onClick={e => changeImage('prev', e)}
            disabled={items[prevBtnIndex].disabled}
            key="prev-btn"
          >
            {
              //eslint-disable-next-line
              <img src={PrevBtn} alt="Previous image" />
            }
          </Button>
          <Button
            className="btn"
            disableRipple
            onClick={e => changeImage('next', e)}
            disabled={items[nextBtnIndex].disabled}
            key="next-btn"
          >
            {
              //eslint-disable-next-line
              <img src={NextBtn} alt="Next image" />
            }
          </Button>
        </div>
      </div>
      {/* Fullscreen Dialog */}
      <Dialog
        onClose={toggleModal}
        open={fullscreenView}
        className={pgStyles.dialog}
        PaperProps={{
          className: 'dialog-paper',
          elevation: 0,
          square: true,
        }}
        maxWidth={false}
        scroll="paper"
        key="item-dialog"
      >
        <div className={pgStyles.minimize}>
          <Button
            className="nav-btn"
            id="#fs-exit"
            disableRipple
            onClick={e => changeImage('fs', e)}
            key="fs-exit-btn"
          >
            <img src={MinimizeBtn} alt="Exit fullscreen view" />
          </Button>
        </div>
        <div className={pgStyles.mainImg} onClick={toggleModal}>
          <div
            className="img-wrapper"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {previewSrcs[items.find(i => i.selected).page - 1].type ===
              'img' && (
              <img
                src={
                  currentItem.imgSrc !== undefined && items.length > 0
                    ? previewSrcs[items.find(i => i.selected).page - 1].src
                    : ''
                }
                alt={currentItem.name}
              />
            )}
            {previewSrcs[items.find(i => i.selected).page - 1].type ===
              'spin' && (
              <div
                className="Sirv"
                data-src={
                  previewSrcs[items.find(i => i.selected).page - 1].src +
                  '?fullscreen=false'
                }
              ></div>
            )}
            {previewSrcs[items.find(i => i.selected).page - 1].type ===
              'video' && (
              <video
                autoPlay={false}
                controls
                src={previewSrcs[items.find(i => i.selected).page - 1].src}
              >
                {/* <source
                  src={previewSrcs[items.find((i) => i.selected).page - 1].src}
                  type='video/mp4'
                /> */}
                Something went wrong
              </video>
            )}
          </div>
        </div>
        <ul className={pgStyles.imagePreviews}>
          <Button
            className="nav-btn"
            id="fs-prev"
            disableRipple
            onClick={e => changeImage('prev', e)}
            disabled={items[prevBtnIndex].disabled}
            key="fs-prev-btn"
          >
            {
              //eslint-disable-next-line
              <img src={PrevBtn} alt="Previous image" />
            }
          </Button>
          {items !== undefined && currentItem.imgSrc !== undefined
            ? previewPages.map(({page, type, selected, ...item}, index) => {
                const previewItem = previewSrcs[page - 1];
                return (
                  <li
                    className={
                      pgStyles.thumbnailWrapper + (selected ? ' current' : '')
                    }
                    key={'fs-img-preview-' + index}
                    onClick={e => handleItemClick(item, e)}
                  >
                    {previewItem.type === 'img' && (
                      <img
                        src={previewItem.src}
                        alt={
                          currentItem.name +
                          ' ' +
                          previewItem.type +
                          ' ' +
                          index
                        }
                        className={pgStyles.itemImage}
                      />
                    )}
                    {previewItem.type === 'spin' && (
                      <img
                        src={previewItem.src + '?image=72&gif.lossy=5&w=67'}
                        alt={
                          currentItem.name +
                          ' ' +
                          previewItem.type +
                          ' ' +
                          index
                        }
                        className={pgStyles.itemImage}
                      />
                    )}
                    {previewItem.type === 'video' && (
                      <img
                      src={previewItem.src + '?thumbnail=72&gif.lossy=5&w=67'}
                      alt={
                        currentItem.name +
                        ' ' +
                        previewItem.type +
                        ' ' +
                        index
                      }
                      className={pgStyles.itemImage}
                    />
                      // <video
                      //   autoPlay={false}
                      //   className={pgStyles.itemVideo}
                      //   preload="metadata"
                      // >
                      //   <source src={previewItem.src} type="video/mp4" />
                      //   Something went wrong
                      // </video>
                    )}
                  </li>
                );
              })
            : null}
          <Button
            className="nav-btn"
            id="fs-next"
            disableRipple
            onClick={e => changeImage('next', e)}
            disabled={items[nextBtnIndex].disabled}
            key="fs-next-btn"
          >
            {
              //eslint-disable-next-line
              <img src={NextBtn} alt="Next image" />
            }
          </Button>
        </ul>
      </Dialog>
    </div>
  );
}
