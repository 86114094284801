/** @format */

import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {usePagination} from '@material-ui/lab/Pagination';
import {ImageStyles, SirvStyles} from './styles';
import NextBtn from '../images/right-chevron.svg';
import PrevBtn from '../images/left-chevron.svg';

export default function ImageViewer(props) {
  const pgStyles = ImageStyles();
  const {currentItem} = props;
  const [fullscreenView, setFullscreenView] = useState(false);
  const {items} = usePagination({
    count: 5,
    showFirstButton: false,
    showLastButton: false,
    hideNextButton: false,
    hidePrevButton: false,
  });
  const prevBtnIndex = items.findIndex(i => i.type === 'previous');
  const nextBtnIndex = items.findIndex(i => i.type === 'next');
  const previewPages = items.filter(i => i.type === 'page');
  const previewSrcs = getPreviews();

  function getPreviews() {
    const vidCount = currentItem.previewVideos.length;
    const imgCount = 5 - vidCount;
    let previews = [];
    for (let i = 0; i < imgCount; i++) {
      let imgType;
      switch (currentItem.imgSrc[i].substr(-4)) {
        case 'spin': imgType = 'spin';
          break;
        case '.mp4': imgType = 'video';
          break;
        default:
          imgType = 'img';
          break;
      }
      previews.push({src: currentItem.imgSrc[i], type: imgType});
    }
    for (let i = 0; i < vidCount; i++) {
      previews.push({src: currentItem.previewVideos[i], type: 'video'});
    }
    return previews;
  }

  const toggleModal = () => {
    setFullscreenView(!fullscreenView);
  };

  const changeImage = (changeType, e) => {
    e.preventDefault();
    e.stopPropagation();

    switch (changeType) {
      case 'next':
        items[nextBtnIndex].onClick(e);
        break;
      case 'prev':
        items[prevBtnIndex].onClick(e);
        break;
      case 'fs':
        toggleModal();
        break;
      default:
        break;
    }
  };

  const handleItemClick = (item, e) => {
    e.stopPropagation();
    item.onClick(e);
  };

  return (
    <div className={pgStyles.wrapper}>
      <div className={pgStyles.mainImg}>
        <div className="img-wrapper">
          {previewSrcs[items.find(i => i.selected).page - 1].type === 'img' && (
            <img
              src={
                currentItem.imgSrc !== undefined && items.length > 0
                  ? previewSrcs[items.find(i => i.selected).page - 1].src
                  : ''
              }
              alt={currentItem.name}
            />
          )}
          {previewSrcs[items.find(i => i.selected).page - 1].type ===
            'spin' && (
            <div
              className="Sirv"
              data-src={
                previewSrcs[items.find(i => i.selected).page - 1].src +
                '?fullscreen=false'
              }
            ></div>
          )}
          {previewSrcs[items.find(i => i.selected).page - 1].type ===
            'video' && (
            <video
              autoPlay={false}
              controls
              src={previewSrcs[items.find(i => i.selected).page - 1].src}
            >
              {/* <source
                src={previewSrcs[items.find((i) => i.selected).page - 1].src}
                type='video/mp4'
              /> */}
              Something went wrong
            </video>
          )}
        </div>
        <div className={pgStyles.navBtns}>
          <Button
            className="btn"
            disableRipple
            onClick={e => changeImage('prev', e)}
            disabled={items[prevBtnIndex].disabled}
            key="prev-btn"
          >
            {
              //eslint-disable-next-line
              <img src={PrevBtn} alt="Previous image" />
            }
          </Button>
          <Button
            className="btn"
            disableRipple
            onClick={e => changeImage('next', e)}
            disabled={items[nextBtnIndex].disabled}
            key="next-btn"
          >
            {
              //eslint-disable-next-line
              <img src={NextBtn} alt="Next image" />
            }
          </Button>
        </div>
      </div>
      <ul className={pgStyles.imagePreviews}>
        {items !== undefined && currentItem.imgSrc !== undefined
          ? previewPages.map(({page, type, selected, ...item}, index) => {
              const previewItem = previewSrcs[page - 1];
              return (
                <li
                  className={
                    pgStyles.thumbnailWrapper + (selected ? ' current' : '')
                  }
                  key={previewItem.type + '-preview-' + index}
                  onClick={e => handleItemClick(item, e)}
                >
                  {previewItem.type === 'img' && (
                    <img
                      src={previewItem.src + '?w=67'}
                      alt={
                        currentItem.name + ' ' + previewItem.type + ' ' + index
                      }
                      className={pgStyles.itemImage}
                    />
                  )}
                  {previewItem.type === 'spin' && (
                    <img
                      src={previewItem.src + '?image=72&gif.lossy=5&w=67'}
                      alt={
                        currentItem.name + ' ' + previewItem.type + ' ' + index
                      }
                      className={pgStyles.itemImage}
                    />
                  )}
                  {previewItem.type === 'video' && (
                    <video
                      autoPlay={false}
                      className={pgStyles.itemVideo}
                      preload="metadata"
                    >
                      <source src={previewItem.src} type="video/mp4" />
                      Something went wrong
                    </video>
                  )}
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
}

export function SirvViewer(props) {
  const pgStyles = SirvStyles();
  const {currentItem = null, isSpool} = props;
  return (
    <div id="productWrapper" className={pgStyles.wrapper}>
      <div
        className="Sirv"
        id="sirv-viewer"
        data-mobile-options="thumbnails.type:bullets;fullscreen.enable:false;lazyImage.fit:contain;lazyImage.resize:true;"
        data-options="thumbnails.type:bullets;fullscreen.enable:false;lazyImage.fit:contain;lazyImage.resize:true;"
      >
        {currentItem
          ? currentItem.imgSrc.map(imgSrc => {
              return (
                <div
                  data-src={
                    imgSrc + (imgSrc.indexOf('.mp4')<0?`?h=${Math.round(200 * window.devicePixelRatio)}`:'')
                  }
                  alt={isSpool?`Printbed ${currentItem.tags.color} ${currentItem.tags.material.toUpperCase()} filament`:currentItem.name}
                  key={imgSrc}
                ></div>
              );
            })
          : null}
      </div>
    </div>
  );
}
