/** @format */

import {makeStyles} from '@material-ui/core/styles';

const ShippingStyles = makeStyles(
  {
    addrLines: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1.75rem',
      marginTop: '1rem',
      '& .combined': {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    cardWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '2rem',
      padding: '2rem',
      '& .MuiCardActionArea-root': {
        flexGrow: 0,
        marginRight: '2rem',
        width: 'min-content',
      },
    },
    deliverBtn: {
      borderRadius: '4px',
      marginBottom: '2rem',
    },
    newAddrWrapper: {
      display: 'none',
      // display: 'flex',
      flexDirection: 'row',
      padding: '2rem',
    },
    title: {
      marginBottom: '1.5rem',
      marginLeft: '2rem',
    },
    wrapper: {
      '& .card-style': {
        backgroundColor: '#fff',
        border: '1px solid #eee',
        borderRadius: '4px',
      },
    },
  },
  {name: 'shipping-pg'}
);

export default ShippingStyles;

export const InputStyles = makeStyles({
  actualInput: {
    height: '6rem',
    paddingRight: '0',
    '&.MuiFilledInput-multiline': {
      height: 'auto',
      padding: '1.75rem 2rem',
    },
    '& .MuiInputBase-input': {
      borderRadius: '3px',
      boxSizing: 'border-box',
    },
  },
  errorTxt: {
    height: '2rem',
    '&.valid': {
      visibility: 'hidden',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.5rem',
    width: '100%',
    color: '#000',
    '&.no-lbl': {
      marginBottom: 0,
    },
  },
  selector: {
    border: 'none',
    borderRadius: '3px',
    fontSize: '2rem',
    height: '6rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    width: '100%',
    height: '100%',
    marginBottom: '1rem',
  },
});

export const AddressFormStyles = makeStyles(
  {
    submitBtn: {
      marginBottom: '2rem',
      marginTop: '7.75rem',
    },
    title: {
      marginBottom: '6rem',
    },
    wrapper: {
      padding: '0 3.5rem',
    },
  },
  {name: 'address-form'}
);
