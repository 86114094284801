/** @format */

import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import CheckoutResultComponent from '../checkoutResult';
import PaypalCheckoutResultsPageStyles from './styles';

const CheckoutResult = props => {
  const history = useHistory();
  const pgStyles = PaypalCheckoutResultsPageStyles();
  const {userData} = props;
  const {loggedIn} = userData;

  const getQuery = () => new URLSearchParams(window.location.search);
  const paypalRefId = getQuery().get('orderId');
  const paypalStatus = getQuery().get('status');

  const [status, setStatus] = useState(paypalStatus);
  const [customerInfo, setCustomerInfo] = useState();
  const [saleId, setSaleId] = useState();

  useEffect(() => {
    if (status === 'success') {
      fetch('/api/sales?limit=1')
        .then(resp => resp.json())
        .then(data => {
          const order = data.orders[0];

          if (paypalRefId === order.payment.refId) {
            setSaleId(order.id);
            setCustomerInfo(order.shipping);
          } else {
            setStatus('error');
          }
        });
    }
  // eslint-disable-next-line
  }, []);

  return (
    <div className={pgStyles.container}>
      <CheckoutResultComponent
        key="checkout-result-comp"
        status={status}
        customerInfo={customerInfo}
        isLoggedIn={loggedIn}
        saleId={saleId}
        {...props}
        onClick={() => history.push('/') }
      />
    </div>
  );
};

export default CheckoutResult;
