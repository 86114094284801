/** @format */

import React from 'react';
// Material UI Component Imports
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
// Material UI Icon Imports
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// USA States import
import * as DatasetStates from 'datasets-us-states-names-abbr';
// Custom imports
import StyledInput from '../styled/input';
import StyledLabel from '../styled/input-label';
import StyledSelect from '../styled/select';
import {InputStyles} from './styles';

const StateNames = Object.keys(DatasetStates);

export default function ShippingInput({
  type = 'text',
  placeholder = '',
  errorText = 'Invalid entry',
  required = false,
  label = '',
  id = null,
  isError = false,
  fullWidth = true,
  onChange = e => {
    console.log('default ShippingInput onChange func');
  },
  onBlur = e => {
    console.log('default ShippingInput onBlur func');
  },
  value = '',
  multiline = false,
  rows = 1,
  addedClasses = '',
  inputProps = {},
  country = 'US',
}) {
  const inpStyles = InputStyles();
  let inputId = null;
  if (id === null) {
    if (label === '') {
      inputId = 'custom-input-' + Math.floor(Math.random() * 100).toString();
    } else {
      inputId = `custom-${label}-input`;
    }
  } else {
    inputId = id;
  }
  return (
    <div className={inpStyles.wrapper + ' ' + addedClasses}>
      <StyledLabel
        className={inpStyles.label}
        htmlFor={inputId}
        disableAnimation
      >
        {label}
        {required ? '*' : ''}
      </StyledLabel>
      <StyledInput
        multiline={multiline}
        rows={rows}
        id={inputId}
        className={inpStyles.actualInput}
        type={type}
        placeholder={placeholder}
        disableUnderline={true}
        error={isError}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{...inputProps}}
      />
      <FormHelperText
        className={inpStyles.errorTxt + ' ' + (isError ? 'invalid' : 'valid')}
        error={isError}
      >
        {errorText}
      </FormHelperText>
    </div>
  );
}

export function StateSelect({
  errorText = 'Invalid entry',
  required = false,
  label = '',
  id = null,
  isError = false,
  fullWidth = true,
  passedOnChange = e => {
    console.log('default ShippingInput onChange func');
  },
  onBlur = e => {
    console.log('default ShippingInput onBlur func');
  },
  values = StateNames,
  addedClasses = '',
  value = '',
}) {
  const inpStyles = InputStyles();
  let inputId = null;
  if (id === null) {
    if (label === '') {
      inputId = 'custom-input-' + Math.floor(Math.random() * 100).toString();
    } else {
      inputId = `custom-${label}-input`;
    }
  } else {
    inputId = id;
  }
  return (
    <div className={inpStyles.wrapper + ' ' + addedClasses}>
      <StyledLabel
        className={inpStyles.label + (label === '' ? ' no-lbl' : '')}
        htmlFor={inputId}
        disableAnimation
      >
        {label}
        {required ? '*' : ''}
      </StyledLabel>
      <StyledSelect
        variant="filled"
        value={value}
        onChange={e => {
          passedOnChange(e);
        }}
        id={inputId}
        className={inpStyles.selector}
        IconComponent={ExpandMoreIcon}
        error={isError}
        disableUnderline
        MenuProps={{
          variant: 'menu',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {values.length > 0
          ? values.map(val => {
              return (
                <MenuItem style={{fontSize:'6rem'}} key={'state-' + val} value={val}>
                  {val}
                </MenuItem>
              );
            })
          : null}
      </StyledSelect>
      <FormHelperText className={inpStyles.errorTxt}>&nbsp;</FormHelperText>
    </div>
  );
}


export function CountrySelect({
  errorText = 'Invalid entry',
  required = false,
  label = '',
  id = null,
  isError = false,
  fullWidth = true,
  passedOnChange = e => {
    console.log('default ShippingInput onChange func');
  },
  onBlur = e => {
    console.log('default ShippingInput onBlur func');
  },
  values = ["US","CA"],
  addedClasses = '',
  value = '',
}) {
  const inpStyles = InputStyles();
  let inputId = null;
  if (id === null) {
    if (label === '') {
      inputId = 'custom-input-' + Math.floor(Math.random() * 100).toString();
    } else {
      inputId = `custom-${label}-input`;
    }
  } else {
    inputId = id;
  }
  return (
    <div className={inpStyles.wrapper + ' ' + addedClasses}>
      <StyledLabel
        className={inpStyles.label + (label === '' ? ' no-lbl' : '')}
        htmlFor={inputId}
        disableAnimation
      >
        {label}
        {required ? '*' : ''}
      </StyledLabel>
      <StyledSelect
        variant="filled"
        value={value}
        onChange={e => {
          passedOnChange(e);
        }}
        id={inputId}
        className={inpStyles.selector}
        IconComponent={ExpandMoreIcon}
        error={isError}
        disableUnderline
        MenuProps={{
          variant: 'menu',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {values.length > 0
          ? values.map(val => {
              return (
                <MenuItem key={'state-' + val} value={val}>
                  {val}
                </MenuItem>
              );
            })
          : null}
      </StyledSelect>
      <FormHelperText className={inpStyles.errorTxt}>&nbsp;</FormHelperText>
    </div>
  );
}
