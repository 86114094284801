/** @format */

import {makeStyles} from '@material-ui/core/styles';

export const Styles = makeStyles({
  bg: {
    backgroundImage: "url('/images/overnight.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backdropFilter: 'blur(17px)',
    WebkitBackdropFilter: 'blur(17px)',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  text: {
    position: 'relative',
    top: -60,
    display: 'flex',
    minHeight: 900,
    flexDirection: 'column',
    justifySelf: 'middle',
    fontSize: '5em',
    width: '100%',
    color: 'white',
    alignItems: 'center',
    '& h2': {
      fontSize: '3em',
    },
    '& h2 span': {
      coloe: '#1252af',
      background: '-webkit-linear-gradient(#1252af, #79a3c7)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      // textShadow: '-2px -2px #1252af'
    },
    '& p': {
      marginTop: 0,
    },
  },
});

export default Styles;
