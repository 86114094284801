/** @format */

import {makeStyles} from '@material-ui/styles';
const AvatarEditStyles = makeStyles(
  {
    btnUpload: {
      margin: '2rem 0',
    },
    cropWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '30rem',
      marginBottom: '2.5rem',
      position: 'relative',
      width: '30rem',
    },
    hiddenWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: 'min-content',
    },
    row: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '2rem',
    },
  },
  {name: 'avatar-editor'}
);
export default AvatarEditStyles;
