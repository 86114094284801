/** @format */

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const CheckoutButton = withStyles({
  root: {
    fontSize: '6rem',
    fontWeight: '600',
    padding: '1.5rem 0',
    width: '100%',
  },
  contained: {
    backgroundColor: '#f5be2e',
    boxShadow: 'none',
    color: '#000',
  },
  label: {
    lineHeight: 'normal',
  },
  outlined: {
    backgroundColor: '#000',
    color: '#f5be2e',
  },
})(Button);

export default CheckoutButton;
