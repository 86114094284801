/** @format */

import React, {useEffect, useState} from 'react';

import {useSnackbar} from 'notistack';
import * as StateData from 'datasets-us-states-names-abbr';
import {provinces} from 'canada';

import Button from '@material-ui/core/Button';
// Custom Component Imports
import ShippingInput, {StateSelect,CountrySelect} from './shippingInput';
import {isValidEmailEvent} from '../../../utils/inputValidation';
import SubmitButton from '../styled/submit-button';
import {Typography} from '@material-ui/core';
import {AddressFormStyles} from './styles';
const USStateAbbrs = Object.values(StateData);
const CAStateAbbrs = Object.keys(provinces);
let isCandaZip = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
let CAShip = true;
let POShip = true;
export default function AddressForm(props) {
  const {
    hasCancelBtn = false,
    onCancel = () => {},
    onSubmit = () => {},
    shippingInfo = null,
    submitBtnTitle = 'Save',
    title = 'Shipping Details',
  } = props;

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [customerInfo, setCustomerInfo] = useState({
    firstName: {value: '', isValid: false},
    lastName: {value: '', isValid: false},
    email: {value: '', isValid: false},
    phone: {value: '', isValid: false},
    address1: {value: '', isValid: false},
    address2: {value: '', isValid: true},
    city: {value: '', isValid: false},
    state: {value: '', isValid: false},
    zipCode: {value: '', isValid: false},
    country: {value: 'US', isValid: true},
  });
  const [fName, setfName] = useState(
    shippingInfo ? shippingInfo.name.split(' ')[0] : ''
  );
  const [lName, setlName] = useState(
    shippingInfo ? shippingInfo.name.split(' ')[1] : ''
  );
  const [email, setEmail] = useState(shippingInfo ? shippingInfo.email : '');
  const [phone, setPhone] = useState(shippingInfo ? shippingInfo.phone : '');
  const [address1, setAddress1] = useState(
    shippingInfo ? shippingInfo.address.line1 : ''
  );
  const [address2, setAddress2] = useState(
    shippingInfo ? shippingInfo.address.line2 : ''
  );
  const [city, setCity] = useState(
    shippingInfo ? shippingInfo.address.city : ''
  );
  const [country, setCountry] = useState(
    shippingInfo ? shippingInfo.address.country : 'US'
  );
  const [state, setState] = useState(
    shippingInfo && shippingInfo.address.state
      ? Object.keys(StateData).find(
          s => StateData[s] === shippingInfo.address.state.toUpperCase()
        )
      : ''
  );
  const [zipCode, setZipCode] = useState(
    shippingInfo ? shippingInfo.address.postal_code : ''
  );

  const [showErrs, setShowErrs] = useState(false);

  useEffect(()=>{
    if(country === 'CA' && CAShip === true) {
      CAShip = false;
      const action = key => (
          <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'2rem', marginRight:'2rem'}}>
              Dismiss
          </Button>
      );
      enqueueSnackbar('Shipping to Canada will not be next day delivery.  This will ship Fedex Ground and we calculate your shipping rate.  In addition your package may take an extra day to ship.', {variant: 'info', persist: true,action});
    }
    // eslint-disable-next-line
  },[country]);

  useEffect(()=>{
    let isPOBox = new RegExp(
      '\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b',
      'i'
    );
    if(POShip === true && (isPOBox.test(address1) || isPOBox.test(address2))) {
        // alert('this is a pobox');
        POShip = false;
        const action = key => (
              <Button onClick={() => { closeSnackbar(key) }} style={{color:'white', fontSize:'2rem', marginRight:'2rem'}}>
                  Dismiss
              </Button>
        );
        enqueueSnackbar('Shipping to a PO box will not be next day delivery.  This will ship Fedex Smart post and we won\'t have a delivery timeline for you.  In addition your package may take an extra day to ship.', {variant: 'info', persist: true,action});
    }
    // eslint-disable-next-line
  },[address1,address2]);

  useEffect(() => {
    if (!shippingInfo) {
      setfName('');
      setlName('');
      setEmail('');
      setPhone('');
      setAddress1('');
      setAddress2('');
      setCity('');
      setState('');
      setZipCode('');
      setCountry('US');
      setShowErrs(false);
    }
    // eslint-disable-next-line
  }, []);

  function handleSubmit() {
    setShowErrs(true);
    if (validateInfo()) {
      onSubmit({
        name: fName + ' ' + lName,
        firstName: fName,
        lastName: lName,
        email: email,
        phone: phone,
        address: {
          city: city,
          country: country,
          line1: address1,
          line2: address2,
          postal_code: zipCode,
          state: state,
        },
      });
    }
  }

  function validateInfo() {
    return (
      fName !== '' &&
      lName !== '' &&
      address1 !== '' &&
      city !== '' &&
      state !== '' &&
      zipCode !== '' &&
      (RegExp(/^[0-9]*$/).test(zipCode) || isCandaZip.test(zipCode))
    );
  }

  const isOnlyNumbers = text => {
    const numsOnly = /^[0-9]*$/g;
    return numsOnly.test(text);
  };

  const handleValidation = event => {
    const dataType = event.target.id.split('-')[1];
    const value = event.target.value;
    const trimVal = value.replace(/\s+/g, '');
    let valid = false;
    switch (dataType) {
      case 'email':
        valid = isValidEmailEvent(event);
        break;
      case 'firstName':
      case 'lastName':
        valid = trimVal.length >= 2;
        break;
      case 'zipCode':
        valid = (trimVal.length >= 5 && isOnlyNumbers(value)) || isCandaZip.test(trimVal);
        break;
      case 'note':
        valid = true;
        break;
      case 'phone':
        const numsOnly = value.replace(/[^\d+$]/g, '');
        valid = numsOnly.length === 10;
        break;
      case 'address2':
      case 'state':
      case 'country':
        valid = true;
        break;
      default:
        valid = value !== '';
        break;
    }
    const newCustomerInfo = customerInfo;
    newCustomerInfo[dataType].isValid = valid;
    setCustomerInfo(newCustomerInfo);
  };

  const pgStyles = AddressFormStyles();

  return (
    <form className={pgStyles.wrapper} onSubmit={onSubmit}>
      <Typography className={pgStyles.title} variant="h4">
        {title}{' '}
      </Typography>
      <ShippingInput
        errorText="This field is required"
        id="inp-firstName"
        isError={showErrs && fName === ''}
        label="First Name"
        value={fName}
        onChange={e => setfName(e.target.value)}
        onBlur={handleValidation}
        required
      />
      <ShippingInput
        required
        label="Last Name"
        id="inp-lastName"
        value={lName}
        onChange={e => setlName(e.target.value)}
        onBlur={handleValidation}
        isError={showErrs && lName === ''}
        errorText="This field is required"
      />
      <ShippingInput
        required
        label="Email Address"
        id="inp-email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        onBlur={handleValidation}
        isError={showErrs && email === ''}
        errorText="This field is required"
      />
      <ShippingInput
        required
        label="Phone Number"
        id="inp-phone"
        value={phone}
        onChange={e => setPhone(e.target.value)}
        onBlur={handleValidation}
        isError={showErrs && phone === ''}
      />
       <CountrySelect
        required
        label="Country"
        id="inp-country"
        value={country}
        passedOnChange={e => {
          setCountry(e.target.value);
        }}
        onBlur={handleValidation}
        isError={
          // Figure something out for this
          showErrs && country === ''
        }
      />
      <ShippingInput
        required
        label="Address Line 1"
        id="inp-address1"
        value={address1}
        onChange={e => setAddress1(e.target.value)}
        onBlur={handleValidation}
        isError={showErrs && address1 === ''}
        errorText="This field is required"
      />
      <ShippingInput
        label="Address Line 2"
        id="inp-address2"
        value={address2}
        onChange={e => setAddress2(e.target.value)}
      />
      <ShippingInput
        required
        label="City"
        id="inp-city"
        value={city}
        onChange={e => setCity(e.target.value)}
        onBlur={handleValidation}
        isError={
          // Figure something out for this
          showErrs && city === ''
        }
      />
      <StateSelect
        required
        label="State"
        id="inp-state"
        value={state}
        values={country==='CA'?CAStateAbbrs:USStateAbbrs}
        passedOnChange={e => {
          setState(e.target.value);
        }}
        onBlur={handleValidation}
        isError={
          // Figure something out for this
          showErrs && state === ''
        }
      />
      <ShippingInput
        required
        label="Zip Code"
        id="inp-zipCode"
        value={zipCode}
        onChange={e => setZipCode(e.target.value)}
        onBlur={handleValidation}
        isError={showErrs && zipCode === ''}
        errorText={
          zipCode === '' ? 'This field is required' : 'Invalid Zip Code'
        }
      />
      <SubmitButton
        className={pgStyles.submitBtn}
        disabled={!validateInfo()}
        onClick={e => handleSubmit()}
        variant="contained"
      >
        {submitBtnTitle}
      </SubmitButton>
      {hasCancelBtn && (
        <SubmitButton onClick={onCancel} variant="outlined">
          Cancel
        </SubmitButton>
      )}
    </form>
  );
}
