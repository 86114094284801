/** @format */

import React from 'react';
// Custom components
// Material UI
import {styled} from '@material-ui/core/styles';
//#region Styled Comps
const StyledDiv = styled('div')(
  {
    background: props => props.bgc ?? 'gainsboro',
    boxShadow: props => (props.shadow ? '0 14px 60px #ff9319' : ''),
    height: '50rem',
    flex: 'none',
    width: '100%',
    zIndex:0,
    position:'absolute',
    top:0
  },
  {name: 'separator'}
);
//#endregion

export default function Separator(props) {
  const {bgColor = 'gainsboro', shadow = false} = props;
  return (
    <StyledDiv bgc={bgColor} shadow={shadow ? 'true' : undefined}>
      &nbsp;
    </StyledDiv>
  );
}
