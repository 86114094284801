/** @format */

import React, {useState} from 'react';
// Material UI Components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// Custom imports
import TabStyles from './tabStyles';
import ItemTabPanel from './tabPanels';
import IndicatorTriangle from '../../../images/triangle-up.svg';

export default function ItemTabs(props) {
  const {
    titles = [],
    contents = [],
    curTab = 0,
    changeFunc = (event, newVal) => {},
  } = props;
  const pgStyles = TabStyles();
  // eslint-disable-next-line
  const [tabVal, setTabVal] = useState(titles[0]);

  return (
    <>
      <Divider
        variant="fullWidth"
        className={pgStyles.divider}
        id="scroll-div"
      />
      <Tabs
        value={curTab}
        onChange={changeFunc}
        variant="standard"
        className={pgStyles.tabHeaders}
        TabIndicatorProps={{
          // eslint-disable-next-line
          children: <img src={IndicatorTriangle} />,
          style: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            height: '1rem',
            '& img': {
              width: '2rem',
            },
          },
        }}
      >
        {titles.map((lbl, index) => {
          return (
            <Tab
              label={<Typography variant="h6">{lbl}</Typography>}
              key={lbl}
              className={pgStyles.tab}
              index={index}
              value={lbl}
              tabIndex={index}
              wrapped
              disableRipple
            />
          );
        })}
      </Tabs>
      <Divider variant="fullWidth" className={pgStyles.divider} />
      {contents.map((tabContent, i) => {
        return (
          <ItemTabPanel
            value={tabVal}
            index={titles[i]}
            children={tabContent}
            key={'item-tabs-' + titles[i]}
            className={pgStyles.tabContent}
          />
        );
      })}
    </>
  );
}
