/** @format */

import React, {useState} from 'react';
// Material UI Component Imports
import Button from '@material-ui/core/Button';
import Cart from '../../components/cart';
import Typography from '@material-ui/core/Typography';
// Custom Component Imports
import ShippingInput from '../shippingDetails/shipping-input';
// Style Imports
import OrderSummaryStyles from './styles';
import {useSnackbar} from 'notistack';
import {useEffect} from 'react';

const OrderSummary = props => {
  const pgStyles = OrderSummaryStyles();
  const [couponCode, setCouponCode] = useState(
    localStorage.getItem('coupon') || ''
  );
  const [couponData, setCouponData] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const applyCoupon = () => {
    // enqueueSnackbar('Not a valid coupon code',{ variant: 'error' })
    fetch(`/api/coupons/${couponCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async resp => {
        if (resp.ok !== true) {
          throw await resp.text();
        }
        return resp.json();
      })
      .then(result => {
        setCouponData(result);
        props.setCouponCode(couponCode);
      })
      .catch(e => {
        console.log(e);
        enqueueSnackbar(e, {variant: 'error'});
      });
  };

  useEffect(() => {
    if (couponCode) {
      applyCoupon();
    }
        // eslint-disable-next-line
  }, []);

  const handleEnter = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      applyCoupon();
      return false;
    }
  };

  return (
    <div className={pgStyles.wrapper}>
      <Typography variant="h4" className="coupon-header">
        Coupon Code
      </Typography>
      <section className={pgStyles.couponSect}>
        <ShippingInput
          addedClasses={pgStyles.inpCoupon}
          label="Have a coupon?"
          id="inp-coupon-code"
          value={couponCode}
          onKeyDown={handleEnter}
          onChange={e => setCouponCode(e.target.value)}
        />
        <Button className={pgStyles.applyCouponButton} onClick={applyCoupon}>
          Apply Coupon
        </Button>
      </section>
      <Cart title="Order Summary" couponData={couponData} {...props} />
    </div>
  );
};

export default OrderSummary;
