/** @format */

import React, {useEffect} from 'react';
// Custom components
import {ReactComponent as BackBtn} from './customBtnarrow.svg';
// Material UI
// Styles
import {DetailStyles} from './styles';

export default function StepsDetails(props) {
  const pgStyles = DetailStyles();
  const {selectedStep, stepObjs} = props;

  useEffect(() => {
    let stepObj = stepObjs.find(s => s.num === selectedStep);
    if (stepObj) {
      document.getElementById(stepObj.detailId).scrollIntoView(true);
    }
  }, [stepObjs, selectedStep]);

  const scrollUp = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <div className={pgStyles.wrapper}>
      <div className={pgStyles.backBtn}>
        <BackBtn style={{width: 40}} fill="black" onClick={scrollUp} />
      </div>
      {stepObjs.map(step => {
        return step.detailCard;
      })}
    </div>
  );
}
